/* eslint-disable react/jsx-fragments */
import React, { Fragment } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector, useDispatch } from "react-redux";

import {
  Table,
  TableScroll,
  CellHeader,
  Cell,
  TableFixed,
  CellHeaderDetails,
  CellDetails
} from "./style";
import ButtonDefault from "../../UI/ButtonDefault";
import { push } from "connected-react-router";

const formatDate = (iso = "") => {
  let date = (iso || "").split("T");
  date = date[0].split("-");
  return date[2] + "/" + date[1] + "/" + date[0];
};

function TableListEndorsement() {
  const dispatch = useDispatch();
  const allEndorsements = useSelector(
    state => state.endorsement.allEndorsements
  );

  return (
    <Table>
      <ScrollContainer>
        <TableScroll>
          <CellHeader paddingLeft="36px">Status</CellHeader>
          <CellHeader>Empresa</CellHeader>
          <CellHeader>CNPJ</CellHeader>
          <CellHeader>Data de solicitação</CellHeader>
          <CellHeader>Tomador</CellHeader>
          <CellHeader>Segurado</CellHeader>
          <CellHeader>Nº processo</CellHeader>
          <CellHeader>Início de vigência</CellHeader>
          <CellHeader>Final de vigência</CellHeader>
          <CellHeader>Modalidade</CellHeader>
          <CellHeader>Data de emissão</CellHeader>
          {}
          {(allEndorsements || []).map(endorsement => (
            <Fragment key={endorsement.id}>
              <Cell paddingLeft="36px">{endorsement.status}</Cell>
              <Cell width="200px">
                {endorsement.idApolice.process.idEmpresa.nome}
              </Cell>
              <Cell width="150px">
                {endorsement.idApolice.process.idEmpresa.cnpj}
              </Cell>
              <Cell>
                {new Date(endorsement.dataEndosso).toLocaleDateString()}
              </Cell>
              <Cell width="200px">{endorsement.idApolice.nomeTomador}</Cell>
              <Cell width="200px">{endorsement.idApolice.nomeSegurado}</Cell>
              <Cell width="150px">
                {endorsement.idApolice.process.idEmpresa.numeroProcesso}
              </Cell>
              <Cell>{formatDate(endorsement.idApolice.inicioVigencia)}</Cell>
              <Cell>{formatDate(endorsement.idApolice.finalVigencia)}</Cell>
              <Cell>{endorsement.tipoEndosso}</Cell>
              <Cell>{formatDate(endorsement.idApolice.dataEmissao)}</Cell>
            </Fragment>
          ))}
        </TableScroll>
      </ScrollContainer>
      <TableFixed>
        <CellHeaderDetails>Detalhes</CellHeaderDetails>
        {(allEndorsements || []).map(endorsement => (
          <CellDetails key={endorsement.id}>
            <ButtonDefault
              color="primary"
              customColor="#3498DB"
              colorLabel="#FFFFFF"
              size="small"
              fontSize="12px"
              onClick={() =>
                dispatch(push(`/documentos-emitidos/endosso/${endorsement.id}`))
              }
            >
              Ver mais
            </ButtonDefault>
          </CellDetails>
        ))}
      </TableFixed>
    </Table>
  );
}

export default TableListEndorsement;
