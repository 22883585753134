import React from 'react';
import PropTypes from 'prop-types';
import { DivCard, DivContentCenter, DivContentEnd } from './style';
import Select from '../../UI/Select/index';

function CardPoliciesInfo(props) {
  const {
    title,
    totalDocuments,
    totalGuaranteed,
    totalPrize,
    value,
    onSelectValue,
  } = props;

  const values = [
    {
      text: '120 dias',
      value: 120,
    },
    {
      text: '60 dias',
      value: 60,
    },
    {
      text: '30 dias',
      value: 30,
    },
    {
      text: '0 dias',
      value: 0,
    },
  ];

  return (
    <DivCard>
      <h1>{title}</h1>
      <Select
        label="Em:"
        valueOption={values}
        width="140px"
        valueSelect={value}
        onChange={onSelectValue}
      />
      <DivContentCenter>
        <h2>Documentos:</h2>
        <p>{totalDocuments ? totalDocuments : 0}</p>
      </DivContentCenter>
      <DivContentEnd>
        <div>
          <h2>Valor total garantido:</h2>
          <p>{`R$ ${totalGuaranteed ? totalGuaranteed : 0}`}</p>
        </div>
        <div>
          <h2>Prêmio total pago:</h2>
          <p>{`R$ ${totalPrize ? totalPrize : 0}`}</p>
        </div>
      </DivContentEnd>
    </DivCard>
  );
}

export default CardPoliciesInfo;

CardPoliciesInfo.propTypes = {
  title: PropTypes.string,
  totalDocuments: PropTypes.number,
  totalGuaranteed: PropTypes.number,
  totalPrize: PropTypes.number,
  onSelectValue: PropTypes.func,
};

CardPoliciesInfo.defaultProps = {
  title: '',
  totalDocuments: 0,
  totalGuaranteed: 0,
  totalPrize: 0,
  onSelectValue: () => {},
};
