import styled from "styled-components";

export const Card = styled.div`
  width: ${props => props.width};
  background: #ffffff;
  border-radius: 8px;
  height: 480px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.1);
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  background: #568ccc;
  border-radius: 8px 8px 0 0;
  padding: 17px 0;
  font-family: "Montserrat", "sans-serif";
  justify-content: center;

  h1 {
    font-family: "Montserrat", "sans-serif";
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
  }

  .ui.grid {
    width: 100%;
  }
`;

export const DivContent = styled.div`
  padding: 0px 24px 0px 24px;
`;

export const DivEmited = styled.div`
  display: flex;
  height: 136px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: "Montserrat", "sans-serif";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #616161;
    margin: 0;
  }
  p {
    font-family: "Montserrat", "sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #185f9b;
    margin: 0;
  }
`;

export const DivTotalValue = styled.div`
  height: 136px;
  width: 100%;
  border-bottom: ${props => props.border};
  display: flex;
  align-content: center;
  align-items: center;

  h2 {
    font-family: "Montserrat", "sans-serif";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #616161;
    margin: 0;
  }

  p {
    font-family: "Montserrat", "sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #568ccc;
    margin: 0;
  }
`;

export const DivLoadingContent = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 70%;
`;
