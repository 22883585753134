import React, { useEffect } from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { StyledSnackbar, DivContent, FailIcon, DivContentText } from "./style";
import { setSnackbarErrorOpen } from "../../../actions/snackbar";

function SnackbarError() {
  const open = useSelector(state => state.snackbar.openError);
  const content = useSelector(state => state.snackbar.errorContent);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setSnackbarErrorOpen(false));
  }

  useEffect(() => {
    function handleClose() {
      dispatch(setSnackbarErrorOpen(false));
    }

    if (open) {
      setTimeout(() => {
        handleClose();
      }, 6000);
    }
  }, [dispatch, open]);

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={6000}
    >
      <SnackbarContent
        message={
          // eslint-disable-next-line react/jsx-fragments, react/jsx-wrap-multilines
          <DivContent>
            <FailIcon style={{ width: "43px", height: "32px" }} />
            <DivContentText>
              <h1>{content.title}</h1>
              <p>{content.subtitle}</p>
            </DivContentText>
          </DivContent>
        }
        action={
          // eslint-disable-next-line react/jsx-fragments, react/jsx-wrap-multilines
          <IconButton>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        }
      />
    </StyledSnackbar>
  );
}

export default SnackbarError;
