/* eslint-disable react/prop-types */
import React from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import {useSelector} from "react-redux";
import {ModalBody, TitleContent, Content, Card} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import {Grid} from "semantic-ui-react";

function ModalActionWarranty(props) {
    const {onClose, title, types} = props;
    const open = useSelector(state => state.modal.ModalActionWarranty);

    return (
        <Modal open={open} onClose={onClose}>
            <ModalBody>
                <TitleContent>
                    <h1>{title}</h1>
                    <ButtonIcon onClick={onClose}>
                        <CloseIcon/>
                    </ButtonIcon>
                </TitleContent>
                <Content>
                    <Grid>
                        {types.map((type, i) => (
                            <Grid.Column width={5}> 
                                <Card>
                                    <Grid>
                                        <Grid.Row>
                                            <h1>{type.nome}</h1>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <h2>Valor total</h2>
                                                <h3>{type.valorCausa}</h3>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <h2>Quantidade</h2>
                                                <h4>{type.quantidade}</h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Card>
                            </Grid.Column>
                        ))}
                    </Grid>
                </Content>
            </ModalBody>
        </Modal>
    );
}

export default ModalActionWarranty;

ModalActionWarranty.propType = {
    onClose: PropType.func
};