import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {Checkbox,} from 'semantic-ui-react';
import {
  Button,
  Label,
  Background,
  SectionLeft,
  SectionRight,
} from './style';
import {
  decodeToken,
  getToken,
  login,
  TOKEN_KEY,
} from '../../actions/auth';
import { setIdCompany } from '../../actions/company';
import { startSocket } from '../../actions/socket';
import consetLogo from '../../assets/images/consetLogoBranco.png';
import facebook from '../../assets/images/FACEBOOK.png';
import linkedin from '../../assets/images/LINKEDIN.png';
import siteConset from '../../assets/images/SITE.png';
import instagram from '../../assets/images/INTAGRAM.png';
import smartProcess from '../../assets/images/smartprocess_sem_fundo.png';
import InputEmail from '../../components/UI/InputEmail';
import InputPassword from '../../components/UI/InputPassword';

function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [responseLogin, setResponseLogin] = useState('');

  const validaLogin = async () => {
    const response = await login({ email, senha });

    if (response) {
      localStorage.setItem(TOKEN_KEY, response.access_token);
      const user = decodeToken(getToken());
      startSocket();
      if (user.perfil === 'empresa') {
        dispatch(setIdCompany(user.idEmpresa));
        return dispatch(push('/dashboard'));
      }

      return dispatch(push('/adm/dashboard'));
    } else {
      setResponseLogin('EMAIL OU SENHA INCORRETOS');
    }
  };

  return (
    <Background>
      <SectionLeft>
        <div>
          <img
            alt="Conset Logo"
            src={consetLogo}
            className="consetLogo"
          />
        </div>
        <div className="divLinks">
          <a
            href="https://www.facebook.com/consetcorretoradeseguros/"
            target="_blank"
          >
            <img
              alt="Link Facebook Conset"
              src={facebook}
            />
          </a>
          <a
            href="https://www.instagram.com/consetcorretora/"
            target="_blank"
          >
            <img
              alt="Link Instagram Conset"
              src={instagram}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/conset-seguros/"
            target="_blank"
          >
            <img
              alt="Link LinkedIn Conset"
              src={linkedin}
            />
          </a>
          <a
            href="https://conset.com.br/"
            target="_blank"
          >
            <img
              alt="Link site Conset"
              src={siteConset}
            />
          </a>
        </div>
      </SectionLeft>
      <SectionRight>
        <img
          alt="Logo SmartProcess"
          src={smartProcess}
          className="logoSmartProcess"
        />
        <h1>Seguro Garantia Judicial</h1>
        <InputEmail
          label="Seu e-mail"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <InputPassword
          label="Sua senha"
          onChange={(e) => setSenha(e.target.value)}
          value={senha}
        />
        <Label>{responseLogin}</Label>
        <Label>
          <Checkbox style={{ margin: '25px 25px 25px 0px' }} />
          Lembre-se de mim
        </Label>
        <Button type="button" onClick={() => validaLogin()}>Entrar</Button>
        <Label justifyContent="center" onClick={() => dispatch(push('/esqueci-senha'))}>Esqueceu a senha?</Label>
      </SectionRight>
    </Background>
  );
}

export default Login;
