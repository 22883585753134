import styled from "styled-components";

export const DivMes = styled.div`
  background: #f8fafd;
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2e5aac;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  margin: 20px 0px;
`;

export const Content = styled.div`
  background: #fbfbfb;
  padding: 25px 12px;

  h1 {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
  }

  h2 {
    font-weight: 500;
    font-size: 12px;
    color: #9e9e9e;
    margin-bottom: 0px;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    color: #568ccc;
    margin-top: 10px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    color: #185f9b;
  }

  h5 {
    font-weight: 600;
    font-size: 18px;
    color: #00366c;
  }

  .arrow {
    background: #bdbdbd;
    min-width: 100px;
    height: 1px;
  }
`;

export const ModalBody = styled.div`
  background-color: #ffffff;
  width: 754px;
  height: 450px;
  overflow-y: scroll;
  margin: auto;
  padding: 40px;
  margin-top: 5%;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;
