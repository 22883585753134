export const initialState = {
  policyAnniversary: {},
  policyDueDate: {}
};

const deadline = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEADLINE':
      return { ...state, ...action.payload.deadline };
    default:
        return state;
  }
};

export default deadline;
