/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  BtnFinish,
  Row,
  Text
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { Form } from "semantic-ui-react";
import { editLeadProcess } from "../../../actions/pipeline";

function ModalProcessWarranty(props) {
  const { onClose, process } = props;
  const open = useSelector(state => state.modal.ModalProcessWarranty);
  const { idLead } = useParams();
  const dispatch = useDispatch();

  const [tipoGarantia, setTipoGarantia] = useState("");
  const pipe = useSelector(state => state.pipeline);

  const sendForm = () => {
    const data = {
      tipoGarantia
    };
    onClose();
    dispatch(editLeadProcess(process.id, data, idLead, pipe.processPage));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1> Tipo de Garantia </h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <Form onSubmit={() => sendForm()}>
            <Row>
              <Form.Input
                label="Definir tipo de garantia como"
                onChange={e => setTipoGarantia(e.target.value)}
              />
              <BtnFinish type="submit"> Aplicar </BtnFinish>
            </Row>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalProcessWarranty;

ModalProcessWarranty.propType = {
  onClose: PropType.func
};
