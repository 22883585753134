/* eslint-disable no-shadow */
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { BiDownload } from 'react-icons/bi';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import {
  Card, DivPdf, DivButton, DivLoading, DivError,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault/index';

function CardDownloadPDF(props) {
  const { pdf, title, loading } = props;
  const pageNumber = 1;

  function downloadFile() {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = `${title}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <Card>
      <h1>{title}</h1>
      {!loading ? (
        <>
          {pdf && (
            <>
              <DivPdf>
                <Document
                  file={`data:application/pdf;base64, ${pdf}`}
                  loading={<CircularProgress />}
                >
                  <Page pageNumber={pageNumber} width={208} />
                </Document>
              </DivPdf>
              <DivButton>
                <ButtonDefault
                  color="primary"
                  variant="outlined"
                  startIcon={<BiDownload style={{ color: '#F9B233' }} />}
                  onClick={downloadFile}
                >
                  Baixar
                </ButtonDefault>
              </DivButton>
            </>
          )}
          {!pdf && (
            <>
              <DivError>
                <h2>Documento não encontrado</h2>
              </DivError>
            </>
          )}
        </>
      ) : (
        <DivLoading>
          <CircularProgress />
        </DivLoading>
      )}
    </Card>
  );
}

export default CardDownloadPDF;

CardDownloadPDF.propTypes = {
  pdf: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

CardDownloadPDF.defaultProps = {
  pdf: '',
  title: '',
  loading: false,
};
