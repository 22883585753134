import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 400px;
  margin:  auto;
  padding: 40px;
  height: 424px;
  margin-top: 5%;
  overflow-y: scroll;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #FAFAFA;
  padding: 14px 24px;
  p{
    max-width: ${(props) => props.maxwidth || '222px'};
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #424242;
  }
`;

export const CloseButton = styled.button`
  display: ${(props) => props.display};
  font-family: Montserrat;
  font-size: 12px;
  padding: 0;
  border: none;
  border-radius: 50px;
  background-color: #FA110C;
  color: #FFFFFF;
  width: 16px;
  height: 16px;
  :hover {
    cursor: pointer;
  }
`;
