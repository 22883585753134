import styled from 'styled-components';

export const Card = styled.div`
  width: ${(props) => props.width};
  background: #FFFFFF;
  border-radius: 8px;
  height: 510px;
`;

export const CardHeader = styled.div`
  width: 100%;
  background: #568CCC;
  border-radius: 8px 8px 0 0;
  padding: 16px 0 16px 0;

  h1{
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
  }
`;

export const CardContentLoading = styled.div`
  display: flex;
  padding: 40px 24px;
  justify-content: center;
  align-content: center;
`;

export const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px 24px;

  h2{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.32px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #9E9E9E;
    width: 100%;
    margin: 0;
  }

  .smallBlue{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #185F9B;
  }

  .smallGreen{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #287D3C;
  }

  .mediumBlue{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #185F9B;
  }

  div{
    display: flex;
  }

  .divButton{
    width: 100%;
    margin-top: 56px;
  }
`;

export const CardContentError = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px 24px;
  width: 100%;
  justify-content: center;
  text-align: center;
  height: 420px;

  .errorTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #C95A5A;
  }

  .errorText{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #C95A5A;
  }
`;

export const FormOfPayment = styled.div`
  color: #000000;
  background: #F5F5F5;
  border-radius: 8px;
  padding: 10px 20px;
  margin-right: 16px;
  margin-top: 16px;
  text-transform: capitalize;
`;

export const ButtonSelect = styled.button`
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.25px;
  color: ${(props) => props.textcolor};
  background: ${(props) => props.color};
  border-radius: 2px;
  padding: 8px 45px;
  border: ${(props) => props.border || 'none'};
  margin-left: auto;
  margin-right: auto;
  :hover{
    cursor: pointer;
  }
`;
