export const initialState = {
  company:{
    tomadores: [],
  },
  companies: [],
  insurers: [],
  idCompany: {
    id: '',
    tomadores: [],
  },
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY':
      return { ...state, company: action.payload.company, insurers: action.payload.insurers };
    case 'SET_COMPANIES':
        return { ...state, companies: action.payload.companies };
    case 'SET_ID_COMPANY':
      return { ...state, idCompany: action.payload.idCompany };
    default:
        return state;
  }
};

export default company;
