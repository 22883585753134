import React, { useEffect, useState } from "react";

import { Content, Container, Navbar, Title, Button } from "./style";
import consetLogo from "../../assets/images/consetlogo.png";
import { Grid, TextField } from "@material-ui/core";
import { AiOutlineMail } from "react-icons/ai";
import { BiCheck } from "react-icons/bi";
import { GiPadlock } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { BsEyeSlashFill } from "react-icons/bs";
import { resetPassword, sendRecoverPasswordEmail } from "../../actions/auth";

function EsqueciSenha(props) {
  const dispatch = useDispatch();
  const token = props.location.search.split("=")[1];
  const [step, setStep] = useState("forget");

  useEffect(() => {
    if (token) setStep("redefine");
  }, [token]);

  function ForgetPassword() {
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [textErrorEmail, setTextErrorEmail] = useState("");

    const recoverPassword = async () => {
      const { success } = await sendRecoverPasswordEmail(email);

      switch (success) {
        case true:
          setStep("verify");
          break;
        case false:
          setTextErrorEmail("Email não existe");
          setErrorEmail(true);
          break;
        default: 
          setStep("forget")
      }
    };

    return (
      <Container>
        <Content>
          <Title>Esqueci minha senha</Title>
          <p>Insira o e-mail para recuperação de senha</p>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <AiOutlineMail size={20} color="#185F9B" />
            </Grid>
            <Grid item>
              <TextField
                error={errorEmail}
                helperText={textErrorEmail}
                label="Email"
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button onClick={() => recoverPassword()}>Enviar</Button>
        </Content>
      </Container>
    );
  }

  function VerifyMail() {
    return (
      <Container>
        <Content>
          <Title>Email enviado</Title>
          <BiCheck color="#287D3C" size={60} style={{ marginBottom: "20px" }} />
          <p style={{ marginBottom: "50px" }}>
            Verifique seu e-mail para alterar sua senha
          </p>
        </Content>
      </Container>
    );
  }

  function RedefinePassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [textErrorPassword, setTextErrorPassword] = useState("");
    const [typeFieldPassword, setTypeFieldPassword] = useState("password");
    const [typeFieldConfirmPassword, setTypeFieldConfirmPassword] = useState(
      "password"
    );

    const handleViewPassword = () => {
      if (typeFieldPassword === "password") return setTypeFieldPassword("text");

      return setTypeFieldPassword("password");
    };

    const handleViewConfirmPassword = () => {
      if (typeFieldConfirmPassword === "password")
        return setTypeFieldConfirmPassword("text");

      return setTypeFieldConfirmPassword("password");
    };

    const eventResetPass = () => {
      if (password !== confirmPassword) {
        setErrorPassword(true);
        return setTextErrorPassword("Senhas não coincidem");
      }

      dispatch(resetPassword(password, token));
    };

    return (
      <Container>
        <Content>
          <Title>Nova senha</Title>
          <p>Insira sua nova senha</p>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <GiPadlock size={20} color="#185F9B" />
            </Grid>
            <Grid item>
              <TextField
                type={typeFieldPassword}
                label="Senha"
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item>
              <BsEyeSlashFill
                size={20}
                color="#BDBDBD"
                onClick={() => handleViewPassword()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item className="gridfalse-xs-2">
              <GiPadlock size={20} color="#185F9B" />
            </Grid>
            <Grid item>
              <TextField
                type={typeFieldConfirmPassword}
                label="Confirmar senha"
                error={errorPassword}
                helperText={textErrorPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item>
              <BsEyeSlashFill
                size={20}
                color="#BDBDBD"
                onClick={() => handleViewConfirmPassword()}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <Button onClick={() => eventResetPass()}>confirmar</Button>
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  }

  return (
    <>
      <Navbar>
        <img src={consetLogo} alt="logo conset" width={87} />
      </Navbar>
      {step === "forget" ? <ForgetPassword /> : ""}
      {step === "verify" ? <VerifyMail /> : ""}
      {step === "redefine" ? <RedefinePassword /> : ""}
    </>
  );
}

export default EsqueciSenha;
