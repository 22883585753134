import React, { useState, useEffect } from 'react';
import { BiDownload } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/Navbar/index';
import { SectionTop, SectionTable, DivFilterTable } from './style';
import ButtonDefault from '../../components/UI/ButtonDefault/index';
import InputSearch from '../../components/UI/InputSearch/index';
import TableStatusProcess from '../../components/Table/TableStatusProcess/index';
import ModalNewProcess from '../../components/Modal/ModalNewProcess';
import { setModalNewProcess } from '../../actions/modal';
import {
  getProcess,
  setFilteredProcess,
  getExportProcess,
} from '../../actions/process';
import ModalFilterProcess from '../../components/Modal/ModalFilterProcess';
import { decodeToken, getToken } from '../../actions/auth';
import axios from '../../actions/api';

function AcompanhamentoProcessual() {
  const dispatch = useDispatch();
  const processes = useSelector((state) => state.process.processes);
  const user = decodeToken(getToken());
  const idEmpresa = (user.idEmpresa || {}).id;
  const [search, setSearch] = useState('');

  const handleOpenModalNewProcess = () => {
    dispatch(setModalNewProcess(true));
  };

  const handleCloseModalNewProcess = () => {
    dispatch(setModalNewProcess(false));
  };

  // Ao renderizar faz a requisição getProcess
  useEffect(() => {
    dispatch(getProcess({
      idEmpresa, sort: 'criado', page: 1, limit: 10,
    }));
  }, [dispatch, idEmpresa]);

  // Sempre que o estado processes mudar irá mudar o estado filteredProcess
  useEffect(() => {
    dispatch(setFilteredProcess(processes));
  }, [processes, dispatch]);

  // Esse UseEffect vai atualizar o estado global dos processos filtadros.
  // O Componente TableStatusProcess está usando esse estado para listar os processos.
  useEffect(() => {
    const filteredProcesses = processes.filter(
      (process) => process.numeroProcesso.indexOf(search) !== -1,
    );
    dispatch(setFilteredProcess(filteredProcesses));
  }, [search, dispatch, processes]);

  const updateSearch = (event) => {
    setSearch(event.target.value.substr(0, 20));
  };

  const modelImport = () => {
    axios({
      url: 'process/export-example',
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'processos-conset-exemplo.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <>
      <Navbar pageSelected="acompanhamento-processual" />
      <SectionTop>
        <div>
          <h1>Inserir novo processo</h1>
          <h2>Cadastre um novo processo para acompanhamento</h2>
        </div>
        <ButtonDefault
          size="large"
          borderRadius="15px"
          customColor="#1299F3"
          color="primary"
          disableElevation={false}
          onClick={() => handleOpenModalNewProcess()}
        >
          Novo
          {' '}
          <br />
          {' '}
          Processo
        </ButtonDefault>
      </SectionTop>
      <SectionTable>
        <DivFilterTable>
          <div>
            <InputSearch
              label="Buscar Processos"
              width="318px"
              value={search}
              onChange={updateSearch}
              placeholder="Número do processo"
            />
          </div>
          <div>
            <ButtonDefault
              variant="outlined"
              color="secondary"
              startIcon={<BiDownload color="#F9B233" />}
              height="40px"
              onClick={modelImport}
              margin="0px 15px"
            >
              Modelo de Importação
            </ButtonDefault>
            <ButtonDefault
              variant="outlined"
              color="secondary"
              startIcon={<BiDownload color="#F9B233" />}
              height="40px"
              onClick={() => getExportProcess({ idEmpresa: (user.idEmpresa || {}).id })}
            >
              Exportar
            </ButtonDefault>
          </div>
        </DivFilterTable>
        <TableStatusProcess />
      </SectionTable>
      <ModalNewProcess onClose={handleCloseModalNewProcess} />
      <ModalFilterProcess />
    </>
  );
}

export default AcompanhamentoProcessual;
