/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import PropType from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import { BiUpload } from "react-icons/bi";
import {
  ModalBody,
  TitleContent,
  Content,
  ButtonIndividual,
  FormProcessNumber,
  NameFile
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import ButtonDefault from "../../UI/ButtonDefault/index";
import {
  setModalNewMassiveProcess,
  setModalNewProcess
} from "../../../actions/modal";
import { upoladProcess } from "../../../actions/process";
import { listValues } from "../../../actions/bipbop-report";
import { formatToBRL } from "brazilian-values";

function ModalNewMassiveProcess(props) {
  const { onClose } = props;
  const open = useSelector(state => state.modal.ModalNewMassiveProcess);
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const bipbopValues = useSelector(state => state.bipbopReport.values);

  useEffect(() => {
    dispatch(listValues());
  }, []);

  const handleBackToModalNewProcess = () => {
    dispatch(setModalNewMassiveProcess(false));
    dispatch(setModalNewProcess(true));
  };

  function handleSelectFile(e) {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  }

  function handleDeleteSelectedFile(e) {
    e.preventDefault();
    setFile("");
  }

  function handleSubmitFile(e) {
    e.preventDefault();
    dispatch(upoladProcess(file));
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <div>
            <ButtonIcon onClick={handleBackToModalNewProcess}>
              <ArrowBackIosIcon />
            </ButtonIcon>
            <h1>Novo Processo</h1>
          </div>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <div>
            <h1>Escolha sua opção</h1>
            <h2>Cadastre um novo processo de forma individual ou massiva</h2>
          </div>
          <div>
            <ButtonIndividual>MASSIVA</ButtonIndividual>
          </div>
          <div>
            <h1>Processo</h1>
            <h2>Insira os arquivos com os números dos processos.</h2>
          </div>
          <FormProcessNumber onSubmit={handleSubmitFile}>
            <input
              id="contained-button-file"
              multiple
              type="file"
              onChange={e => handleSelectFile(e)}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                component="span"
                startIcon={<BiUpload />}
              >
                Anexar Arquivo
              </Button>
            </label>
            <NameFile>
              {file === "" && <p>Nenhum arquivo selecionado</p>}
              {file !== "" && <p>{file.name}</p>}
              {file !== "" && (
                <button type="button" onClick={handleDeleteSelectedFile}>
                  X
                </button>
              )}
            </NameFile>
            <div>
              <p>
                Esta busca está sujeita à taxa de
                {formatToBRL(
                  bipbopValues.acompanhamento.valor *
                    (bipbopValues.acrescimoAcompanhamento.valor / 100 + 1)
                )}
                /Mês
              </p>
              <ButtonDefault color="primary" type="submit">
                Buscar
              </ButtonDefault>
            </div>
          </FormProcessNumber>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalNewMassiveProcess;

ModalNewMassiveProcess.propType = {
  onClose: PropType.func
};
