import React from 'react';
import PropTypes from 'prop-types';
import StepperDefault from '../../UI/StepperDefault/index';

function StepperApolice(props) {
  const { className, activeStep } = props;
  const steps = ['Processo', 'Apólice', 'Finalização'];

  return <StepperDefault className={className} activeStep={activeStep} steps={steps} />;
}

export default StepperApolice;

StepperApolice.propTypes = {
  className: PropTypes.string,
  activeStep: PropTypes.number,
};

StepperApolice.defaultProps = {
  className: '',
  activeStep: 0,
};
