/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from "./api";

export const createMultipleCompanyUsers = newUsers => async dispatch => {
  try {
    await axios.post(`company-user/create-multiple`, newUsers);
  } catch (err) {
    console.log(err);
  }
};

export const setCompanyUsers = users => ({
  type: "SET_COMPANY_USERS",
  payload: {
    users
  }
});

export const findCompanyUsers = query => async dispatch => {
  try {
    const response = await axios.get(`company-user`, {
      params: query
    });
    dispatch(setCompanyUsers(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const createCompanyUser = (newUser) => async dispatch => {
  try {
    await axios.post(`company-user`, newUser);
    dispatch(findCompanyUsers({
      idEmpresa: newUser.idEmpresa
    }));
  } catch (err) {
    console.log(err);
  }
};

export const setCompanyUser = user => ({
  type: "SET_COMPANY_USER",
  payload: {
    user
  }
});

export const findOneCompanyUser = id => async dispatch => {
  try {
    const response = await axios.get(`company-user/list/${id}`);
    dispatch(setCompanyUser(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateCompanyUser = (id, newUser, idEmpresa) => async dispatch => {
  try {
    console.log('user for idEmpresa', idEmpresa);
    await axios.patch(`company-user/update/${id}`, newUser);
    dispatch(findCompanyUsers({
      idEmpresa
    }));
  } catch (err) {
    console.log(err);
  }
};

export const deleteOneCompanyUser = id => async dispatch => {
  try {
    await axios.delete(`company-user/delete/${id}`);
  } catch (err) {
    console.log(err);
  }
};
