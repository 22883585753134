import React, { useState, useEffect, memo } from 'react';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import LuxonUtils from '@date-io/luxon';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  ModalBody, TitleContent, FilterContent, Label,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault/index';
import { setModalFilterPolicy } from '../../../actions/modal';
import Select from '../../UI/Select/index';
import {
  getFilteredPolicies,
  getExportPolicies,
  setOnClickExportPolicies,
} from '../../../actions/policy';
import { decodeToken, getToken } from '../../../actions/auth';
import { useDebounce } from '../../../services/hooks';
import InputText from '../../UI/InputText/index';

function ModalFilterPolicy(props) {
  const open = useSelector((state) => state.modal.ModalFilterPolicy);
  const dispatch = useDispatch();
  const user = decodeToken(getToken());
  const searchPolicies = props.search;
  const { pageSelected } = props;

  const handleCloseModalFilterProcess = () => {
    dispatch(setModalFilterPolicy(false));
  };

  const [seguradoraSelecionada, setSeguradoraSelecionada] = useState('');
  const [statusSelecionado, setStatusSelecionado] = useState('');
  const [finalVigenciaDeSelecionada, setFinalVigenciaDeSelecionada] = useState(
    new Date(),
  );
  const [
    finalVigenciaAteSelecionada,
    setFinalVigenciaAteSelecionada,
  ] = useState(new Date());
  const [dataEmissaoDeSelecionada, setDataEmissaoDeSelecionada] = useState(
    new Date(),
  );
  const [dataEmissaoAteSelecionada, setDataEmissaoAteSelecionada] = useState(
    new Date(),
  );
  const exportPolicies = useSelector(
    (state) => state.policy.onClickExportPolicies,
  );
  const [segurado, setSegurado] = useState('');
  const debouncedSegurado = useDebounce(segurado);

  const seguradoras = [
    {
      value: '',
      text: '-',
    },
    {
      value: 'Junto',
      text: 'Junto',
    },
    {
      value: 'Tokyo',
      text: 'Tokio',
    },
    {
      value: 'BMG',
      text: 'BMG',
    },
    {
      value: 'Pottencial',
      text: 'Pottencial',
    },
  ];

  const status = [
    {
      value: '',
      text: '-',
    },
    {
      value: 'Emitida',
      text: 'Ativa',
    },

    {
      value: 'Vencida',
      text: 'Vencida',
    },
    {
      value: 'A substituir',
      text: 'Renovada',
    },
    {
      value: 'Baixada',
      text: 'Baixada',
    },
    {
      value: 'Cancelada',
      text: 'Cancelada',
    },
  ];

  const [query, setQuery] = useState({
    seguradora: '',
    finalVigenciaFrom: '',
    finalVigenciaTo: '',
    dataEmissaoFrom: '',
    dataEmissaoTo: '',
    status: '',
    idEmpresa: (user.idEmpresa || {}).id,
    segurado: '',
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    setQuery({ ...query, page: pageSelected + 1 });
  }, [pageSelected]);

  function handleChangeSeguradora(event) {
    setSeguradoraSelecionada(event.target.value);
    setQuery({ ...query, seguradora: event.target.value });
  }

  function handleChangeStatus(event) {
    setStatusSelecionado(event.target.value);
    setQuery({ ...query, status: event.target.value });
  }

  function convertDate(date) {
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date
        .getFullYear()
        .toString()
        .substr(2, 4);
      return `${day}/${month}/${year}`;
    }
    return '';
  }

  function handleChangeFinalVigenciaDe(date) {
    const dateFormated = convertDate(new Date(date));
    setFinalVigenciaDeSelecionada(new Date(date));
    setQuery({ ...query, finalVigenciaFrom: `${dateFormated}` });
  }

  function handleChangeFinalVigenciaAte(date) {
    const dateFormated = convertDate(new Date(date));
    setFinalVigenciaAteSelecionada(new Date(date));
    setQuery({ ...query, finalVigenciaTo: `${dateFormated}` });
  }

  function handleChangeDataEmissaoDe(date) {
    const dateFormated = convertDate(new Date(date));
    setDataEmissaoDeSelecionada(new Date(date));
    setQuery({ ...query, dataEmissaoFrom: `${dateFormated}` });
  }

  function handleChangeDataEmissaoAte(date) {
    const dateFormated = convertDate(new Date(date));
    setDataEmissaoAteSelecionada(new Date(date));
    setQuery({ ...query, dataEmissaoTo: `${dateFormated}` });
  }

  function clearQuery() {
    setQuery({
      seguradora: '',
      finalVigenciaFrom: '',
      finalVigenciaTo: '',
      dataEmissaoFrom: '',
      dataEmissaoTo: '',
      status: '',
      idEmpresa: (user.idEmpresa || {}).id,
    });
    setSeguradoraSelecionada('');
    setStatusSelecionado('');
    setFinalVigenciaDeSelecionada(new Date());
    setFinalVigenciaAteSelecionada(new Date());
    setDataEmissaoDeSelecionada(new Date());
    setDataEmissaoAteSelecionada(new Date());
  }

  useEffect(() => {
    dispatch(getFilteredPolicies({ ...query, search: searchPolicies }));
  }, [query, dispatch, searchPolicies]);

  useEffect(() => {
    if (exportPolicies === true) {
      getExportPolicies(query);
      dispatch(setOnClickExportPolicies(false));
    }
  }, [exportPolicies]);

  useEffect(() => {
    setQuery({ ...query, segurado: debouncedSegurado });
  }, [debouncedSegurado]);

  return (
    <Modal open={open} onClose={handleCloseModalFilterProcess}>
      <ModalBody>
        <TitleContent>
          <h1>Filtros</h1>
          <ButtonDefault variant="text" color="primary" onClick={clearQuery}>
            Limpar
          </ButtonDefault>
        </TitleContent>
        <FilterContent>
          <div className="select">
            <Select
              colorLabel="#185F9B"
              width="204px"
              label="Status"
              valueOption={status}
              valueSelect={statusSelecionado}
              onChange={handleChangeStatus}
            />
            <Select
              colorLabel="#185F9B"
              width="204px"
              label="Seguradora"
              valueOption={seguradoras}
              valueSelect={seguradoraSelecionada}
              onChange={handleChangeSeguradora}
            />
            <InputText
              colorLabel="#185F9B"
              width="204px"
              label="Segurado"
              value={segurado}
              onChange={(e) => setSegurado(e.target.value)}
            />
          </div>
          <div className="dateSelect">
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <div className="inputDate">
                <Label color="#185F9B">Fim de vigência De</Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  error={false}
                  id="date-picker-inline"
                  placeholder="DD/MM/YY"
                  value={new Date(finalVigenciaDeSelecionada)}
                  onChange={handleChangeFinalVigenciaDe}
                />
              </div>
              <div className="inputDate">
                <Label color="#185F9B">Fim de vigência Até</Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  error={false}
                  id="date-picker-inline"
                  placeholder="DD/MM/YY"
                  value={new Date(finalVigenciaAteSelecionada)}
                  onChange={handleChangeFinalVigenciaAte}
                />
              </div>
              <div className="inputDate">
                <Label color="#185F9B">Data de emissão De</Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YY"
                  margin="normal"
                  id="date-picker-inline"
                  error={false}
                  value={new Date(dataEmissaoDeSelecionada)}
                  onChange={handleChangeDataEmissaoDe}
                />
              </div>
              <div className="inputDate">
                <Label color="#185F9B">Data de emissão Até</Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YY"
                  margin="normal"
                  id="date-picker-inline"
                  error={false}
                  value={new Date(dataEmissaoAteSelecionada)}
                  onChange={handleChangeDataEmissaoAte}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
        </FilterContent>
      </ModalBody>
    </Modal>
  );
}

export default memo(ModalFilterPolicy);
