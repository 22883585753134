import styled from "styled-components";
import bg_login from "../../assets/images/bg_login.jpg";

export const Container = styled.div`
  background-image: url(${bg_login});
  background-size: cover;
  background-repeat: no-repeat;
  height: calc(100vh - 70px);
  width: 100vw;
`;

export const Content = styled.div`
  width: 50%;
  height: 100%;
  background: #f8fafd;
  margin-left: auto;
  box-shadow: inset -1px 10px 20px #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 14px;
  }

  .MuiGrid-container {
    width: auto;
  }
`;

export const Navbar = styled.div`
  height: 70px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0px 50px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #000000;
  margin-bottom: 15px;
`;

export const Button = styled.div`
  width: 152px;
  height: 40px;
  background: #f9b233;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;

  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
