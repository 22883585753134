import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

export const StyledCard = withStyles({
  root: {
    maxWidth: '319px',
    minHeight: '445px',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    borderRadius: '8px',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.1)',
  },
})(Card);

export const TextTotalValue = styled.p`
  font-family:'Montserrat', 'sans-serif';
  color: ${(props) => props.totalValueColor || '#3498DB'} ;
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.15px;
  display: inline;
  margin: 0 0 0 7px;
`;

export const Text = styled.p`
  font-family:'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: inline;
  color: #616161;
  margin: 0;
`;

export const StyledCardContent = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
})(CardContent);

export const DivChart = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 7.5% 0;
  flex-wrap: wrap;
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  left: 24px;
  top: 340px;
  background: ${(props) => props.circleColor || '#00684F'} ;
  border-radius: 4px;
  display: inline-block;
`;

export const TextLegend = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #424242;
  margin: 0 0 0 8px;
  font-family:'Montserrat', 'sans-serif';
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.16px;
  display: inline;
`;

export const TextValueLegend = styled.p`
  font-family:'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.textColor || '#00684F'};
  margin: 0;
`;

export const DivDataChart = styled.div`
  margin: 0 12px 12px 12px;
  justify-content: center;
  flex-wrap: wrap;
  width: 40%;
  word-break: keep-all;
  white-space: nowrap;
`;
