import IconButton from '@material-ui/core/IconButton';
import {
  withStyles
} from '@material-ui/core/styles';

export const StyledButton = withStyles({
  root: {
    backgroundColor: props => props.background || '#FFFFFF',
    borderRadius: '4px',
    width: (props) => props.width,
    height: (props) => props.width,
    padding: (props) => props.padding || '6px',
    border: (props) => props.border,
    margin: (props) => props.margin,
  },
})(IconButton);
