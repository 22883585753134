import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import PropType from 'prop-types';

import Modal from '@material-ui/core/Modal';
import {Form, Dropdown} from 'semantic-ui-react';

import {
    ModalBody,
    TitleContent,
    Content,
    BtnFinish,
    BtnCancel,
    Row
} from './style';

import {cnpjMask} from '../../../services/mask';
import {createLead, listLeadGroups} from '../../../actions/pipeline';
import {setModalNewLead} from '../../../actions/modal';

function ModalNewLead(props) {
    const dispatch = useDispatch()

    const {onClose} = props;
    const open = useSelector(state => state.modal.ModalNewLead)
    const groups = useSelector(state => state.pipeline.groups)

    const [nome,
        setNome] = useState('');
    const [razaoSocial,
        setRazaoSocial] = useState('');
    const [cnpj,
        setCnpj] = useState('');
    const [grupo,
        setGrupo] = useState('');
    const [novoGrupo,
        setNovoGrupo] = useState(false);

    const groupOptions = groups.map(group => {
        return {key: group.id, value: group.nome, text: group.nome}
    })

    const handleChangeGroup = (event, {value}) => {
        setGrupo(value)
    }

    useEffect(() => {
        dispatch(listLeadGroups())
    }, [dispatch])

    const sendForm = async() => {
        const lead = {
            nome,
            razaoSocial,
            cnpj,
            grupo,
            novoGrupo,
        }

        setCnpj('')

        await dispatch(createLead(lead))
        dispatch(setModalNewLead(false))
    }

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <ModalBody>
                    <TitleContent>
                        <h1>Novo lead</h1>
                    </TitleContent>
                    <Content>
                        <Form onSubmit={() => sendForm()}>
                            <Form.Group>
                                <Form.Input
                                    label="Nome da empresa"
                                    width={8}
                                    onChange={e => setNome(e.target.value)}
                                    required/>
                                <Form.Input
                                    label="Razão social"
                                    width={8}
                                    onChange={e => setRazaoSocial(e.target.value)}
                                    required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    label="CNPJ"
                                    width={8}
                                    value={cnpj}
                                    onChange={e => setCnpj(cnpjMask(e.target.value))}
                                    required/>
                                <div className="search-group field eight wide">
                                    <label>Grupo econômico</label>
                                    {novoGrupo
                                        ? <Form.Input onChange={e => setGrupo(e.target.value)}/>
                                        : <Dropdown
                                            placeholder="Grupo econômico"
                                            width={8}
                                            onChange={handleChangeGroup.bind(this)}
                                            search
                                            selection
                                            options={groupOptions}/>}
                                </div>

                            </Form.Group>
                            <Form.Checkbox
                                label="Novo grupo econômico"
                                width={8}
                                className="checkbox-modal"
                                onChange={() => setNovoGrupo(!novoGrupo)}/>

                            <Row>
                                <BtnCancel onClick={() => onClose()}>CANCELAR</BtnCancel>
                                <BtnFinish type="submit">FINALIZAR</BtnFinish>
                            </Row>
                        </Form>
                    </Content>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalNewLead;

ModalNewLead.propType = {
    onClose: PropType.func
};
