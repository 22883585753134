import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BiDownload } from 'react-icons/bi';
import { FaFilter } from 'react-icons/fa';
import { Tab } from 'semantic-ui-react';
import Pagination from '@material-ui/lab/Pagination';
import Navbar from '../../components/Navbar/index';
import TableListPolicy from '../../components/Table/TableListPolicy/index';
import TableListEndorsement from '../../components/Table/TableListEndorsement/index';
import CardPoliciesInfo from '../../components/Card/CardPoliciesInfo/index';
import ButtonIcon from '../../components/UI/ButtonIcon/index';
import {
  SectionCards, SectionTable, TabStyle, SectionTopTable,
} from './style';
import {
  getPoliciesValidity,
  getPoliciesBirthday,
  setOnClickExportPolicies,
} from '../../actions/policy';
import InputSearch from '../../components/UI/InputSearch/index';
import ModalFilterPolicy from '../../components/Modal/ModalFilterPolicy/index';
import {
  setModalFilterEndorsement,
  setModalFilterPolicy,
} from '../../actions/modal';
import { decodeToken, getToken } from '../../actions/auth';
import ButtonDefault from '../../components/UI/ButtonDefault/index';
import { useDebounce } from '../../services/hooks';
import ModalFilterEndorsement from '../../components/Modal/ModalFilterEndorsement';

function DocumentosEmitidos() {
  const dispatch = useDispatch();
  const user = decodeToken(getToken());

  const tabs = [
    {
      menuItem: 'DOCUMENTOS',
      render: () => <Documentos />,
    },
    {
      menuItem: 'ENDOSSOS',
      render: () => <Endossos />,
    },
  ];

  const handleOpenModalFilterEndorsement = () => {
    dispatch(setModalFilterEndorsement(true));
  };

  const handleCloseModalFilterEndorsement = () => {
    dispatch(setModalFilterEndorsement(false));
  };

  function Documentos() {
    const [searchPolicies, setSearchPolicies] = useState('');
    const debouncedSearchPolicies = useDebounce(searchPolicies);
    const policiesValidity = useSelector(
      (state) => state.policy.policiesValidity,
    );
    const policiesBirthday = useSelector(
      (state) => state.policy.policiesBirthday,
    );
    const [dayPoliciesBirthday, setDayPoliciesBirthday] = useState(120);
    const [dayPoliciesValidity, setDayPoliciesValidity] = useState(120);
    const tableInfo = useSelector((state) => state.policy.policiesTableInfo);
    const [page, setPage] = useState(1);

    useEffect(() => {
      dispatch(
        getPoliciesValidity({
          days: dayPoliciesValidity,
          idEmpresa: (user.idEmpresa || {}).id,
        }),
      );
      dispatch(
        getPoliciesBirthday({
          days: dayPoliciesBirthday,
          idEmpresa: (user.idEmpresa || {}).id,
        }),
      );
    }, [dayPoliciesValidity, dayPoliciesBirthday]);

    const handleChangePage = (event, value) => {
      setPage(value);
    };

    return (
      <>
        <SectionCards>
          <CardPoliciesInfo
            title="Documentos a vencer"
            onSelectValue={(e) => setDayPoliciesValidity(e.target.value)}
            value={dayPoliciesValidity}
            totalDocuments={policiesValidity.apolicesEmitidas}
            totalGuaranteed={policiesValidity.totalIS}
            totalPrize={policiesValidity.totalPremio}
          />
          <CardPoliciesInfo
            title="Aniversário das Apólices"
            value={dayPoliciesBirthday}
            onSelectValue={(e) => setDayPoliciesBirthday(e.target.value)}
            totalDocuments={policiesBirthday.apolicesEmitidas}
            totalGuaranteed={policiesBirthday.totalIS}
            totalPrize={policiesBirthday.totalPremio}
          />
        </SectionCards>
        <SectionTable>
          <SectionTopTable>
            <div>
              <InputSearch
                label="Buscar apólices"
                width="318px"
                value={searchPolicies}
                onChange={(e) => setSearchPolicies(e.target.value)}
              />
              <ButtonIcon
                margin="0 0 0 35px"
                onClick={() => dispatch(setModalFilterPolicy(true))}
              >
                <FaFilter color="#F9B233" />
              </ButtonIcon>
            </div>
            <ButtonDefault
              variant="outlined"
              color="secondary"
              startIcon={<BiDownload color="#F9B233" />}
              height="40px"
              onClick={() => dispatch(setOnClickExportPolicies(true))}
            >
              Exportar
            </ButtonDefault>
          </SectionTopTable>
          <TableListPolicy />
          <Pagination count={tableInfo.totalComFiltro} onChange={handleChangePage} />
        </SectionTable>
        <ModalFilterPolicy search={debouncedSearchPolicies} pageSelected={page} />
      </>
    );
  }

  function Endossos() {
    const [searchEndorsements, setSearchEndorsements] = useState('');
    const debouncedSearchEndorsements = useDebounce(searchEndorsements);

    return (
      <>
        <SectionTable>
          <InputSearch
            label="Buscar cliente"
            width="318px"
            value={searchEndorsements}
            onChange={(e) => setSearchEndorsements(e.target.value)}
          />
          <ButtonIcon
            margin="0 0 0 35px"
            onClick={() => handleOpenModalFilterEndorsement()}
          >
            <FaFilter color="#F9B233" />
          </ButtonIcon>
          <TableListEndorsement />
        </SectionTable>
        <ModalFilterEndorsement
          search={debouncedSearchEndorsements}
          onClose={handleCloseModalFilterEndorsement}
        />
      </>
    );
  }

  return (
    <>
      <Navbar pageSelected="documentos-emitidos" />
      {user.perfil === 'conset' ? (
        <TabStyle
          menu={{
            secondary: true,
            pointing: true,
          }}
          panes={tabs}
          as={Tab}
        />
      ) : (
        <Documentos />
      )}
    </>
  );
}

export default DocumentosEmitidos;
