import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #00366C;
    margin: 0;
    margin-right: 20px;
    white-space : nowrap;
  }

  .line {
    border-width: 0 1px 2px 1px;
    border-style: solid;
    border-color: #00366C;
    width: ${(props) => props.width};
  }
`;
