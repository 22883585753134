/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

function ChartHorizontalBar(props) {
  const {
    categories, data, colors, barHeight, dataType, width,
  } = props;

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      type: 'bar',
    },
    colors,
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight,
        endingShape: 'rounded',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories,
      labels: {
        show: true,
        hideOverlappingLabels: true,
      },
      max: Math.max.apply(null, data) * 1.2,
      axisBorder: {
        show: false,
      },
      position: 'top',
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  const series = [{
    name: dataType,
    data,
  }];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height={208}
      width={width}
    />
  );
}

export default memo(ChartHorizontalBar);

ChartHorizontalBar.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.array,
  colors: PropTypes.arrayOf(PropTypes.string),
  barHeight: PropTypes.string,
  dataType: PropTypes.string,
  width: PropTypes.string,
};

ChartHorizontalBar.defaultProps = {
  categories: ['Recursal', 'Execução Fiscal', 'Judicial'],
  data: [400000, 250000, 350000],
  dataType: '',
  colors: ['#00366C', '#185F9B', '#568CCC'],
  barHeight: '60%',
  width: '100%',
};
