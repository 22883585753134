export const initialState = {
  endorsement: {
    arquivoSolicitacao: [],
    arquivoDevolutiva: [],
    idApolice: {
      process:{
        idEmpresa: {}
      },
    }
  },
  allEndorsements: []
};

const endorsement = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ENDORSEMENT':
      return { ...state, endorsement: action.payload.endorsement };
    case 'SET_ALL_ENDORSEMENTS':
      return { ...state, allEndorsements: action.payload.allEndorsements };
    default:
        return state;
  }
};

export default endorsement;
