/* eslint-disable react/forbid-prop-types */
import React from 'react';
import loadable from 'loadable-components';
import PropTypes from 'prop-types';

const Chart = loadable(() => import('react-apexcharts'), { ssr: false });

function ChartColumn(props) {
  const {
    colors,
    columnWidth,
    endingShape,
    dataLabels,
    legend,
    dataType,
    categories,
    horizontalLabels,
    verticalLabels,
    data,
    height,
    width,
  } = props;

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      type: 'bar',
    },
    colors,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth,
        endingShape,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: dataLabels,
    },
    legend: {
      show: legend,
    },
    xaxis: {
      categories,
      labels: {
        show: horizontalLabels,
      },
    },
    yaxis: {
      labels: {
        show: verticalLabels || false,
      },
    },
  };

  const series = [{
    name: dataType,
    data,
  }];

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={height} width={width} />
    </div>
  );
}

export default ChartColumn;

ChartColumn.propTypes = {
  colors: PropTypes.array,
  columnWidth: PropTypes.string,
  endingShape: PropTypes.oneOf(['flat', 'rounded']),
  dataLabels: PropTypes.bool,
  legend: PropTypes.bool,
  dataType: PropTypes.string,
  categories: PropTypes.array,
  horizontalLabels: PropTypes.bool,
  verticalLabels: PropTypes.bool,
  data: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
};

ChartColumn.defaultProps = {
  colors: ['#00684F', '#9966FF', '#F99D1C', '#F26522'],
  columnWidth: '50%',
  endingShape: 'rounded',
  dataLabels: false,
  legend: false,
  dataType: null,
  categories: [],
  horizontalLabels: false,
  verticalLabels: false,
  data: [],
  height: '177px',
  width: '100%',
};
