import React from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardHeader,
  DivEmited,
  DivContent,
  DivTotalValue,
  DivLoadingContent
} from "./style";
import ButtonDefault from "../../UI/ButtonDefault/index";
import { FaFilter } from "react-icons/fa";
import { Grid } from "semantic-ui-react";

function CardInfoDashboard(props) {
  const dispatch = useDispatch();
  const { totalApolices, totalIS, totalPremio, openModal } = props;
  const loading = useSelector(state => state.loading.LoadingResumeDashboard);

  return (
    <Card width="290px">
      <CardHeader>
        <Grid>
          <Grid.Row>
            <Grid.Column width={11} textAlign="right">
              <h1>Apólices</h1>
            </Grid.Column>
            <Grid.Column width={5} textAlign="right">
              <FaFilter
                color="#fff"
                size={20}
                style={{ marginLeft: "auto" }}
                onClick={openModal}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CardHeader>
      {!loading ? (
        <DivContent>
          <DivEmited>
            <div>
              <h2>Emitidas</h2>
              <p>{totalApolices}</p>
            </div>
            <ButtonDefault
              colorLabel="#185F9B"
              variant="text"
              fontSize="12px"
              onClick={() => dispatch(push("/documentos-emitidos"))}
            >
              ver todas
            </ButtonDefault>
          </DivEmited>
          <DivTotalValue border="1px solid #EEEEEE">
            <div>
              <h2>Valor total garantido</h2>
              <p>{`R$ ${totalIS}`}</p>
            </div>
          </DivTotalValue>
          <DivTotalValue>
            <div>
              <h2>Valor total de prêmio pago</h2>
              <p>{`R$ ${totalPremio}`}</p>
            </div>
          </DivTotalValue>
        </DivContent>
      ) : (
        <DivLoadingContent>
          <CircularProgress />
        </DivLoadingContent>
      )}
    </Card>
  );
}

export default CardInfoDashboard;

CardInfoDashboard.propTypes = {
  totalApolices: PropTypes.number,
  totalIS: PropTypes.number,
  totalPremio: PropTypes.number
};

CardInfoDashboard.defaultProps = {
  totalApolices: 0,
  totalIS: 0,
  totalPremio: 0
};
