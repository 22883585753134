import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Form,
  Input,
  GridColumn,
  GridRow,
} from "semantic-ui-react";
import { AiFillCloseCircle } from "react-icons/ai";

import ScrollContainer from "react-indiana-drag-scroll";

import {
  ButtonInsurance,
  SectionTable,
  Cell,
  CellHeader,
  Table,
  TableScroll,
  DivTop,
  Search,
  DivContent,
} from "./style";

import history from "../../routes/history";

import { findAllInsurer, deleteOneInsurer } from "../../actions/insurer";

function Seguradoras() {
  const dispatch = useDispatch();
  const insurers = useSelector(state => state.insurer.insurers);

  const [searchInsurer, setSearchInsurer] = useState("");

  useEffect(() => {
    dispatch(findAllInsurer({ nome: searchInsurer }));
  }, [dispatch, searchInsurer]);
  const deleteInsurer = async insurer => {
    await dispatch(deleteOneInsurer(insurer.id));
    dispatch(findAllInsurer({ nome: searchInsurer }));
  };

  return (
    <div>
      <DivTop>
        <GridColumn>
          <h2>Nova seguradora</h2>
          <h4>Adicione uma nova seguradora</h4>
        </GridColumn>
        <Grid.Column textAlign="right">
          <ButtonInsurance onClick={() => history.push("/seguradora/nova")}>
            Nova seguradora
          </ButtonInsurance>
        </Grid.Column>
      </DivTop>
      <DivContent>
        <GridRow>
          <h2>Seguradoras cadastradas</h2>
        </GridRow>
        <GridRow>
          <Search>
            <Form.Field>
              <h3>Buscar seguradora</h3>
              <Input
                icon="search"
                placeholder="Buscar..."
                onChange={e => setSearchInsurer(e.target.value)}
              />
            </Form.Field>
          </Search>
        </GridRow>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="5">
                <CellHeader>NOME</CellHeader>
                <CellHeader>CNPJ</CellHeader>
                <CellHeader>RAZÃO SOCIAL</CellHeader>
                <CellHeader>E-MAIL</CellHeader>
                <CellHeader>OPÇÕES</CellHeader>

                {insurers.map((insurer, key) => (
                  <Fragment key={key}>
                    <Cell>{insurer.nome}</Cell>
                    <Cell>{insurer.cnpj}</Cell>
                    <Cell>{insurer.razaoSocial}</Cell>
                    <Cell>{insurer.email}</Cell>
                    <Cell>
                      <AiFillCloseCircle
                        className="icone-tabela"
                        size={27}
                        color="red"
                        onClick={() => deleteInsurer(insurer)}
                      />
                    </Cell>
                  </Fragment>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
      </DivContent>
    </div>
  );
}

export default Seguradoras;
