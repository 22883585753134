import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';

export const StyledInputBase = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
    borderRadius: '2px',
    paddingLeft: '16px',
    paddingRight: '16px',
    fontSize: '14px',
    width: (props) => props.width,
  },
})(InputBase);

export const IconSearch = withStyles({
  root: {
    cursor: 'pointer',
    color: '#F9B233',
  },
})(SearchIcon);

export const Label = styled.h1`
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #424242;
  margin: 0;
  margin-bottom: 4px;
`;
