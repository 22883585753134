import styled from 'styled-components';

export const HeaderPage = styled.div `
    font-weight: 500;
    font-size: 14px;
    color: #0C0142;
    display: flex;
    align-items: center;
`

export const Content = styled.div `
    padding: 0px 60px;
`

export const ButtonDefault = styled.button `
    width: 139px;
    height: 40px;
    background: #F9B233;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    margin-top: 40px;
`

export const Filter = styled.div `
    display: flex;
    margin-top: 30px;
    .MuiFormControl-root {
        min-width: 230px;
        margin: 0;
        margin-right: 24px;
        justify-content: flex-end;
      }

    .MuiInputLabel-formControl {
        text-align: start;
        font-size: 19px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: ${ (props) => props.color || '#424242'};
        margin-bottom: 4px;
    }
    .MuiInput-root {
        border: 1px solid #EEEEEE;
        box-sizing: border-box;
        border-radius: 2px;
        width: auto;
        height: 40px;
        padding: 10px 16px;
        font-size: 14px;
        background: #fff;
    }
    .MuiInput-underline:before{
        border: 0;
    }
    .MuiInputBase-root {
        height: 40px;
    }
    .MuiIconButton-root {
        color: #F9B233;
    }
`