import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

export const StyledCard = withStyles({
  root: {
    width: '319px',
    height: '445px',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    borderRadius: '8px',
    boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.1)',
  },
})(Card);

export const TextTotalValue = styled.p`
  font-weight: 600;
  font-size: 45px;
  text-align: center;
  color: #24126A;
`;

export const StyledCardContent = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: '400px',
    flexDirection: 'column',
  },
})(CardContent);

export const TextLegend = styled.p`
    font-weight: 500;
    font-size: 14px;
    color: #00366C;
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;  
`;

export const TextValueLegend = styled(TextLegend)`
  color: #287D3C;
`;
