import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 640px;
  padding-bottom: 50px;
  margin: auto;
  margin-top: 8%;
  overflow-y: scroll;
  max-height: 500px;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 0px;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0; 
  }
  div {
    display: flex;
  }
`;

export const Subtitle = styled(TitleContent)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #00366C;
  padding: 0px 0px 40px 0px;
`

export const Content = styled.div`
  margin: 0px 40px;

  label {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #9E9E9E;
    margin-bottom: 5px;
  }
  
  h4 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    color: #212121;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;
    color: #424242;
    margin-bottom: 0px;
  }

  h6 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #2E5AAC;
    margin-top: 3px;
    margin-bottom: 20px;
  }

  .ui.grid {
    margin: 0!important;
  }
`;

export const BtnFinish = styled.button `
  font-family: Montserrat;
  border: none;
  background: #F9B233;
  color:white;
  font-weight: 500;
  border-radius:4px;
  text-transform: uppercase;
  font-size: 12px;
  margin: 30px 0px 0px auto;
  padding: 13px 20px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export const BtnBorder = styled(BtnFinish)`
  border: ${props => props.border || '1px solid #185F9B'};
  background: #fff;
  color: #185F9B;
  margin: 30px 20px 0px 0px;
`

export const InputFile = styled.div`
  border: 1px solid #185F9B!important;
  border-radius: 4px!important;
  font-family: Montserrat!important;
  font-weight: bold;
  font-size: 12px;
  color: #185F9B!important;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-bottom: 15px;
`

export const ItemFile = styled.div`
  display: flex;
  justify-content: space-between;  
  padding: 8px;
  border: 1px solid #EEEEEE;
  margin-bottom: 5px;
`

export const Row = styled.div `
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`