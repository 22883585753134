import styled from 'styled-components';

export const SectionTop = styled.div`
  display: flex;
  margin: 0 48px;
  margin-top: -38px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 42px;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #616161;
    margin: 0;
  }
`;

export const Form = styled.form`

  display: flex;
  margin: 90px 276px 276px 276px;
  flex-wrap: wrap;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #24126A;
    margin: 0;
    width: 27%;
  }
`;

export const Line = styled.span`
width: 73%;
border: 1px solid #24126A;
height: 0;
`;

export const DivTop = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DivForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 342px 315px 1fr;
  grid-template-rows: repeat(16, 96px);
  align-items: end;

  h2{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #24126A;
  }

  h3{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: $424242;
  }

  p{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #616161;
  }

  h4{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #00235C;
  }

  .firstInput {
    grid-column: 1;
    grid-row: 1;
  }

  .secondInput {
    grid-column: 1/4;
    grid-row: 2;
  }

  .thirdInput {
    grid-column: 1;
    grid-row: 3;
  }

  .fourthInput {
    grid-column: 1;
    grid-row: 4;
  }

  .fifthInput {
    grid-column: 1;
    grid-row: 5;
  }

  .sixthInput {
    grid-column: 2;
    grid-row: 5;
  }

  .seventhInput {
    grid-column: 1;
    grid-row: 6;
  }

  .eighthInput {
    grid-column: 2;
    grid-row: 6;
  }

  .ninthInput {
    grid-column: 1;
    grid-row: 7;
  }

  .tenthInput {
    grid-column: 1;
    grid-row: 8;
  }

  .eleventhInput {
    grid-column: 2;
    grid-row: 8;
  }

  .twelfthInput {
    grid-column: 1;
    grid-row: 9;
  }

  .thirteenthInput {
    grid-column: 2;
    grid-row: 9;
  }

  .fourteenthInput {
    grid-column: 1;
    grid-row: 10;
  }

  .fifteenthInput {
    grid-column: 2;
    grid-row: 10;
  }

  .sixteenthInput {
    grid-column: 1;
    grid-row: 11;
  }

  .seventeenthInput {
    grid-column: 1;
    grid-row: 12;
  }

  .eighteenthInput {
    grid-column: 1/4;
    grid-row: 14;
  }

  .nineteenthInput {
    grid-column: 1/3;
    grid-row: 15;
    margin-top: -40px;
  }

  .twentiethInput {
    grid-column: 2;
    grid-row: 16;
    width: 178px;
    justify-self: flex-end;
    margin-right: 24px;
  }

  .twenty-first {
    grid-column: 3;
    grid-row: 16;
    width: 178px;
    justify-self: flex-end;
  }
`;

// width: 178px;
// justify-self: end;
