import styled from 'styled-components';

export const Card = styled.div`
  width: 240px;
  min-height: 405px;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1{
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #185F9B;
    width: 100%;
    padding: 16px;
    margin: 0;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 16px;
`;

export const DivLoading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const DivPdf = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const DivError = styled.div`
  display: flex;
  height: 294px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
  h2{
    color: #C95A5A;
    font-size: 13px;
  }
`;
