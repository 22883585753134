/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  BtnFinish,
  Row,
  Text
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { Form } from "semantic-ui-react";
import { editLeadProcess } from "../../../actions/pipeline";
import { formatToNumber, moneyMask } from "../../../services/mask";
import { formatToBRL } from "brazilian-values";
import { useParams } from "react-router-dom";

function ModalEditCauseValue(props) {
  const { onClose } = props;

  const open = useSelector(state => state.modal.ModalEditCauseValue);
  const process = useSelector(state => state.pipeline.process);
  const { idLead } = useParams();
  const dispatch = useDispatch();

  const [valorCausa, setValorCausa] = useState("");

  const processPage = useSelector(state => state.pipeline.processPage);

  useEffect(() => {
    setValorCausa(formatToBRL(process.valorCausa));
  }, [process]);

  const handleEditCauseValue = event => {
    const value = formatToNumber(moneyMask(event.target.value));
    setValorCausa(formatToBRL(value));
  };

  const sendForm = () => {
    const data = {
      valorCausa: formatToNumber(valorCausa)
    };
    onClose();
    dispatch(editLeadProcess(process.id, data, idLead, processPage));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Valor da causa</h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <Form onSubmit={() => sendForm()}>
            <Row>
              <Form.Input value={valorCausa} onChange={handleEditCauseValue} />
            </Row>
            <Row>
              <BtnFinish type="submit">Salvar</BtnFinish>
            </Row>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalEditCauseValue;

ModalEditCauseValue.propType = {
  onClose: PropType.func
};
