import styled from 'styled-components';

export const BackgroundDiv = styled.div`
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
`;

export const SectionTable = styled.div`
  margin: 24px 48px 80px 48px;
`;

export const SectionTitle = styled.div`
  margin: 0 48px 0 48px;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #000000;
    text-transform: uppercase;
    margin: 0;
  }
`;
