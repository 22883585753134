/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  ItemFile,
  BtnBorder,
  Subtitle,
  BtnFinish,
  Row
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { decodeToken, getToken } from "../../../actions/auth";
import { FiDownload } from "react-icons/fi";
import { BsChevronLeft } from "react-icons/bs";
import { Dropdown } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { respondSolicitation } from "../../../actions/endorsement";
import { setModalEndorsementProblem } from "../../../actions/modal";
import ModalEndorsementProblem from "../ModalEndorsementProblem";
import InputText from "../../UI/InputText";

function ModalSolicitationEndorsement(props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const open = useSelector(state => state.modal.ModalSolicitation);
  const user = decodeToken(getToken());
  const { idEndorsement } = useParams();

  const endorsement = useSelector(state => state.endorsement.endorsement);

  const [step, setStep] = useState(1);
  const [tipoEndosso, setTipoEndosso] = useState("");

  const endorsementTypes = [
    {
      key: "Endosso para correção de objeto",
      value: "Endosso para correção de objeto",
      text: "Endosso para correção de objeto"
    },
    {
      key: "Endosso de baixa",
      value: "Endosso de baixa",
      text: "Endosso de baixa"
    },
    {
      key: "Endosso de cancelamento",
      value: "Endosso de cancelamento",
      text: "Endosso de cancelamento"
    },
    {
      key: "Endosso de prorrogação de prazo",
      value: "Endosso de prorrogação de prazo",
      text: "Endosso de prorrogação de prazo"
    },
    {
      key:
        "Endosso de prorrogação de prazo com aumento de importância segurada",
      value:
        "Endosso de prorrogação de prazo com aumento de importância segurada",
      text:
        "Endosso de prorrogação de prazo com aumento de importância segurada"
    },
    {
      key: "Endosso de aumento de importância segurada",
      value: "Endosso de aumento de importância segurada",
      text: "Endosso de aumento de importância segurada"
    },
    {
      key: "Endosso de redução de importância segurada",
      value: "Endosso de redução de importância segurada",
      text: "Endosso de redução de importância segurada"
    },
    {
      key:
        "Endosso de prorrogação de prazo com redução de importância segurada",
      value:
        "Endosso de prorrogação de prazo com redução de importância segurada",
      text:
        "Endosso de prorrogação de prazo com redução de importância segurada"
    }
  ];

  const handleOpenModalEndorsementProblem = () => {
    onClose();
    dispatch(setModalEndorsementProblem(true));
  };

  const handleCloseModalEndorsementProblem = () => {
    dispatch(setModalEndorsementProblem(false));
  };

  const eventResponseSolicitation = () => {
    const data = {
      tipoEndosso,
      idEndosso: idEndorsement,
      idUser: user.id
    };
    dispatch(respondSolicitation(data));
    onClose();
  };

  const download = (file, filename) => {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + file;
    link.download = filename;
    link.click();
  };

  function FirstStep() {
    return (
      <ModalBody>
        <TitleContent>
          <h1>Detalhes da solicitação</h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <Subtitle>Nº {endorsement.id}</Subtitle>
          {user.perfil === "conset" ? (
            <div>
              <h3>{endorsement.idApolice.process.idEmpresa.nome}</h3>
              <h6>{endorsement.idApolice.process.idEmpresa.email}</h6>
            </div>
          ) : (
            ""
          )}
          <label>Descrição do chamado:</label>
          <h4>{endorsement.descricaoSolicitacao}</h4>
          <label>Arquivos:</label>
          {(endorsement.arquivoSolicitacao || []).map(file => (
            <ItemFile>
              <h4>{file.filename}</h4>
              <FiDownload
                color="#F9B233"
                size={20}
                onClick={() => download(file.path, file.filename)}
              />
            </ItemFile>
          ))}
          <div style={{ marginTop: "15px" }}>
            {endorsement.tipoEndosso ? (
              <>
                <InputText
                  label="Tipo de endosso"
                  value={endorsement.tipoEndosso}
                  disabled
                />
                <h4 style={{ marginTop: "20px" }}>Devolutiva</h4>
                <InputText
                  label="Status da apólice"
                  value={endorsement.statusApolice}
                  disabled
                />
                <label>Descrição devolutiva:</label>
                <h4>{endorsement.descricaoDevolutiva}</h4>
                <label>Arquivos:</label>

                {(endorsement.arquivoDevolutiva || []).map(file => (
                  <ItemFile>
                    <h4>{file.filename}</h4>
                    <FiDownload
                      color="#F9B233"
                      size={20}
                      onClick={() => download(file.path, file.filename)}
                    />
                  </ItemFile>
                ))}
              </>
            ) : (
              ""
            )}
            {endorsement.descricaoProblema ? (
              <>
                <h3
                  style={{
                    marginBottom: "20px"
                  }}
                >
                  Resposta
                </h3>
                <label>Descrição do problema:</label>
                <h4>{endorsement.descricaoProblema}</h4>
                <label>Arquivos:</label>
                {(endorsement.arquivoProblema || []).map(file => (
                  <ItemFile>
                    <h4>{file.filename}</h4>
                    <FiDownload
                      color="#F9B233"
                      size={20}
                      onClick={() => download(file.path, file.filename)}
                    />
                  </ItemFile>
                ))}
              </>
            ) : (
              ""
            )}
            {endorsement.idUser ? (
              <>
                <label>Por</label>
                <h4>{(endorsement.idUser || {}).nome}</h4>
              </>
            ) : (
              ""
            )}
          </div>

          {user.perfil === "conset" &&
          endorsement.status === "SOLICITAÇÃO ENVIADA" ? (
            <Row>
              <BtnBorder
                border="none"
                onClick={() => handleOpenModalEndorsementProblem()}
                style={{
                  paddingLeft: "0px"
                }}
              >
                Relatar problema
              </BtnBorder>
              <BtnFinish onClick={() => setStep(2)}>Avançar etapa</BtnFinish>
            </Row>
          ) : (
            ""
          )}
        </Content>
      </ModalBody>
    );
  }

  function SecondStep() {
    return (
      <ModalBody>
        <TitleContent>
          <div>
            <BsChevronLeft
              size={20}
              color="#185F9B"
              onClick={() => setStep(1)}
              style={{
                marginRight: "20px"
              }}
            />
            <h1>Endosso</h1>
            <Subtitle>Nº {endorsement.id}</Subtitle>
          </div>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <h3>{endorsement.idApolice.process.idEmpresa.nome}</h3>
          <h6>{endorsement.idApolice.process.idEmpresa.email}</h6>
          <label>Selecione o tipo de endosso para essa solicitação</label>
          <h4
            style={{
              marginBottom: "5px"
            }}
          >
            Tipo de endosso
          </h4>
          <Dropdown
            placeholder="Selecione"
            fluid
            search
            selection
            options={endorsementTypes}
            value={tipoEndosso}
            onChange={(e, { value }) => setTipoEndosso(value)}
          />
          <Row>
            <BtnFinish onClick={() => eventResponseSolicitation()}>
              Aplicar
            </BtnFinish>
          </Row>
        </Content>
      </ModalBody>
    );
  }

  return (
    <>
      {" "}
      <Modal open={open} onClose={onClose}>
        {step === 1 ? <FirstStep /> : <SecondStep />}
      </Modal>{" "}
      <ModalEndorsementProblem onClose={handleCloseModalEndorsementProblem} />{" "}
    </>
  );
}

export default ModalSolicitationEndorsement;

ModalSolicitationEndorsement.propType = {
  onClose: PropType.func,
  action: PropType.string
};
