/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  StyledButton
} from "./style";

function ButtonIcon(props) {
  const {
    children,
    onClick,
    margin,
    color,
    variant,
    border,
    padding,
    width,
    height,
    background
  } = props;

  return ( <
    StyledButton onClick = {
      onClick
    }
    margin = {
      margin
    }
    color = {
      color
    }
    variant = {
      variant
    }
    border = {
      border
    }
    width = {
      width
    }
    height = {
      height
    }
    padding = {
      padding
    }
    background = {
      background
    } > {
      children
    } <
    /StyledButton>
  );
}

export default ButtonIcon;

ButtonIcon.propType = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  border: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

ButtonIcon.defaultProps = {
  margin: "0",
  color: "",
  variant: "",
  border: "",
  width: "30px",
  height: "30px"
};
