/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table, TableScroll, CellHeader, CellStatus, Cell, HelpButton, DivTableArea,
} from './style';
import DialogNewProcessMassive from '../../Dialog/DialogNewProcessMassive/index';
import { setDialogNewMassiveProcess } from '../../../actions/dialog';
import ButtonDefault from '../../UI/ButtonDefault/index';
import { postMultipleProcess } from '../../../actions/process';
import { decodeToken, getToken } from '../../../actions/auth';

function TableNewMassiveProcess() {
  const processes = useSelector((state) => state.process.massiveProcesses);
  const idEmpresa = useSelector((state) => state.company.idCompany);
  const user = decodeToken(getToken())
  const dispatch = useDispatch();
  const newProcesses = [...processes];

  function handleOpenDialog() {
    dispatch(setDialogNewMassiveProcess(true));
  }

  function handleChangeChecked(event) {
    newProcesses[event.target.value].acompanhamentoBipbop = event.target.checked;
  }

  function handleSubmitProcesses() {
    const processes = newProcesses
    processes.map(process => process.idEmpresa = idEmpresa.id || user.idEmpresa.id)
    dispatch(postMultipleProcess(processes));
  }

  return (
    <DivTableArea>
      <Table>
        <ScrollContainer>
          <TableScroll>
            <CellHeader>
              Acompanhamento automático
              <HelpButton
                variant="contained"
                disableElevation
                color="secondary"
                onClick={handleOpenDialog}
              >
                ?
              </HelpButton>
            </CellHeader>
            <CellHeader>Número do Processo</CellHeader>
            <CellHeader>Nome do Reclamante</CellHeader>
            <CellHeader>Tipo Pessoa</CellHeader>
            <CellHeader>CPF/CNPJ</CellHeader>
            <CellHeader>CEP</CellHeader>
            <CellHeader>Cidade</CellHeader>
            <CellHeader>Estado</CellHeader>
            <CellHeader>Bairro</CellHeader>
            <CellHeader>Logradouro</CellHeader>
            <CellHeader>Número</CellHeader>
            <CellHeader>Complemento</CellHeader>
            <CellHeader>Telefone</CellHeader>
            <CellHeader>Vara</CellHeader>
            <CellHeader>Tribunal</CellHeader>
            { newProcesses.map((process, index) => (
              <Fragment key={index}>
                <CellStatus>
                  <Checkbox
                    value={index}
                    onChange={handleChangeChecked}
                  />
                </CellStatus>
                <Cell>{!process.numeroProcesso ? '-' : process.numeroProcesso}</Cell>
                <Cell>{!process.nomeReclamante ? '-' : process.nomeReclamante}</Cell>
                <Cell>{!process.tipoPessoaReclamante ? '-' : process.tipoPessoaReclamante}</Cell>
                <Cell>{!process.cpfReclamante ? '-' : process.cpfReclamante}</Cell>
                <Cell>{!process.cepReclamante ? '-' : process.cepReclamante}</Cell>
                <Cell>{!process.cidadeReclamante ? '-' : process.cidadeReclamante}</Cell>
                <Cell>{!process.ufReclamante ? '-' : process.ufReclamante}</Cell>
                <Cell>{!process.bairroReclamante ? '-' : process.bairroReclamante}</Cell>
                <Cell>{!process.logradouroReclamante ? '-' : process.logradouroReclamante}</Cell>
                <Cell>{!process.numeroReclamante ? '-' : process.numeroReclamante}</Cell>
                <Cell>{!process.complementoReclamante ? '-' : process.complementoReclamante}</Cell>
                <Cell>{!process.telefoneReclamante ? '-' : process.telefoneReclamante}</Cell>
                <Cell>{!process.vara ? '-' : process.vara}</Cell>
                <Cell>{!process.tribunal ? '-' : process.tribunal}</Cell>
              </Fragment>
            ))}
          </TableScroll>
        </ScrollContainer>
        <DialogNewProcessMassive />
      </Table>
      <ButtonDefault
        className="buttonSend"
        color="secondary"
        size="large"
        type="button"
        onClick={handleSubmitProcesses}
      >
        Finalizar
      </ButtonDefault>
    </DivTableArea>
  );
}

export default TableNewMassiveProcess;
