import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import { ModalBody, TitleContent, Content, ButtonSend } from "./style";

import { useDispatch, useSelector } from "react-redux";
import { decodeToken, getToken } from "../../../actions/auth";
import { updateConsetUser } from "../../../actions/consetUser";
import { Form } from "semantic-ui-react";

function ModalEditPasswordConset(props) {
  const dispatch = useDispatch();
  const user = decodeToken(getToken());

  const open = useSelector(state => state.modal.ModalEditPasswordConset);
  const { onClose } = props;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(null);

  const changePassword = () => {
    if (password !== confirmPassword) {
      return setErrorPassword("Senhas não coincidem");
    }

    const data = {
      senha: password
    };

    dispatch(updateConsetUser(user.id, data));
    onClose()
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Minha conta</h1>
          <VscClose color="#616161" size={20} onClick={onClose} />
        </TitleContent>
        <Content>
          <Form>
            <Form.Group>
              <Form.Input readOnly label="Nome" value={user.nome} width={8} />
              <Form.Input label="Email" value={user.email} width={8} readOnly />
            </Form.Group>{" "}
            <Form.Group>
              <Form.Input
                readOnly
                label="Telefone"
                value={user.telefone}
                width={8}
              />
              <Form.Input
                label="Permissoes"
                value={user.permissoes}
                width={8}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                type="password"
                label="Senha"
                onChange={e => setPassword(e.target.value)}
                width={8}
              />
              <Form.Input
                type="password"
                label="Confirmar senha"
                onChange={e => setConfirmPassword(e.target.value)}
                width={8}
                error={errorPassword}
              />
            </Form.Group>
            <ButtonSend onClick={() => changePassword()}>Salvar</ButtonSend>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalEditPasswordConset;
