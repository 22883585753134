/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Input, Label, StyledDiv } from "./style";

function InputText(props) {
  const {
    label,
    disabled,
    value,
    width,
    id,
    className,
    onChange,
    type,
    name,
    onBlur,
    isRequired,
    error,
    placeholder,
    onKeyPress
  } = props;

  return (
    <StyledDiv id={id} className={className}>
      {label && (
        <Label color={error ? "#C95A5A" : "#424242"}>
          {label}
          {isRequired && <p>*</p>}
        </Label>
      )}
      {disabled ? (
        <Input disabled type={type} value={value} width={width} name={name} />
      ) : (
        <Input
          type={type}
          value={value}
          onChange={e => onChange(e)}
          width={width}
          name={name}
          onBlur={onBlur}
          border={error ? "#C95A5A" : "#EEEEEE"}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
        />
      )}
    </StyledDiv>
  );
}

export default InputText;

InputText.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  width: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.any,
  type: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.any,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  onKeyPress: PropTypes.func
};

InputText.defaultProps = {
  label: "",
  disabled: false,
  width: "318px",
  id: "",
  className: "",
  type: "text",
  value: "",
  name: "",
  isRequired: false,
  error: false,
  placeholder: "",
  onKeyPress: () => {}
};
