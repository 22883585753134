import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ModalBody, TitleContent, FilterContent } from "./style";
import ButtonDefault from "../../UI/ButtonDefault/index";
import Select from "../../UI/Select/index";
import { findAllCompany } from "../../../actions/company";
import { Dropdown, Grid } from "semantic-ui-react";
import { getAllEndorsements } from "../../../actions/endorsement";

function ModalFilterEndorsement(props) {
  const open = useSelector(state => state.modal.ModalFilterEndorsement);
  const companies = useSelector(state => state.company.companies);
  const { onClose } = props;
  const searchEndorsements = props.search;

  const dispatch = useDispatch();

  const [idEmpresa, setIdEmpresa] = useState();
  const [status, setStatus] = useState("");
  const [ultimos, setUltimos] = useState("");

  useEffect(() => {
    dispatch(findAllCompany());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAllEndorsements({
        idEmpresa,
        ultimos,
        status,
        search: searchEndorsements
      })
    );
  }, [dispatch, idEmpresa, status, ultimos, searchEndorsements]);

  const companiesOptions = [
    {
      key: 0,
      value: "",
      text: "-"
    },
    ...companies.map(company => {
      return {
        hey: company.id,
        value: company.id,
        text: company.nome
      };
    })
  ];

  const statusOptions = [
    {
      value: "",
      text: "-"
    },
    {
      text: "Solicitação enviada",
      value: "SOLICITAÇÃO ENVIADA"
    },
    {
      text: "Solicitação recebida",
      value: "SOLICITAÇÃO RECEBIDA"
    },
    {
      text: "Enviada para a seguradora",
      value: "ENVIADA PARA A SEGURADORA"
    },
    {
      value: "FINALIZADA",
      text: "Finalizada"
    },
    {
      value: "FINALIZADA COM PROBLEMA",
      text: "Finaliza com problema"
    },
    {
      value: "",
      text: "Cancelada"
    }
  ];

  const ultimosOptions = [
    {
      value: "",
      text: "-"
    },
    {
      value: 120,
      text: "120 dias"
    },
    {
      value: 90,
      text: "90 dias"
    },
    {
      value: 65,
      text: "65 dias"
    },
    {
      value: 60,
      text: "60 dias"
    }
  ];

  function clearQuery() {
    setIdEmpresa("");
    setStatus("");
    setUltimos("");
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Filtros</h1>
          <ButtonDefault variant="text" color="primary" onClick={clearQuery}>
            Limpar
          </ButtonDefault>
        </TitleContent>
        <FilterContent>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <label>Empresa</label>
                <Dropdown
                  options={companiesOptions}
                  value={idEmpresa}
                  search
                  selection
                  onChange={(e, { value }) => setIdEmpresa(value)}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Select
                  colorLabel="#185F9B"
                  width="304px"
                  label="Status da apólice"
                  valueOption={statusOptions}
                  valueSelect={status}
                  onChange={e => setStatus(e.target.value)}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Select
                  colorLabel="#185F9B"
                  width="204px"
                  label="Últimos"
                  valueOption={ultimosOptions}
                  valueSelect={ultimos}
                  onChange={e => setUltimos(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </FilterContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalFilterEndorsement;
