import jwtDecode from "jwt-decode";
import { push } from "connected-react-router";
import axios from "./api";
export const TOKEN_KEY = "secretKey";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = async data => {
  try {
    const response = await axios.post("auth", data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const logout = () => async dispatch => {
  localStorage.removeItem(TOKEN_KEY);
  return dispatch(setLogout());
};

export const setLogout = () => ({
  type: "USER_LOGOUT"
});

export function decodeToken(token) {
  try {
    const result = jwtDecode(token);
    return result;
  } catch (err) {
    logout();
    return {
      email: "",
      exp: 0,
      iat: 0,
      id: 0,
      nome: "",
      permissoes: [],
      telefone: "",
      idEmpresa: {
        id: ""
      }
    };
  }
}

export const sendRecoverPasswordEmail = async email => {
  try {
    await axios.post("auth/send-recover-email", {
      email
    });
    return { success: true };
  } catch (err) {
    return { success: false };
  }
};

export const resetPassword = (senha, token) => async dispatch => {
  try {
    await axios.patch(
      "auth/reset-password",
      {
        senha
      },
      { params: { token } }
    );

    dispatch(push("/"));
  } catch (err) {
    console.log(err);
  }
};
