import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../routes/history';
import process from './process';
import modal from './modal';
import validCEP from './viacep';
import dialog from './dialog';
import company from './company';
import companyUser from './companyUser';
import insurer from './insurer';
import deadline from './deadline';
import consetUser from './consetUser';
import guarantee from './guarantee';
import policy from './policy';
import loading from './loading';
import socket from './socket';
import snackbar from './snackbar';
import navbar from './navbar';
import dashboard from './dashboard';
import pipeline from './pipeline';
import bipbopReport from './bipbop-report';
import endorsement from './endorsement';
import validCNPJ from './receitaws';

const appReducer = combineReducers({
  router: connectRouter(history),
  process,
  modal,
  validCEP,
  dialog,
  company,
  companyUser,
  consetUser,
  insurer,
  deadline,
  guarantee,
  policy,
  loading,
  socket,
  snackbar,
  navbar,
  dashboard,
  pipeline,
  bipbopReport,
  endorsement,
  validCNPJ,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
