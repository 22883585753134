import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 90%;
  height: 284px;
  margin: auto;
  margin-top: 2%;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const ContentPdf = styled.div`
  height: 500px;
`;

export const ControlPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const ButtonsDiv = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
`;

export const SectionBottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
