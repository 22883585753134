import jsonp from 'jsonp';

const baseUrl = 'https://www.receitaws.com.br/v1/cnpj/';

export const setValidCNPJ = (validCNPJ) => ({
  type: 'SET_VALID_CNPJ',
  payload: {
    validCNPJ,
  },
});

export const getValidCNPJ = (CNPJ) => (dispatch) => {
  jsonp(`${baseUrl}${CNPJ}`, null, (err, data) => {
    if (err) {
      console.error(err.message);
    } else {
      dispatch(setValidCNPJ(data));
    }
  });
};
