import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';

export const StyledCardHeader = withStyles({
  root: {
    backgroundColor: '#568CCC',
    borderRadius: '8px 8px 0px 0px',
  },
  title: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0.1px',
  },
})(CardHeader);
