export const initialState = {
  validCEP: {
    localidade: '',
    uf: '',
    bairro: '',
    logradouro: '',
    complemento: '',
  },
};

const validCEP = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VALID_CEP':
      return { ...state, validCEP: action.payload.validCEP };
    default:
      return state;
  }
};

export default validCEP;
