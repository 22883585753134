import React, { useEffect, Fragment } from "react";
import { Form, Label } from "semantic-ui-react";
import ScrollContainer from "react-indiana-drag-scroll";

import Navbar from "../../components/Navbar";
import {
  SectionTable,
  Table,
  Content,
  CellHeader,
  Cell,
  TableScroll,
  Button,
  TitleContent
} from "./style";
import { decodeToken, getToken } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { findOneCompany } from "../../actions/company";
import { findCompanyUsers } from "../../actions/companyUser";
import ModalEditPasswordCompany from "../../components/Modal/ModalEditPasswordCompany";
import { setModalEditPasswordCompany } from "../../actions/modal";

function PerfilEmpresa() {
  const user = decodeToken(getToken());
  const dispatch = useDispatch();

  const company = useSelector(state => state.company.company);
  const users = useSelector(state => state.companyUser.users);

  useEffect(() => {
    dispatch(findOneCompany(user.idEmpresa.id));
    dispatch(findCompanyUsers({ idEmpresa: user.idEmpresa.id }));
  }, [dispatch, user]);

  const handleOpenModalEditPassword = () => {
    dispatch(setModalEditPasswordCompany(true));
  };

  const handleCloseModalEditPassword = () => {
    dispatch(setModalEditPasswordCompany(false));
  };

  return (
    <div>
      <Navbar />
      <Content>
        <TitleContent>
          <h1>Minha conta</h1>
          <Button onClick={() => handleOpenModalEditPassword()}>
            Alterar senha
          </Button>
        </TitleContent>
        <Form>
          <Form.Group>
            <Form.Input
              readOnly
              label="CNPJ"
              value={company.cnpj}
              placeholder="CNPJ"
              width={4}
            />
            <Form.Input
              label="Razão Social"
              placeholder="Razão Social"
              value={company.razaoSocial}
              width={5}
              readOnly
            />
            <Form.Input
              label="Nome da empresa"
              placeholder="Nome da empresa"
              width={5}
              value={company.nome}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="E-mail"
              placeholder="E-mail"
              width={4}
              value={company.email}
              readOnly
            />
            <Form.Input
              label="Telefone"
              placeholder="Telefone"
              width={3}
              value={company.telefone}
              readOnly
            />
          </Form.Group>
          <h1>Endereço</h1>
          <Form.Group>
            <Form.Input
              label="Estado"
              placeholder="Estado"
              width={3}
              value={company.estado}
              readOnly
            />
            <Form.Input
              label="UF"
              placeholder="UF"
              width={2}
              value={company.uf}
            />
            <Form.Input
              label="CEP"
              placeholder="CEP"
              width={3}
              value={company.cep}
              readOnly
            />
            <Form.Input
              label="Cidade"
              placeholder="Cidade"
              width={4}
              value={company.cidade}
              readOnly
            />
            <Form.Input
              label="Bairro"
              placeholder="Bairro"
              width={4}
              value={company.bairro}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Logradouro"
              placeholder="Logradouro"
              width={5}
              value={company.logradouro}
              readOnly
            />
            <Form.Input
              label="Número"
              placeholder="Nº"
              width={2}
              value={company.numero}
              readOnly
            />
            <Form.Input
              label="Complemento"
              placeholder="Complemento"
              width={4}
              value={company.complemento}
            />
          </Form.Group>
        </Form>
        <h1>Integrantes (Acesso)</h1>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="5">
                <CellHeader singleLine>NOME</CellHeader>
                <CellHeader>E-MAIL</CellHeader>
                <CellHeader>TELEFONE</CellHeader>
                <CellHeader>TIPO</CellHeader>
                <CellHeader>PERMISSÕES</CellHeader>
                {users.map((member, key) => (
                  <Fragment key={key}>
                    <Cell singleLine>{member.nome}</Cell>
                    <Cell singleLine>{member.email}</Cell>
                    <Cell singleLine>{member.telefone}</Cell>
                    <Cell>{member.tipo}</Cell>
                    <Cell
                      alignItems="center"
                      justifyContent="start"
                      flexDirection="row"
                    >
                      {member.permissoes.map(permissao => (
                        <Label className="label-permissoes" circular>
                          {permissao}
                        </Label>
                      ))}
                    </Cell>
                  </Fragment>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
      </Content>
      <ModalEditPasswordCompany onClose={handleCloseModalEditPassword} />
    </div>
  );
}

export default PerfilEmpresa;
