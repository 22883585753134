import styled from 'styled-components';

export const SectionTop = styled.div`
  display: flex;
  margin: 0 48px;
  margin-top: -38px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 42px;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #616161;
    margin: 0;
  }
`;

export const SectionStepper = styled.div`
  margin-top: 40px;
  padding-left: 200px;
  padding-right: 200px;
`;

export const SectionCards = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 50px;
`;

export const DivSendMail = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .divPaper {
    width: 100%;
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .Label-emails {
    display: block;
    width: 100%;
    text-align: start;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${(props) => props.color || '#424242'};
    margin-bottom: 4px;
  }
  .MuiPaper-root {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
  }
  .MuiChip-root {
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;

export const SectionBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 8% 80px 8%;
  width: 100%;
`;
