import React, { useState } from "react";
import { useSelector } from "react-redux";

import PropType from "prop-types";

import Modal from "@material-ui/core/Modal";

import { ModalBody, TitleContent, ButtonDefault } from "./style";

import { Grid } from "semantic-ui-react";
import { TextField } from "@material-ui/core";
import "moment/locale/pt-br";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { formatToBRL } from "brazilian-values";

function ModalBudget(props) {
  const { onClose, amount, unitValue, type } = props;
  const open = useSelector(state => state.modal.ModalBudget);
  const lead = useSelector(state => state.pipeline.lead);

  const [pesquisaFim, setPesquisaFim] = useState(new Date());
  const [pesquisaInicio, setPesquisaInicio] = useState(new Date());

  const realizeSearch = () => {
    onClose();
    props.searchProcess({ pesquisaInicio, pesquisaFim });
  };

  function handleChangeToDate(momentDate) {
    const dateString = momentDate.format().split("T")[0];
    console.log(Date.parse(momentDate.format()));
    setPesquisaFim(dateString);
  }

  function handleChangeFromDate(momentDate) {
    const dateString = momentDate.format().split("T")[0];
    setPesquisaInicio(dateString);
  }

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalBody>
          <TitleContent>
            <h1>Orçamento do saneamento.</h1>
          </TitleContent>
          <h1>Busca por empresa</h1>

          <Grid>
            <Grid.Row
              style={{
                borderBottom: "1px solid #e0e0e0"
              }}
            >
              <Grid.Column width={6}>
                <h6>Valor total</h6>
                <h2>{formatToBRL(unitValue * amount)}</h2>
              </Grid.Column>
              <Grid.Column width={5}>
                <h6>Unitário</h6>
                <h3>{formatToBRL(unitValue)}</h3>
              </Grid.Column>
              <Grid.Column width={5}>
                <h6>Quantidade</h6>
                <h3>{amount}</h3>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            {type === "pesquisaSimples" ? (
              <>
                <h1>Período da publicação dos processos</h1>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="date"
                        label="De"
                        value={pesquisaInicio}
                        onChange={handleChangeFromDate}
                      ></KeyboardDatePicker>
                    </MuiPickersUtilsProvider>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="date"
                        label="Até"
                        value={pesquisaFim}
                        onChange={handleChangeToDate}
                      ></KeyboardDatePicker>
                    </MuiPickersUtilsProvider>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ""
            )}
            <Grid.Row verticalAlign="bottom">
              <ButtonDefault onClick={() => realizeSearch()}>
                Realizar busca
              </ButtonDefault>
            </Grid.Row>
          </Grid>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalBudget;

ModalBudget.propType = {
  onClose: PropType.func
};
