/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ContentStatus,
  Content,
  CircleNumber,
  StatusDiv,
  StatusDivDisabled,
  CircleStatus,
  CircleStatusDisabled,
  CircleNumberDisabled
} from "./style";

import "rc-tooltip/assets/bootstrap_white.css";
import Tooltip from "rc-tooltip";

function Status(props) {
  const { disabled, number, title, date, description } = props;

  if (disabled) {
    return (
      <>
        <CircleNumberDisabled>{number}</CircleNumberDisabled>
        <StatusDivDisabled>
          <CircleStatusDisabled />
          <h2>{title}</h2>
          <p>{date}</p>
        </StatusDivDisabled>
      </>
    );
  }

  return (
    <>
      <CircleNumber>{number}</CircleNumber>

      <Tooltip
        placement="top"
        trigger={["hover", "click"]}
        overlay={<span>{description}</span>}
      >
        <StatusDiv>
          <CircleStatus />
          <h2>{title}</h2>
          <p>{date}</p>
        </StatusDiv>
      </Tooltip>
    </>
  );
}

function CardProcessMonitoring(props) {
  const { title, listStatus, waitingStatus, textStatus } = props;

  return (
    <Card>
      <CardHeader>
        <h1>{title}</h1>
      </CardHeader>
      {waitingStatus ? (
        <ContentStatus>
          <p>{textStatus}</p>
        </ContentStatus>
      ) : (
        <Content>
          {listStatus.map((status, index) => (
            <Status
              key={status.id}
              disabled={status.disabled}
              number={index + 1}
              title={status.title}
              date={status.date}
              description={status.description}
            />
          ))}
        </Content>
      )}
    </Card>
  );
}

export default CardProcessMonitoring;

CardProcessMonitoring.propTypes = {
  title: PropTypes.string,
  listStatus: PropTypes.array,
  waitingStatus: PropTypes.bool,
  textStatus: PropTypes.string
};

CardProcessMonitoring.defaultProps = {
  title: "",
  listStatus: [],
  waitingStatus: false,
  textStatus: "Aguarde enquanto atualizamos o status do processo..."
};

Status.propTypes = {
  disabled: PropTypes.bool,
  number: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string
};

Status.defaultProps = {
  disabled: false,
  number: 0,
  title: "",
  description: "",
  date: ""
};
