import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import consetlogo from "../../assets/images/consetlogo.svg";
import {
  DivNavbar,
  DivLogo,
  DivButtonGroup,
  DivButton,
  ButtonNav,
  ButtonBar,
  DivProfile,
  LogoutButton
} from "./style";
import { getToken, logout, decodeToken } from "../../actions/auth";
import { Dropdown } from "semantic-ui-react";
import { setModalEditPasswordConset } from "../../actions/modal";
import ModalEditPasswordConset from "../Modal/ModalEditPasswordConset";

function Navbar(props) {
  const { pageSelected } = props;

  const dispatch = useDispatch();
  const policyRoute = useSelector(state => state.navbar.policyRoute);
  const token = getToken();
  const user = decodeToken(token);

  const eventLogout = () => {
    dispatch(logout());
    dispatch(push("/"));
  };

  const menuItems = [
    {
      title: "Dashboard",
      pageSelected: "dashboard",
      permission: true,
      eventClick: () =>
        dispatch(
          push(user.perfil === "conset" ? "/adm/dashboard" : "/dashboard")
        )
    },
    {
      title: "Cotação/Emissão",
      pageSelected: "cotacao",
      permission: "Gerar Apólice",
      eventClick: () => dispatch(push(policyRoute))
    },
    {
      title: "Documentos Emitidos",
      pageSelected: "documentos-emitidos",
      permission: "Gerar Apólice",
      eventClick: () => dispatch(push("/documentos-emitidos"))
    },
    {
      title: "Acompanhamento Processual",
      pageSelected: "acompanhamento-processual",
      permission: "Acompanhamento Jurídico",
      eventClick: () => dispatch(push("/acompanhamento-processual"))
    },
    {
      title: "Pipeline",
      pageSelected: "pipeline",
      permission: "pipeline",
      eventClick: () => dispatch(push("/pipeline"))
    },
    {
      title: "Configurações",
      pageSelected: "configuracoes",
      eventClick: () => dispatch(push("/configuracoes"))
    }
  ];

  const handleOpenProfile = () => {
    if (user.perfil === "empresa") return dispatch(push("/minha-conta"));

    return handleOpenModalEditPassword();
  };

  const handleOpenModalEditPassword = () => {
    dispatch(setModalEditPasswordConset(true));
  };

  const handleCloseModalEditPassword = () => {
    dispatch(setModalEditPasswordConset(false));
  };

  return (
    <>
      {" "}
      <DivNavbar>
        <DivLogo>
          <img alt="logo" src={consetlogo} />
        </DivLogo>
        <DivButtonGroup>
          {menuItems.map((item, index) =>
            item.permission === true ||
            user.permissoes.includes(item.permission) ||
            user.permissoes.includes("Administração") ? (
              <DivButton key={index}>
                <ButtonNav onClick={() => item.eventClick()}>
                  {item.title}
                </ButtonNav>
                {pageSelected === item.pageSelected && <ButtonBar />}
              </DivButton>
            ) : (
              ""
            )
          )}
        </DivButtonGroup>
        <DivProfile>
          <div>
            <h1>{user.nome}</h1>
            <LogoutButton color="secondary" onClick={() => eventLogout()}>
              Sair
            </LogoutButton>
          </div>
          <Dropdown direction="right" >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Minha conta"
                onClick={() => handleOpenProfile()}
              />
            </Dropdown.Menu>
          </Dropdown>
        </DivProfile>
      </DivNavbar>{" "}
      <ModalEditPasswordConset onClose={handleCloseModalEditPassword}/>
    </>
  );
}

export default Navbar;

Navbar.propTypes = {
  pageSelected: PropTypes.oneOf([
    "dashboard",
    "cotacao",
    "documentos-emitidos",
    "acompanhamento-processual",
    "pipeline",
    "configuracoes"
  ])
};

Navbar.defaultProps = {
  pageSelected: ""
};
