import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { ModalBody, TitleContent, Content, BtnFinish } from './style';
import { Form, Grid } from 'semantic-ui-react';
import { setModalNewTomador } from '../../../actions/modal';
import { createNewTaker } from '../../../actions/company';

function ModalNewTomador(props) {
  const { idEmpresa } = props;
  const [newTomador, setNewTomador] = useState({
    nome: '',
    cnpj: '',
    razaoSocial: '',
  });

  const open = useSelector((state) => state.modal.ModalNewTomador);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setModalNewTomador(false));
  }

  function sendForm() {
    dispatch(createNewTaker({
      taker: newTomador,
      empresa: idEmpresa,
    },
    idEmpresa));
    handleClose();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalBody>
        <TitleContent>
          <h1>Novo Tomador</h1>
        </TitleContent>
        <Content>
          <Form onSubmit={sendForm}>
            <Form.Input
              required
              label="Nome"
              onChange={(e) => setNewTomador({...newTomador, nome: e.target.value})}
              placeholder="Nome"
              width={16}
            />
            <Form.Input
              required
              label="CNPJ"
              onChange={(e) => setNewTomador({...newTomador, cnpj: e.target.value})}
              placeholder="CNPJ"
              width={16}
            />
            <Form.Input
              required
              label="Razão Social"
              onChange={(e) => setNewTomador({...newTomador, razaoSocial: e.target.value})}
              placeholder="Razão Social"
              width={16}
            />
            <Grid>
              <Grid.Row textAlign="right">
                <Grid.Column width={16}>
                  <BtnFinish type="submit">Salvar</BtnFinish>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalNewTomador;
