import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  input[type=text]:disabled {
    background: #EEEEEE;
    border: 1px solid #BDBDBD;
  }
`;

export const Input = styled.input`
  background: #FFFFFF;
  border: 1px solid ${(props) => props.border || '#EEEEEE'};
  box-sizing: border-box;
  border-radius: 2px;
  width: ${(props) => props.width || '318px'};
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
`;

export const Label = styled.label`
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${(props) => props.color || '#424242'};
  margin-bottom: 4px;

  p{
    display: inline;
    color: red!important;
  }
`;
