import styled from 'styled-components';

export const ModalBody = styled.div `
  background: #F8FAFD;
  width: 1000px;
  padding-bottom: 20px;
  margin: auto;
  margin-top: 6%;
  max-height: 600px;
  overflow-x: scroll;
`;

export const TitleContent = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div `
  margin: 0px 40px;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00235C;
    letter-spacing: 0.1px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }

  .ui.form .field {
    label {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export const ContentDetail = styled.div`
  background: #fff;
  padding: 0px 10px;

  .ui.grid{
    margin: 0 ;
  }
`

export const BtnFinish = styled.button `
  font-family: Montserrat;
  border: none;
  background: #F9B233;
  color:white;
  font-weight: 700;
  border-radius:4px;
  text-transform: uppercase;
  font-size: 12px;
  margin: 30px 0px 0px;
  padding: 13px 20px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export const BtnBorder = styled(BtnFinish)`
  border: 1px solid #185F9B;
  background: #fff;
  color: #185F9B;
  margin-right: 20px;
`

export const InputFile = styled.div `
  border: 1px solid #185F9B!important;
  border-radius: 4px!important;
  font-family: Montserrat!important;
  font-weight: bold;
  font-size: 12px;
  color: #185F9B!important;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-bottom: 15px;
`

export const ItemFile = styled.div `
  display: flex;
  justify-content: space-between;  
  padding: 8px;
`

export const Row = styled.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

export const Table = styled.div `
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  font-family: 'Montserrat', 'sans-serif';
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
`;

export const TableScroll = styled.div `
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  cursor: grab;
`;

export const CellHeader = styled.div `
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #24126A;
  padding: 10px 16px;
  padding-left: ${ (props) => props.paddingLeft || '16px'};
  word-break: keep-all;
  white-space: nowrap;
  background: #FFFFFF;
  width: ${ (props) => props.width};
  margin-bottom: 8px;
`;

export const CellStatus = styled.div `
  display: flex;
  background: #FFFFFF;
  height: 72px;
  padding-right: 32px;
  border-left: 4px solid #FFFFFF;
  margin-bottom: 4px;

  h1 {
    color: #2C3E50;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
`;

export const Cell = styled.div `
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #2C3E50;
  background: #FFFFFF;
  padding-left: ${props => props.paddingLeft || '16px'};
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: ${props => props.width};
`;

export const TableFixed = styled.div `
  background: #fff;
`;

export const CellHeaderDetails = styled(CellHeader)`
  box-shadow: rgba(0, 0, 0, 0.25) -6px 0px 4px -3.5px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
`;

export const CellDetails = styled(Cell)`
  width: 114px;
  min-height: 72px;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.25) -6px 0px 4px -3.5px;
`;