import React, { useState, Fragment, useEffect } from "react";
import { isCNPJ } from "brazilian-values";
import { getValidCNPJ, setValidCNPJ } from "../../actions/receitaws";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Label, Grid } from "semantic-ui-react";
import StepperEmpresa from "../../components/Stepper/StepperEmpresa/index";
import { AiFillCloseCircle, AiOutlineMail } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import ScrollContainer from "react-indiana-drag-scroll";
import Navbar from "../../components/Navbar";
import ModalListTakers from '../../components/Modal/ModalListTakers';

import {
  TitleContent,
  Content,
  CellHeader,
  Cell,
  Table,
  TableScroll,
  SectionTable,
  ButtonBorder,
  ButtonSend
} from "./style";

import history from "../../routes/history";

import { cnpjMask, cepMask, telMask, numberMask } from "../../services/mask";
import { createCompany } from "../../actions/company";
import { createMultipleCompanyUsers } from "../../actions/companyUser";
import { getValidCEP, setValidCEP } from "../../actions/viacep";
import { MenuItem, TextField } from "@material-ui/core";
import {
  getDashboardBMG,
  getDashboardJunto,
  getDashboardTokio
} from "../../actions/dashboard";
import ButtonDefault from '../../components/UI/ButtonDefault';
import { setModalListTakers } from '../../actions/modal';

function NovaEmpresa() {
  const dispatch = useDispatch();

  const validCEP = useSelector(state => state.validCEP.validCEP);
  const validCNPJ = useSelector(state => state.validCNPJ.validCNPJ);
  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [confirmarEmail, setConfirmarEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [estado, setEstado] = useState("");
  const [uf, setUf] = useState("");
  const [cep, setCep] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");

  const [memberNome, setMemberNome] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [memberSenha, setMemberSenha] = useState("");
  const [memberTelefone, setMemberTelefone] = useState("");
  const [memberTipo, setMemberTipo] = useState("");
  const [memberPermissoes, setMemberPermissoes] = useState([]);

  const [members, setMembers] = useState({ companyUsers: [] });
  const [changeMembers, setChangeMembers] = useState(false);

  const [errorEmail, setErrorEmail] = useState(null);
  const [errorNomeMember, setErrorNomeMember] = useState(null);
  const [errorTelefoneMember, setErrorTelefoneMember] = useState(null);
  const [errorTipoMember, setErrorTipoMember] = useState(null);
  const [errorEmailMember, setErrorEmailMember] = useState(null);
  const [errorSenhaMember, setErrorSenhaMember] = useState(null);
  const [errorPermissoesMember, setErrorPermissoesMember] = useState(null);
  const [errorUf, setErrorUf] = useState(null);

  const [step, setStep] = useState("first");

  const [seguradoras, setSeguradoras] = useState([]);
  const [seguradoraSelecionada, setSeguradoraSelecionada] = useState({});

  const [bmg, setBmg] = useState({});
  const [tokyo, setTokyo] = useState({});
  const [junto, setJunto] = useState({});

  const seguradorasOptions = [
    {
      text: "Tokio Marine",
      value: "Tokio"
    },
    {
      text: "BMG",
      value: "BMG"
    },
    {
      text: "Junto",
      value: "Junto"
    }
  ];

  const ufs = [
    {
      value: "AC",
      text: "AC"
    },
    {
      value: "AL",
      text: "AL"
    },
    {
      value: "AP",
      text: "AP"
    },
    {
      value: "AM",
      text: "AM"
    },
    {
      value: "BA",
      text: "BA"
    },
    {
      value: "CE",
      text: "CE"
    },
    {
      value: "DF",
      text: "DF"
    },
    {
      value: "ES",
      text: "ES"
    },
    {
      value: "GO",
      text: "GO"
    },
    {
      value: "MA",
      text: "MA"
    },
    {
      value: "MT",
      text: "MT"
    },
    {
      value: "MS",
      text: "MS"
    },
    {
      value: "MG",
      text: "MG"
    },
    {
      value: "PA",
      text: "PA"
    },
    {
      value: "PB",
      text: "PB"
    },
    {
      value: "PR",
      text: "PR"
    },
    {
      value: "PE",
      text: "PE"
    },
    {
      value: "PI",
      text: "PI"
    },
    {
      value: "RJ",
      text: "RJ"
    },
    {
      value: "RN",
      text: "RN"
    },
    {
      value: "RS",
      text: "RS"
    },
    {
      value: "RO",
      text: "RO"
    },
    {
      value: "RR",
      text: "RR"
    },
    {
      value: "SC",
      text: "SC"
    },
    {
      value: "SP",
      text: "SP"
    },
    {
      value: "SE",
      text: "SE"
    },
    {
      value: "TO",
      text: "TO"
    }
  ];

  const tipos = [
    {
      value: "Interno",
      text: "Interno"
    },
    {
      value: "Externo",
      text: "Externo"
    }
  ];

  const permissoes = [
    {
      value: "Gerar Apólice",
      text: "Gerar apólice"
    },
    {
      value: "Acompanhamento Jurídico",
      text: "Acompanhamento Jurídico"
    }
  ];

  useEffect(() => {
    setCidade(validCEP.localidade);
    setUf(validCEP.uf);
    setBairro(validCEP.bairro);
    setLogradouro(validCEP.logradouro);
    setComplemento(validCEP.complemento);
  }, [validCEP]);

  const preencherEndereco = async cep => {
    setCep(cepMask(cep));
    if (cep.length === 9) {
      dispatch(getValidCEP(cep));
    }
  };

  const validaEmail = (email, confirmarEmail) => {
    setEmail(email);
    setConfirmarEmail(confirmarEmail);
    if (email && confirmarEmail && email !== confirmarEmail)
      setErrorEmail("Emails não correspondem");
    else setErrorEmail(null);
  };

  const handleChangeUf = (event, { value }) => {
    setUf(value);
    setErrorUf(null);
  };

  const handleChangeTipo = (event, { value }) => {
    setMemberTipo(value);
    setErrorTipoMember(null);
  };

  const handleChangePermissoes = (event, { value }) => {
    setMemberPermissoes(value);
    setErrorPermissoesMember(null);
  };

  const addMember = e => {
    e.preventDefault();

    if (memberNome.length === 0)
      return setErrorNomeMember("Preenchimento obrigatório");
    if (memberEmail.length === 0)
      return setErrorEmailMember("Preenchimento obrigatório");
    if (memberSenha.length === 0)
      return setErrorSenhaMember("Preenchimento obrigatório");
    if (memberTelefone.length === 0)
      return setErrorTelefoneMember("Preenchimento obrigatório");
    if (memberTipo.length === 0)
      return setErrorTipoMember("Preenchimento obrigatório");
    if (memberPermissoes.length === 0)
      return setErrorPermissoesMember("Preenchimento obrigatório");

    const newTomadores = [];
    tomadoresMembers.forEach((tomador) => {
      tomadores.forEach((tomadorObj) => {
        if (tomadorObj.cnpj === tomador) {
          newTomadores.push(tomadorObj);
        }
      });
    });

    const membersNew = members;
    const newMember = {
      nome: memberNome,
      email: memberEmail,
      senha: memberSenha,
      telefone: memberTelefone,
      tipo: memberTipo,
      permissoes: memberPermissoes,
      tomadores: newTomadores,
    };

    membersNew.companyUsers.push(newMember);

    setMembers(membersNew);

    setMemberNome("");
    setMemberEmail("");
    setMemberSenha("");
    setMemberTelefone("");
    setMemberTipo("");
    setMemberPermissoes([]);
    setTomadoresMembers([]);
  };

  const removeMember = member => {
    const membersNew = members;

    membersNew.companyUsers.splice(membersNew.companyUsers.indexOf(member), 1);

    setMembers(membersNew);
    setChangeMembers(!changeMembers);
  };

  const handleChangeData = async (situacaoCCG, validade, seguradoraOld) => {
    const seguradorasNew = seguradoras;

    seguradorasNew.forEach(seguradora => {
      if ((seguradora || {}).seguradora === seguradoraOld.seguradora) {
        seguradora.situacaoCCG = situacaoCCG;
        seguradora.validade = validade;
        if ((seguradora || {}).seguradora === "Tokio Marine")
          setTokyo(seguradora);
        if ((seguradora || {}).seguradora === "BMG") setBmg(seguradora);
        if ((seguradora || {}).seguradora === "Junto") setJunto(seguradora);
      }
    });

    setSeguradoras(seguradorasNew);
    setSeguradoraSelecionada({});
  };

  const handleChangeSeguradora = async event => {
    const insurer = event.target.value;

    let seguradora = {};
    if (insurer === "Tokio") {
      const tokioDashboard = await getDashboardTokio(numberMask(cnpj));
      seguradora = {
        ...tokioDashboard,
        seguradora: "Tokio Marine"
      };
      setTokyo({
        situacaoCCG: "",
        validade: new Date().toLocaleDateString()
      });
    }
    if (insurer === "Junto") {
      const juntoDashboard = await getDashboardJunto(numberMask(cnpj));
      seguradora = {
        ...juntoDashboard,
        seguradora: "Junto"
      };
      setJunto({
        situacaoCCG: "",
        validade: new Date().toLocaleDateString()
      });
    }
    if (insurer === "BMG") {
      const bmgDashboard = await getDashboardBMG(numberMask(cnpj));
      seguradora = {
        ...bmgDashboard,
        seguradora: "BMG"
      };
      setBmg({
        situacaoCCG: "",
        validade: new Date().toLocaleDateString()
      });
    }

    const seguradorasNew = seguradoras;
    seguradorasNew.push(seguradora);
    setSeguradoras(seguradorasNew);
    setSeguradoraSelecionada(seguradora);
  };

  const sendForm = async () => {
    if (!uf) return setErrorUf("Preencha este campo");

    if (!errorEmail && step === "first" && !errorUf) {
      return setStep("second");
    }

    const newCompany = {
      cnpj,
      razaoSocial,
      nome,
      email,
      confirmarEmail,
      telefone: numberMask(telefone),
      estado,
      uf,
      cep: numberMask(cep),
      cidade,
      bairro,
      logradouro,
      numero,
      complemento,
      tomadores,
      bmg: {
        reserva: bmg.reserva,
        situacaoCCG: bmg.situacaoCCG,
        validade: bmg.validade
      },
      tokyo: {
        reserva: tokyo.reserva,
        situacaoCCG: tokyo.situacaoCCG,
        validade: tokyo.validade
      },
      junto: {
        reserva: junto.reserva,
        situacaoVVG: junto.situacaoCCG,
        validade: junto.validade
      }
    };

    const resp = await dispatch(createCompany(newCompany));

    const newMembers = members;

    newMembers.companyUsers.forEach(member => {
      member.idEmpresa = resp.id;
      member.permissoes = `{${member.permissoes.reduce(
        (acc, permissao, i) =>
          acc + permissao + (i !== member.permissoes.length - 1 ? "," : " "),
        " "
      )}}`;
      return;
    });

    dispatch(createMultipleCompanyUsers(newMembers));

    history.push("/configuracoes");
    dispatch(
      setValidCNPJ({
        nome: "",
        cep: "",
        email: "",
        telefone: ""
      })
    );
    dispatch(
      setValidCEP({
        localidade: "",
        uf: "",
        bairro: "",
        logradouro: "",
        complemento: ""
      })
    );
  };

  function handleChangeCNPJ(value) {
    setCnpj(cnpjMask(value));
    if (isCNPJ(value)) {
      dispatch(getValidCNPJ(numberMask(value)));
    }
  }

  useEffect(() => {
    setRazaoSocial(validCNPJ.nome);
    setNome(validCNPJ.nome);
    setCep(validCNPJ.cep);
    setEmail(validCNPJ.email);
    setConfirmarEmail(validCNPJ.email);
    setTelefone(validCNPJ.telefone.split("/")[0]);
  }, [dispatch, validCNPJ]);

  const [tomador, setTomador] = useState({
    nome: '',
    cnpj: '',
    razaoSocial: '',
  });
  const [tomadorError, setTomadorError] = useState({
    nome: null,
    cnpj: null,
    razaoSocial: null,
  });
  const [tomadores, setTomadores] = useState([]);
  const [selectTomadores, setSelectTomadores] = useState([]);

  const handleDeleteTomador = (tomadorToDelete) => {
    setTomadores((listOfTomadores) => listOfTomadores.filter((tomador, index) => index !== tomadores.indexOf(tomadorToDelete)));
    setSelectTomadores((listOfTomadores) => listOfTomadores.filter((tomador, index) => index !== tomadores.indexOf(tomadorToDelete)));
  };

  const onClickAddTomador = (e) => {
    e.preventDefault();
    if (!tomador.nome) {
      setTomadorError({
        ...tomadorError,
        nome: 'Preenchimento obrigatório',
      });
    }
    if (!tomador.cnpj) {
      setTomadorError({
        ...tomadorError,
        cnpj: 'Preenchimento obrigatório',
      });
    }
    if (!isCNPJ(tomador.cnpj)) {
      setTomadorError({
        ...tomadorError,
        cnpj: 'CNPJ Iinvalido',
      });
    }
    if (!tomador.razaoSocial) {
      setTomadorError({
        ...tomadorError,
        razaoSocial: 'Preenchimento obrigatório',
      });
    }
    if (tomador.nome && tomador.cnpj && isCNPJ(tomador.cnpj) && tomador.razaoSocial) {
      setTomadores([...tomadores, tomador]);
      setSelectTomadores([...selectTomadores, {
        text: tomador.nome,
        value: tomador.cnpj,
      }]);
      setTomadorError({
        nome: null,
        cnpj: null,
        razaoSocial: null,
      });
    }
    setTomador({
      nome: '',
      cnpj: '',
      razaoSocial: '',
    });
  };

  const [tomadoresMembers, setTomadoresMembers] = useState([]);

  const handleChangeTomadoresMembers = (event, { value, text }) => {
    setTomadoresMembers(value, text);
  };

  const [listOfTomadoresByMember, setlistOfTomadoresByMember] = useState([]);

  const handleOpenModalTomadores = (tomadores) => {
    setlistOfTomadoresByMember(tomadores);
    dispatch(setModalListTakers(true));
  };

  function firstStep() {
    return (
      <Content>
        <Form
          onSubmit={() => sendForm()}
          style={{
            paddingTop: "35px"
          }}
        >
          <StepperEmpresa className="item-1" activeStep={0} />
          <h1>Empresa (Holding)</h1>
          <Form.Group>
            <Form.Input
              required
              label="CNPJ"
              onChange={e => handleChangeCNPJ(e.target.value)}
              value={cnpj}
              placeholder="CNPJ"
              width={4}
            />
            <Form.Input
              label="Razão Social"
              placeholder="Razão Social"
              onChange={e => setRazaoSocial(e.target.value)}
              value={razaoSocial}
              width={5}
              required
            />
            <Form.Input
              label="Nome da empresa"
              placeholder="Nome da empresa"
              width={5}
              onChange={e => setNome(e.target.value)}
              value={nome}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="E-mail"
              placeholder="E-mail"
              width={4}
              onChange={e => validaEmail(e.target.value, confirmarEmail)}
              value={email}
              required
            />
            <Form.Input
              label="Confirmar e-mail"
              placeholder="Confirmar e-mail"
              width={4}
              onChange={e => validaEmail(email, e.target.value)}
              value={confirmarEmail}
              required
              error={errorEmail}
            />
            <Form.Input
              label="Telefone"
              placeholder="Telefone"
              width={3}
              onChange={e => setTelefone(telMask(e.target.value))}
              value={telefone}
              required
            />
          </Form.Group>
          <h1>Endereço</h1>
          <Form.Group>
            <Form.Input
              label="Estado"
              placeholder="Estado"
              width={3}
              onChange={e => setEstado(e.target.value)}
              value={estado}
              required
            />
            <Form.Select
              label="UF"
              placeholder="UF"
              width={2}
              options={ufs}
              onChange={handleChangeUf.bind(this)}
              value={uf}
              error={errorUf}
            />
            <Form.Input
              label="CEP"
              placeholder="CEP"
              width={3}
              onChange={e => preencherEndereco(e.target.value)}
              value={cep}
              required
            />
            <Form.Input
              label="Cidade"
              placeholder="Cidade"
              width={4}
              onChange={e => setCidade(e.target.value)}
              value={cidade}
              required
            />
            <Form.Input
              label="Bairro"
              placeholder="Bairro"
              width={4}
              onChange={e => setBairro(e.target.value)}
              value={bairro}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              label="Logradouro"
              placeholder="Logradouro"
              width={5}
              onChange={e => setLogradouro(e.target.value)}
              value={logradouro}
              required
            />
            <Form.Input
              label="Número"
              placeholder="Nº"
              width={2}
              onChange={e => setNumero(e.target.value)}
              value={numero}
              required
            />
            <Form.Input
              label="Complemento"
              placeholder="Complemento"
              width={4}
              onChange={e => setComplemento(e.target.value)}
              value={complemento}
            />
          </Form.Group>
          <h1>Tomadores</h1>
          <Form.Group>
            <Form.Input
              label="Nome"
              placeholder="Nome"
              width={4}
              value={tomador.nome}
              onChange={(e) => setTomador({ ...tomador, nome: e.target.value })}
              error={tomadorError.nome}
            />
            <Form.Input
              label="CNPJ"
              placeholder="CNPJ"
              width={4}
              value={tomador.cnpj}
              onChange={(e) => setTomador({ ...tomador, cnpj: cnpjMask(e.target.value) })}
              error={tomadorError.cnpj}
            />
            <Form.Input
              label="Razão Social"
              placeholder="Razão Social"
              width={4}
              value={tomador.razaoSocial}
              onChange={(e) => setTomador({ ...tomador, razaoSocial: e.target.value })}
              error={tomadorError.razaoSocial}
            />
            <Button
              color="blue"
              style={{
                backgroundColor: '#00366C',
              }}
              onClick={(e) => onClickAddTomador(e)}
            >
              Adicionar
            </Button>
          </Form.Group>
          <SectionTable>
            <Table>
              <ScrollContainer>
                <TableScroll columns="4">
                  <CellHeader singleLine>NOME</CellHeader>
                  <CellHeader>CNPJ</CellHeader>
                  <CellHeader>Razão Social</CellHeader>
                  <CellHeader>OPÇÕES</CellHeader>
                  {
                    tomadores.map((tomador, key) => (
                      <Fragment key={key}>
                        <Cell singleLine>{tomador.nome}</Cell>
                        <Cell singleLine>{tomador.cnpj}</Cell>
                        <Cell singleLine>{tomador.razaoSocial}</Cell>
                        <Cell
                          alignItems="center"
                          justifyContent="start"
                          flexDirection="row"
                        >
                          <AiFillCloseCircle
                            className="icone-tabela"
                            size={27}
                            color="red"
                            onClick={() => handleDeleteTomador(tomador)}
                          />
                        </Cell>
                      </Fragment>
                    ))
                  }
                </TableScroll>
              </ScrollContainer>
            </Table>
          </SectionTable>
          <h1>Integrantes (Acesso)</h1>
          <Form.Group>
            <Form.Input
              label="Nome completo"
              placeholder="Nome completo"
              width={4}
              onChange={e => {
                setMemberNome(e.target.value);
                setErrorNomeMember(null);
              }}
              value={memberNome}
              error={errorNomeMember}
            />
            <Form.Input
              label="E-mail"
              placeholder="E-mail"
              width={4}
              onChange={e => {
                setMemberEmail(e.target.value);
                setErrorEmailMember(null);
              }}
              value={memberEmail}
              error={errorEmailMember}
            />
            <Form.Input
              type="password"
              label="Senha"
              placeholder="Senha"
              width={4}
              onChange={e => setMemberSenha(e.target.value)}
              value={memberSenha}
              error={errorSenhaMember}
            />
            <Form.Input
              label="Telefone"
              placeholder="Telefone"
              width={3}
              onChange={e => {
                setMemberTelefone(telMask(e.target.value));
                setErrorTelefoneMember(null);
              }}
              value={memberTelefone}
              error={errorTelefoneMember}
            />
            <Form.Select
              label="Tipo"
              placeholder="Tipo"
              width={3}
              onChange={handleChangeTipo.bind(this)}
              value={memberTipo}
              options={tipos}
              error={errorTipoMember}
            />
          </Form.Group>
          <Form.Group
            style={{
              marginBottom: "50px"
            }}
          >
            <Form.Select
              label="Permissão"
              placeholder="Permissão"
              width={4}
              options={permissoes}
              multiple
              onChange={handleChangePermissoes.bind()}
              value={memberPermissoes}
              error={errorPermissoesMember}
            />
            <Form.Select
              label="Tomadores"
              placeholder="Tomadores"
              width={4}
              multiple
              options={selectTomadores}
              onChange={handleChangeTomadoresMembers}
              value={tomadoresMembers}
            />
            <Button
              color="blue"
              style={{
                backgroundColor: "#00366C"
              }}
              onClick={e => addMember(e)}
            >
              Adicionar
            </Button>
          </Form.Group>

          <SectionTable>
            <Table>
              <ScrollContainer>
                <TableScroll columns="7">
                  <CellHeader singleLine>NOME</CellHeader>
                  <CellHeader>E-MAIL</CellHeader>
                  <CellHeader>TELEFONE</CellHeader>
                  <CellHeader>TIPO</CellHeader>
                  <CellHeader>PERMISSÕES</CellHeader>
                  <CellHeader>TOMADOR</CellHeader>
                  <CellHeader>OPÇÕES</CellHeader>
                  {members.companyUsers.map((member, key) => (
                    <Fragment key={key}>
                      <Cell singleLine>{member.nome}</Cell>
                      <Cell singleLine>{member.email}</Cell>
                      <Cell singleLine>{member.telefone}</Cell>
                      <Cell>{member.tipo}</Cell>
                      <Cell
                        alignItems="center"
                        justifyContent="start"
                        flexDirection="row"
                      >
                        {member.permissoes.map(permissao => (
                          <Label className="label-permissoes" circular>
                            {permissao}
                          </Label>
                        ))}
                      </Cell>
                      <Cell
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="row"
                    >
                      {member.tomadores.map((tomador, index) => (
                        <>
                          { index <= 1 && (
                            <Label className="label-tomadores" circular>
                              {tomador.nome}
                            </Label>
                          )}
                          {
                            index === 2 && (
                              <ButtonDefault
                                color="primary"
                                variant="text"
                                size="small"
                                onClick={() => handleOpenModalTomadores(member.tomadores)}
                              >
                                ver todos
                              </ButtonDefault>
                          )
                          }
                        </>
                      ))}
                    </Cell>
                      <Cell
                        alignItems="center"
                        justifyContent="start"
                        flexDirection="row"
                      >
                        <AiFillCloseCircle
                          className="icone-tabela"
                          size={27}
                          color="red"
                          onClick={() => removeMember(member)}
                        />
                        <AiOutlineMail size={27} color="#00366C" />
                      </Cell>
                    </Fragment>
                  ))}
                </TableScroll>
              </ScrollContainer>
            </Table>
          </SectionTable>
          <ModalListTakers
            showButtonDelete
            takers={listOfTomadoresByMember}
          />
          <Grid>
            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                <ButtonBorder onClick={() => history.push("/configuracoes")}>
                  CANCELAR
                </ButtonBorder>
                <ButtonSend type="submit">AVANÇAR</ButtonSend>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Content>
    );
  }

  function secondStep() {
    return (
      <Content>
        <Form
          onSubmit={() => sendForm()}
          style={{
            paddingTop: "35px"
          }}
        >
          <StepperEmpresa className="item-1" activeStep={1} />
          <SectionTable>
            <Table
              style={{
                overflowX: "scroll"
              }}
            >
              <TableScroll columns="8">
                <CellHeader fontWeight="500">Seguradora</CellHeader>
                <CellHeader fontWeight="500">Limite aprovado</CellHeader>
                <CellHeader fontWeight="500">Limite tomado</CellHeader>
                <CellHeader fontWeight="500">Limite disponível</CellHeader>
                <CellHeader fontWeight="500">Reserva</CellHeader>
                <CellHeader fontWeight="500">Taxa</CellHeader>
                <CellHeader fontWeight="500" width="300px">
                  Situação CCG
                </CellHeader>
                <CellHeader fontWeight="500">Validade do cadastro</CellHeader>
                {seguradoras.map((seguradora, key) => (
                  <>
                    <Fragment key={key}>
                      <Cell fontWeight="600">{seguradora.seguradora}</Cell>
                      <Cell fontWeight="600">R$ {seguradora.limiteTotal}</Cell>
                      <Cell fontWeight="600">
                        R$ {seguradora.limiteUtilizado}
                      </Cell>
                      <Cell fontWeight="600">
                        R$ {seguradora.limiteDisponivel}
                      </Cell>
                      <Cell fontWeight="600">R$ {seguradora.reserva}</Cell>
                      <Cell fontWeight="600">{seguradora.taxa}%</Cell>
                      <Cell fontWeight="600">
                        <TextField
                          value={seguradora.situacaoCCG}
                          onChange={e =>
                            handleChangeData(
                              e.target.value,
                              seguradora.validade,
                              seguradora
                            )
                          }
                        />
                      </Cell>
                      <Cell fontWeight="600">
                        <TextField
                          type="date"
                          value={seguradora.validade}
                          onChange={e =>
                            handleChangeData(
                              seguradora.situacaoCCG,
                              e.target.value,
                              seguradora
                            )
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Cell>
                    </Fragment>

                    <Fragment key={key}>
                      <Cell fontWeight="400">Judicial Recursal</Cell>
                      <Cell fontWeight="400">R$ {seguradora.limiteTotal}</Cell>
                      <Cell fontWeight="400">
                        R$ {seguradora.limiteUtilizado}
                      </Cell>
                      <Cell fontWeight="400">
                        R$ {seguradora.limiteDisponivel}
                      </Cell>
                      <Cell fontWeight="400">R$ {seguradora.reserva}</Cell>
                      <Cell fontWeight="400">{seguradora.taxa}%</Cell>
                      <Cell fontWeight="400">{seguradora.situacaoCCG}</Cell>
                      <Cell fontWeight="400">
                        {new Date(
                          seguradoraSelecionada.validade || Date.now()
                        ).toLocaleDateString()}
                      </Cell>
                    </Fragment>
                  </>
                ))}
                <Fragment>
                  <Cell>
                    <TextField
                      select
                      style={{
                        width: "200px"
                      }}
                      label="Nova Seguradora"
                      onChange={handleChangeSeguradora}
                    >
                      {seguradorasOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.limiteTotal}
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.limiteUtilizado}
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.limiteDisponivel}
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.reserva}
                  </Cell>
                  <Cell width="80px" fontWeight="bold">
                    {seguradoraSelecionada.taxa}%
                  </Cell>
                  <Cell></Cell>
                  <Cell>
                    {new Date(
                      seguradoraSelecionada.validade || Date.now()
                    ).toLocaleDateString()}
                  </Cell>
                </Fragment>
              </TableScroll>
            </Table>
          </SectionTable>

          <Grid>
            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                <ButtonBorder onClick={() => history.push("/configuracoes")}>
                  CANCELAR
                </ButtonBorder>
                <ButtonSend type="submit">FINALIZAR</ButtonSend>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Content>
    );
  }

  return (
    <div>
      <Navbar pageSelected="configuracoes" />

      <TitleContent>
        {step === "second" ? (
          <FiChevronLeft
            size={30}
            color="#568CCC"
            onClick={() => setStep("first")}
          />
        ) : (
          ""
        )}
        <h1>Novo grupo empresarial</h1>
      </TitleContent>
      {step === "first" ? firstStep() : secondStep()}
    </div>
  );
}

export default NovaEmpresa;
