import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";
// import DateFnsUtils from '@date-io/date-fns';
import { ModalBody, TitleContent, FilterContent } from "./style";
import ButtonDefault from "../../UI/ButtonDefault/index";
import Select from "../../UI/Select/index";
// import 'date-fns';
import { TextField } from "@material-ui/core";

function ModalFilterPipeline(props) {
  const { onClose, handleChangeQuery } = props;
  const open = useSelector(state => state.modal.ModalFilterPipeline);
  const grupos = useSelector(state => state.pipeline.groups);

  const [grupoSelecionado, setGrupoSelecionado] = useState("");
  const [consumoDados, setConsumoDados] = useState();
  const [toDateSelecionado, setToDateSelecionado] = useState(new Date());
  const [fromDateSelecionado, setFromDateSelecionado] = useState(new Date());
  const [query, setQuery] = useState({
    grupo: null,
    valorInvestido: null,
    to: "",
    from: ""
  });

  const gruposOptions = [
    { text: "-" },
    ...grupos.map(grupo => {
      return { text: grupo.nome, value: grupo.id };
    })
  ];

  const consumoOptions = [
    {
      text: "-",
      value: null
    },
    {
      text: "Sim",
      value: 1
    },
    {
      text: "Não",
      value: 0
    }
  ];

  function handleChangeGrupo(event) {
    setGrupoSelecionado(event.target.value);
    setQuery({
      ...query,
      grupo: event.target.value
    });
  }

  function handleChangeConsumo(event) {
    setConsumoDados(event.target.value);
    setQuery({
      ...query,
      valorInvestido: event.target.value
    });
  }

  function handleChangeToDate(event) {
    setToDateSelecionado(event.target.value);
    setQuery({
      ...query,
      to: event.target.value
    });
  }

  function handleChangeFromDate(event) {
    setFromDateSelecionado(event.target.value);
    setQuery({
      ...query,
      from: event.target.value
    });
  }

  function clearQuery() {
    setQuery({
      ...query,
      valorInvestido: null,
      grupo: null,
      to: "",
      from: ""
    });
    setGrupoSelecionado("");
    setConsumoDados("");
    setFromDateSelecionado(new Date());
    setToDateSelecionado(new Date());
  }

  useEffect(() => {
    const querySearch = {
      ...query,
      grupo: query.grupo === "-" ? null : query.grupo,
      valorInvestido: query.valorInvestido === "-" ? null : query.valorInvestido
    };
    handleChangeQuery(querySearch);
  }, [query]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Filtros</h1>
          <ButtonDefault variant="text" color="primary" onClick={clearQuery}>
            Limpar
          </ButtonDefault>
        </TitleContent>
        <FilterContent>
          <div className="select">
            <Select
              colorLabel="#185F9B"
              width="424px"
              label="Grupo econômico"
              valueOption={gruposOptions}
              valueSelect={grupoSelecionado}
              onChange={handleChangeGrupo}
            />
            <Select colorLabel="#185F9B" width="204px" label="Ordenar por" />
          </div>
          <div className="select">
            <TextField
              id="date"
              label="De"
              type="date"
              value={fromDateSelecionado}
              onChange={handleChangeFromDate}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="date"
              label="Até"
              type="date"
              value={toDateSelecionado}
              onChange={handleChangeToDate}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Select
              colorLabel="#185F9B"
              width="204px"
              label="Consumo de dados"
              valueOption={consumoOptions}
              valueSelect={consumoDados}
              onChange={handleChangeConsumo}
            />
          </div>
        </FilterContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalFilterPipeline;
