import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import {
  SectionTop,
  SectionCards,
  DetailsDiv,
  Row,
  ButtonBorder,
  TextStatus,
  DivStatus,
  Title,
  Content,
  TitleDetails,
  InputText
} from "./style";
import CardProcessMonitoring from "../../components/Card/CardProcessMonitoring";
import Navbar from "../../components/Navbar/index";
import {
  getAccompanimentProcess,
  getAccompanimentProcessWithoutStatus
} from "../../actions/process";
import { getPolicy } from "../../actions/policy";
import { Grid, GridRow } from "semantic-ui-react";
import CardDownloadPDF from "../../components/Card/CardDownloadPDF";
import { cnpjMask } from "../../services/mask";
import ModalNewEndorsement from "../../components/Modal/ModalNewEndorsement";
import { setModalNewEndorsement } from "../../actions/modal";

function DetalhesApolice() {
  const accompanimentProcess = useSelector(
    state => state.process.accompanimentProcess
  );
  const policy = useSelector(state => state.policy.policy.policy);
  const documentos = useSelector(state => state.policy.policy.documentos);

  const firstInstance = useSelector(
    state => state.process.accompanimentProcess.allDetails.firstInstance
  );
  const secondInstance = useSelector(
    state => state.process.accompanimentProcess.allDetails.secondInstance
  );
  const lastInstance = useSelector(
    state => state.process.accompanimentProcess.allDetails.lastInstance
  );
  const accompanimentProcessWithoutStatus = useSelector(
    state => state.process.accompanimentProcessWithoutStatus
  );

  const dispatch = useDispatch();
  const { idApolice } = useParams();

  useEffect(() => {
    dispatch(getPolicy(idApolice));
  }, [dispatch, idApolice]);

  useEffect(() => {
    if ((policy.process || {}).id)
      dispatch(getAccompanimentProcess(policy.process.id));
  }, [dispatch, policy]);

  const [waitingStatus, setWaitingStatus] = useState(false);
  const [tribunal, setTribunal] = useState({
    firstInstance: "",
    secondInstance: "",
    lastInstance: ""
  });
  const [process, setProcess] = useState({
    numeroProcesso: "",
    tribunal: "",
    vara: "",
    ativoPartes: [],
    passivoPartes: [],
    valorCausa: ""
  });

  // esse trecho busca informações do proprio banco caso a bipbop não retornar
  // nada.
  useEffect(() => {
    if (
      !accompanimentProcess.firstInstance &&
      !accompanimentProcess.secondInstance &&
      !accompanimentProcess.lastInstance
    ) {
      dispatch(getAccompanimentProcessWithoutStatus((policy.process || {}).id));
    }
  }, [accompanimentProcess, dispatch, policy]);

  useEffect(() => {
    const result = accompanimentProcessWithoutStatus.firstInstance;
    setProcess(p => {
      return {
        ...p,
        ...result
      };
    });
  }, [accompanimentProcessWithoutStatus]);

  useEffect(() => {
    if (
      !accompanimentProcess.firstInstance &&
      !accompanimentProcess.secondInstance &&
      !accompanimentProcess.lastInstance
    ) {
      setWaitingStatus(true);
    }

    // Esse trecho remove o nome inteiro dos tribunais deixando só a sigla.
    // espera-se que por padrão a sigla venha antes de um "-" na requisição.
    const tribunalFirst =
      accompanimentProcess.allDetails.firstInstance.tribunal;
    const tribunalSecond =
      accompanimentProcess.allDetails.secondInstance.tribunal;
    const tribunalLast = accompanimentProcess.allDetails.firstInstance.tribunal;
    setTribunal(t => {
      return {
        ...t,
        firstInstance: tribunalFirst.substr(0, tribunalFirst.indexOf("-")),
        secondInstance: tribunalSecond.substr(0, tribunalSecond.indexOf("-")),
        lastInstance: tribunalLast.substr(0, tribunalLast.indexOf("-"))
      };
    });
  }, [accompanimentProcess]);

  useEffect(() => {
    if (accompanimentProcess.firstInstance) {
      setProcess(p => {
        return {
          ...p,
          numeroProcesso: firstInstance.numeroProcesso,
          tribunal: firstInstance.tribunal,
          vara: firstInstance.vara,
          ativoPartes: firstInstance.ativoPartes,
          passivoPartes: firstInstance.passivoPartes,
          valorCausa: firstInstance.valorCausa
        };
      });
    } else if (accompanimentProcess.secondInstance) {
      setProcess(p => {
        return {
          ...p,
          numeroProcesso: secondInstance.numeroProcesso,
          tribunal: secondInstance.tribunal,
          vara: secondInstance.vara,
          ativoPartes: secondInstance.ativoPartes,
          passivoPartes: secondInstance.passivoPartes,
          valorCausa: secondInstance.valorCausa
        };
      });
    } else if (accompanimentProcess.lastInstance) {
      setProcess(p => {
        return {
          ...p,
          numeroProcesso: lastInstance.numeroProcesso,
          tribunal: lastInstance.tribunal,
          vara: lastInstance.vara,
          ativoPartes: lastInstance.ativoPartes,
          passivoPartes: lastInstance.passivoPartes,
          valorCausa: lastInstance.valorCausa
        };
      });
    }
  }, [accompanimentProcess, firstInstance, secondInstance, lastInstance]);

  const handleCloseModalNewEndorsement = () => {
    dispatch(setModalNewEndorsement(false));
  };

  const handleOpenModalNewEndorsement = () => {
    dispatch(setModalNewEndorsement(true));
  };

  return (
    <div>
      <Navbar pageSelected="documentos-emitidos" />
      <Content>
        <SectionTop>
          <Grid>
            <GridRow>
              <ArrowBackIosIcon
                onClick={() => dispatch(push("/documentos-emitidos"))}
              />
              <h1>Detalhes do Processo</h1>
            </GridRow>
          </Grid>
          {policy.endorsement.some(
            endorsement =>
              !["FINALIZADA", "FINALIZADA COM PROBLEMA"].includes(
                endorsement.status
              )
          ) ? (
            ""
          ) : (
            <ButtonBorder onClick={() => handleOpenModalNewEndorsement()}>
              Solicitar endosso
            </ButtonBorder>
          )}
        </SectionTop>
        <DivStatus vencida={new Date(policy.finalVigencia) <= new Date()}>
          <TextStatus
            weight="400"
            size="12px"
            style={{
              color: "#424242"
            }}
          >
            Status da apólice
          </TextStatus>
          <TextStatus>
            {new Date(policy.finalVigencia) >= new Date()
              ? "Vigente"
              : "Vencida"}
          </TextStatus>
        </DivStatus>
        <Title>Acompanhamento</Title>
        <SectionCards>
          <CardProcessMonitoring
            title={
              tribunal.firstInstance
                ? `1ª Instância - ${tribunal.firstInstance}`
                : "1ª Instância"
            }
            listStatus={accompanimentProcess.firstInstance}
            waitingStatus={waitingStatus}
          />
          <CardProcessMonitoring
            title={
              tribunal.secondInstance
                ? `2ª Instância - ${tribunal.secondInstance}`
                : "2ª Instância"
            }
            listStatus={accompanimentProcess.secondInstance}
            waitingStatus={waitingStatus}
          />
          <CardProcessMonitoring
            title="TST"
            listStatus={accompanimentProcess.lastInstance}
            waitingStatus={waitingStatus}
          />
        </SectionCards>
        <Title>Arquivos</Title>
        <SectionCards>
          <CardDownloadPDF title="Apólice" pdf={documentos.policyBase64} />
          <CardDownloadPDF
            title="Boleto"
            pdf={(documentos.billetBase64 || [])[0]}
          />
          <CardDownloadPDF
            title="Certificado da Apólice"
            pdf={documentos.certificadoApoliceBase64}
          />
          <CardDownloadPDF
            title="Certificado da Seguradora"
            pdf={documentos.certificadoSeguradoraBase64}
          />
        </SectionCards>
        <Title>Informações do processo</Title>
        <DetailsDiv
          rows={process.ativoPartes.length + process.passivoPartes.length}
        >
          <InputText
            className="firstInput"
            label="Tipo de recurso"
            width="278px"
            disabled
            value={policy.tipoRecurso}
          />
          <TitleDetails>Tomador</TitleDetails>
          <Row>
            <InputText
              label="Nome do Tomador"
              width="430px"
              disabled
              value={policy.nomeTomador}
            />
            <InputText
              label="CNPJ"
              width="320px"
              disabled
              value={cnpjMask(policy.tomadorCNPJ || "")}
            />
          </Row>

          <TitleDetails>Informações da obrigação do segurado</TitleDetails>
          <Row>
            <InputText
              label="CPF/CNPJ do segurado"
              width="320px"
              disabled
              value={cnpjMask(policy.process.cpfReclamante || "")}
            />
            <InputText
              label="Nome do Segurado"
              width="430px"
              disabled
              value={policy.nomeSegurado}
            />
          </Row>

          <TitleDetails>Endereço</TitleDetails>
          <Row>
            <InputText
              label="CEP do Segurado"
              width="200px"
              disabled
              value={policy.process.cepReclamante}
            />
            <InputText
              label="Cidade do Segurado"
              width="320px"
              disabled
              value={policy.process.cidadeReclamante}
            />
            <InputText
              label="UF"
              width="90px"
              disabled
              value={policy.process.ufReclamante}
            />
            <InputText
              label="Bairro"
              width="320px"
              disabled
              value={policy.process.bairroReclamante}
            />
          </Row>
          <Row>
            <InputText
              label="Logradouro"
              width="320px"
              disabled
              value={policy.process.logradouroReclamante}
            />
            <InputText
              label="Número"
              width="90px"
              disabled
              value={policy.process.numeroReclamante}
            />

            <InputText
              label="Complemento"
              width="546px"
              disabled
              value={policy.process.complementoReclamante}
            />
          </Row>

          <TitleDetails>Processo</TitleDetails>
          <Row>
            <InputText
              label="Número do Processo"
              width="278px"
              disabled
              value={policy.process.numeroProcesso}
            />
            <InputText
              label="Vara vinculada"
              width="392px"
              disabled
              value={policy.process.vara}
            />
            <InputText
              className="secondInput"
              label="Tribunal e região da justiça de trabalho"
              width="500px"
              disabled
              value={policy.process.tribunal}
            />
          </Row>
          <Row>
            <InputText
              label="Identificador do cliente"
              width="278px"
              disabled
              value={policy.identificadorCliente}
            />
          </Row>
        </DetailsDiv>
      </Content>
      <ModalNewEndorsement onClose={handleCloseModalNewEndorsement} />
    </div>
  );
}

export default DetalhesApolice;
