import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 720px;
  height: 240px;
  margin: auto;
  margin-top: 8%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0C0142;
    margin: 0;
    line-height: 20px;
  }
`;
