/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  Table,
  TableFixed,
  TableScroll,
  Cell,
  CellDetails,
  CellHeader,
  ContentDetail,
  CellHeaderDetails
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import ButtonDefault from "../../UI/ButtonDefault";
import ScrollContainer from "react-indiana-drag-scroll";
import { FiDownload } from "react-icons/fi";
import InputText from "../../UI/InputText";
import { Grid } from "semantic-ui-react";
import { getDetailEndorsement } from "../../../actions/endorsement";

function ModalEndorsementHistoric(props) {
  const { onClose } = props;
  const open = useSelector(state => state.modal.ModalEndorsementHistoric);
  const { policy } = useSelector(state => state.policy.policy);

  const [seeDetailEndorsement, setSeeDetailEndorsement] = useState(false);
  const [endorsement, setEndorsement] = useState({});

  const download = (file, filename) => {
    const link = document.createElement("a");
    link.href = "data:application/pdf;base64," + file;
    link.download = filename;
    link.click();
  };

  const selectEndorsement = async id => {
    setSeeDetailEndorsement(true);
    setEndorsement(await getDetailEndorsement(id));
  };

  const DetailEndorsement = () => {
    return (
      <>
        <Table>
          <ScrollContainer>
            <TableScroll>
              <CellHeader>Tipo de endosso</CellHeader>
              <CellHeader>solicitado em</CellHeader>
              <CellHeader>por</CellHeader>
              <CellHeader>finalizado em</CellHeader>
              <CellHeader>processo</CellHeader>
              <CellHeader>status</CellHeader>

              <Fragment key={endorsement.id}>
                <Cell>{endorsement.tipoEndosso}</Cell>
                <Cell>
                  {new Date(endorsement.dataEndosso).toLocaleDateString()}
                </Cell>
                <Cell>{(endorsement.idUser || {}).nome}</Cell>
                <Cell>
                  {new Date(endorsement.dataFinalizacao).toLocaleDateString()}
                </Cell>
                <Cell>{policy.process.numeroProcesso}</Cell>
                <Cell>{endorsement.status}</Cell>
              </Fragment>
            </TableScroll>
          </ScrollContainer>
          <TableFixed>
            <CellHeaderDetails>Detalhes</CellHeaderDetails>
            <CellDetails key={endorsement.id}>
              <ButtonDefault
                color="primary"
                customColor="#3498DB"
                colorLabel="#FFFFFF"
                size="small"
                fontSize="12px"
                onClick={() => setSeeDetailEndorsement(false)}
              >
                Ver Menos
              </ButtonDefault>
            </CellDetails>
          </TableFixed>
        </Table>
        <ContentDetail>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <label>Descrição do chamado:</label>
                <p>{endorsement.descricaoSolicitacao}</p>
              </Grid.Column>
              <Grid.Column width={8}>
                <label>Arquivos:</label>
                {(endorsement.arquivoSolicitacao || []).map(file => (
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={10}>
                        <h4>{file.filename}</h4>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <FiDownload
                          color="#F9B233"
                          size={20}
                          onClick={() => download(file.path, file.filename)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                ))}
              </Grid.Column>
            </Grid.Row>
            {endorsement.tipoEndosso ? (
              <Grid.Row>
                <Grid.Column>
                  <InputText
                    label="Tipo de endosso"
                    value={endorsement.tipoEndosso}
                    disabled
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              ""
            )}
            {endorsement.descricaoDevolutiva ? (
              <>
                {" "}
                <Grid.Row>
                  <Grid.Column>
                    <h4 style={{ marginTop: "20px" }}>Devolutiva</h4>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <InputText
                      label="Status da apólice"
                      value={endorsement.statusApolice}
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label>Descrição devolutiva:</label>
                    <p>{endorsement.descricaoDevolutiva}</p>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <label>Arquivos:</label>

                    {(endorsement.arquivoDevolutiva || []).map(file => (
                      <div>
                        <h4>{file.filename}</h4>
                        <FiDownload
                          color="#F9B233"
                          size={20}
                          onClick={() => download(file.path, file.filename)}
                        />
                      </div>
                    ))}
                  </Grid.Column>
                </Grid.Row>{" "}
              </>
            ) : (
              ""
            )}
            {endorsement.descricaoProblema ? (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <h4 style={{ marginTop: "20px" }}>Resposta</h4>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <label>Descrição do problema:</label>
                    <p>{endorsement.descricaoProblema}</p>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <label>Arquivos:</label>
                    {(endorsement.arquivoProblema || []).map(file => (
                      <div>
                        <h4>{file.filename}</h4>
                        <FiDownload
                          color="#F9B233"
                          size={20}
                          onClick={() => download(file.path, file.filename)}
                        />
                      </div>
                    ))}
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ""
            )}
          </Grid>
        </ContentDetail>
      </>
    );
  };

  const Historic = () => {
    return (
      <Table>
        <ScrollContainer>
          <TableScroll>
            <CellHeader>Tipo de endosso</CellHeader>
            <CellHeader>solicitado em</CellHeader>
            <CellHeader>por</CellHeader>
            <CellHeader>finalizado em</CellHeader>
            <CellHeader>processo</CellHeader>
            <CellHeader>status</CellHeader>

            {policy.endorsement.map(endorsement => (
              <Fragment key={endorsement.id}>
                <Cell>{endorsement.tipoEndosso}</Cell>
                <Cell>
                  {new Date(endorsement.dataEndosso).toLocaleDateString()}
                </Cell>
                <Cell>{(endorsement.idUser || {}).nome}</Cell>
                <Cell>
                  {new Date(endorsement.dataFinalizacao).toLocaleDateString()}
                </Cell>
                <Cell>{policy.process.numeroProcesso}</Cell>
                <Cell>{endorsement.status}</Cell>
              </Fragment>
            ))}
          </TableScroll>
        </ScrollContainer>
        <TableFixed>
          <CellHeaderDetails>Detalhes</CellHeaderDetails>
          {policy.endorsement.map(endorsement => (
            <CellDetails key={endorsement.id}>
              <ButtonDefault
                color="primary"
                customColor="#3498DB"
                colorLabel="#FFFFFF"
                size="small"
                fontSize="12px"
                onClick={() => selectEndorsement(endorsement.id)}
              >
                Ver Mais
              </ButtonDefault>
            </CellDetails>
          ))}
        </TableFixed>
      </Table>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Histórico de endossos</h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          {seeDetailEndorsement ? <DetailEndorsement /> : <Historic />}
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalEndorsementHistoric;

ModalEndorsementHistoric.propType = {
  onClose: PropType.func
};
