/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from './api';
import { push } from 'connected-react-router';

export const setInsurer = (insurer) => ({
  type: 'SET_INSURER',
  payload: {
    insurer,
  },
});

export const createInsurer = (newInsurer) => async (dispatch) => {
  try {
    await axios.post(`insurer`, newInsurer);
    dispatch(push('/configuracoes'));
  } catch (err) {
    console.log(err);
  }
};

export const setInsurers = (insurers) => ({
  type: 'SET_INSURERS',
  payload: {
    insurers,
  },
});

export const findAllInsurer = (query) => async (dispatch) => {
  try {
    const response = await axios.get(`insurer`, {params: query});
    dispatch(setInsurers(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const findOneInsurer = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`insurer/list/${id}`);
    dispatch(setInsurer(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const deleteOneInsurer = (id) => async (dispatch) => {
  try {
    await axios.delete(`insurer/delete/`+id);
  } catch (err) {
    console.log(err);
  }
};


