import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const StyledButton = withStyles({
  root: {
    borderRadius: (props) => props.borderradius,
    backgroundColor: (props) => props.backgroundcolor,
    fontSize: (props) => props.fontSize,
    '&:hover': {
      backgroundColor: (props) => props.backgroundcolor,
    },
    height: (props) => props.height,
    width: (props) => props.fullWidth,
    margin: (props) => props.margin
  },
  label: {
    color: (props) => props.colorlabel,
  },
})(Button);
