export const initialState = {
  ModalNewProcess: false,
  ModalNewIndividualProcess: false,
  ModalNewMassiveProcess: false,
  ModalFilterProcess: false,
  ModalEditCompany: false,
  ModalEditMember: false,
  ModalCreateMember: false,
  ModalMinuta: false,
  ModalNewProcessError: false,
  ModalNewLead: false,
  ModalArchiveLead: false,
  ModalFilterPolicy: false,
  ModalBudget: false,
  ModalFilterPipeline: false,
  ModalNewPolicyAdm: false,
  ModalConsetUser: false,
  ModalNewEndorsement: false,
  ModalEndorsementProblem: false,
  ModalDevolutiveEndorsement: false,
  ModalEndorsementHistoric: false,
  ModalProcessSentence: false,
  ModalProcessAction: false,
  ModalActionWarranty: false,
  ModalCompromisePolicy: false,
  ModalFilterDashboard: false,
  ModalFilterEndorsement: false,
  ModalEditPasswordCompany: false,
  ModalEditPasswordConset: false,
  ModalPaymentHistoric: false,
  ModalEditCauseValue: false,
  ModalUpdateProcessesLead: false,
  ModalNewTomador: false,
  ModalListTakers: false,
  ModalLimits: false,
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODAL_NEW_PROCESS':
      return {
        ...state, ModalNewProcess: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_INDIVIDUAL_PROCESS':
      return {
        ...state, ModalNewIndividualProcess: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_MASSIVE_PROCESS':
      return {
        ...state, ModalNewMassiveProcess: action.payload.trigger,
      };
    case 'SET_MODAL_FILTER_PROCESS':
      return {
        ...state, ModalFilterProcess: action.payload.trigger,
      };
    case 'SET_MODAL_EDIT_COMPANY':
      return {
        ...state, ModalEditCompany: action.payload.trigger,
      };
    case 'SET_MODAL_EDIT_MEMBER':
      return {
        ...state, ModalEditMember: action.payload.trigger,
      };
    case 'SET_MODAL_CREATE_MEMBER':
      return {
        ...state, ModalCreateMember: action.payload.trigger,
      };
    case 'SET_MODAL_MINUTA':
      return {
        ...state, ModalMinuta: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_PROCESS_ERROR':
      return {
        ...state, ModalNewProcessError: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_LEAD':
      return {
        ...state, ModalNewLead: action.payload.trigger,
      };
    case 'SET_MODAL_ARCHIVE_LEAD':
      return {
        ...state, ModalArchiveLead: action.payload.trigger,
      };
    case 'SET_MODAL_FILTER_POLICY':
      return {
        ...state, ModalFilterPolicy: action.payload.trigger,
      };
    case 'SET_MODAL_BUDGET':
      return {
        ...state, ModalBudget: action.payload.trigger,
      };
    case 'SET_MODAL_FILTER_PIPELINE':
      return {
        ...state, ModalFilterPipeline: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_POLICY_ADM':
      return {
        ...state, ModalNewPolicyAdm: action.payload.trigger,
      };
    case 'SET_MODAL_CONSET_USER':
      return {
        ...state, ModalConsetUser: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_ENDORSEMENT':
      return {
        ...state, ModalNewEndorsement: action.payload.trigger,
      };
    case 'SET_MODAL_SOLICITATION':
      return {
        ...state, ModalSolicitation: action.payload.trigger,
      };
    case 'SET_MODAL_ENDORSEMENT_PROBLEM':
      return {
        ...state, ModalEndorsementProblem: action.payload.trigger,
      };
    case 'SET_MODAL_DEVOLUTIVE_ENDORSEMENT':
      return {
        ...state, ModalDevolutiveEndorsement: action.payload.trigger,
      };
    case 'SET_MODAL_ENDORSEMENT_HISTORIC':
      return {
        ...state, ModalEndorsementHistoric: action.payload.trigger,
      };
    case 'SET_MODAL_PROCESS_SENTENCE':
      return {
        ...state, ModalProcessSentence: action.payload.trigger,
      };
    case 'SET_MODAL_PROCESS_WARRANTY':
      return {
        ...state, ModalProcessWarranty: action.payload.trigger,
      };
    case 'SET_MODAL_ACTION_WARRANTY':
      return {
        ...state, ModalActionWarranty: action.payload.trigger,
      };
    case 'SET_MODAL_COMPROMISE_POLICY':
      return {
        ...state, ModalCompromisePolicy: action.payload.trigger,
      };
    case 'SET_MODAL_FILTER_DASHBOARD':
      return {
        ...state, ModalFilterDashboard: action.payload.trigger,
      };
    case 'SET_MODAL_FILTER_ENDORSEMENT':
      return {
        ...state, ModalFilterEndorsement: action.payload.trigger,
      };
    case 'SET_MODAL_EDIT_PASSWORD_COMPANY':
      return {
        ...state, ModalEditPasswordCompany: action.payload.trigger,
      };
    case 'SET_MODAL_EDIT_PASSWORD_CONSET':
      return {
        ...state, ModalEditPasswordConset: action.payload.trigger,
      };
    case 'SET_MODAL_PAYMENT_HISTORIC':
      return {
        ...state, ModalPaymentHistoric: action.payload.trigger,
      };
    case 'SET_MODAL_EDIT_CAUSE_VALUE':
      return {
        ...state, ModalEditCauseValue: action.payload.trigger,
      };
    case 'SET_MODAL_UPDATE_PROCESSES_LEAD':
      return {
        ...state, ModalUpdateProcessesLead: action.payload.trigger,
      };
    case 'SET_MODAL_NEW_TOMADOR':
      return {
        ...state, ModalNewTomador: action.payload.trigger,
      };
    case 'SET_MODAL_LIST_TAKERS':
      return {
        ...state, ModalListTakers: action.payload.trigger,
      };
    case 'SET_MODAL_LIMITS':
      return { ...state, ModalLimits: action.payload.trigger };
    default:
      return state;
  }
};

export default modal;
