import styled from 'styled-components';
import InputText from '../../components/UI/InputText';

export const SectionTop = styled.div `
  display: flex;
  justify-content: start;
  margin: 0 48px;
  margin-top: -38px;
  align-items: center;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #0C142;
    margin: 0;
    margin-left: 15px;
  }
`;

export const SectionCards = styled.div `
  margin: 59px 48px 40px 48px;
  display: flex;
  justify-content: space-around;
`;

export const SectionProcessDetails = styled.div `
  display: flex;
  margin: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 30px 40px 100px 40px;
  flex-wrap: wrap;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #00235C;
    width: 100%;
  }
`;

export const DetailsDiv = styled.div `
  width: 100%;
  display: grid;
  grid-template-columns: 302px 114px 1fr;
  grid-template-rows: repeat(${(props) => 3 + props.rows}, 96px);
  align-items: end;

  .firstInput {
    grid-column: 1;
    grid-row: 1;
  }

  .secondInput {
    grid-column: 2/4;
    grid-row: 1;
  }

  .thirdInput {
    grid-column: 1/3;
    grid-row: 2;
  }


  .eighthInput {
    grid-column: 1;
    grid-row: ${(props) => props.row};
  }
`;

export const InputTextOfList = styled(InputText)
`
  .fourthInput {
    grid-column: 1/3;
    grid-row: ${(props) => 3 + props.row};
  }

  .fifthInput {
    grid-column: 3;
    grid-row: ${(props) => 3 + props.row};
  }

  .sixthInput {
    grid-column: 1/3;
    grid-row: ${(props) => 4 + props.row};
  }

  .seventhInput {
    grid-column: 3;
    grid-row: ${(props) => 4 + props.row};
  }
  grid-row: ${(props) => props.row};
  grid-column: ${(props) => props.column} ;
`;

export const AccompanimentDiv = styled.div `
  background: #FFFFFF;
  border-radius: 8px;
  margin: 48px;
  padding: 30px 40px 100px 40px;
  h1 {
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #424242;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #616161;
    margin: 0;
    width: 736px;
    margin-bottom: 32px;
  }

  h3{
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #00366C;
    margin: 0;
    margin-bottom: 40px;
  }
`;


export const ProcessInfoLabel = styled.div `
  text-align: center;
  p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    -webkit-letter-spacing: 0.1px;
    -moz-letter-spacing: 0.1px;
    -ms-letter-spacing: 0.1px;
    letter-spacing: 0.1px;
    color: #00235C;
    width: 100%;
  }
`;
