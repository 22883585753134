import React, { useEffect, useState, Fragment } from "react";
import { formatToBRL, parseToNumber } from "brazilian-values";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";

import {
  FiAlertCircle,
  FiAlertTriangle,
  FiChevronLeft,
  FiDownload,
  FiEye
} from "react-icons/fi";
import { AiFillFile } from "react-icons/ai";
import { Checkbox, Form, Grid, Pagination } from "semantic-ui-react";
import ModalBudget from "../../../components/Modal/ModalBudget";
import Navbar from "../../../components/Navbar";
import {
  HeaderPage,
  Content,
  TitleCard,
  LegendCard,
  ValueLegendCard,
  ButtonDefault,
  CardLoading,
  CardInfo,
  ButtonBorder,
  FooterBudget
} from "./style";
import {
  Table,
  CellStatus,
  TableScroll,
  Cell,
  CellSelect,
  CellHeader,
  CellHeaderSelect,
  TableFixed
} from "../style";

import {
  editLead,
  editLeadProcess,
  exportListLeadProcesses,
  fullScan,
  getActionTypes,
  getAllProcesses,
  getWarrantyTypes,
  listLead,
  listLeadProcesses,
  searchProcess,
  setLeadProcess,
  setLeadProcesses,
  setProcessPage
} from "../../../actions/pipeline";
import {
  setModalActionWarranty,
  setModalBudget,
  setModalEditCauseValue,
  setModalUpdateProcessesLead
} from "../../../actions/modal";
import { push } from "connected-react-router";
import { listValues } from "../../../actions/bipbop-report";
import ChartLineColumn from "../../../components/Chart/ChartLineColumn";
import {
  setModalProcessSentence,
  setModalProcessWarranty
} from "../../../actions/modal";
import ModalProcessSentence from "../../../components/Modal/ModalProcessSentence";
import ModalProcessWarranty from "../../../components/Modal/ModalProcessWarranty";
import ModalActionWarranty from "../../../components/Modal/ModalActionWarranty";
import { setLoadingSearchProcess } from "../../../actions/loading";
import { formatToNumber, moneyMask } from "../../../services/mask";
import { useDebounce } from "../../../services/hooks";
import ModalEditCauseValue from "../../../components/Modal/ModalEditCauseValue";
import ModalUpdateProcessesLead from "../../../components/Modal/ModalUpdateProcessLead";

function DetalhesLead() {
  const dispatch = useDispatch();
  const { idLead } = useParams();
  const lead = useSelector(state => state.pipeline.lead);
  const processes = useSelector(state => state.pipeline.processes);
  const actionDashboard = useSelector(state => state.pipeline.actionDashboard);
  const warrantyDashboard = useSelector(
    state => state.pipeline.warrantyDashboard
  );
  const bipbopValues = useSelector(state => state.bipbopReport.values);
  const socketId = useSelector(state => state.socket.clientID);
  const loadingDetailProcess = useSelector(
    state => state.loading.LoadingDetailProcess
  );

  const [searchBipbop, setSearchBipbop] = useState(false);
  const [change, setChange] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [pageProcess, setPageProcess] = useState(1);
  const perPageProcess = 50;
  const [selectProcess, setSelectProcess] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [unitValueBudget, setUnitValueBudget] = useState(0);
  const [amountBudget, setAmountBudget] = useState(0);
  const [typeBudget, setTypeBudget] = useState("");

  const [process, setProcess] = useState({});

  const [titleType, setTitleType] = useState("");
  const [typesActionWarranty, setTypesActionWarranty] = useState([]);

  const [causeValue, setSearchEndorsements] = useState("");

  useEffect(() => {
    dispatch(listLead(idLead));
    dispatch(listValues());
  }, [dispatch, idLead]);

  useEffect(() => {
    dispatch(getActionTypes(idLead));
    dispatch(getWarrantyTypes(idLead));
  }, [dispatch, idLead, lead]);

  useEffect(() => {
    dispatch(
      listLeadProcesses({
        leadId: idLead,
        limit: 50,
        page: pageProcess - 1
      })
    );
    dispatch(setProcessPage(pageProcess));
  }, [dispatch, idLead, pageProcess]);


  const formatDate = (iso = "") => {
    let date = (iso || "").split("T");
    date = date[0].split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  };

  const labelsActionType = actionDashboard.slice(0, 5).map(({ nome }) => nome);

  const dataActionType = [
    {
      name: "Valor da causa",
      type: "column",
      data: actionDashboard.slice(0, 5).map(({ valorCausa }) => valorCausa)
    },
    {
      name: "Qtd",
      type: "line",
      data: actionDashboard.slice(0, 5).map(({ quantidade }) => quantidade)
    }
  ];

  const labelsWarrantyType = warrantyDashboard
    .slice(0, 5)
    .map(({ nome }) => nome);

  const dataWarrantyType = [
    {
      name: "Valor da causa",
      type: "column",
      data: warrantyDashboard.slice(0, 5).map(({ valorCausa }) => valorCausa)
    },
    {
      name: "Qtd",
      type: "line",
      data: warrantyDashboard.slice(0, 5).map(({ quantidade }) => quantidade)
    }
  ];

  const addProcess = process => {
    const processes = processList;
    processes.push(process);
    setProcessList(processes);
    setChange(!change);
  };

  const removeProcess = process => {
    const processes = processList;
    processes.splice(
      processes.findIndex(processL => processL === process),
      1
    );
    setProcessList(processes);
    setChange(!change);
    setAllChecked(false);
  };

  const eventAllChecked = async checked => {
    let processesNew = [];

    if (checked) {
      processesNew = await getAllProcesses({
        leadId: idLead,
        limit: lead.quantidadeProcessos
      });
    }

    setProcessList(processesNew.map(process => process.id));
    setAllChecked(checked);
  };

  const eventSearchProcess = dates => {
    if (typeBudget === "pesquisaSimples") {
      dispatch(setLoadingSearchProcess(true));
      setSearchBipbop(true);
      dispatch(searchProcess({ leadId: lead.id, socketId, ...dates }));
    }
    if (typeBudget === "pesquisaCompleta") {
      setSelectProcess(false);
      dispatch(setLoadingSearchProcess(true));
      const data = {
        socketId,
        processList,
        leadId: lead.id
      };
      dispatch(fullScan(data));
    }
  };

  const handleCloseModalProcessSentence = () => {
    dispatch(setModalProcessSentence(false));
  };

  const handleOpenModalProcessSentence = process => {
    setProcess(process || {});
    dispatch(setModalProcessSentence(true));
  };

  const handleCloseModalProcessWarranty = () => {
    dispatch(setModalProcessWarranty(false));
  };

  const handleOpenModalProcessWarranty = process => {
    setProcess(process || {});
    dispatch(setModalProcessWarranty(true));
  };

  const handleCloseModalBudget = () => {
    dispatch(setModalBudget(false));
  };

  const handleOpenModalBudget = ({ unitValue, amount, type }) => {
    setUnitValueBudget(unitValue);
    setAmountBudget(amount);
    setTypeBudget(type);
    dispatch(setModalBudget(true));
  };

  const handleOpenModalActionWarranty = (title, types) => {
    dispatch(setModalActionWarranty(true));
    setTitleType(title);
    setTypesActionWarranty(types);
  };

  const handleCloseModalActionWarranty = () => {
    dispatch(setModalActionWarranty(false));
  };

  const handleOpenModalEditCauseValue = process => {
    dispatch(setModalEditCauseValue(true));
    dispatch(setLeadProcess(process));
  };

  const handleCloseModalEditCauseValue = () => {
    dispatch(setModalEditCauseValue(false));
  };

  const handleOpenModalUpdateProcesses = () => {
    dispatch(setModalUpdateProcessesLead(true));
  };

  const handleCloseModalUpdateProcesses = () => {
    dispatch(setModalUpdateProcessesLead(false));
  };

  const handleChangeSubstituicaoEstudo = value => {
    dispatch(
      editLead(lead.id, {
        substituicaoEstudo: parseToNumber(value.replace("R$", ""))
      })
    );
  };

  const exportLeadProcesses = async () => {
    const response = await exportListLeadProcesses({ leadId: idLead });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "processos.xlsx");
    link.click();
  };

  function WithoutProcesses() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <CardInfo>
              <TitleCard>Informações do grupo</TitleCard>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <LegendCard marginTop="30px">Grupo econômico</LegendCard>
                    <ValueLegendCard>{(lead.grupo || {}).nome}</ValueLegendCard>
                    <LegendCard marginTop="30px">Razão Social</LegendCard>
                    <ValueLegendCard>{lead.razaoSocial}</ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <LegendCard marginTop="30px">Empresa</LegendCard>
                    <ValueLegendCard>{lead.nome}</ValueLegendCard>
                    <LegendCard marginTop="30px">CNPJ</LegendCard>
                    <ValueLegendCard>{lead.cnpj}</ValueLegendCard>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </CardInfo>
          </Grid.Column>
          <Grid.Column width={8}>
            <CardInfo>
              <TitleCard>Saneamento de dados</TitleCard>
              <LegendCard>Essa busca está sujeita a taxas</LegendCard>
              <ButtonDefault
                onClick={() =>
                  handleOpenModalBudget({
                    unitValue: bipbopValues.pesquisaEmpresa.valor,
                    amount: 1,
                    type: "pesquisaSimples"
                  })
                }
              >
                ORÇAMENTO
              </ButtonDefault>
            </CardInfo>
          </Grid.Column>
        </Grid.Row>
        {searchBipbop ? (
          <CardLoading>
            <h1>Aguarde um momento</h1>
            <img
              src={require("../../../assets/images/loading.gif")}
              width="300"
              alt="loading"
            />
            <p>O processo de saneamento de dados está em andamento!</p>
          </CardLoading>
        ) : (
          ""
        )}
      </Grid>
    );
  }

  function WithProcesses() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <CardInfo height="150px">
              <TitleCard>Informações do grupo</TitleCard>
              <Grid>
                <Grid.Row verticalAlign="middle">
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">Grupo econômico</LegendCard>
                    <ValueLegendCard>{(lead.grupo || {}).nome}</ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">Razão Social</LegendCard>
                    <ValueLegendCard>{lead.razaoSocial}</ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">Empresa</LegendCard>
                    <ValueLegendCard>{lead.nome}</ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">CNPJ</LegendCard>
                    <ValueLegendCard>{lead.cnpj}</ValueLegendCard>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </CardInfo>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <CardInfo height="220px">
              <TitleCard>Valores investido na busca</TitleCard>
              <Grid>
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <LegendCard marginTop="30px">Valor final</LegendCard>
                          <ValueLegendCard fontSize="24px" weight="600">
                            {formatToBRL(lead.valorInvestido)}
                          </ValueLegendCard>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row verticalAlign="middle">
                        <Grid.Column width={8}>
                          <LegendCard>Data da primeira busca</LegendCard>
                          <ValueLegendCard weight="600">
                            {formatDate(lead.primeiraBusca)}
                          </ValueLegendCard>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <div
                            className="ver-grupo"
                            onClick={() =>
                              dispatch(push("/configuracoes?tab=3"))
                            }
                          >
                            VER PAGAMENTOS
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <LegendCard
                            marginTop="30px"
                            weight="normal"
                            fontSize="14px"
                            color="#616161"
                          >
                            Potencial de substituição do estudo
                          </LegendCard>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row verticalAlign="bottom">
                        <Grid.Column width={9}>
                          <Form.Input
                            defaultValue={
                              "R$ " +
                              moneyMask(`${lead.substituicaoEstudo * 100}`)
                            }
                            onChange={e =>
                              handleChangeSubstituicaoEstudo(e.target.value)
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={7}>
                          <LegendCard>Modificado em</LegendCard>
                          <ValueLegendCard weight="600">
                            {formatDate(lead.atualizado)}
                          </ValueLegendCard>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <LegendCard
                            marginTop="30px"
                            weight="normal"
                            fontSize="14px"
                            color="#616161"
                          >
                            Potencial de substituição da proposta
                          </LegendCard>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </CardInfo>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <CardInfo height="auto">
              <TitleCard color="#000000" fontSize="16px">
                Resultado da busca
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <ButtonBorder
                    onClick={() => handleOpenModalUpdateProcesses()}
                  >
                    ATUALIZAR
                  </ButtonBorder>
                  <ButtonBorder>
                    <FiDownload
                      size={20}
                      color="#185F9B"
                      onClick={() => exportLeadProcesses()}
                    />
                  </ButtonBorder>
                </div>
              </TitleCard>
              <Grid>
                <Grid.Row verticalAlign="top">
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">
                      Processos encontrados
                    </LegendCard>
                    <ValueLegendCard fontSize="24px" weight="600">
                      {lead.quantidadeProcessos}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <LegendCard marginTop="30px">Autor</LegendCard>
                    <ValueLegendCard fontSize="24px" weight="600">
                      {lead.autor}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <LegendCard marginTop="30px">Réu</LegendCard>
                    <ValueLegendCard fontSize="24px" weight="600">
                      {lead.reu}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <LegendCard marginTop="30px">Não identificado</LegendCard>
                    <ValueLegendCard fontSize="24px" weight="600">
                      {lead.quantidadeProcessos - lead.reu - lead.autor}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">
                      Valor total da causa
                    </LegendCard>
                    <ValueLegendCard fontSize="24px" weight="600">
                      {formatToBRL(lead.total)}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <LegendCard marginTop="30px">
                      Data da última atualização
                    </LegendCard>
                    <ValueLegendCard weight="600">
                      {formatDate((processes[0] || {}).atualizado)}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <LegendCard marginTop="30px">
                      Data início da busca
                    </LegendCard>
                    <ValueLegendCard weight="600">
                      {formatDate(lead.pesquisaInicio)}
                    </ValueLegendCard>
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <LegendCard marginTop="30px">Data fim da busca</LegendCard>
                    <ValueLegendCard weight="600">
                      {formatDate(lead.pesquisaFim)}
                    </ValueLegendCard>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8} textAlign="center">
                    <TitleCard color="#000000" fontSize="16px" marginTop="70px">
                      Tipo de ação
                    </TitleCard>
                    <ChartLineColumn
                      labels={labelsActionType}
                      data={dataActionType}
                    />
                    <ButtonBorder
                      onClick={() =>
                        handleOpenModalActionWarranty(
                          "Tipo de ação",
                          actionDashboard
                        )
                      }
                    >
                      Ver todas
                    </ButtonBorder>
                  </Grid.Column>
                  {lead.pesquisaCompleta === 0 ? (
                    <Grid.Column
                      width={8}
                      textAlign="center"
                      verticalAlign="middle"
                    >
                      <ValueLegendCard>
                        Nenhum tipo de garantia buscado
                      </ValueLegendCard>
                      <TitleCard
                        marginBottom="20px"
                        marginTop="10px"
                        width="400px"
                        justifyContent="center"
                      >
                        Você ainda não fez a busca por tipos de garantia. Para
                        realiza-la clique no botão abaixo e selecione os
                        processos que deseja essa informação
                      </TitleCard>
                      <ButtonBorder onClick={() => setSelectProcess(true)}>
                        Realizar busca
                      </ButtonBorder>
                    </Grid.Column>
                  ) : (
                    <Grid.Column width={8} textAlign="center">
                      <TitleCard
                        color="#000000"
                        fontSize="16px"
                        marginTop="70px"
                      >
                        Tipo de garantia
                      </TitleCard>
                      <ChartLineColumn
                        labels={labelsWarrantyType}
                        data={dataWarrantyType}
                      />
                      <ButtonBorder
                        onClick={() =>
                          handleOpenModalActionWarranty(
                            "Tipo de garantia",
                            warrantyDashboard
                          )
                        }
                      >
                        Ver todas
                      </ButtonBorder>
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
              <TitleCard color="#000000" fontSize="16px" marginTop="70px">
                Processos
              </TitleCard>
              {loadingDetailProcess ? (
                <CardLoading>
                  <h1>Aguarde um momento</h1>
                  <img
                    alt="loading"
                    src={require("../../../assets/images/loading.gif")}
                    width="300"
                  />
                  <p>O processo de saneamento de dados está em andamento!</p>
                </CardLoading>
              ) : (
                <>
                  <Table>
                    {selectProcess ? (
                      <TableFixed>
                        <CellHeaderSelect>
                          <Checkbox
                            onChange={(e, { checked }) =>
                              eventAllChecked(checked)
                            }
                            checked={allChecked}
                          />
                        </CellHeaderSelect>
                        {processes.map(process => (
                          <CellSelect key={process.id}>
                            <Checkbox
                              checked={processList.some(
                                processL => processL === process.id
                              )}
                              onChange={(e, { checked }) =>
                                checked
                                  ? addProcess(process.id)
                                  : removeProcess(process.id)
                              }
                            />
                          </CellSelect>
                        ))}
                      </TableFixed>
                    ) : (
                      ""
                    )}
                    <ScrollContainer>
                      <TableScroll repeat="11">
                        <CellHeader width="280px">Razão social</CellHeader>
                        <CellHeader width="200px">Nº do processo</CellHeader>
                        <CellHeader>Tribunal e vara</CellHeader>
                        <CellHeader>Data de início do processo</CellHeader>
                        <CellHeader>Situação do processo</CellHeader>
                        <CellHeader width="250px">Tipo de ação</CellHeader>
                        <CellHeader>Andamento processual</CellHeader>
                        <CellHeader>Tipo de garantia</CellHeader>
                        <CellHeader>Instância</CellHeader>
                        <CellHeader>Valor da causa</CellHeader>
                        <CellHeader>Documentos</CellHeader>
                        {processes.map(process => (
                          <Fragment key={process.id}>
                            <Cell>{process.razaoSocial}</Cell>
                            <Cell>{process.numeroProcesso}</Cell>
                            <Cell>{process.tribunalVara}</Cell>
                            <Cell>{formatDate(process.inicio)}</Cell>
                            <Cell>{process.situacao}</Cell>
                            <Cell>{process.tipoAcao}</Cell>
                            <Cell>
                              {process.sentencas && process.sentencas.length ? (
                                <FiEye
                                  color="#2ECC71"
                                  size={15}
                                  onClick={() =>
                                    handleOpenModalProcessSentence(process)
                                  }
                                ></FiEye>
                              ) : (
                                <FiEye
                                  color="#ABCDEF"
                                  size={15}
                                  onClick={() =>
                                    handleOpenModalProcessSentence(process)
                                  }
                                ></FiEye>
                              )}
                            </Cell>
                            <Cell>
                              {process.tipoGarantia === "Não Identificado" ? (
                                <FiAlertCircle
                                  color="#FF8F39"
                                  size={15}
                                  onClick={() =>
                                    handleOpenModalProcessWarranty(process)
                                  }
                                />
                              ) : (
                                process.tipoGarantia
                              )}
                            </Cell>
                            <Cell>{process.instancia}</Cell>
                            <Cell
                              onClick={() =>
                                handleOpenModalEditCauseValue(process)
                              }
                            >
                              {formatToBRL(process.valorCausa)}
                            </Cell>
                            <Cell>
                              {process.url ? (
                                <a
                                  href={process.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <AiFillFile size={30} />
                                </a>
                              ) : (
                                ""
                              )}
                            </Cell>
                          </Fragment>
                        ))}
                      </TableScroll>
                    </ScrollContainer>
                  </Table>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Pagination
                          style={{
                            marginBottom: "100px"
                          }}
                          onPageChange={(event, { activePage }) =>
                            setPageProcess(activePage)
                          }
                          activePage={pageProcess}
                          boundaryRange={0}
                          firstItem={null}
                          lastItem={null}
                          siblingRange={1}
                          totalPages={Math.ceil(
                            lead.quantidadeProcessos / perPageProcess
                          )}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </>
              )}
            </CardInfo>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  return (
    <div>
      <Navbar pageSelected="pipeline" />
      <Content>
        <HeaderPage>
          <FiChevronLeft
            size={30}
            onClick={() => dispatch(push("/pipeline"))}
            color="#185F9B"
            style={{
              marginRight: "30px"
            }}
          />
          Detalhes
        </HeaderPage>
        {lead.pesquisaSimples === 0 ? <WithoutProcesses /> : <WithProcesses />}
      </Content>
      {selectProcess ? (
        <FooterBudget>
          <div>
            {processList.length + " "}
            processos selecionados
          </div>
          <ButtonDefault
            onClick={() =>
              handleOpenModalBudget({
                unitValue: bipbopValues.acompanhamento.valor,
                amount: processList.length,
                type: "pesquisaCompleta"
              })
            }
            marginTop="0px"
          >
            ORÇAR
          </ButtonDefault>
        </FooterBudget>
      ) : (
        ""
      )}
      <ModalBudget
        onClose={handleCloseModalBudget}
        searchProcess={dates => eventSearchProcess(dates)}
        unitValue={unitValueBudget}
        amount={amountBudget}
        type={typeBudget}
      />
      <ModalProcessWarranty
        onClose={handleCloseModalProcessWarranty}
        process={process}
      ></ModalProcessWarranty>
      <ModalProcessSentence
        onClose={handleCloseModalProcessSentence}
        process={process}
        pageProcess={pageProcess - 1}
      />
      <ModalActionWarranty
        onClose={handleCloseModalActionWarranty}
        title={titleType}
        types={typesActionWarranty}
      />
      <ModalEditCauseValue
        onClose={handleCloseModalEditCauseValue}
        pageProcess={pageProcess - 1}
      />
      <ModalUpdateProcessesLead
        onClose={handleCloseModalUpdateProcesses}
        action={value =>
          value === "SEARCH_PROCESS"
            ? setSelectProcess(true)
            : handleOpenModalBudget({
                unitValue: bipbopValues.pesquisaEmpresa.valor,
                amount: 1,
                type: "pesquisaSimples"
              })
        }
      />
    </div>
  );
}

export default DetalhesLead;
