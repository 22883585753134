export const initialState = {
  validCNPJ: {
    nome: '',
    cep: '',
    email: '',
    telefone: '',
  },
};

const validCNPJ = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VALID_CNPJ':
      return { ...state, validCNPJ: action.payload.validCNPJ };
    default:
      return state;
  }
};

export default validCNPJ;
