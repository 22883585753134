/* eslint-disable react/jsx-fragments */
import React, { Fragment, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Pagination from '@material-ui/lab/Pagination';
import {
  Table,
  TableScroll,
  TableFixed,
  CellHeader,
  CellHeaderDetails,
  CellStatus,
  Cell,
  CellDetails,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault';
import { getProcess } from '../../../actions/process';
import { decodeToken, getToken } from '../../../actions/auth';

function TableStatusProcess() {
  const filteredProcesses = useSelector((state) => state.process.filteredProcesses);
  const totalOfProcesses = useSelector((state) => state.process.qtdProcesses);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const user = decodeToken(getToken());
  const idEmpresa = (user.idEmpresa || {}).id;

  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getProcess({
      idEmpresa, sort: 'criado', page: value, limit: 10,
    }));
  };
  return (
    <>
      <Table>
        <ScrollContainer>
          <TableScroll>
            <CellHeader paddingLeft="36px">Nº Processo</CellHeader>
            <CellHeader width="200px">Tribunal</CellHeader>
            <CellHeader width="100px">Vara</CellHeader>
            <CellHeader>Acompanhamento processual</CellHeader>
            {/* <CellHeader>Identificador do Cliente</CellHeader> */}
            <CellHeader>Nome do Reclamante</CellHeader>
            <CellHeader width="100px">tipo</CellHeader>
            <CellHeader>cpf/cnpj</CellHeader>
            <CellHeader>cep</CellHeader>
            <CellHeader>cidade</CellHeader>
            <CellHeader>uf</CellHeader>
            <CellHeader width="100px">bairro</CellHeader>
            <CellHeader>logradouro</CellHeader>
            <CellHeader>número</CellHeader>
            <CellHeader>complemento</CellHeader>
            <CellHeader>telefone</CellHeader>
            {filteredProcesses.map((process) => (
              <Fragment key={process.id}>
                <CellStatus>
                  {!process.numeroProcesso ? '-' : process.numeroProcesso}
                </CellStatus>
                <Cell>{!process.tribunal ? '-' : process.tribunal}</Cell>
                <Cell>{!process.vara ? '-' : process.vara}</Cell>
                <Cell>{process.acompanhamentoBipbop ? 'Sim' : 'Não'}</Cell>
                {/* <Cell>{!process.identificadorCliente ? '-' : process.identificadorCliente}</Cell> */}
                <Cell>
                  {!process.nomeReclamante ? '-' : process.nomeReclamante}
                </Cell>
                <Cell>
                  {!process.tipoPessoaReclamante
                    ? '-'
                    : process.tipoPessoaReclamante}
                </Cell>
                <Cell>
                  {!process.cpfReclamante ? '-' : process.cpfReclamante}
                </Cell>
                <Cell>
                  {!process.cepReclamante ? '-' : process.cepReclamante}
                </Cell>
                <Cell>
                  {!process.cidadeReclamante ? '-' : process.cidadeReclamante}
                </Cell>
                <Cell>{!process.ufReclamante ? '-' : process.ufReclamante}</Cell>
                <Cell>
                  {!process.bairroReclamante ? '-' : process.bairroReclamante}
                </Cell>
                <Cell>
                  {!process.logradouroReclamante
                    ? '-'
                    : process.logradouroReclamante}
                </Cell>
                <Cell>
                  {!process.numeroReclamante ? '-' : process.numeroReclamante}
                </Cell>
                <Cell>
                  {!process.complementoReclamante
                    ? '-'
                    : process.complementoReclamante}
                </Cell>
                <Cell>
                  {!process.telefoneReclamante ? '-' : process.telefoneReclamante}
                </Cell>
              </Fragment>
            ))}
          </TableScroll>
        </ScrollContainer>
        <TableFixed>
          <CellHeaderDetails>Detalhes</CellHeaderDetails>
          {filteredProcesses.map((process) => (
            <CellDetails key={process.id}>
              <ButtonDefault
                color="primary"
                customColor="#3498DB"
                colorLabel="#FFFFFF"
                size="small"
                fontSize="12px"
                onClick={() => dispatch(push(`/acompanhamento-processual/detalhes/${process.id}`))}
              >
                Ver Mais
              </ButtonDefault>
            </CellDetails>
          ))}
        </TableFixed>
      </Table>
      <Pagination count={totalOfProcesses} onChange={handleChange} />
    </>
  );
}

export default TableStatusProcess;
