import React, {useEffect} from 'react';

import {Tab} from 'semantic-ui-react';

import {TabStyle} from './style';
import Navbar from '../../components/Navbar';

import Dashboard from './Dashboard';
import FasesComerciais from './FasesComerciais';
import {useDispatch} from 'react-redux';
import { getDashboard } from '../../actions/pipeline';

function Pipeline() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDashboard())
    }, [dispatch])
    
    const tabs = [
        {
            menuItem: 'FASES COMERCIAIS',
            render: () => <FasesComerciais/>
        }, {
            menuItem: 'DASHBOARD',
            render: () => <Dashboard/>
        }
    ]

    return <div>
        <Navbar pageSelected="pipeline"/>
        <TabStyle
            menu={{
            secondary: true,
            pointing: true
        }}
            panes={tabs}
            as={Tab}/>
    </div>
}

export default Pipeline;