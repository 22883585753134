import styled from "styled-components";

export const ModalBody = styled.div`
  background-color: #ffffff;
  width: 745px;
  margin: auto;
  padding: 40px;
  margin-top: 7%;
  border-radius: 8px;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #000000;
  }
`;

export const Content = styled.div`
  .ui.form {
    input {
      font-family: "Montserrat";
      background: #eeeeee;
    }

    .field label {
      font-weight: 500;
      font-size: 14px;
      color: #424242;
    }
  } 
`;

export const ButtonSend = styled.div`
  background: #f9b233;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  padding: 8px;
  margin: 50px 0px 10px auto;
  width: 204px;
`;
