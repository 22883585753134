import styled from 'styled-components';

export const Content = styled.div`
  margin: 0 48px 96px 48px;
`;

export const SectionTop = styled.div`
  display: flex;
  margin-top: -38px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 42px;
  margin-bottom: 40px;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #616161;
    margin: 0;
  }
`;

export const StatusDiv = styled.div`
  width: 778px;
  height: 160px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 88px;

  h1 {
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #000000;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
  }

  h2 {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #616161;
    text-align: center;
    margin: 0;
    margin-top: 8px;
  }
`;

export const AccompanimentDiv = styled.div`
  h1 {
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #424242;
    margin: 0;
    margin-bottom: 10px;
  }

  h2 {
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #616161;
    margin: 0;
    width: 736px;
    margin-bottom: 32px;
  }

  h3{
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #00366C;
    margin: 0;
    margin-bottom: 40px;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  .button-styled {
    margin-right: 24px;
  }
  margin-right: 40px;
`;
