import axios from "axios";
import { getToken } from "./auth";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const api = axios.create({
  baseURL: baseURL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;
