import React from 'react';
import PropTypes from 'prop-types';
import { StyledCardHeader } from './style';

function CardHeaderStyled({ title }) {
  return <StyledCardHeader title={title} />;
}

export default CardHeaderStyled;

CardHeaderStyled.propTypes = {
  title: PropTypes.string.isRequired,
};
