/* eslint-disable no-console */
import { push } from 'connected-react-router';
import axios from './api';
import {
  setLoadingCardProposalTokio,
  setLoadingCardProposalJunto,
  setLoadingCardDocument,
  setLoadingEmittingPolicy,
  setLoadingCardProposalBMG,
  setLoadingCardProposalPottencial,
} from './loading';
import {
  setSnackbarContent,
  setSnackbarResult,
  setSnackbarRoute,
  setSnackbarOpen,
  setSnackbarErrorOpen,
  setSnackbarErrorContent,
} from './snackbar';
import { setRoute } from './navbar';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  'Access-Control-Allow-Credentials': false,
  crossorigin: true,
};
axios.defaults.headers = headers;
axios.defaults.timeout = 240000;

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export const setPolicyRequeriments = (policyRequeriments) => ({
  type: 'SET_POLICY_REQUERIMENTS',
  payload: {
    policyRequeriments,
  },
});

export const getPolicyRequeriments = () => async (dispatch) => {
  try {
    const response = await axios.get('policies/requirements');
    dispatch(setPolicyRequeriments(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const setPolicyProcess = (process) => ({
  type: 'SET_POLICY_PROCESS',
  payload: {
    process,
  },
});

export const setPolicy = (policy) => ({
  type: 'SET_POLICY',
  payload: {
    policy,
  },
});

export const getPolicy = (id, query) => async (dispatch) => {
  try {
    const response = await axios.get(
      `policies/${id}`,
      {
        params: query,
      },
    );
    dispatch(setPolicy(response.data));
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProcess = (query) => async (dispatch) => {
  try {
    const response = await axios.get(
      'process',
      {
        params: query,
      },
    );
    if (response.data.processes[0]) {
      dispatch(setPolicyProcess(response.data.processes[0]));
    }
  } catch (err) {
    console.log(err);
  }
};

export const setProposalTokyo = (proposal) => ({
  type: 'SET_PROPOSAL_TOKYO',
  payload: {
    proposal,
  },
});

export const setProposalTokyoError = (error) => ({
  type: 'SET_PROPOSAL_TOKYO_ERROR',
  payload: {
    error,
  },
});

export const makeProposalTokyo = (data) => async (dispatch) => {
  try {
    await axios.post(
      'policies/tokyo/make-proposal',
      data,
    );

    dispatch(setRoute('/propostas'));
    dispatch(push('/propostas'));
  } catch (err) {
    dispatch(setSnackbarErrorOpen(true));
    dispatch(setSnackbarErrorContent({
      title: 'Erro no formulário',
      subtitle: 'Campo obrigatório não preenchido',
    }));
    console.log(err);
  }
};

export const setProposalJunto = (proposal) => ({
  type: 'SET_PROPOSAL_JUNTO',
  payload: {
    proposal,
  },
});

export const setProposalJuntoError = (error) => ({
  type: 'SET_PROPOSAL_JUNTO_ERROR',
  payload: {
    error,
  },
});

export const makeProposalJunto = (data) => async (dispatch) => {
  try {
    await axios.post('policies/junto/make-proposal', data);
  } catch (err) {
    dispatch(setSnackbarErrorOpen(true));
    dispatch(setSnackbarErrorContent({
      title: 'Erro no formulário',
      subtitle: 'Campo obrigatório não preenchido',
    }));
    console.log(err);
  }
};

export const setProposalBMG = (proposal) => ({
  type: 'SET_PROPOSAL_BMG',
  payload: {
    proposal,
  },
});

export const setProposalBMGError = (error) => ({
  type: 'SET_PROPOSAL_BMG_ERROR',
  payload: {
    error,
  },
});

export const makeProposalBMG = (data) => async (dispatch) => {
  try {
    await axios.post('/policies/bmg/make-proposal', data);
  } catch (err) {
    dispatch(setSnackbarErrorOpen(true));
    dispatch(setSnackbarErrorContent({
      title: 'Erro no formulário',
      subtitle: 'Campo obrigatório não preenchido',
    }));
    console.log(err);
  }
};

export const setProposalPottencial = (proposal) => ({
  type: 'SET_PROPOSAL_POTTENCIAL',
  payload: {
    proposal,
  },
});

export const setProposalPottencialError = (error) => ({
  type: 'SET_PROPOSAL_POTTENCIAL_ERROR',
  payload: {
    error,
  },
});

export const makeProposalPottencial = (data) => async (dispatch) => {
  try {
    await axios.post('/policies/pottencial/make-proposal', data);
  } catch (err) {
    dispatch(setSnackbarErrorContent({
      title: 'Erro no formulário',
      subtitle: 'Campo obrigatório não preenchido',
    }));
    console.log(err);
  }
};

export const setPolicyEmited = (data) => ({
  type: 'SET_POLICY_EMITED',
  payload: {
    data,
  },
});

export const emitPolicyTokio = (data) => async (dispatch) => {
  try {
    dispatch(setLoadingEmittingPolicy(true));
    await axios.post(
      'policies/tokyo/emit-policy',
      data,
    );
    dispatch(setRoute('/emitir-apolice'));
    dispatch(push('/emitir-apolice'));
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setLoadingCardProposalJunto(true));
    dispatch(setLoadingCardProposalBMG(true));
    dispatch(setLoadingCardProposalTokio(true));
    dispatch(setLoadingCardProposalPottencial(true));
  } catch (err) {
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setSnackbarErrorContent({
      title: 'Falha ao emitir apólice',
      subtitle: 'Algo inesperado aconteceu ao tentar emitir apólice',
    }));
    dispatch(setSnackbarErrorOpen(true));
  }
};

export const emitPolicyPottencial = (data) => async (dispatch) => {
  try {
    dispatch(setLoadingEmittingPolicy(true));
    await axios.post(
      'policies/pottencial/emit-policy',
      data,
    );
    dispatch(setRoute('/emitir-apolice'));
    dispatch(push('/emitir-apolice'));
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setLoadingCardProposalJunto(true));
    dispatch(setLoadingCardProposalBMG(true));
    dispatch(setLoadingCardProposalTokio(true));
    dispatch(setLoadingCardProposalPottencial(true));
  } catch (err) {
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setSnackbarErrorContent({
      title: 'Falha ao emitir apólice',
      subtitle: 'Algo inesperado aconteceu ao tentar emitir apólice',
    }));
    dispatch(setSnackbarErrorOpen(true));
  }
};

export const setProposalData = (proposalData) => ({
  type: 'SET_PROPOSAL_DATA',
  payload: {
    proposalData,
  },
});

export const emitPolicyJunto = (data) => async (dispatch) => {
  try {
    dispatch(setLoadingEmittingPolicy(true));
    await axios.post(
      'policies/junto/emit-policy',
      data,
    );
    dispatch(setRoute('/emitir-apolice'));
    dispatch(push('/emitir-apolice'));
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setLoadingCardProposalJunto(true));
    dispatch(setLoadingCardProposalBMG(true));
    dispatch(setLoadingCardProposalTokio(true));
    dispatch(setLoadingCardProposalPottencial(true));
  } catch (err) {
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setSnackbarErrorContent({
      title: 'Falha ao emitir apólice',
      subtitle: 'Algo inesperado aconteceu ao tentar emitir apólice',
    }));
    dispatch(setSnackbarErrorOpen(true));
  }
};

export const emitPolicyBMG = (data) => async (dispatch) => {
  try {
    dispatch(setLoadingEmittingPolicy(true));
    await axios.post('/policies/bmg/emit-policy', data);
    dispatch(setRoute('/emitir-apolice'));
    dispatch(push('/emitir-apolice'));
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setLoadingCardProposalJunto(true));
    dispatch(setLoadingCardProposalBMG(true));
    dispatch(setLoadingCardProposalTokio(true));
    dispatch(setLoadingCardProposalPottencial(true));
  } catch (err) {
    dispatch(setLoadingEmittingPolicy(false));
    dispatch(setSnackbarErrorContent({
      title: 'Falha ao emitir apólice',
      subtitle: 'Algo inesperado aconteceu ao tentar emitir apólice',
    }));
    dispatch(setSnackbarErrorOpen(true));
  }
};

export const setSusepDocument = (document) => ({
  type: 'SET_SUSEP_DOCUMENT',
  payload: {
    document,
  },
});

export const getSusepDocument = (data) => async (dispatch) => {
  try {
    const response = await axios.post('crawler/susep-policy', {
      cnpj: '00001774117916',
      policyNumber: '030692020009900750371835',
    });

    dispatch(setSusepDocument(response.data));
    dispatch(setSnackbarContent({
      title: 'Certificado Emitido',
      subtitle: 'Certificado da apólice emitido com sucesso',
    }));
    dispatch(setSnackbarResult(true));
    dispatch(setSnackbarRoute('/emitir-apolice'));
    dispatch(setSnackbarOpen(true));
    dispatch(setLoadingCardDocument(false, 'CERTIFICADO'));
    // dispatch(setLoadingCardDocument(false, "CERTIFICADO"));
  } catch (err) {
    dispatch(setLoadingCardDocument(false, 'CERTIFICADO'));
    console.log(err);
  }
};

export const setSusepInsurance = (document) => ({
  type: 'SET_SUSEP_INSURANCE',
  payload: {
    document,
  },
});

export const getSusepInsurance = (data) => async (dispatch) => {
  try {
    const response = await axios.post('crawler/susep-insurance', {
      cnpj: '84948157000133',
    });

    dispatch(setSusepInsurance(response.data));
    dispatch(setSnackbarContent({
      title: 'Certificado emitido',
      subtitle: 'Certificado da seguradora emitido com sucesso',
    }));
    dispatch(setSnackbarResult(true));
    dispatch(setSnackbarRoute('/emitir-apolice'));
    dispatch(setSnackbarOpen(true));
    dispatch(setLoadingCardDocument(false, 'INSURANCE'));
  } catch (err) {
    console.log(err);
    dispatch(setLoadingCardDocument(false, 'INSURANCE'));
  }
};

export const setAllPolicies = (policies) => ({
  type: 'SET_ALL_POLICIES',
  payload: {
    policies,
  },
});

export const getAllPolicies = () => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}policies`);
    dispatch(setAllPolicies(response.data.policies));
  } catch (err) {
    console.log(err);
  }
};

export const setPoliciesValidity = (validity) => ({
  type: 'SET_POLICIES_VALIDITY',
  payload: {
    validity,
  },
});

export const getPoliciesValidity = (data) => async (dispatch) => {
  try {
    const response = await axios.post('policies/resume/validity', data);
    dispatch(setPoliciesValidity(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const setPoliciesBirthday = (birthday) => ({
  type: 'SET_POLICIES_BIRTHDAY',
  payload: {
    birthday,
  },
});

export const getPoliciesBirthday = (data) => async (dispatch) => {
  try {
    const response = await axios.post('policies/resume/birthday', data);
    dispatch(setPoliciesBirthday(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const setPoliciesResume = (data) => ({
  type: 'SET_POLICIES_RESUME',
  payload: {
    data,
  },
});

export const getPoliciesResume = (query) => async (dispatch) => {
  try {
    const response = await axios.get('policies/dashboard/resume', {
      params: query,
    });
    dispatch(setPoliciesResume(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const setPoliciesFilters = (filters, info) => ({
  type: 'SET_POLICIES_FILTER',
  payload: {
    filters,
    info,
  },
});

export const getFilteredPolicies = (query) => async (dispatch) => {
  try {
    const response = await axios.get('/policies', {
      params: query,
    });
    const info = {
      qtdPaginas: response.data.qtdPaginas,
      totalComFiltro: response.data.totalComFiltro,
      totalSemFiltro: response.data.totalSemFiltro,
    };
    dispatch(setAllPolicies(response.data.policies, info));
  } catch (err) {
    console.log(err);
  }
};

export const sendMailPolicyDocs = (data) => async (dispatch) => {
  try {
    await axios.post('/policies/send-email', data);
  } catch (err) {
    console.log(err);
  }
};

export const setFormNewPolicyData = (data) => ({
  type: 'SET_FORM_NEW_POLICY_DATA',
  payload: {
    data,
  },
});

export const getExportPolicies = async (query) => {
  axios({
    url: '/policies/export',
    method: 'GET',
    responseType: 'blob',
    params: query,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'apolices.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => console.log(err));
};

export const setOnClickExportPolicies = (trigger) => ({
  type: 'SET_ON_CLICK_EXPORT_POLICIES',
  payload: {
    trigger,
  },
});

export const setStatusEmitPolicy = (trigger) => ({
  type: 'SET_STATUS_EMIT_POLICY',
  payload: {
    trigger,
  },
});

export const setTribunal = (tribunal) => ({
  type: 'SET_TRIBUAL',
  payload: {
    tribunal,
  },
});

export const getTribunal = (processNumber) => async (dispatch) => {
  try {
    const response = await axios.get(`/courts/?numeroProcesso=${processNumber}`);
    dispatch(setTribunal(response.data));
  } catch (err) {
    console.log(err);
  }
};
