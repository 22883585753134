export const initialState = {
  policyRequeriments: {
    judicialTypes: [],
    resourceTypes: [],
    takers: [],
    policyDeadlines: [],
  },
  policyProcess: {
    id: '',
  },
  proposalTokyo: {
    premio: '',
    limiteDisponivel: '',
    limiteTotal: '',
    limiteUtilizado: '',
    formaPagamento: [],
  },
  proposalTokyoError: {
    error: false,
    errorTitle: '',
    errorText: '',
  },
  proposalJunto: {
    premio: '',
    limiteDisponivel: '',
    limiteTotal: '',
    limiteUtilizado: '',
  },
  proposalJuntoError: {
    error: false,
    errorTitle: '',
    errorText: '',
  },
  proposalPottencial: {
    premio: '',
    limiteDisponivel: '',
    limiteTotal: '',
    limiteUtilizado: '',
    formaPagamento: [],
  },
  proposalPottencialError: {
    error: false,
    errorTitle: '',
    errorText: '',
  },
  proposalBMGError: {
    error: false,
    errorTitle: '',
    errorText: '',
  },
  proposalBMG: {
    premio: '',
    limiteDisponivel: '',
    limiteTotal: '',
    limiteUtilizado: '',
  },
  proposalData: {},
  emitedPolicy: {
    documentos: {
      billetBase64: '',
      policyBase64: '',
      minutaBase64: '',
      certificadoSeguradoraBase64: '',
      certificadoApoliceBase64: '',
    },
    policy: {
      tomadorCNPJ: '',
      cdApoliceSusep: '',
      id: '',
      process: {
        acompanhamentoBipbop: false,
        id: 0,
      },
    },
  },
  susepDocument: '',
  susepInsurance: '',
  allPolicies: [],
  policiesTableInfo: {
    qtdPaginas: 0,
    totalComFiltro: 0,
    totalSemFiltro: 0,
  },
  policiesValidity: {
    apolicesEmitidas: '',
    totalIS: 0,
    totalPremio: 0,
  },
  policiesBirthday: {
    apolicesEmitidas: '',
    totalIS: 0,
    totalPremio: 0,
  },
  policiesResume: {},
  policy: {
    policy: {
      endorsement: [],
      process: {},
    },
    documentos: {},
  },
  newPolicyData: {

  },
  policiesFilters: {
    seguradora: '',
    finalVigencia: '',
    dataEmissao: '',
    status: '',
    idEmpresa: '',
  },
  onClickExportPolicies: false,
  statusEmitPolicy: null,
  tribunal: {
    nome: '',
    vara: [],
  },
};

const policy = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_POLICY_REQUERIMENTS':
      return {
        ...state, policyRequeriments: action.payload.policyRequeriments,
      };
    case 'SET_POLICY_PROCESS':
      return {
        ...state, policyProcess: action.payload.process,
      };
    case 'SET_PROPOSAL_TOKYO':
      return {
        ...state, proposalTokyo: action.payload.proposal,
      };
    case 'SET_PROPOSAL_JUNTO':
      return {
        ...state, proposalJunto: action.payload.proposal,
      };
    case 'SET_PROPOSAL_POTTENCIAL':
      return { ...state, proposalPottencial: action.payload.proposal };
    case 'SET_PROPOSAL_DATA':
      return {
        ...state, proposalData: action.payload.proposalData,
      };
    case 'SET_SUSEP_DOCUMENT':
      return {
        ...state, susepDocument: action.payload.document,
      };
    case 'SET_ALL_POLICIES':
      return {
        ...state, allPolicies: action.payload.policies,
      };
    case 'SET_POLICY_EMITED':
      return { ...state, emitedPolicy: action.payload.data };
    case 'SET_PROPOSAL_TOKYO_ERROR':
      return { ...state, proposalTokyoError: action.payload.error };
    case 'SET_PROPOSAL_JUNTO_ERROR':
      return { ...state, proposalJuntoError: action.payload.error };
    case 'SET_POLICIES_VALIDITY':
      return { ...state, policiesValidity: action.payload.validity };
    case 'SET_POLICIES_BIRTHDAY':
      return { ...state, policiesBirthday: action.payload.birthday };
    case 'SET_POLICIES_RESUME':
      return { ...state, policiesResume: action.payload.data };
    case 'SET_PROPOSAL_BMG':
      return { ...state, proposalBMG: action.payload.proposal };
    case 'SET_PROPOSAL_BMG_ERROR':
      return { ...state, proposalBMGError: action.payload.error };
    case 'SET_PROPOSAL_POTTENCIAL_ERROR':
      return { ...state, proposalPottencialError: action.payload.error };
    case 'SET_POLICY':
      return { ...state, policy: action.payload.policy };
    case 'SET_SUSEP_INSURANCE':
      return { ...state, susepInsurance: action.payload.document };
    case 'SET_FORM_NEW_POLICY_DATA':
      return { ...state, newPolicyData: action.payload.data };
    case 'SET_POLICIES_FILTER':
      return { ...state, policiesFilters: action.payload.filters, policiesTableInfo: action.payload.info };
    case 'SET_ON_CLICK_EXPORT_POLICIES':
      return { ...state, onClickExportPolicies: action.payload.trigger };
    case 'SET_STATUS_EMIT_POLICY':
      return { ...state, statusEmitPolicy: action.payload.trigger };
    case 'SET_TRIBUAL':
      return { ...state, tribunal: action.payload.tribunal };
    default:
      return state;
  }
};

export default policy;
