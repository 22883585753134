/* eslint-disable no-console */
import axios from './api';
import {
  setLoadingAllDashboard,
  setLoadingResumeDashboard,
  setLoadingModalLimits,
} from './loading';

export const setAllDashboard = (data) => ({
  type: 'SET_ALL_DASHBOARD',
  payload: {
    data,
  },
});

export const setResumeDashboard = (data) => ({
  type: 'SET_RESUME_DASHBOARD',
  payload: {
    data,
  },
});

export const setDashboardConset = (data) => ({
  type: 'SET_DASHBOARD_CONSET',
  payload: {
    data,
  },
});

export const setDashboardConsetCompany = (data) => ({
  type: 'SET_DASHBOARD_CONSET_COMPANY',
  payload: {
    data,
  },
});

export const setQueryDashboardConset = (data) => ({
  type: 'SET_QUERY_DASHBOARD',
  payload: {
    data,
  },
});

export const getDashboardTokio = async (cnpj) => {
  try {
    const response = await axios.get('/policies/tokyo/dashboard', {
      params: {
        cnpj,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return {
      limiteUtilizado: 0,
      limiteDisponivel: 0,
      limiteTotal: 0,
    };
  }
};

export const getDashboardBMG = async (cnpj) => {
  try {
    const response = await axios.get('/policies/bmg/dashboard', {
      params: {
        cnpj,
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return {
      limiteUtilizado: 0,
      limiteDisponivel: 0,
      limiteTotal: 0,
    };
  }
};

export const getDashboardJunto = async (cnpj) => {
  try {
    const response = await axios.get('/policies/junto/dashboard', {
      params: {
        cnpj,
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return {
      limiteUtilizado: 0,
      limiteDisponivel: 0,
      limiteTotal: 0,
    };
  }
};

export const getDasboardPottencial = async (cnpj) => {
  try {
    const response = await axios.get('/policies/pottencial/dashboard', {
      params: {
        cnpj,
      },
    });

    return response.data;
  } catch (err) {
    return {
      limiteUtilizado: 0,
      limiteDisponivel: 0,
      limiteTotal: 0,
    };
  }
};

export const getAllDashboard = (cnpj) => async (dispatch) => {
  try {
    const responseTokio = getDashboardTokio(cnpj);
    const responseBMG = getDashboardBMG(cnpj);
    const responseJunto = getDashboardJunto(cnpj);
    const responsePottencial = getDasboardPottencial(cnpj);

    const responseAll = await Promise.all([
      responseTokio,
      responseBMG,
      responseJunto,
      responsePottencial,
    ]);
    const allLimiteUtilizado = responseAll.map((seguradora) => seguradora.limiteUtilizado);
    const allLimiteDisponivel = responseAll.map((seguradora) => seguradora.limiteDisponivel);
    const allLimiteTotal = responseAll.map((seguradora) => seguradora.limiteTotal);
    dispatch(
      setAllDashboard({
        allLimiteUtilizado,
        allLimiteDisponivel,
        allLimiteTotal,
      }),
    );
    dispatch(setLoadingAllDashboard(false));
  } catch (err) {
    console.log(err);
  }
};

export const getDashboardResume = () => async (dispatch) => {
  try {
    const response = await axios.get('/policies/dashboard/resume');
    dispatch(setResumeDashboard(response.data));
    dispatch(setLoadingResumeDashboard(false));
  } catch (err) {
    console.log(err);
  }
};

export const getFilteredDashboardResume = (query) => async (dispatch) => {
  try {
    const response = await axios.get(
      '/policies/dashboard/resume', {
        params: query,
      },
    );

    dispatch(setResumeDashboard(response.data));
    dispatch(setLoadingResumeDashboard(false));
  } catch (err) {
    console.log(err);
  }
};

export const getDashboardConset = (query) => async (dispatch) => {
  try {
    const response = await axios.get('/dashboard/conset', {
      params: query,
    });
    dispatch(setDashboardConset(response.data));
  } catch (error) {
    throw error;
  }
};

export const getDashboardConsetCompany = (query) => async (dispatch) => {
  try {
    const response = await axios.get('/dashboard/conset/company', {
      params: query,
    });
    dispatch(setDashboardConsetCompany(response.data));
  } catch (error) {
    throw error;
  }
};

export const setDashboardLimits = (data) => ({
  type: 'SET_DASHBOARD_LIMITS',
  payload: {
    data,
  },
});

export const getDashboardLimits = (query) => async (dispatch) => {
  try {
    const response = await axios.get('/dashboard/full-limits', {
      params: query,
    });
    dispatch(setDashboardLimits(response.data));
    dispatch(setLoadingModalLimits(false));
  } catch (error) {
    console.log(error);
  }
};
