import React, {useState} from 'react';
import {useDispatch} from 'react-redux'
import {
    Form,
    Grid
} from "semantic-ui-react";

import Navbar from '../../components/Navbar';
import {
    Content,
    ButtonSend,
    ButtonBorder
} from './style';

import {cnpjMask} from '../../services/mask';
import history from "../../routes/history";
import { createInsurer } from '../../actions/insurer';
import { push } from 'connected-react-router';

function NovaSeguradora() {
    const dispatch = useDispatch()

    const [cnpj,
        setCnpj] = useState('')
    const [razaoSocial,
        setRazaoSocial] = useState('')
    const [nome,
        setNome] = useState('')
    const [email,
        setEmail] = useState('')

    const sendForm = async() => {
        const newInsurance = {
            cnpj,
            razaoSocial,
            nome,
            email
        }

        dispatch(createInsurer(newInsurance));
        dispatch(push('/configuracoes'))
    }

    return (
        <div>
            <Navbar pageSelected="configuracoes"/>
            <Content>
                <Form onSubmit={() => sendForm()}>
                    <h1>Empresa</h1>
                    <Form.Group>
                        <Form.Input
                            required
                            label="CNPJ"
                            onChange={e => setCnpj(cnpjMask(e.target.value))}
                            value={cnpj}
                            placeholder="CNPJ"
                            width={8}/>
                        <Form.Input
                            label="Razão Social"
                            placeholder="Razão Social"
                            onChange={e => setRazaoSocial(e.target.value)}
                            value={razaoSocial}
                            width={8}
                            required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            label="Nome da empresa"
                            placeholder="Nome da empresa"
                            width={8}
                            onChange={e => setNome(e.target.value)}
                            value={nome}
                            required/>

                        <Form.Input
                            label="E-mail"
                            placeholder="E-mail"
                            width={8}
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            required/>
                    </Form.Group>
                    <Grid>
                        <Grid.Row textAlign="right">
                            <Grid.Column width={16}>
                                <ButtonBorder
                                    onClick={() => history.push('/configuracoes?tab=1')}>CANCELAR</ButtonBorder>
                                <ButtonSend type='submit' className="btn-finish">FINALIZAR</ButtonSend>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

            </Content>
        </div>
    )
}

export default NovaSeguradora;