import React, { useLayoutEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import history from './history';

// Componentes importados para suas rotas

import PropostasApolice from '../Pages/PropostasApolice/index';
import EmitirApolice from '../Pages/EmitirApolice/index';
import AcompanhamentoProcessual from '../Pages/AcompanhamentoProcessual/index';
import DetalhesDoProcesso from '../Pages/DetalhesDoProcesso/index';
import NovoProcessoIndividual from '../Pages/NovoProcessoIndividual/index';
import NovoProcessoMassivo from '../Pages/NovoProcessoMassivo/index';
import Pipeline from '../Pages/Pipeline/Pipeline';
import NovaApolice from '../Pages/NovaApolice/index';
import NovaEmpresa from '../Pages/Configuracoes/NovaEmpresa';
import Configuracoes from '../Pages/Configuracoes/Configuracoes';
import DetalhesEmpresa from '../Pages/Configuracoes/DetalhesEmpresa';
import NovaSeguradora from '../Pages/Configuracoes/NovaSeguradora';
import EditarSeguradoras from '../Pages/Configuracoes/EditarSeguradoras';
import Login from '../Pages/Login/Login';
import DocumentosEmitidos from '../Pages/DocumentosEmitidos/index';
import DetalhesLead from '../Pages/Pipeline/DetalhesLead/DetalhesLead';
import Dashboard from '../Pages/Dashboard/index';
import ArchivedLead from '../Pages/Pipeline/ArchivedLead/ArchivedLead';
import DetalhesApolice from '../Pages/DocumentosEmitidos/DetalhesApolice';
import Endosso from '../Pages/Endosso/index';
import DashboardADM from '../Pages/DashboardADM/index';
import FinalizarApolice from '../Pages/FinalizarApolice/index';
import PerfilEmpresa from '../Pages/PerfilEmpresa/index';
import RedefinirSenha from '../Pages/RedefinirSenha/RedefinirSenha';
import ValoresBipbop from '../Pages/Configuracoes/ValoresBipbop';

import ChartHorizontalBar from '../components/Chart/ChartHorizontalBar';
import ModalLimits from '../components/Modal/ModalLimits';

function Routes() {
  useLayoutEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/testes" component={ModalLimits} />
        <Route exact path="/" component={Login} />
        <Route
          exact
          path="/acompanhamento-processual"
          component={AcompanhamentoProcessual}
        />
        <Route
          exact
          path="/acompanhamento-processual/detalhes/:id"
          component={DetalhesDoProcesso}
        />
        <Route
          exact
          path="/novo-processo-individual"
          component={NovoProcessoIndividual}
        />
        <Route
          exact
          path="/novo-processo-massivo"
          component={NovoProcessoMassivo}
        />
        <Route
          exact
          path="/pipeline/lead/detalhes/:idLead"
          component={DetalhesLead}
        />
        <Route exact path="/pipeline" component={Pipeline} />
        <Route exact path="/pipeline/arquivados" component={ArchivedLead} />
        <Route exact path="/nova-apolice" component={NovaApolice} />
        <Route exact path="/configuracoes" component={Configuracoes} />
        <Route exact path="/valores-bipbop" component={ValoresBipbop} />
        <Route exact path="/empresa/nova" component={NovaEmpresa} />
        <Route
          exact
          path="/empresa/detalhes/:idEmpresa"
          component={DetalhesEmpresa}
        />
        <Route
          exact
          path="/empresa/seguradoras/:idEmpresa"
          component={EditarSeguradoras}
        />
        <Route exact path="/seguradora/nova" component={NovaSeguradora} />

        <Route exact path="/propostas" component={PropostasApolice} />
        <Route exact path="/emitir-apolice" component={EmitirApolice} />
        <Route
          exact
          path="/documentos-emitidos"
          component={DocumentosEmitidos}
        />
        <Route
          exact
          path="/documentos-emitidos/detalhes/:idApolice"
          component={DetalhesApolice}
        />
        <Route
          exact
          path="/documentos-emitidos/endosso/:idEndorsement"
          component={Endosso}
        />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/finalizar-apolice" component={FinalizarApolice} />
        <Route exact path="/adm/dashboard" component={DashboardADM} />
        <Route exact path="/minha-conta" component={PerfilEmpresa} />
        <Route exact path="/esqueci-senha" component={RedefinirSenha} />
      </Switch>
    </ConnectedRouter>
  );
}

export default Routes;
