import { push } from "connected-react-router";
import React, { useState, Fragment, useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import CardChartColumn from "../../components/Card/CardChartColumn";
import CardCauseValue from "../../components/Card/CardCauseValue";
import CardProcessActive from "../../components/Card/CardProcessActive";
import ButtonDefault from "../../components/UI/ButtonDefault";

import {
  ButtonLead,
  IconFilter,
  DivTop,
  DivContent,
  InputSearch,
  Table,
  Cell,
  CellDetails,
  CellHeader,
  TableScroll,
  CellStatus,
  CellHeaderDetails,
  TableFixed
} from "./style";
import { Grid, Form } from "semantic-ui-react";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { listLeads } from "../../actions/pipeline";
import { formatToBRL } from "brazilian-values";

function Dashboard() {
  const dispatch = useDispatch();

  const leads = useSelector(state => state.pipeline.leads);
  const tipoAcao = useSelector(state => state.pipeline.dashboard.tipoAcao);

  const [searchEmpresa, setSearchEmpresa] = useState("");

  useEffect(() => {
    dispatch(
      listLeads({
        nome: searchEmpresa
      })
    );
  }, [dispatch, searchEmpresa]);

  const categoriesAction = tipoAcao
    .slice(0, 4)
    .map(acao => acao.nome.slice(0, 10) + "...");
  const valuesAction = tipoAcao.slice(0, 4).map(acao => acao.quantidade);
  const colorsAction = ["#00684F", "#9966FF", "#F99D1C", "#F26522"];

  return (
    <div>
      <DivTop>
        <div>
          <h2>Nova empresa</h2>
          <h4>Busque uma nova empresa</h4>
        </div>
        <div>
          <ButtonLead onClick={() => dispatch(push("/pipeline/detalhes"))}>
            Novo lead
          </ButtonLead>
        </div>
      </DivTop>
      <DivContent>
        <Grid>
          <Grid.Row verticalAlign="bottom">
            <Grid.Column width={5}>
              <InputSearch
                as={Form.Input}
                icon="search"
                label="Buscar cliente"
                onChange={e => setSearchEmpresa(e.target.value)}
              />
            </Grid.Column>
            <Grid.Column>
              <IconFilter>
                <FaFilter color="#F9B233" size={18} />
              </IconFilter>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid
          style={{
            marginTop: "80px"
          }}
        >
          <Grid.Row textAlign="center" centered>
            <Grid.Column width={5}>
              <CardProcessActive />
            </Grid.Column>
            <Grid.Column width={5}>
              <CardCauseValue />
            </Grid.Column>
            <Grid.Column width={5}>
              <CardChartColumn
                colors={colorsAction}
                categories={categoriesAction}
                data={valuesAction}
                title="Tipo de Ação"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table>
          <ScrollContainer>
            <TableScroll>
              <CellHeader paddingLeft="36px">Empresa</CellHeader>
              <CellHeader width="200px">CNPJ</CellHeader>
              <CellHeader width="100px">PROCESSOS ATIVOS</CellHeader>
              <CellHeader>VALOR TOTAL DAS CAUSAS</CellHeader>
              <CellHeader width="100px">ULTIMA ATUALIZAÇÃO</CellHeader>
              {leads.map(lead => (
                <Fragment key={lead.id}>
                  <CellStatus>{lead.nome}</CellStatus>
                  <Cell>{lead.cnpj}</Cell>
                  <Cell>{lead.quantidadeProcessos}</Cell>
                  <Cell>{formatToBRL(`${lead.valorInvestido}`)}</Cell>
                  <Cell>{new Date(lead.atualizado).toLocaleDateString()}</Cell>
                </Fragment>
              ))}
            </TableScroll>
          </ScrollContainer>
          <TableFixed>
            <CellHeaderDetails>Detalhes</CellHeaderDetails>
            {leads.map(process => (
              <CellDetails key={process.id}>
                <ButtonDefault
                  color="primary"
                  customColor="#3498DB"
                  colorLabel="#FFFFFF"
                  size="small"
                  fontSize="12px"
                >
                  Ver Mais
                </ButtonDefault>
              </CellDetails>
            ))}
          </TableFixed>
        </Table>
      </DivContent>
    </div>
  );
}

export default Dashboard;
