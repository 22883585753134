/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from "./api";

export const setLead = lead => ({
  type: "SET_LEAD",
  payload: {
    lead
  }
});

export const setDashboardAction = actionDashboard => ({
  type: "SET_ACTION_DASHBOARD",
  payload: {
    actionDashboard
  }
});

export const setDashboardWarranty = warrantyDashboard => ({
  type: "SET_WARRANTY_DASHBOARD",
  payload: {
    warrantyDashboard
  }
});

export const setLeads = leads => ({
  type: "SET_LEADS",
  payload: {
    leads
  }
});

export const setGroups = groups => ({
  type: "SET_GROUPS",
  payload: {
    groups
  }
});

export const setLeadProcesses = processes => ({
  type: "SET_LEAD_PROCESSES",
  payload: {
    processes
  }
});


export const setLeadProcess = process => ({
  type: "SET_LEAD_PROCESS",
  payload: {
    process
  }
});

export const setDashboardLead = dashboard => ({
  type: "SET_DASHBOARD_LEAD",
  payload: {
    dashboard
  }
});

export const setProcessPage = processPage => ({
  type: "SET_PROCESS_PAGE",
  payload: {
    processPage
  }
})


export const createLead = lead => async dispatch => {
  try {
    await axios.post(`pipeline/create-lead`, lead);
  } catch (err) {
    console.log(err);
  }
};

export const listLeadGroups = () => async dispatch => {
  try {
    const response = await axios.get(`pipeline/list-lead-groups`);
    dispatch(setGroups(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const listLeads = query => async dispatch => {
  try {
    const response = await axios.get(`pipeline/list-lead`, {
      params: {
        ...query
      }
    });
    dispatch(setLeads(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const listLead = idLead => async dispatch => {
  try {
    const response = await axios.get(`pipeline/list-lead/${idLead}`);
    dispatch(setLead(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const editLead = (id, newLead) => async dispatch => {
  try {
    await axios.put(`pipeline/edit-lead/${id}`, newLead);
    dispatch(listLead(id));
  } catch (err) {
    console.log(err);
  }
};

export const editLeadProcess = (
  id,
  newProcess,
  leadId,
  pageProcess
) => async dispatch => {
  try {
    await axios.put(`pipeline/edit-lead-process/${id}`, newProcess);
    dispatch(listLeadProcesses({
      leadId,
      limit: 50,
      page: pageProcess - 1
    }));
    dispatch(listLead(leadId));
  } catch (err) {
    console.log(err);
  }
};

export const searchProcess = data => async dispatch => {
  try {
    await axios.post(`pipeline/search-process/`, data);
  } catch (err) {
    console.log(err);
  }
};

export const fullScan = data => async dispatch => {
  try {
    await axios.post(`pipeline/full-scan/`, data);
  } catch (err) {
    console.log(err);
  }
};

export const listLeadProcesses = query => async dispatch => {
  try {
    const response = await axios.get(`pipeline/list-lead-processes`, {
      params: query
    });
    dispatch(setLeadProcesses(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const exportListLeadProcesses = async query => {
  try {
    const response = await axios.get(`pipeline/export-lead-processes`, {
      responseType: "blob",
      params: query
    });

    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getAllProcesses = async query => {
  try {
    const response = await axios.get(`pipeline/list-lead-processes`, {
      params: query
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDashboard = () => async dispatch => {
  try {
    const response = await axios.get(`pipeline/dashboard`);
    dispatch(setDashboardLead(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const getActionTypes = id => async dispatch => {
  try {
    const response = await axios.get(`pipeline/action-types/${id}`);
    dispatch(setDashboardAction(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const getWarrantyTypes = id => async dispatch => {
  try {
    const response = await axios.get(`pipeline/warranty-types/${id}`);
    dispatch(setDashboardWarranty(response.data));
  } catch (err) {
    console.log(err);
  }
};
