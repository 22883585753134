import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Button,
  Form,
  GridColumn,
  GridRow,
  Label
} from "semantic-ui-react";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdModeEdit } from "react-icons/md";

import ScrollContainer from "react-indiana-drag-scroll";

import {
  SectionTable,
  Cell,
  CellHeader,
  Table,
  TableScroll,
  DivTop,
  DivContent
} from "./style";

import {
  findConsetUsers,
  createConsetUser,
  deleteOneConsetUser,
  findOneConsetUser
} from "../../actions/consetUser";
import { telMask } from "../../services/mask";
import { setModalConsetUser } from "../../actions/modal";
import ModalConsetUser from "../../components/Modal/ModalConsetUser";

function Acessos() {
  const dispatch = useDispatch();
  const consetUsers = useSelector(state => state.consetUser.users);

  const [consetUserName, setConsetUserName] = useState("");
  const [consetUserEmail, setConsetUserEmail] = useState("");
  const [consetUserPassword, setConsetUserPassword] = useState("");
  const [consetUserPhone, setConsetUserPhone] = useState("");
  const [consetUserPermissions, setConsetUserPermissions] = useState([]);

  const permissions = [
    {
      value: "Pipeline",
      text: "Pipeline"
    },
    {
      value: "Administração",
      text: "Administração"
    }
  ];

  useEffect(() => {
    dispatch(findConsetUsers());
  }, [dispatch]);

  const deleteConsetUser = async consetUser => {
    await dispatch(deleteOneConsetUser(consetUser.id));
    dispatch(findConsetUsers());
  };

  const addConsetUser = async () => {
    const permissoes = `{${consetUserPermissions.reduce(
      (acc, permissao, i) =>
        acc + permissao + (i !== consetUserPermissions.length - 1 ? ", " : ""),
      ""
    )}}`;

    const newUser = {
      nome: consetUserName,
      email: consetUserEmail,
      telefone: consetUserPhone,
      permissoes,
      senha: consetUserPassword
    };

    dispatch(createConsetUser(newUser));

    setConsetUserName("");
    setConsetUserEmail("");
    setConsetUserPassword("");
    setConsetUserPhone("");
    setConsetUserPermissions([]);
  };

  const handleChangePermissoes = (event, { value }) => {
    setConsetUserPermissions(value);
  };

  const handleOpenModalConsetUser = user => {
    dispatch(setModalConsetUser(true));
    dispatch(findOneConsetUser(user.id));
  };

  const handleCloseModalConsetUser = () => {
    dispatch(setModalConsetUser(false));
  };

  return (
    <div>
      <DivTop>
        <Grid>
          <GridRow width={16}>
            <GridColumn>
              <h2>Acessos Administrador</h2>
              <h4>Cria novos acessos para o perfil administrador</h4>
            </GridColumn>
          </GridRow>
          <GridRow>
            <Form onSubmit={() => addConsetUser()}>
              <Form.Group>
                <Form.Input
                  label="Nome"
                  placeholder="Nome"
                  width={4}
                  onChange={e => setConsetUserName(e.target.value)}
                  value={consetUserName}
                  required
                />
                <Form.Input
                  label="E-mail"
                  placeholder="E-mail"
                  width={4}
                  onChange={e => setConsetUserEmail(e.target.value)}
                  value={consetUserEmail}
                  required
                />
                <Form.Input
                  label="Senha"
                  type="password"
                  placeholder="Senha"
                  width={4}
                  onChange={e => setConsetUserPassword(e.target.value)}
                  value={consetUserPassword}
                  required
                />
                <Form.Input
                  label="Telefone"
                  placeholder="Telefone"
                  width={4}
                  value={consetUserPhone}
                  onChange={e => setConsetUserPhone(telMask(e.target.value))}
                  required
                />
                <Form.Select
                  label="Permissão"
                  placeholder="Permissão"
                  width={4}
                  options={permissions}
                  value={consetUserPermissions}
                  onChange={handleChangePermissoes.bind(this)}
                  multiple
                  required
                />
              </Form.Group>

              <Grid>
                <Grid.Row textAlign="right">
                  <Button type="submit">ADICIONAR</Button>
                </Grid.Row>
              </Grid>
            </Form>
          </GridRow>
        </Grid>
      </DivTop>
      <DivContent>
        <SectionTable margin="0px 0px">
          <Table>
            <ScrollContainer>
              <TableScroll columns="5">
                <CellHeader singleLine>NOME</CellHeader>
                <CellHeader>E-MAIL</CellHeader>
                <CellHeader>TELEFONE</CellHeader>
                <CellHeader>PERMISSÕES</CellHeader>
                <CellHeader>OPÇÕES</CellHeader>

                {consetUsers.map((user, key) => (
                  <Fragment key={key}>
                    <Cell>{user.nome}</Cell>
                    <Cell>{user.email}</Cell>
                    <Cell>{user.telefone}</Cell>
                    <Cell
                      alignItems="center"
                      justifyContent="start"
                      flexDirection="row"
                    >
                      {user.permissoes.map((permissao, keyLabel) => (
                        <Label
                          key={keyLabel}
                          className="label-permissoes"
                          circular
                        >
                          {permissao}
                        </Label>
                      ))}
                    </Cell>
                    <Cell flexDirection="row" justifyContent="start">
                      <AiFillCloseCircle
                        className="icone-tabela"
                        size={27}
                        color="red"
                        onClick={() => deleteConsetUser(user)}
                      />
                      <MdModeEdit
                        size={27}
                        onClick={() => handleOpenModalConsetUser(user)}
                      />
                    </Cell>
                  </Fragment>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
      </DivContent>
      <ModalConsetUser close={handleCloseModalConsetUser} />
    </div>
  );
}

export default Acessos;
