/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import PrintIcon from "@material-ui/icons/Print";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import Navbar from "../../components/Navbar/index";
import {
  SectionTop,
  SectionStepper,
  SectionCards,
  DivButtonPrint,
  DivButtonMinuta,
  SectionFormPayment
} from "./style";
import TitleForm from "../../components/UI/TitleForm/index";
import Select from "../../components/UI/Select/index";
import StepperApolice from "../../components/Stepper/StepperApolice/index";
import CardPolicyProposal from "../../components/Card/CardPolicyProposal/index";
import ButtonDefault from "../../components/UI/ButtonDefault/index";
import ModalMinuta from "../../components/Modal/ModalMinuta/index";
import ModalCompromisePolicy from "../../components/Modal/ModalCompromisePolicy/index";
import { setModalMinuta, setModalCompromisePolicy } from "../../actions/modal";
import {
  emitPolicyTokio,
  emitPolicyJunto,
  emitPolicyBMG,
  emitPolicyPottencial,
  setProposalBMG,
  setProposalJunto,
  setProposalPottencial,
  setProposalPottencialError,
  setProposalJuntoError,
  setProposalTokyoError,
  setProposalBMGError,
  setProposalTokyo
} from "../../actions/policy";
import {
  setSnackbarErrorOpen,
  setSnackbarErrorContent
} from "../../actions/snackbar";
import { setValidCEP } from "../../actions/viacep";
import { setValidCNPJ } from "../../actions/receitaws";
import ReactToPrint from "react-to-print";

function PropostasApolice() {
  const dispatch = useDispatch();
  const proposalTokyo = useSelector(state => state.policy.proposalTokyo);
  const proposalJunto = useSelector(state => state.policy.proposalJunto);
  const proposalBMG = useSelector(state => state.policy.proposalBMG);
  const proposalTokyoError = useSelector(
    state => state.policy.proposalTokyoError
  );
  const proposalJuntoError = useSelector(
    state => state.policy.proposalJuntoError
  );
  const proposalBMGError = useSelector(state => state.policy.proposalBMGError);
  const proposalData = useSelector(state => state.policy.proposalData);
  const proposalPottencial = useSelector(
    state => state.policy.proposalPottencial
  );
  const proposalPottencialError = useSelector(
    state => state.policy.proposalPottencialError
  );
  const loading = useSelector(state => state.loading);
  const route = useSelector(state => state.navbar.policyRoute);

  const [formOfPayment, setFormOfPayment] = useState({
    tokyo: ["Boleto"],
    junto: ["Boleto"],
    bmg: ["Boleto"],
    pottencial: ["Boleto"]
  });
  const [proposalSelected, setproposalSelected] = useState("");
  const initialSelectValue = { value: "", text: "-" };
  const [selectFormOfPayment, setSelectFormOfPayment] = useState({
    disabled: true,
    formaPagamento: [],
    formaSelecionada: {}
  });
  const [pdfMinuta, setPdfMinuta] = useState("");

  let componentRef = null;

  useEffect(() => {
    if (route !== "/propostas") {
      dispatch(push(route));
    }
  }, []);

  useEffect(() => {
    // limpa states validCEP validCNPJ do form anterior
    dispatch(
      setValidCEP({
        localidade: "",
        uf: "",
        bairro: "",
        logradouro: "",
        complemento: ""
      })
    );
    dispatch(
      setValidCNPJ({
        nome: "",
        cep: "",
        email: "",
        telefone: ""
      })
    );
  }, []);

  useEffect(() => {
    const formOfPaymentTokyo = proposalTokyo.formaPagamento.map(
      item => item.descricaoFormaPagamento
    );

    setFormOfPayment({ ...formOfPayment, tokyo: formOfPaymentTokyo });
  }, [proposalTokyo.formaPagamento]);

  function handleSelectProposal(event) {
    setproposalSelected(event.target.name);
    setSelectFormOfPayment({
      ...selectFormOfPayment,
      disabled: true
    });

    if (event.target.name === "tokio" || event.target.name === "pottencial") {
      const company = event.target.name;
      const formaPagamento = (
        (company === "tokio" && proposalTokyo.formaPagamento) ||
        (company === "pottencial" && proposalPottencial.formaPagamento)
      ).map(item => ({
        value: item.codigoFormaPagamento,
        text: item.descricaoFormaPagamento,
        codigoFormaParcelamento: item.codigoFormaParcelamento,
        descricaoFormaParcelamento: item.descricaoFormaParcelamento
      }));

      setSelectFormOfPayment({
        ...selectFormOfPayment,
        disabled: false,
        formaPagamento: [initialSelectValue, ...formaPagamento]
      });
    }
  }

  function handleOpenModalMinuta() {
    switch (proposalSelected) {
      case "tokio":
        setPdfMinuta(proposalTokyo.linkMinuta);
        dispatch(setModalMinuta(true));
        break;
      case "junto":
        setPdfMinuta(proposalJunto.linkMinuta);
        dispatch(setModalMinuta(true));
        break;
      case "bmg":
        setPdfMinuta(proposalBMG.linkMinuta);
        dispatch(setModalMinuta(true));
        break;
      case "pottencial":
        setPdfMinuta(proposalPottencial.linkMinuta);
        dispatch(setModalMinuta(true));
        break;
      default:
        dispatch(
          setSnackbarErrorContent({
            title: "Falha ao abrir minuta",
            subtitle: "É necessário selecionar uma proposta"
          })
        );
        dispatch(setSnackbarErrorOpen(true));
        break;
    }
  }

  function handleChangeSelect(event) {
    const selectedValue = selectFormOfPayment.formaPagamento.find(
      // eslint-disable-next-line radix
      forma => forma.value === parseInt(event.target.value)
    );
    setSelectFormOfPayment({
      ...selectFormOfPayment,
      formaSelecionada: selectedValue
    });
  }

  function clearCardsState() {
    dispatch(
      setProposalBMG({
        premio: "",
        limiteDisponivel: "",
        limiteTotal: "",
        limiteUtilizado: ""
      })
    );
    dispatch(
      setProposalJunto({
        premio: "",
        limiteDisponivel: "",
        limiteTotal: "",
        limiteUtilizado: ""
      })
    );
    dispatch(
      setProposalTokyo({
        premio: "",
        limiteDisponivel: "",
        limiteTotal: "",
        limiteUtilizado: "",
        formaPagamento: []
      })
    );
    dispatch(
      setProposalPottencial({
        premio: "",
        limiteDisponivel: "",
        limiteTotal: "",
        limiteUtilizado: "",
        formaPagamento: []
      })
    );
    const errorDefault = {
      error: false,
      errorTitle: "",
      errorText: ""
    };
    dispatch(setProposalJuntoError(errorDefault));
    dispatch(setProposalBMGError(errorDefault));
    dispatch(setProposalTokyoError(errorDefault));
    dispatch(setProposalPottencialError(errorDefault));
  }

  function emitPolicy() {
    if (proposalSelected === "tokio") {
      const { formaSelecionada } = selectFormOfPayment;
      if (!formaSelecionada.value) {
        dispatch(
          setSnackbarErrorContent({
            title: "Falha ao emitir apólice",
            subtitle: "É necessário selecionar uma forma de pagamento"
          })
        );
        dispatch(setSnackbarErrorOpen(true));
      } else {
        const data = {
          ...proposalData,
          payment: {
            codigoFormaPagamento: formaSelecionada.value,
            descricaoFormaPagamento: formaSelecionada.text,
            codigoFormaParcelamento: formaSelecionada.codigoFormaParcelamento,
            descricaoFormaParcelamento:
              formaSelecionada.descricaoFormaParcelamento
          },
          numeroCotacao: proposalTokyo.numeroCotacao,
          numeroProposta: proposalTokyo.numeroProposta,
          premio: proposalTokyo.premio
        };
        dispatch(emitPolicyTokio(data));
        clearCardsState();
      }
    } else if (proposalSelected === "junto") {
      const data = {
        ...proposalData,
        numeroProposta: proposalJunto.numeroProposta,
        premio: proposalJunto.premio
      };
      dispatch(emitPolicyJunto(data));
      clearCardsState();
    } else if (proposalSelected === "bmg") {
      const data = {
        ...proposalData,
        numeroProposta: proposalBMG.numeroProposta,
        premio: proposalBMG.premio
      };
      dispatch(emitPolicyBMG(data));
      clearCardsState();
    } else if (proposalSelected === "pottencial") {
      const { formaSelecionada } = selectFormOfPayment;
      if (!formaSelecionada.value) {
        dispatch(
          setSnackbarErrorContent({
            title: "Falha ao emitir apólice",
            subtitle: "É necessário selecionar uma forma de pagamento"
          })
        );
        dispatch(setSnackbarErrorOpen(true));
      } else {
        const data = {
          ...proposalData,
          payment: {
            codigoFormaPagamento: formaSelecionada.value,
            descricaoFormaPagamento: formaSelecionada.text,
            codigoFormaParcelamento: formaSelecionada.codigoFormaParcelamento,
            descricaoFormaParcelamento:
              formaSelecionada.descricaoFormaParcelamento
          },
          numeroCotacao: proposalPottencial.numeroCotacao,
          numeroProposta: proposalPottencial.numeroProposta,
          premio: proposalPottencial.premio
        };
        dispatch(emitPolicyPottencial(data));
        clearCardsState();
      }
    }
    dispatch(setModalMinuta(false));
    dispatch(setModalCompromisePolicy(false));
  }

  function OpenTermOfCompromise() {
    dispatch(setModalCompromisePolicy(true));
    dispatch(setModalMinuta(false));
  }

  return (
    <>
      <Navbar pageSelected="cotacao" />
      <SectionTop>
        <h1>Cotação/Escolha da seguradora</h1>
      </SectionTop>
      <SectionStepper>
        <StepperApolice activeStep={1} className="item-1" />
        <TitleForm title="Seguradora" className="item-2" />
      </SectionStepper>

      <ReactToPrint
        trigger={() => (
          <DivButtonPrint>
            <ButtonDefault
              variant="outlined"
              color="secondary"
              startIcon={<PrintIcon style={{ color: "#F9B233" }} />}
            >
              Imprimir Propostas
            </ButtonDefault>
          </DivButtonPrint>
        )}
        content={() => componentRef}
      />

      <SectionCards ref={el => (componentRef = el)}>
        <CardPolicyProposal
          title="Tokio Marine"
          width="300px"
          premium={proposalTokyo.premio ? proposalTokyo.premio.toString() : ""}
          limiteAprovado={
            proposalTokyo.limiteTotal ? proposalTokyo.limiteTotal : 0
          }
          limiteDisponivel={
            proposalTokyo.limiteDisponivel ? proposalTokyo.limiteDisponivel : 0
          }
          limiteTomado={
            proposalTokyo.limiteUtilizado ? proposalTokyo.limiteUtilizado : 0
          }
          formOfPayment={formOfPayment.tokyo}
          buttonName="tokio"
          selected={proposalSelected === "tokio" && true}
          onClick={handleSelectProposal}
          loading={loading.LoadingCardProposalTokio}
          error={proposalTokyoError.error}
          errorTitle={proposalTokyoError.errorTitle}
          errorText={proposalTokyoError.errorText}
        />
        <CardPolicyProposal
          title="BMG"
          width="300px"
          premium={proposalBMG.premio.toString()}
          limiteAprovado={proposalBMG.limiteTotal ? proposalBMG.limiteTotal : 0}
          limiteDisponivel={
            proposalBMG.limiteDisponivel ? proposalBMG.limiteDisponivel : 0
          }
          limiteTomado={
            proposalBMG.limiteUtilizado ? proposalBMG.limiteUtilizado : 0
          }
          formOfPayment={formOfPayment.bmg}
          buttonName="bmg"
          loading={loading.LoadingCardProposalBMG}
          selected={proposalSelected === "bmg" && true}
          onClick={handleSelectProposal}
          error={proposalBMGError.error}
          errorTitle={proposalBMGError.errorTitle}
          errorText={proposalBMGError.errorText}
        />
        <CardPolicyProposal
          title="Junto Seguros"
          width="300px"
          premium={proposalJunto.premio.toString()}
          limiteAprovado={
            proposalJunto.limiteTotal ? proposalJunto.limiteTotal : 0
          }
          limiteDisponivel={
            proposalJunto.limiteDisponivel ? proposalJunto.limiteDisponivel : 0
          }
          limiteTomado={
            proposalJunto.limiteUtilizado ? proposalJunto.limiteUtilizado : 0
          }
          formOfPayment={formOfPayment.junto}
          buttonName="junto"
          selected={proposalSelected === "junto" && true}
          onClick={handleSelectProposal}
          loading={loading.LoadingCardProposalJunto}
          error={proposalJuntoError.error}
          errorTitle={proposalJuntoError.errorTitle}
          errorText={proposalJuntoError.errorText}
        />
        <CardPolicyProposal
          title="Pottencial"
          width="300px"
          premium={proposalPottencial.premio.toString()}
          limiteAprovado={
            proposalPottencial.limiteTotal ? proposalPottencial.limiteTotal : 0
          }
          limiteDisponivel={
            proposalPottencial.limiteDisponivel
              ? proposalPottencial.limiteDisponivel
              : 0
          }
          limiteTomado={
            proposalPottencial.limiteUtilizado
              ? proposalPottencial.limiteUtilizado
              : 0
          }
          formOfPayment={formOfPayment.pottencial}
          buttonName="pottencial"
          selected={proposalSelected === "pottencial" && true}
          onClick={handleSelectProposal}
          loading={loading.LoadingCardProposalPottencial}
          error={proposalPottencialError.error}
          errorTitle={proposalPottencialError.errorTitle}
          errorText={proposalPottencialError.errorText}
        />
      </SectionCards>
      <SectionFormPayment>
        <TitleForm title="Forma de Pagamento" className="item-2" />
        <Select
          label="Selecione"
          className="item-3"
          width="318px"
          valueOption={selectFormOfPayment.formaPagamento}
          disabled={selectFormOfPayment.disabled}
          onChange={handleChangeSelect}
        />
      </SectionFormPayment>
      <DivButtonMinuta>
        <ButtonDefault
          color="secondary"
          customColor="#F9B233"
          borderRadius="2px"
          size="large"
          onClick={handleOpenModalMinuta}
        >
          Gerar Minuta
        </ButtonDefault>
      </DivButtonMinuta>
      <ModalMinuta pdfMinuta={pdfMinuta} onClick={OpenTermOfCompromise} />
      <ModalCompromisePolicy onClickAccept={emitPolicy} />
    </>
  );
}

export default PropostasApolice;
