import React from "react";
import {
  Tab,
} from "semantic-ui-react";

import Navbar from "../../components/Navbar";

import {
  TabStyle,
} from "./style";

import Clientes from "./Clientes";
import Seguradoras from "./Seguradoras";
import RelatorioBipbop from "./RelatorioBipbop";
import Acessos from "./Acessos";
import TipoGarantia from "./TipoGarantia";
import SLAs from "./SLAs";

function Configuracoes(props) {
  const activeTab = props.location.search.split('=')[1]
  
  const tabs = [
    {
      menuItem: "Clientes",
      render: () => <Clientes />
    },
    {
      menuItem: "Seguradoras",
      render: () => <Seguradoras />
    },
    {
      menuItem: "SLAs",
      render: () => <SLAs />
    },
    {
      menuItem: "Relatório BipBop",
      render: () => <RelatorioBipbop />
    },
    {
      menuItem: "Acessos",
      render: () => <Acessos />
    },
    {
      menuItem: "Tipo de garantia",
      render: () => <TipoGarantia />
    }
  ];

  return (
    <div>
      <Navbar pageSelected="configuracoes" />
      <TabStyle
        menu={{
          secondary: true,
          pointing: true
        }}
        defaultActiveIndex={activeTab}
        panes={tabs}
        as={Tab}
      />
    </div>
  );
}

export default Configuracoes;
