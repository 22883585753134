export const setLoadingCardProposalTokio = (trigger) => ({
  type: 'SET_LOADING_CARD_PROPOSAL_TOKIO',
  payload: {
    trigger,
  },
});

export const setLoadingCardProposalJunto = (trigger) => ({
  type: 'SET_LOADING_CARD_PROPOSAL_JUNTO',
  payload: {
    trigger,
  },
});

export const setLoadingCardProposalBMG = (trigger) => ({
  type: 'SET_LOADING_CARD_PROPOSAL_BMG',
  payload: {
    trigger,
  },
});

export const setLoadingCardProposalPottencial = (trigger) => ({
  type: 'SET_LOADING_CARD_PROPOSAL_POTTENCIAL',
  payload: {
    trigger,
  },
});

export const setLoadingCardDocument = (trigger, document) => ({
  type: `SET_LOADING_CARD_DOCUMENT_${document}`,
  payload: {
    trigger,
  },
});

export const setLoadingEmittingPolicy = (trigger) => ({
  type: 'SET_LOADING_EMITING_POLICY',
  payload: {
    trigger,
  },
});

export const setLoadingAllDashboard = (trigger) => ({
  type: 'SET_LOADING_ALL_DASHBOARD',
  payload: {
    trigger,
  },
});

export const setLoadingResumeDashboard = (trigger) => ({
  type: 'SET_LOADING_RESUME_DASHBOARD',
  payload: {
    trigger,
  },
});

export const setLoadingSearchProcess = (trigger) => ({
  type: 'SET_LOADING_DETAIL_PROCESS',
  payload: {
    trigger,
  },
});

export const setLoadingModalLimits = (trigger) => ({
  type: 'SET_LOADING_MODAL_LIMITS',
  payload: {
    trigger,
  },
});

export const setLoadingAccompanimentProcess = (trigger) => ({
  type: 'SET_LOADING_ACCOMPANIMENT_PROCESS',
  payload: {
    trigger,
  },
});
