/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { StyledButton } from "./style";

function ButtonDefault(props) {
  const {
    color,
    variant,
    size,
    fullWidth,
    disabled,
    disableElevation,
    borderRadius,
    colorLabel,
    customColor,
    children,
    fontSize,
    startIcon,
    endIcon,
    onClick,
    height,
    type,
    className,
    margin
  } = props;

  return (
    <>
      <StyledButton
        variant={variant}
        color={color}
        size={size}
        fullWidth={fullWidth}
        disabled={disabled}
        disableElevation={disableElevation}
        borderradius={borderRadius}
        colorlabel={colorLabel}
        backgroundcolor={customColor}
        fontSize={fontSize}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        height={height}
        type={type}
        className={className}
        margin={margin}
      >
        {children}
      </StyledButton>
    </>
  );
}

export default ButtonDefault;

ButtonDefault.propTypes = {
  /** Define a cor de acordo com o padrão definido no theme do Material UI */
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  size: PropTypes.oneOf(["large", "medium", "small"]),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  borderRadius: PropTypes.string,
  colorLabel: PropTypes.string,
  customColor: PropTypes.string,
  children: PropTypes.any,
  fontSize: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  onClick: PropTypes.func,
  height: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  className: PropTypes.string
};

ButtonDefault.defaultProps = {
  color: "default",
  variant: "contained",
  size: "medium",
  fullWidth: false,
  disabled: false,
  disableElevation: true,
  borderRadius: null,
  colorLabel: null,
  customColor: null,
  children: "default",
  fontSize: "",
  startIcon: null,
  endIcon: null,
  onClick: () => {},
  height: null,
  type: "button",
  className: ""
};
