import React, { useState } from "react";
import { Grid, Modal, TextField } from "@material-ui/core";
import { VscClose } from "react-icons/vsc";
import { GiPadlock } from "react-icons/gi";
import { BsEyeSlashFill } from "react-icons/bs";
import { ModalBody, TitleContent, Content, ButtonSend } from "./style";

import { updateCompanyUser } from "../../../actions/companyUser";
import { useDispatch, useSelector } from "react-redux";
import { decodeToken, getToken } from "../../../actions/auth";

function ModalEditPasswordCompany(props) {
  const dispatch = useDispatch();
  const user = decodeToken(getToken());

  const open = useSelector(state => state.modal.ModalEditPasswordCompany);
  const { onClose } = props;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [textErrorPassword, setTextErrorPassword] = useState("");
  const [typeFieldPassword, setTypeFieldPassword] = useState("password");
  const [typeFieldConfirmPassword, setTypeFieldConfirmPassword] = useState(
    "password"
  );

  const changePassword = () => {
    if (password !== confirmPassword) {
      setErrorPassword(true);
      return setTextErrorPassword("Senhas não coincidem");
    }

    const data = {
      senha: password
    };

    dispatch(updateCompanyUser(user.id, data));
    onClose()
  };

  const handleViewPassword = () => {
    if (typeFieldPassword === "password") return setTypeFieldPassword("text");

    return setTypeFieldPassword("password");
  };

  const handleViewConfirmPassword = () => {
    if (typeFieldConfirmPassword === "password")
      return setTypeFieldConfirmPassword("text");

    return setTypeFieldConfirmPassword("password");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Nova senha</h1>
          <VscClose
            color="#616161"
            size={20}
            onClick={onClose}
          />
        </TitleContent>
        <p>Insira sua nova senha</p>
        <Content>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <GiPadlock size={20} color="#185F9B" />
            </Grid>
            <Grid item>
              <TextField
                type={typeFieldPassword}
                label="Senha"
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item>
              <BsEyeSlashFill
                size={20}
                color="#BDBDBD"
                onClick={() => handleViewPassword()}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item className="grid-xs-2">
              <GiPadlock size={20} color="#185F9B" />
            </Grid>
            <Grid item>
              <TextField
                type={typeFieldConfirmPassword}
                label="Confirmar senha"
                error={errorPassword}
                helperText={textErrorPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item>
              <BsEyeSlashFill
                size={20}
                color="#BDBDBD"
                onClick={() => handleViewConfirmPassword()}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <ButtonSend onClick={() => changePassword()}>
                confirmar
              </ButtonSend>
            </Grid>
          </Grid>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalEditPasswordCompany;
