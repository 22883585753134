/* eslint-disable no-mixed-operators */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { formatToBRL } from 'brazilian-values';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  ModalBody,
  ModalContent,
  SectionTitle,
  SectionChart,
  Menu,
  ButtonMenu,
  ChartArea,
  InfoTopChart,
  InfoAsideChart,
  LoadingArea,
} from './style';
import ButtonIcon from '../../UI/ButtonIcon/index';
import ChartHorizontalBar from '../../Chart/ChartHorizontalBar';
import { setModalLimits } from '../../../actions/modal';
import { getDashboardLimits } from '../../../actions/dashboard';
import { decodeToken, getToken } from '../../../actions/auth';
import { numberMask } from '../../../services/mask';
import { setLoadingModalLimits } from '../../../actions/loading';

function ModalLimits(props) {
  const {
    title,
    type,
    insurersValues,
    companyCNPJ,
  } = props;
  const user = decodeToken(getToken());
  const [selected, setSelected] = useState(1);
  const open = useSelector((state) => state.modal.ModalLimits);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.dashboardLimitsData);
  const loading = useSelector((state) => state.loading.LoadingModalLimits);
  const [values, setValues] = useState({
    recursal: 0,
    fiscal: 0,
    judicial: 0,
    validade: '01/01/2020',
    taxa: 0,
    valorTotalPottencial: 0,
    valorTotalTokio: 0,
    valorTotalJunto: 0,
    valorTotalBMG: 0,
  });

  useEffect(() => {
    insurersValues.forEach((valor, index) => {
      switch (index) {
        case 0:
          setValues({ ...values, valorTotalTokio: valor });
          break;
        case 1:
          setValues({ ...values, valorTotalBMG: valor });
          break;
        case 2:
          setValues({ ...values, valorTotalJunto: valor });
          break;
        default:
          break;
      }
    });
  }, [insurersValues]);

  function handleClose() {
    dispatch(setModalLimits(false));
  }

  useEffect(() => {
    switch (type) {
      case 'aprovado':
        setValues({
          ...values,
          recursal: data.recursal.total,
          fiscal: data.execucaoFiscal.total,
          judicial: data.judicial.total,
          taxa: data.recursal.taxa,
        });
        break;
      case 'tomado':
        setValues({
          ...values,
          recursal: data.recursal.utilizado,
          fiscal: data.execucaoFiscal.utilizado,
          judicial: data.judicial.utilizado,
          taxa: data.recursal.taxa,
        });
        break;
      case 'disponivel':
        setValues({
          ...values,
          recursal: data.recursal.disponivel,
          fiscal: data.execucaoFiscal.disponivel,
          judicial: data.judicial.disponivel,
          taxa: data.recursal.taxa,
        });
        break;
      default:
        setValues({ ...values });
        break;
    }
  }, [data]);

  function getData(insurer) {
    dispatch(setLoadingModalLimits(true));
    let cnpj;
    if (user.idEmpresa === undefined) {
      cnpj = companyCNPJ;
    } else {
      cnpj = user.idEmpresa.cnpj;
    }
    dispatch(getDashboardLimits({
      cnpj: numberMask(cnpj),
      insurer,
    }));
  }

  function handleChangeCompany(index) {
    setSelected(index);
    switch (index) {
      case 0:
        getData('pottencial');
        setSelected(index);
        break;
      case 1:
        getData('tokio');
        setSelected(index);
        break;
      case 2:
        getData('junto');
        setSelected(index);
        break;
      case 3:
        getData('bmg');
        setSelected(index);
        break;
      default:
        setSelected(index);
        break;
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalBody>
        <ModalContent>
          <SectionTitle>
            <h1>{title}</h1>
            <ButtonIcon onClick={handleClose}>
              <CloseIcon />
            </ButtonIcon>
          </SectionTitle>
          <SectionChart>
            <Menu>
              <ButtonMenu
                onClick={() => handleChangeCompany(0)}
                color={selected === 0 ? '#185F9B' : '#424242'}
              >
                <h2>Pottencial</h2>
                {selected === 0 && (<span />)}
                <p>{formatToBRL(values.valorTotalPottencial)}</p>
              </ButtonMenu>
              <ButtonMenu
                onClick={() => handleChangeCompany(1)}
                color={selected === 1 ? '#185F9B' : '#424242'}
              >
                <h2>Tokio Marine</h2>
                {selected === 1 && (<span />)}
                <p>{formatToBRL(values.valorTotalTokio)}</p>
              </ButtonMenu>
              <ButtonMenu
                onClick={() => handleChangeCompany(2)}
                color={selected === 2 ? '#185F9B' : '#424242'}
              >
                <h2>Junto Seguros</h2>
                {selected === 2 && (<span />)}
                <p>{formatToBRL(values.valorTotalJunto)}</p>
              </ButtonMenu>
              <ButtonMenu
                onClick={() => handleChangeCompany(3)}
                color={selected === 3 ? '#185F9B' : '#424242'}
              >
                <h2>BMG</h2>
                {selected === 3 && (<span />)}
                <p>{formatToBRL(values.valorTotalBMG)}</p>
              </ButtonMenu>
            </Menu>
            <ChartArea>
              <InfoTopChart>
                <div className="info-1">
                  <h1>Seguradora</h1>
                  <p>
                    {
                      selected === 0 && 'Pottencial'
                      || selected === 1 && 'Tokio Marine'
                      || selected === 2 && 'Junto Seguros'
                      || selected === 3 && 'BMG'
                    }
                  </p>
                </div>
                <div className="container-info-2">
                  <div className="info-2">
                    <h1>Limite aprovado</h1>
                    <p>
                      {
                        selected === 0 && formatToBRL(values.valorTotalPottencial)
                        || selected === 1 && formatToBRL(values.valorTotalTokio)
                        || selected === 2 && formatToBRL(values.valorTotalJunto)
                        || selected === 3 && formatToBRL(values.valorTotalBMG)
                      }
                    </p>
                  </div>
                  <div className="info-2">
                    <h1>Taxa</h1>
                    <p>{`${values.taxa}%`}</p>
                  </div>
                  <div className="info-2">
                    <h1>Validade do cadastro</h1>
                    <p>{values.validade}</p>
                  </div>
                </div>
              </InfoTopChart>
              <InfoAsideChart>
                <div className="info-1">
                  <h1>Recursal</h1>
                  <p>
                    {formatToBRL(values.recursal)}
                  </p>
                </div>
                <div className="info-2">
                  <h1>Execução fiscal</h1>
                  <p>
                    {formatToBRL(values.fiscal)}
                  </p>
                </div>
                <div className="info-3">
                  <h1>Judicial</h1>
                  <p>
                    {formatToBRL(values.judicial)}
                  </p>
                </div>
              </InfoAsideChart>
              {loading ? (
                <LoadingArea>
                  <CircularProgress />
                </LoadingArea>
              ) : (
                <ChartHorizontalBar
                  dataType="R$"
                  width="80%"
                  data={[values.recursal, values.fiscal, values.judicial]}
                />
              )}
            </ChartArea>
          </SectionChart>
        </ModalContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalLimits;

ModalLimits.propTypes = {
  title: '',
  type: PropTypes.oneOf(['aprovado', 'tomado', 'disponivel']),
  insurersValues: PropTypes.array,
};

ModalLimits.defaultProps = {
  title: '',
  type: 'aprovado',
  insurersValues: [],
};
