import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { ModalBody, TitleContent } from './style';
import ButtonDefault from '../../UI/ButtonDefault/index';
import { setModalFilterProcess } from '../../../actions/modal';

function ModalFilterProcess() {
  const open = useSelector((state) => state.modal.ModalFilterProcess);
  const dispatch = useDispatch();

  const handleCloseModalFilterProcess = () => {
    dispatch(setModalFilterProcess(false));
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalFilterProcess}
    >
      <ModalBody>
        <TitleContent>
          <h1>Filtros</h1>
          <ButtonDefault variant="text" color="primary">Limpar</ButtonDefault>
        </TitleContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalFilterProcess;
