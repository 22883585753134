/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatToNumber } from 'brazilian-values';
import CardChartDashboard from '../../components/Card/CardChartDashboard/index';
import CardInfoDashboard from '../../components/Card/CardInfoDashboard/index';
import {
  getAllDashboard,
  getFilteredDashboardResume,
  getDashboardLimits,
} from '../../actions/dashboard';
import Navbar from '../../components/Navbar/index';
import { SectionCard } from './style';
import { numberMask } from '../../services/mask';
import { decodeToken, getToken } from '../../actions/auth';
import ModalFilterDashboard from '../../components/Modal/ModalFilterDashboard';
import { setModalFilterDashboard, setModalLimits } from '../../actions/modal';
import ModalLimits from '../../components/Modal/ModalLimits';
import { setLoadingModalLimits } from '../../actions/loading';

function Dashboard() {
  const dispatch = useDispatch();
  const user = decodeToken(getToken());

  const queryDashboard = useSelector((state) => state.dashboard.queryDashboard);
  const allDashboard = useSelector((state) => state.dashboard.allDashboard);
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const resume = useSelector((state) => state.dashboard.resumeDashboard);
  const [modalLimitInfo, setModalLimitInfo] = useState({
    title: '',
    type: '',
  });
  const [valuesToModal, setValuesToModal] = useState([]);

  useEffect(() => {
    dispatch(
      getFilteredDashboardResume({
        ...queryDashboard,
        idEmpresa: user.idEmpresa.id,
      }),
    );
  }, [dispatch, queryDashboard]);

  useEffect(() => {
    if (user.idEmpresa.cnpj) {
      dispatch(getAllDashboard(numberMask(user.idEmpresa.cnpj)));
    }
  }, [dispatch]);

  const handleOpenModalFilterDashboard = () => {
    dispatch(setModalFilterDashboard(true));
  };

  const handleCloseModalFilterDashboard = () => {
    dispatch(setModalFilterDashboard(false));
  };

  function handleOpenModalLimit(type) {
    switch (type) {
      case 'aprovado':
        setModalLimitInfo({ ...modalLimitInfo, title: 'Limite aprovado', type });
        setValuesToModal(allDashboard.allLimiteTotal);
        break;
      case 'tomado':
        setModalLimitInfo({ ...modalLimitInfo, title: 'Limite tomado', type });
        setValuesToModal(allDashboard.allLimiteUtilizado);
        break;
      case 'disponivel':
        setModalLimitInfo({ ...modalLimitInfo, title: 'Limite disponível', type });
        setValuesToModal(allDashboard.allLimiteDisponivel);
        break;
      default:
        setModalLimitInfo({ ...modalLimitInfo });
        break;
    }
    dispatch(setLoadingModalLimits(true));
    dispatch(getDashboardLimits({
      cnpj: numberMask(user.idEmpresa.cnpj),
      insurer: 'tokio',
    }));
    dispatch(setModalLimits(true));
  }

  return (
    <>
      <Navbar pageSelected="dashboard" />
      <SectionCard>
        <CardInfoDashboard
          totalApolices={resume.apolicesEmitidas}
          totalIS={formatToNumber(parseInt(resume.totalIS))}
          totalPremio={formatToNumber(parseInt(resume.totalPremio))}
          openModal={handleOpenModalFilterDashboard}
        />
        <CardChartDashboard
          title="Limite aprovado"
          textTop="Valor total:"
          totalValue={`R$ ${formatToNumber(
            parseInt(allDashboard.allLimiteTotal.reduce(reducer)),
          )}`}
          categories={['Tokio Marine', 'BMG', 'Junto', 'Pottencial']}
          data={allDashboard.allLimiteTotal}
          onClickDetails={() => handleOpenModalLimit('aprovado')}
        />
        <CardChartDashboard
          title="Limite tomado"
          textTop="Valor total:"
          totalValue={`R$ ${formatToNumber(
            parseInt(allDashboard.allLimiteUtilizado.reduce(reducer)),
          )}`}
          categories={['Tokio Marine', 'BMG', 'Junto', 'Pottencial']}
          data={allDashboard.allLimiteUtilizado}
          onClickDetails={() => handleOpenModalLimit('tomado')}
        />
        <CardChartDashboard
          title="Limite disponível"
          textTop="Valor total:"
          totalValue={`R$ ${formatToNumber(
            parseInt(allDashboard.allLimiteDisponivel.reduce(reducer)),
          )}`}
          categories={['Tokio Marine', 'BMG', 'Junto', 'Pottencial']}
          data={allDashboard.allLimiteDisponivel}
          onClickDetails={() => handleOpenModalLimit('disponivel')}
        />
      </SectionCard>
      <ModalFilterDashboard onClose={handleCloseModalFilterDashboard} />
      <ModalLimits
        title={modalLimitInfo.title}
        insurersValues={valuesToModal}
      />
    </>
  );
}

export default Dashboard;
