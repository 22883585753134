import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { FiChevronLeft } from "react-icons/fi";
import Navbar from "../../components/Navbar";
import { TitleContent, Content, ButtonSend } from "./style";

import { push } from "connected-react-router";
import { editValues, listValues } from "../../actions/bipbop-report";
import { formatToNumber, moneyMask, percentMask } from "../../services/mask";

function ValoresBipbop() {
  const dispatch = useDispatch();
  const bipbopValues = useSelector(state => state.bipbopReport.values);
  const [pesquisaEmpresa, setPesquisaEmpresa] = useState(0);
  const [numeroProcessos, setNumeroProcessos] = useState(0);
  const [documento, setDocumento] = useState(0);
  const [acrescimoDocumento, setAcrescimoDocumento] = useState(0);
  const [acompanhamento, setAcompanhamento] = useState(0);
  const [acrescimoAcompanhamento, setAcrescimoAcompanhamento] = useState(0);

  useEffect(() => {
    dispatch(listValues());
  }, [dispatch]);

  useEffect(() => {
    setPesquisaEmpresa(
      "R$ " + moneyMask(`${bipbopValues.pesquisaEmpresa.valor * 100}`)
    );
    setNumeroProcessos(
      "R$ " + moneyMask(`${bipbopValues.numeroProcessos.valor * 100}`)
    );
    setDocumento("R$ " + moneyMask(`${bipbopValues.documento.valor * 100}`));
    setAcrescimoDocumento(
      percentMask(`${bipbopValues.acrescimoDocumento.valor * 100}`)
    );
    setAcompanhamento(
      "R$ " + moneyMask(`${bipbopValues.acompanhamento.valor * 100}`)
    );
    setAcrescimoAcompanhamento(
      percentMask(`${bipbopValues.acrescimoAcompanhamento.valor * 100}`)
    );
  }, [bipbopValues]);

  const sendForm = () => {
    const data = {
      pesquisaEmpresa: {
        valor: formatToNumber(pesquisaEmpresa)
      },
      numeroProcessos: {
        valor: formatToNumber(numeroProcessos)
      },
      documento: {
        valor: formatToNumber(documento)
      },
      acrescimoDocumento: {
        valor: formatToNumber(acrescimoDocumento)
      },
      acompanhamento: {
        valor: formatToNumber(acompanhamento)
      },
      acrescimoAcompanhamento: {
        valor: formatToNumber(acrescimoAcompanhamento)
      }
    };

    dispatch(editValues(data));
  };

  return (
    <div>
      <Navbar pageSelected="configuracoes" />

      <TitleContent>
        <div>
          {" "}
          <FiChevronLeft
            size={30}
            color="#568CCC"
            style={{ marginRight: "20px" }}
            onClick={() => dispatch(push("/configuracoes?tab=3"))}
          />
          <h1>Valores Bipbop</h1>
        </div>
        <ButtonSend margin="0px" onClick={() => sendForm()}>
          SALVAR
        </ButtonSend>
      </TitleContent>
      <Content padding="40px 0px">
        <Form>
          <h2>Valores Bipbop</h2>
          <h3>Defina os valores pelos serviços da BipBop</h3>
          <h1>Por CNPJ</h1>
          <Form.Group>
            <Form.Input
              label="Valor"
              width={3}
              value={pesquisaEmpresa}
              onChange={e =>
                setPesquisaEmpresa("R$ " + moneyMask(e.target.value))
              }
            />
          </Form.Group>
          <h3>
            Editado em{" "}
            {new Date(
              bipbopValues.pesquisaEmpresa.editado
            ).toLocaleDateString()}
          </h3>
          <h1>Processos em XML</h1>
          <Form.Group>
            <Form.Input
              label="Valor"
              width={3}
              value={numeroProcessos}
              onChange={e =>
                setNumeroProcessos("R$ " + moneyMask(e.target.value))
              }
            />
          </Form.Group>
          <h3>
            Editado em{" "}
            {new Date(
              bipbopValues.numeroProcessos.editado
            ).toLocaleDateString()}
          </h3>
          <h1>Documentos do processo</h1>
          <Form.Group>
            <Form.Input
              label="Valor (unidade)"
              width={3}
              value={documento}
              onChange={e => setDocumento("R$ " + moneyMask(e.target.value))}
            />
            <Form.Input
              label="Acréscimo do cliente"
              width={3}
              value={acrescimoDocumento}
              onChange={e => setAcrescimoDocumento(percentMask(e.target.value))}
            />
          </Form.Group>{" "}
          <h3>
            Editado em{" "}
            {new Date(
              bipbopValues.acrescimoDocumento.editado
            ).toLocaleDateString()}
          </h3>
          <h1>Acompanhamento automático / Busca por tipo de garantia</h1>
          <Form.Group>
            <Form.Input
              label="Valor"
              width={3}
              value={acompanhamento}
              onChange={e =>
                setAcompanhamento("R$ " + moneyMask(e.target.value))
              }
            />
            <Form.Input
              label="Acréscimo do cliente"
              width={3}
              value={acrescimoAcompanhamento}
              onChange={e =>
                setAcrescimoAcompanhamento(percentMask(e.target.value))
              }
            />
          </Form.Group>{" "}
          <h3>
            Editado em{" "}
            {new Date(
              bipbopValues.acrescimoAcompanhamento.editado
            ).toLocaleDateString()}
          </h3>
        </Form>
      </Content>
    </div>
  );
}

export default ValoresBipbop;
