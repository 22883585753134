/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from "./api";

export const setConsetUsers = users => ({
  type: "SET_CONSET_USERS",
  payload: {
    users
  }
});

export const findConsetUsers = query => async dispatch => {
  try {
    const response = await axios.get(`conset-user`, { params: query });
    dispatch(setConsetUsers(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const createConsetUser = newUser => async dispatch => {
  try {
    await axios.post(`conset-user`, newUser);
    dispatch(findConsetUsers());
  } catch (err) {
    console.log(err);
  }
};

export const setConsetUser = user => ({
  type: "SET_CONSET_USER",
  payload: {
    user
  }
});

export const findOneConsetUser = id => async dispatch => {
  try {
    const response = await axios.get(`conset-user/list/${id}`);
    dispatch(setConsetUser(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateConsetUser = (id, newUser) => async dispatch => {
  try {
    await axios.patch(`conset-user/update/${id}`, newUser);
    dispatch(findConsetUsers());
  } catch (err) {
    console.log(err);
  }
};

export const deleteOneConsetUser = id => async dispatch => {
  try {
    await axios.delete(`conset-user/delete/${id}`);
  } catch (err) {
    console.log(err);
  }
};
