/* eslint-disable react/no-array-index-key */
import React, { Fragment, useEffect, useState } from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router-dom";
import { formatToBRL } from "brazilian-values";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  SectionTop,
  SectionCards,
  SectionProcessDetails,
  DetailsDiv,
  InputTextOfList,
  AccompanimentDiv,
  ProcessInfoLabel
} from "./style";
import ButtonIcon from "../../components/UI/ButtonIcon";
import CardProcessMonitoring from "../../components/Card/CardProcessMonitoring";
import InputText from "../../components/UI/InputText";
import Navbar from "../../components/Navbar/index";
import {
  getAccompanimentProcess,
  getAccompanimentProcessWithoutStatus,
  getProcessWithoutBipbop,
  updateProcess,
  clearProcessState
} from "../../actions/process";
import { listValues } from "../../actions/bipbop-report";
import { setLoadingAccompanimentProcess } from "../../actions/loading";

function DetalhesDoProcesso() {
  const accompanimentProcess = useSelector(
    state => state.process.accompanimentProcess
  );
  const firstInstance = useSelector(
    state => state.process.accompanimentProcess.allDetails.firstInstance
  );
  const secondInstance = useSelector(
    state => state.process.accompanimentProcess.allDetails.secondInstance
  );
  const lastInstance = useSelector(
    state => state.process.accompanimentProcess.allDetails.lastInstance
  );
  const accompanimentProcessWithoutStatus = useSelector(
    state => state.process.accompanimentProcessWithoutStatus
  );
  const loading = useSelector(
    state => state.loading.LoadingAccompanimentProcess
  );
  const bipbopValues = useSelector(state => state.bipbopReport.values);
  const accompanimentBipbop = useSelector(
    state => state.process.processWithoutBipbop.acompanhamentoBipbop
  );
  const [acompanhamento, setAcompanhamento] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    setAcompanhamento(accompanimentBipbop);
  }, [accompanimentBipbop]);

  useEffect(() => {
    dispatch(setLoadingAccompanimentProcess(true));
    dispatch(clearProcessState());
  }, []);

  useEffect(() => {
    dispatch(getAccompanimentProcess(id));
    dispatch(getProcessWithoutBipbop(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(listValues());
  }, []);

  const [waitingStatus, setWaitingStatus] = useState(false);
  const [tribunal, setTribunal] = useState({
    firstInstance: "",
    secondInstance: "",
    lastInstance: ""
  });
  const [process, setProcess] = useState({
    numeroProcesso: "",
    tribunal: "",
    vara: "",
    ativoPartes: [],
    passivoPartes: [],
    valorCausa: ""
  });

  // esse trecho busca informações do proprio banco caso a bipbop não retornar nada.
  useEffect(() => {
    if (
      !accompanimentProcess.firstInstance &&
      !accompanimentProcess.secondInstance &&
      !accompanimentProcess.lastInstance
    ) {
      dispatch(getAccompanimentProcessWithoutStatus(id));
    }
  }, [accompanimentProcess, dispatch, id]);

  useEffect(() => {
    const result = accompanimentProcessWithoutStatus.firstInstance;
    setProcess(p => ({
      ...p,
      ...result
    }));
  }, [accompanimentProcessWithoutStatus]);

  useEffect(() => {
    if (
      !accompanimentProcess.firstInstance &&
      !accompanimentProcess.secondInstance &&
      !accompanimentProcess.lastInstance &&
      accompanimentProcess.status
    ) {
      setWaitingStatus(true);
    } else {
      setWaitingStatus(false);
    }

    // Esse trecho remove o nome inteiro dos tribunais deixando só a sigla.
    // espera-se que por padrão a sigla venha antes de um "-" na requisição.
    const tribunalFirst =
      accompanimentProcess.allDetails.firstInstance.tribunal;
    const tribunalSecond =
      accompanimentProcess.allDetails.secondInstance.tribunal;
    const tribunalLast = accompanimentProcess.allDetails.lastInstance.tribunal;
    setTribunal(t => ({
      ...t,
      firstInstance: tribunalFirst.substr(0, tribunalFirst.indexOf("-")),
      secondInstance: tribunalSecond.substr(0, tribunalSecond.indexOf("-")),
      lastInstance: tribunalLast.substr(0, tribunalLast.indexOf("-"))
    }));
  }, [accompanimentProcess]);

  useEffect(() => {
    if (accompanimentProcess.firstInstance) {
      setProcess(p => ({
        ...p,
        numeroProcesso: firstInstance.numeroProcesso,
        tribunal: firstInstance.tribunal,
        vara: firstInstance.vara,
        ativoPartes: firstInstance.ativoPartes,
        passivoPartes: firstInstance.passivoPartes,
        valorCausa: firstInstance.valorCausa
      }));
    } else if (accompanimentProcess.secondInstance) {
      setProcess(p => ({
        ...p,
        numeroProcesso: secondInstance.numeroProcesso,
        tribunal: secondInstance.tribunal,
        vara: secondInstance.vara,
        ativoPartes: secondInstance.ativoPartes,
        passivoPartes: secondInstance.passivoPartes,
        valorCausa: secondInstance.valorCausa
      }));
    } else if (accompanimentProcess.lastInstance) {
      setProcess(p => ({
        ...p,
        numeroProcesso: lastInstance.numeroProcesso,
        tribunal: lastInstance.tribunal,
        vara: lastInstance.vara,
        ativoPartes: lastInstance.ativoPartes,
        passivoPartes: lastInstance.passivoPartes,
        valorCausa: lastInstance.valorCausa
      }));
    }
  }, [accompanimentProcess, firstInstance, secondInstance, lastInstance]);

  function handleChangeAcompanhamento() {
    setAcompanhamento(!acompanhamento);
    const data = {
      acompanhamentoBipbop: !acompanhamento
    };
    dispatch(updateProcess(id, data));
  }

  return (
    <>
      <Navbar pageSelected="acompanhamento-processual" />

      <SectionTop>
        <ButtonIcon
          onClick={() => dispatch(push("/acompanhamento-processual"))}
        >
          <ArrowBackIosIcon />
        </ButtonIcon>
        <h1>Acompanhamento do Processo</h1>
      </SectionTop>
      {loading ? (
        <SectionCards>
          <CircularProgress />
        </SectionCards>
      ) : (
        <>
          {acompanhamento === true && (
            <>
              <SectionCards>
                <CardProcessMonitoring
                  title={
                    tribunal.firstInstance
                      ? `1ª Instância - ${tribunal.firstInstance}`
                      : "1ª Instância"
                  }
                  listStatus={accompanimentProcess.firstInstance}
                  waitingStatus={waitingStatus}
                  textStatus={accompanimentProcess.status}
                />
                <CardProcessMonitoring
                  title={
                    tribunal.secondInstance
                      ? `2ª Instância - ${tribunal.secondInstance}`
                      : "2ª Instância"
                  }
                  listStatus={accompanimentProcess.secondInstance}
                  waitingStatus={waitingStatus}
                  textStatus={accompanimentProcess.status}
                />
                <CardProcessMonitoring
                  title={tribunal.lastInstance || ""}
                  listStatus={accompanimentProcess.lastInstance}
                  waitingStatus={waitingStatus}
                  textStatus={accompanimentProcess.status}
                />
              </SectionCards>
              <ProcessInfoLabel>
                <p>
                  Está compartilhada a última movimentação do andamento do
                  processo.
                </p>
              </ProcessInfoLabel>
            </>
          )}
        </>
      )}
      <SectionProcessDetails>
        <h1>Informações do Processo</h1>
        <DetailsDiv
          rows={process.ativoPartes.length + process.passivoPartes.length}
        >
          <InputText
            className="firstInput"
            label="Número do Processo"
            width="278px"
            disabled
            value={process.numeroProcesso}
          />
          <InputText
            className="secondInput"
            label="Tribunal e região da justiça de trabalho"
            width="774px"
            disabled
            value={process.tribunal}
          />
          <InputText
            className="thirdInput"
            label="Vara"
            width="392px"
            disabled
            value={process.vara}
          />
          {process.ativoPartes.map((parte, index) => (
            <Fragment key={index}>
              <InputTextOfList
                label="Nome do Autor"
                width="392px"
                disabled
                value={parte.nome}
                row={3 + index}
                column="1/3"
              />
              <InputTextOfList
                label="CPF/CNPJ"
                width="318px"
                disabled
                value={parte.documento}
                row={3 + index}
                column="3"
              />
            </Fragment>
          ))}
          {process.passivoPartes.map((parte, index) => (
            <Fragment key={index}>
              <InputTextOfList
                label="Nome do Réu"
                width="392px"
                disabled
                value={parte.nome}
                row={3 + process.ativoPartes.length + index}
                column="1/3"
              />
              <InputTextOfList
                label="CPF/CNPJ"
                width="318px"
                disabled
                value={parte.documento}
                row={3 + process.ativoPartes.length + index}
                column="3"
              />
            </Fragment>
          ))}
          <InputTextOfList
            label="Valor da Causa"
            width="278px"
            disabled
            value={process.valorCausa}
            row={3 + process.ativoPartes.length + process.passivoPartes.length}
          />
        </DetailsDiv>
      </SectionProcessDetails>
      <AccompanimentDiv>
        <h1>
          Você deseja ativar o acompanhamento do processo de forma automática
        </h1>
        <h2>
          Acompanhe o status da evolução do processo, do início ao fim, de
          maneira automática. Essa funcionalidade está sujeita a taxas.
        </h2>
        <h3>
          Valor:{" "}
          {formatToBRL(
            bipbopValues.acompanhamento.valor *
              (bipbopValues.acrescimoAcompanhamento.valor / 100 + 1)
          )}
          /Mês
        </h3>
        <FormControlLabel
          control={<Switch />}
          label="Acompanhamento automático"
          checked={acompanhamento}
          onChange={handleChangeAcompanhamento}
        />
      </AccompanimentDiv>
    </>
  );
}

export default DetalhesDoProcesso;
