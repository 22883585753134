/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  ButtonIndividual,
  ButtonMassivo
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import ModalNewIndividualProcess from "../ModalNewIndividualProcess/index";
import {
  setModalNewIndividualProcess,
  setModalNewMassiveProcess,
  setModalNewProcess
} from "../../../actions/modal";
import ModalNewMassiveProcess from "../ModalNewMassiveProcess/index";

import JwtDecode from "jwt-decode";
import { getToken } from "../../../actions/auth";
import { findAllCompany, setIdCompany } from "../../../actions/company";
import { Dropdown } from "semantic-ui-react";

function ModalNewProcess(props) {
  const { onClose } = props;
  const open = useSelector(state => state.modal.ModalNewProcess);
  const dispatch = useDispatch();

  const companies = useSelector(state => state.company.companies);
  const idCompany = useSelector(state => state.company.idCompany);
  const [errorSelectCompany, setErrorSelectCompany] = useState(false);

  const user = JwtDecode(getToken());

  const companiesOptions = companies.map(company => {
    return {
      key: company.id,
      value: company,
      text: company.nome
    };
  });

  useEffect(() => {
    dispatch(findAllCompany());
  }, [dispatch]);

  const handleOpenModalNewIndividualProcess = () => {
    if (!idCompany.id && user.perfil === "conset")
      return setErrorSelectCompany(true);

    dispatch(setModalNewIndividualProcess(true));
    dispatch(setModalNewProcess(false));
  };

  const handleCloseModalNewIndividualProcess = () => {
    dispatch(setModalNewIndividualProcess(false));
  };

  const handleOpenModalNewMassiveProcess = () => {
    if (!idCompany.id && user.perfil === "conset")
      return setErrorSelectCompany(true);

    dispatch(setModalNewMassiveProcess(true));
    dispatch(setModalNewProcess(false));
  };

  const handleChangeCompany = (event, { value }) => {
    dispatch(setIdCompany(value));
    setErrorSelectCompany(false);
  };

  const handleCloseModalNewMassiveProcess = () => {
    dispatch(setModalNewMassiveProcess(false));
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalBody>
          <TitleContent>
            <h1> Novo Processo </h1>
            <ButtonIcon onClick={onClose}>
              <CloseIcon />
            </ButtonIcon>
          </TitleContent>
          <Content>
            {user.permissoes.includes("Administração") ? (
              <div>
                <h1> Escolha a empresa </h1>
                <Dropdown
                  search
                  selection
                  options={companiesOptions}
                  onChange={handleChangeCompany.bind(this)}
                  error={errorSelectCompany}
                />
              </div>
            ) : (
              ""
            )}
            <div>
              <h1> Escolha sua opção </h1>
              <h2>Cadastre um novo processo de forma individual ou massiva</h2>
            </div>
            <div>
              <ButtonIndividual onClick={handleOpenModalNewIndividualProcess}>
                INDIVIDUAL
              </ButtonIndividual>
              <ButtonMassivo onClick={handleOpenModalNewMassiveProcess}>
                MASSIVO
              </ButtonMassivo>
            </div>
          </Content>
        </ModalBody>
      </Modal>
      <ModalNewIndividualProcess
        onClose={handleCloseModalNewIndividualProcess}
      />
      <ModalNewMassiveProcess onClose={handleCloseModalNewMassiveProcess} />
    </>
  );
}

export default ModalNewProcess;

ModalNewProcess.propType = {
  onClose: PropType.func
};
