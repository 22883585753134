import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const DivNavbar = styled.div`
  width: 100%;
  box-shadow: 0px 7px 38px rgba(0, 49, 180, 0.15);
  margin: 0 0 80px 0;
  display: flex;
  justify-content: space-around;
  background: #ffffff;

  .ui.dropdown .menu {
    right: 0 !important;
    left: inherit;
  }
`;

export const DivLogo = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const TextLogo = styled.h1`
  font-family: "Montserrat", "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #114a8a;
  margin: 0;
  margin-top: 8px;
`;

export const DivButtonGroup = styled.div`
  display: flex;
`;

export const DivButton = styled.div``;

export const ButtonNav = withStyles({
  root: {
    padding: "0 16px",
    maxWidth: "190px",
    height: "90px"
  },
  label: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#00366C"
  }
})(Button);

export const ButtonBar = styled.div`
  width: 100%;
  height: 7px;
  margin-top: -7px;
  background: #f9b233;
  border-radius: 5px;
`;

export const DivProfile = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    align-items: flex-end;
    justify-content: center;
  }

  h1 {
    font-family: "Montserrat", "sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #00366c;
    text-align: right;
    margin: 0;
  }

  h2 {
    font-family: "Montserrat", "sans-serif";
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.4px;
    color: #114a8a;
    margin: 0;
    margin-top: 4px;
  }
`;

export const LogoutButton = withStyles({
  root: {
    height: "30px",
    justifyContent: "flex-end",
    padding: "0",
    width: "30px",
    minWidth: "30px"
  },
  label: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#185F9B"
  }
})(Button);

export const HelpButton = withStyles({
  root: {
    height: "25px",
    minWidth: "25px",
    width: "25px",
    padding: "0",
    borderRadius: "50%",
    margin: "31px 0 0 4px"
  },
  label: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    width: "15px"
  }
})(Button);
