import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parseToNumber, formatToBRL, isCNPJ } from "brazilian-values";
import { push } from "connected-react-router";
import Navbar from "../../components/Navbar/index";
import { SectionTop, Form, SectionForm, TextArea } from "./style";
import TitleForm from "../../components/UI/TitleForm/index";
import StepperApolice from "../../components/Stepper/StepperApolice/index";
import InputText from "../../components/UI/InputText/index";
import Select from "../../components/UI/Select/index";
import SubtitleForm from "../../components/UI/SubtitleForm/index";
import ButtonDefault from "../../components/UI/ButtonDefault/index";

import {
  getPolicyRequeriments,
  makeProposalTokyo,
  makeProposalJunto,
  makeProposalBMG,
  setProposalData,
  getTribunal,
  makeProposalPottencial
} from "../../actions/policy";
import { getValidCNPJ } from "../../actions/receitaws";
import { listUF } from "../../assets/lists/uf";
import SnackbarError from "../../components/Snackbar/SnackbarError/index";
import ModalNewPolicyAdm from "../../components/Modal/ModalNewPolicyAdmin";
import { setModalNewPolicyAdm } from "../../actions/modal";
import { decodeToken, getToken } from "../../actions/auth";
import { getValidCEP } from "../../actions/viacep";
import {
  cepMask,
  moneyMask,
  percentMask,
  numberMask,
  cnpjMask,
  cpfMask,
  formatToNumber,
  processNumberMask
} from "../../services/mask";

function NovaApolice() {
  const validCEP = useSelector(state => state.validCEP.validCEP);
  const route = useSelector(state => state.navbar.policyRoute);
  const idCompany = useSelector(state => state.company.idCompany);
  const dispatch = useDispatch();
  const user = decodeToken(getToken());
  const proposalData = useSelector(state => state.policy.proposalData);
  const validCNPJ = useSelector(state => state.validCNPJ.validCNPJ);
  const tribunal = useSelector(state => state.policy.tribunal);

  useEffect(() => {
    if (user.permissoes.includes("Administração") && !idCompany.id) {
      dispatch(setModalNewPolicyAdm(true));
    }
    if (route !== "/nova-apolice") {
      dispatch(push(route));
    }
  }, []);

  useEffect(() => {
    dispatch(getPolicyRequeriments());
  }, []);

  const initialSelectValue = {
    value: "",
    text: "-"
  };

  const tipoPessoaArray = [
    initialSelectValue,
    {
      value: "cpf",
      text: "Pessoa Física"
    },
    {
      text: "Pessoa Jurídica",
      value: "cnpj"
    }
  ];

  const policyRequeriments = useSelector(
    state => state.policy.policyRequeriments
  );
  const policyProcess = useSelector(state => state.policy.policyProcess);
  const clientID = useSelector(state => state.socket.clientID);

  const [state, setState] = useState({
    agravo: 0
  });

  const [proposal, setProposal] = useState({
    tipoJudicial: "",
    tipoRecurso: "",
    tomadorCNPJ: "",
    valorIS: 0,
    prazo: 0,
    numeroProcesso: "",
    vara: "",
    tribunal: "",
    identificadorCliente: "",
    valorDepositoRecursal: ""
  });

  const [segurado, setSegurado] = useState({
    tipoPessoaSegurado: "",
    numeroDocumentoSegurado: "",
    nomeSegurado: "",
    cepSegurado: "",
    cidadeSegurado: "",
    ufSegurado: "",
    bairroSegurado: "",
    logradouroSegurado: "",
    numeroSegurado: "",
    complementoSegurado: ""
  });

  const [select, setSelect] = useState({
    judicialTypes: [initialSelectValue],
    resourceTypes: [initialSelectValue],
    takers: [initialSelectValue],
    policyDeadlines: [initialSelectValue]
  });

  function mapListToSelectFormat(list) {
    return list.map(item => ({
      value: item,
      text: item
    }));
  }

  useEffect(() => {
    setSegurado({
      ...segurado,
      cidadeSegurado: validCEP.localidade,
      ufSegurado: validCEP.uf,
      bairroSegurado: validCEP.bairro,
      logradouroSegurado: validCEP.logradouro,
      complementoSegurado: validCEP.complemento
    });
  }, [validCEP]);

  useEffect(() => {
    const judicialTypes = mapListToSelectFormat(
      policyRequeriments.judicialTypes
    );
    const resourceTypes = mapListToSelectFormat(
      policyRequeriments.resourceTypes
    );
    const takers = policyRequeriments.takers.map(item => ({
      value: item.cpfCnpj,
      text: item.name
    }));
    const policyDeadlines = policyRequeriments.policyDeadlines.map(item => ({
      value: item,
      text: `${item} Anos`
    }));
    const { grievancePercentage } = policyRequeriments;

    setSelect({
      ...select,
      judicialTypes: [initialSelectValue, ...judicialTypes],
      resourceTypes: [initialSelectValue, ...resourceTypes],
      takers: [initialSelectValue, ...takers],
      policyDeadlines: [initialSelectValue, ...policyDeadlines]
    });
    setState({
      ...state,
      agravo: grievancePercentage
    });
  }, [policyRequeriments]);

  const handleChangeSegurado = event => {
    setSegurado({
      ...segurado,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeCep = event => {
    setSegurado({
      ...segurado,
      cepSegurado: cepMask(event.target.value)
    });
    if (event.target.value.length === 9) {
      dispatch(getValidCEP(event.target.value));
    }
  };
  const handleChangeProposal = event => {
    if (event.target.name === "valorDepositoRecursal") {
      const value = formatToNumber(moneyMask(event.target.value));
      const valueCalculated = (value * (100 + state.agravo)) / 100;
      return setProposal({
        ...proposal,
        valorIS: formatToBRL(valueCalculated),
        valorDepositoRecursal: formatToBRL(value)
      });
    }
    if (event.target.name === "numeroProcesso") {
      return setProposal({
        ...proposal,
        [event.target.name]: processNumberMask(event.target.value)
      });
    }
    return setProposal({
      ...proposal,
      [event.target.name]: event.target.value
    });
  };

  const onBlurProcessNumber = () => {
    dispatch(getTribunal(numberMask(proposal.numeroProcesso)));
  };

  const [listOfVara, setListOfVara] = useState();

  useEffect(() => {
    setProposal({
      ...proposal,
      tribunal: tribunal.nome
    });
    const newListOfVara = tribunal.vara.map(vara => ({
      text: vara.nome,
      value: vara.nome
    }));
    setListOfVara([initialSelectValue, ...newListOfVara]);
  }, [tribunal]);

  useEffect(() => {
    setProposal({
      ...proposal,
      idProcesso: policyProcess.id,
      numeroProcesso: policyProcess.numeroProcesso,
      nomeReclamante: policyProcess.nomeReclamante,
      cpfReclamante: policyProcess.cpfReclamante,
      cepReclamante: policyProcess.cepReclamante,
      cidadeReclamante: policyProcess.cidadeReclamante,
      ufReclamante: policyProcess.ufReclamante,
      bairroReclamante: policyProcess.bairroReclamante,
      logradouroReclamante: policyProcess.logradouroReclamante,
      numeroReclamante: policyProcess.numeroReclamante,
      complementoReclamante: policyProcess.complementoReclamento,
      telefoneReclamante: policyProcess.telefoneReclamante,
      tipoPessoaReclamante: policyProcess.tipoPessoaReclamante,
      vara: policyProcess.vara,
      tribunal: policyProcess.tribunal
    });
  }, [policyProcess]);

  function setMakeProposal(e) {
    e.preventDefault();

    if (user.permissoes.includes("Administração") && !idCompany.id) {
      return dispatch(setModalNewPolicyAdm(true));
    }

    const data = {
      socketId: clientID,
      // eslint-disable-next-line radix
      valorIS: parseToNumber(
        `${proposal.valorDepositoRecursal}`.replace("R$", "")
      ),
      // eslint-disable-next-line radix
      prazo: parseInt(proposal.prazo),
      tomadorCNPJ: numberMask(proposal.tomadorCNPJ),
      numeroProcesso: proposal.numeroProcesso,
      idProcesso: proposal.idProcesso,
      nomeReclamante: segurado.nomeSegurado, //
      tribunal: proposal.tribunal,
      vara: proposal.vara,
      judicialDepositoRecursal: proposal.tipoJudicial,
      valorDepositoRecursal: parseToNumber(
        `${proposal.valorDepositoRecursal}`.replace("R$", "")
      ),
      porcentagemAgravo: parseToNumber(`${state.agravo}`.replace("R$", "")),
      tipoRecurso: proposal.tipoRecurso,
      tipoJudicial: proposal.tipoJudicial,
      segurado: {
        ...segurado,
        cepSegurado: numberMask(segurado.cepSegurado),
        numeroDocumentoSegurado: numberMask(segurado.numeroDocumentoSegurado)
      },
      cpfReclamante: segurado.numeroDocumentoSegurado, //
      cepReclamante: numberMask(segurado.cepSegurado), //
      cidadeReclamante: segurado.cidadeSegurado, //
      ufReclamante: segurado.ufSegurado, //
      bairroReclamante: segurado.bairroSegurado, //
      logradouroReclamante: segurado.logradouroSegurado, //
      numeroReclamante: segurado.numeroSegurado, //
      telefoneReclamante: proposal.telefoneReclamante,
      tipoPessoaReclamante: segurado.tipoPessoaSegurado, //
      complementoReclamante: segurado.complementoSegurado, //
      idEmpresa: idCompany.id || (user.idEmpresa || {}).id,
      identificadorCliente: proposal.identificadorCliente
    };

    dispatch(makeProposalTokyo(data));
    dispatch(makeProposalJunto(data));
    dispatch(makeProposalBMG(data));
    dispatch(makeProposalPottencial(data));
    dispatch(setProposalData(data));
  }

  const handleCloseModalNewPolicyAdm = () => {
    dispatch(setModalNewPolicyAdm(false));
  };

  const [selectTomador, setSelectTomador] = useState([]);

  useEffect(() => {
    if (
      user.permissoes.includes("Administração") &&
      idCompany.tomadores.length !== 0
    ) {
      setProposal({
        ...proposal,
        tomadorCNPJ: idCompany.tomadores[0].cnpj
      });
      const listOfTomadores = idCompany.tomadores.map(tomador => ({
        text: tomador.nome,
        value: tomador.cnpj
      }));
      setSelectTomador(listOfTomadores);
    }
    if (!user.permissoes.includes("Administração") && user.tomadores.length) {
      const listOfTomadores = user.tomadores.map(tomador => ({
        text: tomador.nome,
        value: tomador.cnpj
      }));
      setSelectTomador(listOfTomadores);
      setProposal({
        ...proposal,
        tomadorCNPJ: listOfTomadores[0].value
      });
    }
  }, [idCompany]);

  function insertDataToUpdate() {
    if (proposalData.numeroProcesso) {
      setProposal({
        ...proposal,
        tipoJudicial: proposalData.judicialDepositoRecursal,
        tipoRecurso: proposalData.tipoRecurso,
        tomadorCNPJ: proposalData.tomadorCNPJ,
        valorDepositoRecursal: formatToBRL(proposalData.valorDepositoRecursal),
        valorIS: formatToBRL(proposalData.valorDepositoRecursal),
        prazo: proposalData.prazo,
        numeroProcesso: proposalData.numeroProcesso,
        tribunal: proposalData.tribunal,
        vara: proposalData.vara,
        identificadorCliente: proposalData.identificadorCliente
      });
      setSegurado({
        ...segurado,
        tipoPessoaSegurado: proposalData.segurado.tipoPessoaSegurado,
        numeroDocumentoSegurado: proposalData.segurado.numeroDocumentoSegurado,
        nomeSegurado: proposalData.segurado.nomeSegurado,
        cepSegurado: proposalData.segurado.cepSegurado,
        cidadeSegurado: proposalData.segurado.cidadeSegurado,
        ufSegurado: proposalData.segurado.ufSegurado,
        bairroSegurado: proposalData.segurado.bairroSegurado,
        logradouroSegurado: proposalData.segurado.logradouroSegurado,
        numeroSegurado: proposalData.segurado.numeroSegurado,
        complementoSegurado: proposalData.segurado.complementoSegurado
      });
    }
  }

  useEffect(() => {
    insertDataToUpdate();
  }, []);

  function handleChangeCNPJ(event) {
    setSegurado({
      ...segurado,
      [event.target.name]: event.target.value
    });
    if (isCNPJ(event.target.value)) {
      dispatch(getValidCNPJ(numberMask(event.target.value)));
    }
  }

  useEffect(() => {
    if (validCNPJ.nome !== "" && validCNPJ.cep !== "") {
      setSegurado({
        ...segurado,
        nomeSegurado: validCNPJ.nome,
        cepSegurado: validCNPJ.cep
      });
      dispatch(getValidCEP(numberMask(validCNPJ.cep)));
    }
  }, [validCNPJ]);

  return (
    <>
      <Navbar pageSelected="cotacao" />
      <SectionTop>
        <h1> Dados do Processo </h1>
      </SectionTop>
      <SectionForm>
        <Form onSubmit={setMakeProposal}>
          <StepperApolice className="item-1" activeStep={0} />
          <TitleForm className="item-2" title="Demanda" />
          <Select
            label="Judicial depósito recursal"
            width="204px"
            className="item-3"
            valueOption={select.judicialTypes}
            valueSelect={proposal.tipoJudicial}
            name="tipoJudicial"
            onChange={handleChangeProposal}
            isRequired
          />
          <Select
            label="Tipo de Recurso"
            width="318px"
            className="item-4"
            valueOption={select.resourceTypes}
            valueSelect={`${proposal.tipoRecurso}`}
            name="tipoRecurso"
            onChange={handleChangeProposal}
            isRequired
          />
          <TitleForm className="item-5" title="Tomador" />
          <InputText
            label="CNPJ"
            className="item-7"
            width="204px"
            disabled
            value={proposal.tomadorCNPJ}
            isRequired
          />
          <Select
            label="Nome do Tomador"
            className="item-6"
            width="204px"
            name="tomadorCNPJ"
            isRequired
            value={proposal.tomadorCNPJ}
            onChange={handleChangeProposal}
            valueOption={selectTomador}
          />
          <TitleForm className="item-8" title="Valores e Vigência" />
          <InputText
            label="Valor de depósito recursal"
            className="item-9-1"
            width="204px"
            value={proposal.valorDepositoRecursal}
            name="valorDepositoRecursal"
            onChange={handleChangeProposal}
            isRequired
          />
          <InputText
            label="Porcentagem de Agravo"
            className="item-9"
            width="204px"
            value={percentMask(`${state.agravo * 100}`)}
            name="agravo"
            disabled
          />
          <InputText
            label="Importância Segurada"
            className="item-10"
            width="204px"
            value={`${proposal.valorIS}`}
            name="valorIS"
            disabled
          />
          <Select
            label="Prazo"
            className="item-11"
            width="204px"
            valueOption={select.policyDeadlines}
            valueSelect={proposal.prazo}
            name="prazo"
            onChange={handleChangeProposal}
            isRequired
          />
          <TitleForm
            className="item-13"
            title="Informações de Obrigação e do Reclamante"
          />
          <Select
            label="Tipo Pessoa"
            valueOption={tipoPessoaArray}
            valueSelect={segurado.tipoPessoaSegurado}
            onChange={handleChangeSegurado}
            name="tipoPessoaSegurado"
            className="item-14"
            width="204px"
            isRequired
          />
          {segurado.tipoPessoaSegurado === "" && (
            <InputText
              label="CPF/CNPJ"
              className="item-15"
              width="204px"
              disabled
              isRequired
            />
          )}
          {segurado.tipoPessoaSegurado === "cpf" && (
            <InputText
              label="CPF"
              className="item-15"
              width="204px"
              value={cpfMask(segurado.numeroDocumentoSegurado)}
              name="numeroDocumentoSegurado"
              onChange={handleChangeSegurado}
              isRequired
            />
          )}
          {segurado.tipoPessoaSegurado === "cnpj" && (
            <InputText
              label="CNPJ"
              className="item-15"
              width="204px"
              value={cnpjMask(segurado.numeroDocumentoSegurado)}
              name="numeroDocumentoSegurado"
              onChange={handleChangeCNPJ}
              isRequired
            />
          )}
          <InputText
            label="Nome do reclamante"
            className="item-16"
            width="436px"
            value={segurado.nomeSegurado}
            name="nomeSegurado"
            onChange={handleChangeSegurado}
            isRequired
          />
          <SubtitleForm text="Endereço" className="item-17" />
          <InputText
            label="CEP do Reclamante"
            className="item-18"
            width="204px"
            value={segurado.cepSegurado}
            name="cepSegurado"
            onChange={handleChangeCep}
            isRequired
          />
          <InputText
            label="Cidade do Reclamante"
            className="item-19"
            width="204px"
            value={segurado.cidadeSegurado}
            name="cidadeSegurado"
            onChange={handleChangeSegurado}
            isRequired
          />
          <Select
            label="UF"
            className="item-20"
            width="90px"
            valueOption={listUF}
            valueSelect={segurado.ufSegurado}
            name="ufSegurado"
            onChange={handleChangeSegurado}
            isRequired
          />
          <InputText
            label="Bairro"
            className="item-21"
            width="318px"
            value={segurado.bairroSegurado}
            name="bairroSegurado"
            onChange={handleChangeSegurado}
            isRequired
          />
          <InputText
            label="Logradouro"
            className="item-22"
            width="408px"
            value={segurado.logradouroSegurado}
            name="logradouroSegurado"
            onChange={handleChangeSegurado}
            isRequired
          />
          <InputText
            label="Número"
            className="item-23"
            width="90px"
            value={segurado.numeroSegurado}
            name="numeroSegurado"
            onChange={handleChangeSegurado}
            isRequired
          />
          <InputText
            label="Complemento"
            className="item-24"
            width="318px"
            value={segurado.complementoSegurado}
            name="complementoSegurado"
            onChange={handleChangeSegurado}
          />
          <TitleForm className="item-25" title="Informações do Processo" />
          <InputText
            label="Número do Processo"
            className="item-26"
            width="315px"
            value={proposal.numeroProcesso}
            name="numeroProcesso"
            onChange={handleChangeProposal}
            onBlur={onBlurProcessNumber}
            isRequired
          />
          <InputText
            label="Tribunal e Região da justiça do trabalho"
            className="item-27"
            width="774px"
            value={proposal.tribunal}
            name="tribunal"
            onChange={handleChangeProposal}
            isRequired
          />
          {tribunal.vara.length === 0 && (
            <InputText
              label="Vara"
              className="item-28"
              width="320px"
              value={proposal.vara}
              name="vara"
              onChange={handleChangeProposal}
              isRequired
            />
          )}
          {tribunal.vara.length !== 0 && (
            <Select
              label="Vara"
              className="item-28"
              width="320px"
              valueOption={listOfVara}
              valueSelect={proposal.vara}
              name="vara"
              onChange={handleChangeProposal}
              isRequired
            />
          )}
          <InputText
            label="Identificador do cliente"
            className="item-29"
            width="320px"
            value={proposal.identificadorCliente}
            name="identificadorCliente"
            onChange={handleChangeProposal}
          />
          <TitleForm className="item-30" title="Objeto" />
          <TextArea className="item-31">
            Na forma do§ 11 artigo 899 da CLT, serve a presente garantia para
            preparo do competente recurso a ser distribuído pelo Tomador, no
            âmbito do processo <i className="devicons devicons-apple" />
            <mark>
              {proposal.numeroProcesso ? proposal.numeroProcesso : ""}
            </mark>
            , sendo o reclamante o Sr(a)
            <i className="devicons devicons-apple" />
            <mark>{segurado.nomeSegurado ? segurado.nomeSegurado : ""}</mark>
            <i className="devicons devicons-apple" />
            <mark>
              {segurado.tipoPessoaSegurado
                ? `${segurado.tipoPessoaSegurado} ${segurado.numeroDocumentoSegurado}`
                : ""}
            </mark>
            , para o tipo de recurso <i className="devicons devicons-apple" />
            <mark> {proposal.tipoRecurso ? proposal.tipoRecurso : ""} </mark> ,
            em trâmite perante o <i className="devicons devicons-apple" />
            <mark>{`${proposal.tribunal || ""} - ${proposal.vara || ""}`}</mark>
            , percentual de agravo <i className="devicons devicons-apple" />
            <mark> {state.agravo ? state.agravo : ""} </mark> % .
          </TextArea>
          <ButtonDefault
            className="item-32"
            color="secondary"
            customColor="#F9B233"
            borderRadius="2px"
            margin="0px 0px 100px 0px"
            fullWidth
            type="submit"
          >
            Avançar
          </ButtonDefault>
        </Form>
      </SectionForm>
      <SnackbarError />
      <ModalNewPolicyAdm onClose={handleCloseModalNewPolicyAdm} />
    </>
  );
}

export default NovaApolice;
