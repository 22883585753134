import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatToBRL } from "brazilian-values";
import { Grid, GridColumn, Dropdown } from "semantic-ui-react";
import { DivTop, ButtonBorderEdit, DivContent, DivRelatorio } from "./style";

import ChartLine from "../../components/Chart/ChartLine";
import { push } from "connected-react-router";
import ModalPaymentHistoric from "../../components/Modal/ModalPaymentHistoric";
import { getDashboard, getDashboardMes } from "../../actions/bipbop-report";
import { setModalPaymentHistoric } from "../../actions/modal";

function RelatorioBipbop() {
  const dispatch = useDispatch();

  const dashboardBipbop = useSelector(state => state.bipbopReport.dashboard);
  const dashboardMesBipbop = useSelector(
    state => state.bipbopReport.dashboardMes
  );

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    dispatch(getDashboard());
    dispatch(getDashboardMes(new Date().getMonth() + 1));
  }, [dispatch]);

  const optionsMonth = [
    {
      key: "Janeiro",
      text: "Janeiro",
      value: 1
    },
    {
      key: "Fevereiro",
      text: "Fevereiro",
      value: 2
    },
    {
      key: "Março",
      text: "Março",
      value: 3
    },
    {
      key: "Abril",
      text: "Abril",
      value: 4
    },
    {
      key: "Maio",
      text: "Maio",
      value: 5
    },
    {
      key: "Junho",
      text: "Junho",
      value: 6
    },
    {
      key: "Julho",
      text: "Julho",
      value: 7
    },
    {
      key: "Agosto",
      text: "Agosto",
      value: 8
    },
    {
      key: "Setembro",
      text: "Setembro",
      value: 9
    },
    {
      key: "Outubro",
      text: "Outubro",
      value: 10
    },
    {
      key: "Novembro",
      text: "Novembro",
      value: 11
    },
    {
      key: "Dezembro",
      text: "Dezembro",
      value: 12
    }
  ];

  const months = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ"
  ];

  const categoriesChartBipbop = dashboardBipbop.reports.map(
    ({ mes, ano }) => `${months[mes - 1]}/${ano}`
  );
  const dataChartBipbop = dashboardBipbop.reports.map(({ total }) => total);

  const selectMonthDashboard = month => {
    setSelectedMonth(month);
    dispatch(getDashboardMes(month));
  };

  const handleOpenModalPaymentHistoric = () => {
    dispatch(setModalPaymentHistoric(true));
  };

  const handleCloseModalPaymentHistoric = () => {
    dispatch(setModalPaymentHistoric(false));
  };

  return (
    <div>
      <DivTop>
        <GridColumn>
          <Dropdown
            icon="calendar outline"
            direction="left"
            inline
            value={selectedMonth}
            options={optionsMonth}
            onChange={(e, { value }) => selectMonthDashboard(value)}
          />
        </GridColumn>
        <Grid.Column textAlign="right">
          <ButtonBorderEdit
            borderRadius="4px"
            color="#185F9B"
            onClick={() => dispatch(push("/valores-bipbop"))}
          >
            VALORES BIPBOP
          </ButtonBorderEdit>
        </Grid.Column>
      </DivTop>
      <DivContent>
        <DivRelatorio>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <h3>Relatório de pagamento</h3>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <h4 onClick={() => handleOpenModalPaymentHistoric()}>
                  VER TODOS
                </h4>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="middle">
              <Grid.Column width={4}>
                <h6>Valor total</h6>
                <h1>{formatToBRL(dashboardBipbop.valorFinal)}</h1>
              </Grid.Column>
              <Grid.Column width={12}>
                <ChartLine
                  data={dataChartBipbop}
                  categories={categoriesChartBipbop}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </DivRelatorio>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <DivRelatorio padding="40px 40px 80px">
                <h3>Acompanhamento jurídico automático</h3>
                <Grid>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column width={8}>
                      <h6>Quantidade</h6>
                      <h2>{dashboardMesBipbop.acompanhamentos}</h2>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <h6>Valor</h6>
                      <h2>
                        {formatToBRL(dashboardMesBipbop.valorAcompanhamento)}
                      </h2>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </DivRelatorio>
            </Grid.Column>
            <Grid.Column width={8}>
              <DivRelatorio>
                <h3>Saneamento de dados</h3>
                <Grid>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column width={8}>
                      <h6>Quantidade</h6>
                      <h2>{dashboardMesBipbop.saneamentos}</h2>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <h6>Valor</h6>
                      <h2>{formatToBRL(dashboardMesBipbop.valorSaneamento)}</h2>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <h3>Busca por tipo de garantia</h3>
                <Grid>
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column width={8}>
                      <h6>Quantidade</h6>
                      <h2>{dashboardMesBipbop.buscaGarantias}</h2>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <h6>Valor</h6>
                      <h2>
                        {formatToBRL(dashboardMesBipbop.valorBuscaGarantia)}
                      </h2>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </DivRelatorio>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </DivContent>
      <ModalPaymentHistoric
        onClose={handleCloseModalPaymentHistoric}
        date={categoriesChartBipbop[selectedMonth - 1]}
      />
    </div>
  );
}

export default RelatorioBipbop;
