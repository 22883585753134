import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import { setDialogNewMassiveProcess } from '../../../actions/dialog';
import { DivDialog } from './style';
import ButtonIcon from '../../UI/ButtonIcon/index';

function DialogNewProcessMassive() {
  const open = useSelector((state) => state.dialog.DialogNewMassiveProcess);
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    dispatch(setDialogNewMassiveProcess(false));
  };

  return (
    <Dialog maxWidth={false} open={open} onClose={handleCloseDialog}>
      <DivDialog>
        <div>
          <h1>Acompanhamento Automático</h1>
          <ButtonIcon onClick={handleCloseDialog} className="buttonClose">
            <CloseIcon />
          </ButtonIcon>
        </div>
        <p>
          Acompanhe o status da evolução do processo, do início ao fim, de
          maneira automática.
        </p>
        <p>Essa funcionalidade está sujeita as taxas.</p>
      </DivDialog>
    </Dialog>
  );
}

export default DialogNewProcessMassive;
