import styled from 'styled-components';

export const SectionTop = styled.div `
  display: flex;
  margin: 0 48px;
  margin-top: -38px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 42px;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #616161;
    margin: 0;
  }
`;

export const SectionForm = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 360px;
`;

export const TextArea = styled.p `
  border: 1px solid #EEEEEE;
  border-radius: 2px;
  padding: 16px;
  margin-top: 50px;
  background: #FFFFFF;
  font-family: 'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #424242;
  mark {
    background-color: #FFFFFF;
    text-decoration: underline;
    text-transform: uppercase;
  }
`;

export const Form = styled.form `
  width: 61.81%;
  display: grid;
  grid-template-columns: repeat(4, 222px);
  grid-template-rows: repeat(21, 96px);
  align-items: flex-end;
  margin-bottom: 40px;

  .item-1 {
    grid-row: 1;
    grid-column: 1/5;
  }

  .item-2 {
    grid-row: 2;
    grid-column: 1/5;
  }

  .item-3 {
    grid-row: 3;
    grid-column: 1;
  }

  .item-4 {
    grid-row: 3;
    grid-column: 2/4;
  }

  .item-5 {
    grid-row: 4;
    grid-column: 1/5;
  }

  .item-6 {
    grid-row: 5;
    grid-column: 1;
  }

  .item-7 {
    grid-row: 5;
    grid-column: 2/5;
  }

  .item-8 {
    grid-row: 6;
    grid-column: 1/5;
  }

  .item-9-1 {
    grid-row: 7;
    grid-column: 1;
  }

  .item-9 {
    grid-row: 7;
    grid-column: 2;
  }

  .item-10 {
    grid-row: 7;
    grid-column: 3;
  }

  .item-11 {
    grid-row: 7;
    grid-column: 4;
  }

  .item-12 {
    grid-row: 7;
    grid-column: 5;
  }

  .item-13 {
    grid-row: 8;
    grid-column: 1/5;
  }

  .item-14 {
    grid-row: 9;
    grid-column: 1;
  }

  .item-15 {
    grid-row: 9;
    grid-column: 2;
  }

  .item-16 {
    grid-row: 9;
    grid-column: 3/5;
    justify-self: end;
  }

  .item-17 {
    grid-row: 10;
    grid-column: 1/5;
  }

  .item-18 {
    grid-row: 11;
    grid-column: 1;
  }

  .item-19 {
    grid-row: 11;
    grid-column: 2;
  }

  .item-20 {
    grid-row: 11;
    grid-column: 3;
  }

  .item-21 {
    grid-row: 11;
    grid-column: 4;
    justify-self: end;
  }

  .item-22 {
    grid-row: 12;
    grid-column: 1/3;
  }

  .item-23 {
    grid-row: 12;
    grid-column: 3;
  }

  .item-24 {
    grid-row: 12;
    grid-column: 4;
    justify-self: end;
  }

  .item-25 {
    grid-row: 13;
    grid-column: 1/5;
  }

  .item-26 {
    grid-row: 14;
    grid-column: 1/3;
  }

  .item-27 {
    grid-row: 15;
    grid-column: 1/5;
  }

  .item-28 {
    grid-row: 16;
    grid-column: 1/3;
  }

  .item-29 {
    grid-row: 17;
    grid-column: 1/3;
  }

  .item-30 {
    grid-row: 18;
    grid-column: 1/5;
  }

  .item-31 {
    grid-row: 19/21;
    grid-column: 1/5;
    align-self: flex-start;
  }

  .item-32 {
    grid-row: 21;
    grid-column: 4;
    justify-self: end;
    margin-bottom: 80px;
  }
`;
