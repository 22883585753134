import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const DivTableArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .buttonSend{
    margin-right: 16px;
    margin-top: 80px;
  }
`;

export const Table = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  font-family: 'Montserrat', 'sans-serif';
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
`;

export const TableScroll = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  cursor: grab;
`;

export const CellHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  padding: 10px 16px;
  padding-left: ${(props) => props.paddingLeft || '16px'};
  word-break: keep-all;
  white-space: nowrap;
  background: #FAFAFA;
  width: ${(props) => props.width};
  margin-bottom: 8px;
`;

export const Cell = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #2C3E50;
  background: #FFFFFF;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 4px;
`;

export const CellStatus = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2C3E50;
  background: #FFFFFF;
  height: 72px;
  padding-left: 32px;
  padding-right: 32px;
  border-left: 4px solid #FFFFFF;
  margin-bottom: 4px;
`;

export const HelpButton = withStyles({
  root: {
    height: '15px',
    minWidth: '15px',
    width: '15px',
    padding: '0',
    borderRadius: '50%',
    margin: '0 0 0 4px',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '17px',
    width: '15px',
  },
})(Button);
