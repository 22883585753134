import styled from 'styled-components';

export const SectionTop = styled.div `
  display: flex;
  margin: 0 48px;
  margin-top: -38px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 42px;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #616161;
    margin: 0;
  }
`;

export const SectionStepper = styled.div `
  display: grid;
  width: 100%;
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 40px;
  grid-template-columns: repeat(4, 222px);
  grid-template-rows: repeat(3, 96px);
  align-items: end;

  .item-1{
    grid-row: 1;
    grid-column: 1/5;
  }

  .item-2{
    grid-row: 2;
    grid-column: 1/5;
  }

  .item-3{
    grid-row: 3;
    grid-column: 1/3;
  }
`;

export const SectionFormPayment = styled.div `
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 40px;
  .item-3 {
    margin-top: 40px;
  }
`;

export const SectionCards = styled.div `
  display: flex;
  margin: 24px 50px 88px 50px;
  justify-content: space-between;
`;

export const DivButtonPrint = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 48px;
`;

export const DivButtonMinuta = styled.div `
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  padding-right: 48px;
  margin-bottom: 100px;
`;
