import styled from "styled-components";

export const ModalBody = styled.div`
  background-color: #ffffff;
  width: 720px;
  height: 300px;
  margin: auto;
  margin-top: 8%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0142;
    margin: 0;
    line-height: 20px;
  }
`;

export const FilterContent = styled.div`
  margin: 0px 20px;

  .MuiFormControl-root {
    width: 204px;
    margin: 0;
    margin-right: 24px;
  }
  .MuiInputBase-root {
    height: 40px;
  }
  .MuiIconButton-root {
    color: #f9b233;
  }
  .ui.grid {
    margin: 0px;
  }

  .ui.grid > .row > .column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  input {
    border: 1px solid #eeeeee !important;
  }

  label {
    color: #185f9b;
  }
`;

export const Label = styled.label`
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${props => props.color || "#424242"};
  margin-bottom: 4px;
`;
