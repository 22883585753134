import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  GridRow,
} from "semantic-ui-react";


import {
  SectionTable,
  Cell,
  CellHeader,
  Table,
  TableScroll,
  DivTop,
  DivContent,
  InputSla,
} from "./style";


import { findDeadline, updateDeadline } from "../../actions/deadline";

function SLAs(){
    const dispatch = useDispatch();
  const policyDueDate = useSelector(state => state.deadline.policyDueDate);
  const policyAnniversary = useSelector(
    state => state.deadline.policyAnniversary
  );

  const [deadline, setDeadline] = useState();

    useEffect(() => {
        if (deadline) dispatch(updateDeadline(deadline));
        dispatch(findDeadline());
      }, [dispatch, deadline]);
    const healthyAnniversaryChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate
          },
          policyAnniversary: {
            ...policyAnniversary,
            healthy: value
          }
        };
    
        setDeadline(newDeadline);
      };
    
    const healthyDueDateChange = async (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate,
            healthy: value
          },
          policyAnniversary: {
            ...policyAnniversary
          }
        };
    
        setDeadline(newDeadline);
      };
    
      const attentionAnniversaryChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate
          },
          policyAnniversary: {
            ...policyAnniversary,
            attention: value
          }
        };
    
        setDeadline(newDeadline);
      };
    
      const attentionDueDateChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate,
            attention: value
          },
          policyAnniversary: {
            ...policyAnniversary
          }
        };
    
        setDeadline(newDeadline);
      };
    
      const lateAnniversaryChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate
          },
          policyAnniversary: {
            ...policyAnniversary,
            late: value
          }
        };
    
        setDeadline(newDeadline);
      };
    
      const lateDueDateChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate,
            late: value
          },
          policyAnniversary: {
            ...policyAnniversary
          }
        };
    
        setDeadline(newDeadline);
      };
    
      const criticalAnniversaryChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate
          },
          policyAnniversary: {
            ...policyAnniversary,
            critical: value
          }
        };
    
        setDeadline(newDeadline);
      };
    
      const criticalDueDateChange = (event, { value }) => {
        const newDeadline = {
          policyDueDate: {
            ...policyDueDate,
            critical: value
          },
          policyAnniversary: {
            ...policyAnniversary
          }
        };
    
        setDeadline(newDeadline);
      };
    
        return (
          <div>
            <DivTop>
              <h2>SLA EM DIAS</h2>
            </DivTop>
            <DivContent>
              <SectionTable margin="0px">
                <Table>
                  <TableScroll columns="6">
                    <CellHeader fontWeight="500">SERVIÇOS</CellHeader>
                    <CellHeader alignItems="center" fontWeight="500">
                      NO PRAZO
                    </CellHeader>
                    <CellHeader
                      alignItems="center"
                      fontWeight="500"
                      widthText="170px"
                    >
                      <p>ATENÇÃO</p>
                      <p
                        style={{
                          fontWeight: 400
                        }}
                      >
                        (Intervalo de tempo que necessita atenção para não perder o
                        prazo)
                      </p>
                    </CellHeader>
                    <CellHeader
                      alignItems="center"
                      fontWeight="500"
                      widthText="170px"
                    >
                      <p>ALERTA</p>
                      <p
                        style={{
                          fontWeight: 400
                        }}
                      >
                        (Intervalo de tempo que necessita alerta para não perder o
                        prazo)
                      </p>
                    </CellHeader>
                    <CellHeader
                      alignItems="center"
                      fontWeight="500"
                      widthText="170px"
                    >
                      <p>CRÍTICO</p>
                      <p
                        style={{
                          fontWeight: 400
                        }}
                      >
                        (Intervalo de tempo que necessita atenção ESPECIAL)
                      </p>
                    </CellHeader>
                    <CellHeader
                      alignItems="center"
                      fontWeight="500"
                      widthText="170px"
                    >
                      <p>TEMPO MÁXIMO PARA RENOVAÇÃO DA APÓLICE</p>
                    </CellHeader>
                    <Fragment>
                      <Cell fontWeight="500" fontSize="14px">
                        Vencimento da apólice
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyDueDate.healthy}
                            onChange={healthyDueDateChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do vencimento</p>
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyDueDate.attention}
                            onChange={attentionDueDateChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do vencimento</p>
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyDueDate.late}
                            onChange={lateDueDateChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do vencimento</p>
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyDueDate.critical}
                            onChange={criticalDueDateChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do vencimento</p>
                      </Cell>
                      <Cell alignItems="center">
                        <p>DATA DO VENCIMENTO DA APÓLICE</p>
                      </Cell>
                    </Fragment>
                    <Fragment>
                      <Cell fontWeight="500" fontSize="14px">
                        Aniversário da apólice
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyAnniversary.healthy}
                            onChange={healthyAnniversaryChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do aniversário</p>
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyDueDate.attention}
                            onChange={attentionAnniversaryChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do vencimento</p>
                      </Cell>
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyAnniversary.late}
                            onChange={lateAnniversaryChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do aniversário</p>
                      </Cell>
    
                      <Cell alignItems="center">
                        <GridRow style={{ justifyContent: "center" }}>
                          <InputSla
                            as={Form.Input}
                            defaultValue={policyAnniversary.critical}
                            onChange={criticalAnniversaryChange.bind(this)}
                          />
                          dias{" "}
                        </GridRow>
                        <p>ANTES do aniversário</p>
                      </Cell>
                      <Cell alignItems="center" widthText="170px">
                        <p>DATA DO ANIVERSÁRIO DA APÓLICE</p>
                      </Cell>
                    </Fragment>
                  </TableScroll>
                </Table>
              </SectionTable>
            </DivContent>
          </div>
        );
      
    
}

export default SLAs;