import React from 'react';
import TableNewMassiveProcess from '../../components/Table/TableNewMassiveProcess/index';
import { BackgroundDiv, SectionTable, SectionTitle } from './style';
import Navbar from '../../components/Navbar/index';

function NovoProcessoMassivo() {
  return (
    <BackgroundDiv>
      <Navbar pageSelected="acompanhamento-processual" />
      <SectionTitle>
        <h1>Processos</h1>
      </SectionTitle>
      <SectionTable>
        <TableNewMassiveProcess />
      </SectionTable>
    </BackgroundDiv>
  );
}

export default NovoProcessoMassivo;
