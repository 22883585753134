import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 640px;
  height: 344px;
  margin: auto;
  margin-top: 10%;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div`
  margin-left: 40px;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00235C;
    letter-spacing: 0.1px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }
`;

export const ButtonIndividual = styled.button`
  width: 160px;
  height: 40px;
  background: #F8FAFD;
  box-shadow: inset -1px 0px 0px rgba(0,0,0, 0.25);
  border-radius: 10px 0px 0px 10px;
  color: #00366C;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin-top: 36px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 35, 92, 0.04);
  }
`;

export const ButtonMassivo = styled(ButtonIndividual)`
  border-radius: 0px 10px 10px 0px;
  box-shadow: none;
`;
