import styled from 'styled-components';

export const Subtitle = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #00366C;
`;
