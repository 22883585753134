import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledIconPassword, StyledFormControl, StyledLabel, StyledInput,
} from './style';

function InputPassword({ label, onChange, value }) {
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <div>
      <StyledIconPassword color={inputFocused === true ? 'primary' : 'secondary'} />
      <StyledFormControl>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onChange={onChange}
          value={value}
          required
          type="password"
        />
      </StyledFormControl>
    </div>
  );
}

export default InputPassword;

InputPassword.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

InputPassword.defaultProps = {
  label: 'Sua Senha',
  onChange: () => {},
  value: '',
};
