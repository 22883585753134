import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import PropType from 'prop-types';

import Modal from '@material-ui/core/Modal';

import {
    ModalBody,
    TitleContent,
    Content,
    BtnFinish,
    BtnCancel,
    Text,
    Row
} from './style';

import {editLead, listLeads} from '../../../actions/pipeline';
import {setModalArchiveLead} from '../../../actions/modal';

function ModalArchiveLead(props) {
    const dispatch = useDispatch()

    const {onClose} = props;
    const open = useSelector(state => state.modal.ModalArchiveLead)
    const lead = useSelector(state => state.pipeline.lead)
    
    const archiveLead = () => {
        dispatch(editLead(lead.id, {arquivado: true, dataArquivamento: `${`${new Date().toISOString()}`.split('T')[0]}`}))
        dispatch(setModalArchiveLead(false))
        dispatch(listLeads({arquivado: false}))
    }

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <ModalBody>
                    <TitleContent>
                        <h1>Arquivar lead</h1>
                    </TitleContent>
                    <Content>
                        <Text color="#616161">Deseja arquivar esse lead?</Text>
                        <Text>Os dados buscados NÃO serão apagados!</Text>
                        <Text>Você poderá reativar o lead quando quiser!</Text>
                        <Row>
                            <BtnCancel onClick={() => onClose()}>CANCELAR</BtnCancel>
                            <BtnFinish onClick={() => archiveLead()}>ARQUIVAR</BtnFinish>
                        </Row>
                    </Content>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalArchiveLead;

ModalArchiveLead.propType = {
    onClose: PropType.func
};
