/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { StyledDiv, Label, SelectStyled } from './style';

function Select(props) {
  const {
    label,
    valueOption,
    valueSelect,
    onChange, width,
    className,
    name,
    disabled,
    isRequired,
    error,
    colorLabel,
  } = props;

  return (
    <StyledDiv className={className}>
      <Label htmlFor={name} color={error ? '#C95A5A' : colorLabel}>
        {label}
        {isRequired && <p>*</p>}
      </Label>
      <SelectStyled
        name={name}
        value={valueSelect}
        onChange={onChange}
        width={width}
        disabled={disabled}
        border={error ? '#C95A5A' : '#EEEEEE'}
      >
        {
          valueOption.map((item) => (
            <option value={item.value} key={item.value}>{item.text}</option>
          ))
        }
      </SelectStyled>
    </StyledDiv>
  );
}

export default Select;

Select.propTypes = {
  label: PropTypes.string,
  valueOption: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  valueSelect: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.any,
  width: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  colorLabel: PropTypes.string,
};

Select.defaultProps = {
  label: '',
  valueOption: [],
  width: '318px',
  className: '',
  name: 'select',
  disabled: false,
  isRequired: false,
  error: false,
  colorLabel: '#424242',
};
