import React from 'react';
import PropTypes from 'prop-types';
import StepperDefault from '../../UI/StepperDefault/index';

function StepperEmpresa(props) {
  const { className, activeStep } = props;
  const steps = ['Empresa e acessos', 'Seguradoras e valores'];

  return <StepperDefault className={className} activeStep={activeStep} steps={steps} />;
}

export default StepperEmpresa;

StepperEmpresa.propTypes = {
  className: PropTypes.string,
  activeStep: PropTypes.number,
};

StepperEmpresa.defaultProps = {
  className: '',
  activeStep: 0,
};
