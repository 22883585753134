import React, { useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "../../store/index";
import Routes from "../../routes/index";
import "./global.css";
import SnackbarProposal from "../Snackbar/SnackbarProposal/index";
import SnackbarSaneamento from "../Snackbar/SnackbarSaneamento/index";
import SnackbarError from "../Snackbar/SnackbarError/index";
import axios from "axios";
import { startSocket } from "../../actions/socket";
import { isAuthenticated } from "../../actions/auth";

axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*"
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2b3b98"
    },
    secondary: {
      main: "#00235C"
    },
    error: {
      main: "#c10015"
    },
    warning: {
      main: "#ffd80b"
    }
  },
  typography: {
    fontFamily: ["'Montserrat', 'sans-serif'"]
  }
});

function App() {
  useEffect(() => {
    document.body.style.padding = "0";
    document.body.style.margin = "0";
    document.body.style.fontFamily = "'Montserrat', 'sans-serif'";
    document.body.style.backgroundColor = "#F8FAFD";
  });

  useEffect(() => {
    if (isAuthenticated()) {
      startSocket();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Routes />
        <SnackbarProposal />
        <SnackbarError />
        <SnackbarSaneamento />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
