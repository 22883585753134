import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Input,
  GridColumn,
  GridRow,
} from "semantic-ui-react";
import { AiFillCloseCircle } from "react-icons/ai";

import ScrollContainer from "react-indiana-drag-scroll";

import {
  ButtonCompany,
  SectionTable,
  Cell,
  CellHeader,
  Table,
  TableScroll,
  DivTop,
  Search,
  DivContent,
} from "./style";

import history from "../../routes/history";

import { findAllCompany, deleteOneCompany } from "../../actions/company";

function Clientes() {
    const dispatch = useDispatch();
  const companies = useSelector(state => state.company.companies);

  const [searchCompany, setSearchCompany] = useState("");

    useEffect(() => {
        dispatch(findAllCompany({ nome: searchCompany }));
      }, [dispatch, searchCompany]);

    const deleteCompany = async company => {
        await dispatch(deleteOneCompany(company.id));
        dispatch(findAllCompany({ nome: searchCompany }));
      };
  return (
    <div>
      <DivTop>
        <GridColumn>
          <h2>Nova empresa (holding)</h2>
          <h4>Adicione uma nova holding</h4>
        </GridColumn>
        <GridColumn textAlign="right">
          <ButtonCompany
            className="btn-new"
            color="blue"
            onClick={() => history.push("/empresa/nova")}
          >
            Novo grupo empresarial
          </ButtonCompany>
        </GridColumn>
      </DivTop>
      <DivContent>
        <GridRow>
          <h2>Empresas cadastradas</h2>
        </GridRow>
        <GridRow columns={2}>
          <Search>
            <Form.Field>
              <h3>Buscar empresa</h3>
              <Input
                icon="search"
                placeholder="Buscar..."
                onChange={e => setSearchCompany(e.target.value)}
              />
            </Form.Field>
          </Search>
        </GridRow>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="6">
                <CellHeader>NOME</CellHeader>
                <CellHeader>CNPJ</CellHeader>
                <CellHeader>E-MAIL</CellHeader>
                <CellHeader>TELEFONE</CellHeader>
                <CellHeader>ENDEREÇO</CellHeader>
                <CellHeader>OPÇÕES</CellHeader>

                {companies.map((company, key) => (
                  <Fragment key={key}>
                    <Cell>
                      <p>{company.nome}</p>
                      <p>{company.razaoSocial}</p>
                    </Cell>
                    <Cell>{company.cnpj}</Cell>
                    <Cell>{company.email}</Cell>
                    <Cell>{company.telefone}</Cell>
                    <Cell textAlign="left">
                      <p>
                        {company.logradouro} {company.numero}- {company.bairro}
                      </p>
                      <p>
                        {company.cidade}- {company.uf}
                      </p>
                    </Cell>
                    <Cell
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="start"
                    >
                      <Button
                        color="blue"
                        style={{
                          padding: "6px",
                          fontWeight: "300"
                        }}
                        onClick={() =>
                          history.push(`/empresa/detalhes/${company.id}`)
                        }
                      >
                        Ver mais
                      </Button>
                      <AiFillCloseCircle
                        className="icone-tabela"
                        size={27}
                        color="red"
                        onClick={() => deleteCompany(company)}
                      />
                    </Cell>
                  </Fragment>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
      </DivContent>
    </div>
  );
}

export default Clientes;
