import styled from 'styled-components';

export const SectionTop = styled.section`
  display: flex;
  margin: 0 50px;
  justify-content: space-between;
  padding-bottom: 40px;
  border-bottom: 1px solid #E0E0E0;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #00235C;
  }

  h2{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #616161;
  }
`;

export const SectionTable = styled.section`
  margin:  38px 50px 200px 50px;
`;

export const DivFilterTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;
