import React, { useEffect, Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Label } from "semantic-ui-react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiChevronLeft } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import ScrollContainer from "react-indiana-drag-scroll";

import Navbar from "../../components/Navbar";
import ModalEditCompany from "../../components/Modal/ModalEditCompany";
import ModalEditMember from "../../components/Modal/ModalCompanyUser";
import ModalCreateMember from "../../components/Modal/ModalCompanyUser";
import ModalNewTomador from "../../components/Modal/ModalNewTomador";
import ModalListTakers from "../../components/Modal/ModalListTakers";
import {
  SectionTable,
  Cell,
  CellHeader,
  TableScroll,
  Table,
  DetalhesContent,
  ButtonBorderEdit
} from "./style";

import history from "../../routes/history";

import {
  setModalEditCompany,
  setModalEditMember,
  setModalCreateMember,
  setModalNewTomador,
  setModalListTakers
} from "../../actions/modal";
import {
  findOneCompany,
  deleteOneCompany,
  deleteTomador
} from "../../actions/company";
import {
  findCompanyUsers,
  deleteOneCompanyUser,
  findOneCompanyUser
} from "../../actions/companyUser";
import {
  getDashboardBMG,
  getDashboardJunto,
  getDashboardTokio
} from "../../actions/dashboard";
import { numberMask } from "../../services/mask";
import ButtonDefault from "../../components/UI/ButtonDefault";

function DetalhesEmpresa() {
  const dispatch = useDispatch();

  const empresa = useSelector(state => state.company.company);
  const membros = useSelector(state => state.companyUser.users);

  const [seguradoras, setSeguradoras] = useState([]);

  const [loading, setLoading] = useState(false);
  const { idEmpresa } = useParams();

  useEffect(() => {
    dispatch(findOneCompany(idEmpresa));
    dispatch(findCompanyUsers({ idEmpresa }));
  }, [idEmpresa, dispatch]);

  useEffect(() => {
    const getLimiteSeguradoras = async () => {
      let seguradorasMap = [
        Object.keys(empresa.bmg || {}).length > 0 ? "BMG" : null,
        Object.keys(empresa.tokyo || {}).length > 0 ? "Tokio Marine" : null,
        Object.keys(empresa.junto || {}).length > 0 ? "Junto" : null
      ];

      const insurers = [];

      seguradorasMap.map(async insurer => {
        if (insurer) {
          if (insurer === "Tokio Marine") {
            const tokioDashboard = await getDashboardTokio(
              numberMask(empresa.cnpj || "")
            );
            let result = {
              ...tokioDashboard,
              seguradora: "Tokio Marine"
            };
            insurers.push(
              (result = {
                ...result,
                ...empresa.tokyo
              })
            );
          }

          if (insurer === "Junto") {
            const juntoDashboard = await getDashboardJunto(
              numberMask(empresa.cnpj || "")
            );
            let result = {
              ...juntoDashboard,
              seguradora: "Junto"
            };
            insurers.push(
              (result = {
                ...result,
                ...empresa.junto
              })
            );
          }

          if (insurer === "BMG") {
            const bmgDashboard = await getDashboardBMG(
              numberMask(empresa.cnpj || "")
            );
            let result = {
              ...bmgDashboard,
              seguradora: "BMG"
            };
            insurers.push(
              (result = {
                ...result,
                ...empresa.bmg
              })
            );
          }

          setSeguradoras(insurers);
          setLoading(l => !l);
        }
      });
    };
    getLimiteSeguradoras();
  }, [empresa]);

  const handleOpenModalEditCompany = () => {
    dispatch(setModalEditCompany(true));
  };

  const handleCloseModalEditCompany = () => {
    dispatch(setModalEditCompany(false));
  };

  const handleOpenModalEditMember = member => {
    dispatch(setModalEditMember(true));
    dispatch(findOneCompanyUser(member.id));
  };

  const handleCloseModalEditMember = () => {
    dispatch(setModalEditMember(false));
  };

  const handleOpenModalCreateMember = () => {
    dispatch(setModalCreateMember(true));
  };

  const handleCloseModalCreateMember = () => {
    dispatch(setModalCreateMember(false));
  };

  const deleteMembro = async membro => {
    await dispatch(deleteOneCompanyUser(membro.id));
    dispatch(findCompanyUsers({ idEmpresa }));
  };

  const deleteEmpresa = async empresa => {
    dispatch(deleteOneCompany(empresa.id));
    history.push("/configuracoes");
  };

  const [listToModalTakers, setListToModalTakers] = useState([]);

  const openModalListTakers = tomadores => {
    setListToModalTakers(tomadores);
    dispatch(setModalListTakers(true));
  };

  return (
    <div>
      <Navbar pageSelected="configuracoes" />

      <DetalhesContent>
        <Grid.Row className="top">
          <FiChevronLeft
            size={35}
            color="#185F9B"
            onClick={() => history.push("/configuracoes")}
          />
          <h2>Detalhes da empresa</h2>
        </Grid.Row>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="6">
                <CellHeader>NOME</CellHeader>
                <CellHeader>CNPJ</CellHeader>
                <CellHeader>E-MAIL</CellHeader>
                <CellHeader>TELEFONE</CellHeader>
                <CellHeader>ENDEREÇO</CellHeader>
                <CellHeader>OPÇÕES</CellHeader>
                <Fragment>
                  <Cell>
                    <p>{empresa.nome}</p>
                    <p>{empresa.razaoSocial}</p>
                  </Cell>
                  <Cell>{empresa.cnpj}</Cell>
                  <Cell>{empresa.email}</Cell>
                  <Cell>{empresa.telefone}</Cell>
                  <Cell textAlign="left">
                    <p>
                      {empresa.logradouro} {empresa.numero}- {empresa.bairro}
                    </p>
                    <p>
                      {empresa.cidade}- {empresa.uf}
                    </p>
                  </Cell>
                  <Cell flexDirection="row" justifyContent="start">
                    <AiFillCloseCircle
                      className="icone-tabela"
                      size={27}
                      color="red"
                      onClick={() => deleteEmpresa(empresa)}
                    />
                    <MdModeEdit
                      size={27}
                      onClick={() => handleOpenModalEditCompany()}
                    />
                  </Cell>
                </Fragment>
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
        <Grid verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <h2>Tomadores</h2>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <ButtonBorderEdit
                borderRadius="4px"
                onClick={() => dispatch(setModalNewTomador(true))}
              >
                Novo Tomador
              </ButtonBorderEdit>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="4">
                <CellHeader singleLine>NOME</CellHeader>
                <CellHeader>CNPJ</CellHeader>
                <CellHeader>Razão Social</CellHeader>
                <CellHeader>OPÇÕES</CellHeader>

                {empresa.tomadores.map((tomador, key) => (
                  <Fragment key={key}>
                    <Cell singleLine>{tomador.nome}</Cell>
                    <Cell>{tomador.cnpj}</Cell>
                    <Cell>{tomador.razaoSocial}</Cell>
                    <Cell flexDirection="row" justifyContent="start">
                      <AiFillCloseCircle
                        className="icone-tabela"
                        size={27}
                        color="red"
                        onClick={() =>
                          dispatch(deleteTomador(tomador.id, idEmpresa))
                        }
                      />
                    </Cell>
                  </Fragment>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
        <Grid verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <h2>Acessos da empresa</h2>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <ButtonBorderEdit
                borderRadius="4px"
                onClick={() => handleOpenModalCreateMember()}
              >
                Adicionar acesso
              </ButtonBorderEdit>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="7">
                <CellHeader singleLine>NOME</CellHeader>
                <CellHeader>E-MAIL</CellHeader>
                <CellHeader>TELEFONE</CellHeader>
                <CellHeader>TIPO</CellHeader>
                <CellHeader>PERMISSÕES</CellHeader>
                <CellHeader>TOMADOR</CellHeader>
                <CellHeader>OPÇÕES</CellHeader>

                {membros.map((membro, key) => (
                  <Fragment key={key}>
                    <Cell singleLine>{membro.nome}</Cell>
                    <Cell>{membro.email}</Cell>
                    <Cell>{membro.telefone}</Cell>
                    <Cell>{membro.tipo}</Cell>
                    <Cell
                      alignItems="center"
                      justifyContent="start"
                      flexDirection="row"
                    >
                      {membro.permissoes.map(permissao => (
                        <Label className="label-permissoes" circular>
                          {permissao}
                        </Label>
                      ))}
                    </Cell>
                    <Cell
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="row"
                    >
                      {membro.tomadores.map((tomador, index) => (
                        <>
                          {index <= 1 && (
                            <Label className="label-tomadores" circular>
                              {tomador.nome}
                            </Label>
                          )}
                          {index === 2 && (
                            <ButtonDefault
                              color="primary"
                              variant="text"
                              size="small"
                              onClick={() =>
                                openModalListTakers(membro.tomadores)
                              }
                            >
                              ver todos
                            </ButtonDefault>
                          )}
                        </>
                      ))}
                    </Cell>
                    <Cell flexDirection="row" justifyContent="start">
                      <AiFillCloseCircle
                        className="icone-tabela"
                        size={27}
                        color="red"
                        onClick={() => deleteMembro(membro)}
                      />
                      <MdModeEdit
                        size={27}
                        onClick={() => handleOpenModalEditMember(membro)}
                      />
                    </Cell>
                  </Fragment>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
        <Grid verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column width={8}>
              <h2>Seguradoras</h2>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <ButtonBorderEdit
                borderRadius="4px"
                color="#185F9B"
                onClick={() =>
                  history.push("/empresa/seguradoras/" + idEmpresa)
                }
              >
                Editar
              </ButtonBorderEdit>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SectionTable>
          <Table>
            <ScrollContainer>
              <TableScroll columns="8">
                <CellHeader fontWeight="500">Seguradora</CellHeader>
                <CellHeader fontWeight="500">Limite aprovado</CellHeader>
                <CellHeader fontWeight="500">Limite tomado</CellHeader>
                <CellHeader fontWeight="500">Limite disponível</CellHeader>
                <CellHeader fontWeight="500">Reserva</CellHeader>
                <CellHeader fontWeight="500">Taxa</CellHeader>
                <CellHeader fontWeight="500">Situação CCG</CellHeader>
                <CellHeader fontWeight="500">Validade do cadastro</CellHeader>
                {seguradoras.map((seguradora, key) => (
                  <>
                    <Fragment key={key}>
                      <Cell fontWeight="600">{seguradora.seguradora}</Cell>
                      <Cell fontWeight="600">R$ {seguradora.limiteTotal}</Cell>
                      <Cell fontWeight="600">
                        R$ {seguradora.limiteUtilizado}
                      </Cell>
                      <Cell fontWeight="600">
                        R$ {seguradora.limiteDisponivel}
                      </Cell>
                      <Cell fontWeight="600">R$ {seguradora.reserva}</Cell>
                      <Cell fontWeight="600">{seguradora.taxa}%</Cell>
                      <Cell fontWeight="600">{seguradora.situacaoCCG}</Cell>
                      <Cell fontWeight="600">
                        {new Date(seguradora.validade).toLocaleDateString(
                          "pt-BR"
                        )}
                      </Cell>
                    </Fragment>
                  </>
                ))}
              </TableScroll>
            </ScrollContainer>
          </Table>
        </SectionTable>
      </DetalhesContent>
      <ModalEditCompany onClose={handleCloseModalEditCompany} />
      <ModalEditMember
        onClose={handleCloseModalEditMember}
        title="Editar membro"
        labelBtnFinish="Atualizar"
        open={useSelector(state => state.modal.ModalEditMember)}
        action="edit"
        empresa={empresa}
      />
      <ModalCreateMember
        onClose={handleCloseModalCreateMember}
        title="Adicionar membro"
        labelBtnFinish="Finalizar"
        open={useSelector(state => state.modal.ModalCreateMember)}
        action="create"
        empresa={empresa}
      />
      <ModalNewTomador idEmpresa={idEmpresa} />
      <ModalListTakers takers={listToModalTakers} />
    </div>
  );
}

export default DetalhesEmpresa;
