import React, { useState, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { formatToBRL } from 'brazilian-values';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Navbar from '../../components/Navbar/index';
import {
  Content,
  StatusDiv,
  AccompanimentDiv,
  ButtonsDiv,
  SectionTop,
} from './style';
import ButtonDefault from '../../components/UI/ButtonDefault/index';
import { updateProcess } from '../../actions/process';
import { setRoute } from '../../actions/navbar';
import { setPolicyEmited, setProposalData } from '../../actions/policy';
import { setLoadingCardDocument } from '../../actions/loading';
import { setValidCEP } from '../../actions/viacep';
import { setIdCompany } from '../../actions/company';
import { listValues } from '../../actions/bipbop-report';

function FinalizarApolice() {
  const dispatch = useDispatch();
  const [acompanhamento, setAcompanhamento] = useState(false);
  const process = useSelector((state) => state.policy.emitedPolicy.policy.process);
  const bipbopValues = useSelector((state) => state.bipbopReport.values);

  useEffect(() => {
    setAcompanhamento(process.acompanhamentoBipbop);
    dispatch(listValues());
  }, []);

  function sendUpdateAccompanimento() {
    const { id } = process;
    const data = {
      acompanhamentoBipbop: acompanhamento,
    };
    dispatch(updateProcess(id, data));
    dispatch(setRoute('/nova-apolice'));
    dispatch(push('/documentos-emitidos'));
    dispatch(setPolicyEmited({
      documentos: {
        billetBase64: '',
        policyBase64: '',
        minutaBase64: '',
      },
      policy: {
        tomadorCNPJ: '',
        cdApoliceSusep: '',
        id: '',
        process: {
          acompanhamentoBipbop: false,
          id: 0,
        },
      },
    }));
    dispatch(setLoadingCardDocument(true, 'APOLICE'));
    dispatch(setLoadingCardDocument(true, 'BOLETO'));
    dispatch(setLoadingCardDocument(true, 'CERTIFICADO'));
    dispatch(setLoadingCardDocument(true, 'INSURANCE'));
    dispatch(setValidCEP({}));
    dispatch(setIdCompany(''));
    dispatch(setProposalData({}));
  }

  function decideLater() {
    dispatch(setRoute('/nova-apolice'));
    dispatch(push('/documentos-emitidos'));
    dispatch(setPolicyEmited({
      documentos: {
        billetBase64: '',
        policyBase64: '',
        minutaBase64: '',
      },
      policy: {
        tomadorCNPJ: '',
        cdApoliceSusep: '',
        id: '',
        process: {
          acompanhamentoBipbop: false,
          id: 0,
        },
      },
    }));
    dispatch(setLoadingCardDocument(true, 'APOLICE'));
    dispatch(setLoadingCardDocument(true, 'BOLETO'));
    dispatch(setLoadingCardDocument(true, 'CERTIFICADO'));
    dispatch(setValidCEP({}));
    dispatch(setIdCompany(''));
    dispatch(setProposalData({}));
  }

  return (
    <>
      <Navbar pageSelected="cotacao" />
      <Content>
        <SectionTop>
          <h1>Documentos emitidos</h1>
        </SectionTop>
        <StatusDiv>
          <CheckIcon
            style={{
              width: '60px',
              height: '50px',
              color: '#5ACa65',
              marginRight: '30px',
            }}
          />
          <div>
            <h1>Apólice emitida com sucesso</h1>
            <h2>A documentação foi enviada</h2>
          </div>
        </StatusDiv>
        <AccompanimentDiv>
          <h1>Você deseja ativar o acompanhamento do processo de forma automática</h1>
          <h2>Acompanhe o status da evolução do processo, do início ao fim, de maneira automática. Essa funcionalidade está sujeita a taxas.</h2>
          <h3>
            Valor:
            {' '}
            {formatToBRL(bipbopValues.acompanhamento.valor * (bipbopValues.acrescimoAcompanhamento.valor / 100 + 1))}
            /Mês
          </h3>
          <FormControlLabel
            control={<Switch />}
            label="Acompanhamento automático"
            checked={acompanhamento}
            onChange={() => { setAcompanhamento(!acompanhamento); }}
          />
        </AccompanimentDiv>
        <ButtonsDiv>
          <ButtonDefault
            className="button-styled"
            variant="outlined"
            color="secondary"
            borderRadius="2px"
            onClick={decideLater}
          >
            Decidir Depois
          </ButtonDefault>
          <ButtonDefault
            customColor="#F9B233"
            colorLabel="#FFFFFF"
            borderRadius="2px"
            onClick={sendUpdateAccompanimento}
          >
            Finalizar
          </ButtonDefault>
        </ButtonsDiv>
      </Content>
    </>
  );
}

export default FinalizarApolice;
