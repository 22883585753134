import React, { useState, useEffect } from 'react';
import { BiDownload } from 'react-icons/bi';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  DivButton,
  DivLoading,
  DivImg,
  DivError,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault/index';

function CardDownloadImg(props) {
  const { title, loading, image } = props;
  const [img, setImg] = useState('');

  useEffect(() => {
    setImg(`data:application/png;base64, ${image}`);
  }, [image]);

  function downloadFile() {
    const linkSource = `data:application/png;base64,${image}`;

    const downloadLink = document.createElement('a');

    const fileName = `${title}.png`;


    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <Card>
      <h1>{title}</h1>
      {
        !loading ? (
          <>
            { image && (
              <>
                <DivImg>
                  <img
                    alt={title}
                    src={img}
                  />
                </DivImg>
                <DivButton>
                  <ButtonDefault
                    color="primary"
                    variant="outlined"
                    startIcon={<BiDownload style={{ color: '#F9B233' }} />}
                    onClick={downloadFile}
                  >
                    Baixar
                  </ButtonDefault>
                </DivButton>
              </>
            )}
            { !image && (
              <>
                <DivError>
                  <h2>Documento não encontrado</h2>
                </DivError>
              </>
            )}
          </>
        ) : (
          <DivLoading>
            <CircularProgress />
          </DivLoading>
        )
      }
    </Card>
  );
}

export default CardDownloadImg;

CardDownloadImg.propTypes = {
  image: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

CardDownloadImg.defaultProps = {
  image: '',
  loading: false,
  title: '',
};
