import React from "react";
import { useSelector } from "react-redux";

import Modal from "@material-ui/core/Modal";
import { BiCalendar } from "react-icons/bi";
import { ModalBody, TitleContent, DivMes, Content } from "./style";

import { Grid } from "semantic-ui-react";
import { formatToBRL } from "brazilian-values";

function ModalPaymentHistoric(props) {
  const { onClose, date } = props;
  const open = useSelector(state => state.modal.ModalPaymentHistoric);
  const dashboardMesBipbop = useSelector(
    state => state.bipbopReport.dashboardMes
  );

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalBody>
          <TitleContent>
            <h1>Histórico de pagamentos</h1>
          </TitleContent>
          <DivMes>
            <BiCalendar size={20} color="#F9B233" />
            {date}
          </DivMes>
          <Content>
            <Grid>
              <h1>Acompanhamento automático</h1>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <h2>Qtd</h2>
                  <h3>{dashboardMesBipbop.acompanhamentos}</h3>
                </Grid.Column>
                <Grid.Column width={1}>
                  <h2>x</h2>
                </Grid.Column>
                <Grid.Column width={3}>
                  <h2>Valor unitário</h2>
                  <h3>{formatToBRL(dashboardMesBipbop.precoAcompanhamento)}</h3>
                </Grid.Column>
                <Grid.Column width={6}>
                  <div className="arrow" />
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <h2>Sub-total</h2>
                  <h4>{formatToBRL(dashboardMesBipbop.valorAcompanhamento)}</h4>
                </Grid.Column>
              </Grid.Row>
              <h1>Saneamento de dados</h1>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <h2>Qtd</h2>
                  <h3>{dashboardMesBipbop.saneamentos}</h3>
                </Grid.Column>
                <Grid.Column width={1}>
                  <h2>x</h2>
                </Grid.Column>
                <Grid.Column width={3}>
                  <h2>Valor unitário</h2>
                  <h3>{formatToBRL(dashboardMesBipbop.precoPesquisa)}</h3>
                </Grid.Column>
                <Grid.Column width={6}>
                  <div className="arrow" />
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <h2>Sub-total</h2>
                  <h4>{formatToBRL(dashboardMesBipbop.valorSaneamento)}</h4>
                </Grid.Column>
              </Grid.Row>
              <h1>Busca por tipo de garantia</h1>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={2}>
                  <h2>Qtd</h2>
                  <h3>{dashboardMesBipbop.buscaGarantias}</h3>
                </Grid.Column>
                <Grid.Column width={1}>
                  <h2>x</h2>
                </Grid.Column>
                <Grid.Column width={3}>
                  <h2>Valor unitário</h2>
                  <h3>{formatToBRL(dashboardMesBipbop.precoAcompanhamento)}</h3>
                </Grid.Column>
                <Grid.Column width={6}>
                  <div className="arrow" />
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <h2>Sub-total</h2>
                  <h4>{formatToBRL(dashboardMesBipbop.valorBuscaGarantia)}</h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={15} textAlign="right">
                  <h2>Valor final</h2>
                  <h5>
                    {formatToBRL(
                      dashboardMesBipbop.valorAcompanhamento +
                        dashboardMesBipbop.valorBuscaGarantia +
                        dashboardMesBipbop.valorSaneamento
                    )}
                  </h5>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Content>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalPaymentHistoric;
