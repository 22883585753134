import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Modal, Loader, Dimmer } from 'semantic-ui-react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { BiDownload } from 'react-icons/bi';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { setModalMinuta } from '../../../actions/modal';
import 'semantic-ui-css/semantic.min.css';
import './pdfViewerStyle.css';
import ButtonDefault from '../../UI/ButtonDefault/index';
import ButtonIcon from '../../UI/ButtonIcon/index';
import { SectionBottom, ControlPage, ButtonsDiv } from './style';
import { setRoute } from '../../../actions/navbar';
import {
  setProposalBMG,
  setProposalJunto,
  setProposalTokyo,
  setProposalPottencial,
  setProposalJuntoError,
  setProposalTokyoError,
  setProposalBMGError,
  setProposalPottencialError,
} from '../../../actions/policy';
import {
  setLoadingCardProposalBMG,
  setLoadingCardProposalJunto,
  setLoadingCardProposalTokio,
  setLoadingCardProposalPottencial,
} from '../../../actions/loading';

function ModalMinuta(props) {
  const open = useSelector((state) => state.modal.ModalMinuta);
  const loadingEmittingPolicy = useSelector(
    (state) => state.loading.LoadingEmittingPolicy,
  );
  const dispatch = useDispatch();
  const { pdfMinuta, onClick } = props;

  function handleCloseModalMinuta() {
    dispatch(setModalMinuta(false));
  }

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPreviousPage() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function goToNextPage() {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }

  function downloadFile() {
    const linkSource = `data:application/pdf;base64,${pdfMinuta}`;
    const downloadLink = document.createElement('a');
    const fileName = 'minuta.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function goToEditForm() {
    dispatch(setRoute('/nova-apolice'));
    dispatch(push('/nova-apolice'));
    handleCloseModalMinuta();
    dispatch(
      setProposalBMG({
        premio: '',
        limiteDisponivel: '',
        limiteTotal: '',
        limiteUtilizado: '',
      }),
    );
    dispatch(
      setProposalJunto({
        premio: '',
        limiteDisponivel: '',
        limiteTotal: '',
        limiteUtilizado: '',
      }),
    );
    dispatch(
      setProposalTokyo({
        premio: '',
        limiteDisponivel: '',
        limiteTotal: '',
        limiteUtilizado: '',
        formaPagamento: [],
      }),
    );
    dispatch(
      setProposalPottencial({
        premio: '',
        limiteDisponivel: '',
        limiteTotal: '',
        limiteUtilizado: '',
        formaPagamento: [],
      }),
    );
    dispatch(setLoadingCardProposalBMG(true));
    dispatch(setLoadingCardProposalJunto(true));
    dispatch(setLoadingCardProposalTokio(true));
    dispatch(setLoadingCardProposalPottencial(true));
    const errorDefault = {
      error: false,
      errorTitle: '',
      errorText: '',
    };
    dispatch(setProposalJuntoError(errorDefault));
    dispatch(setProposalBMGError(errorDefault));
    dispatch(setProposalTokyoError(errorDefault));
    dispatch(setProposalPottencialError(errorDefault));
  }

  return (
    <Modal open={open} onClose={handleCloseModalMinuta}>
      <Modal.Header> Minuta </Modal.Header>
      <Modal.Content scrolling>
        <Document
          file={`data:application/pdf;base64, ${pdfMinuta}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page scale={1} pageNumber={pageNumber} />
        </Document>
        <Dimmer active={loadingEmittingPolicy}>
          <Loader active={loadingEmittingPolicy} inverted />
        </Dimmer>
      </Modal.Content>
      <Modal.Actions>
        <SectionBottom>
          <ButtonIcon
            color="primary"
            variant="outlined"
            onClick={downloadFile}
            border="1px solid #185F9B"
            width="38px"
            heigh="30px"
          >
            <BiDownload style={{ color: '#F9B233' }} />
          </ButtonIcon>
          <ControlPage>
            <ButtonIcon onClick={goToPreviousPage} color="default">
              <ArrowBackIosIcon />
            </ButtonIcon>
            <p>{`${pageNumber}/${numPages}`}</p>
            <ButtonIcon onClick={goToNextPage} color="default">
              <ArrowForwardIosIcon />
            </ButtonIcon>
          </ControlPage>
          <ButtonsDiv>
            <ButtonDefault
              color="primary"
              variant="outlined"
              size="large"
              onClick={goToEditForm}
            >
              Editar
            </ButtonDefault>
            <ButtonDefault
              customColor="#F9B233"
              colorLabel="#FFFFFF"
              onClick={onClick}
              size="large"
            >
              Emitir
            </ButtonDefault>
          </ButtonsDiv>
        </SectionBottom>
      </Modal.Actions>
    </Modal>
  );
}
export default ModalMinuta;

ModalMinuta.propTypes = {
  pdfMinuta: PropTypes.string,
  onClick: PropTypes.func,
};

ModalMinuta.defaultProps = {
  pdfMinuta: '',
  onClick: () => {},
};
