import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledStepLabel,
  IconCircleDisabled,
  IconCircle,
  StyledStep,
  IconCheck,
  StyledStepper,
} from './style';

function StepperDefault(props) {
  const {
    activeStep,
    steps,
    alternativeLabel,
    className,
  } = props;

  return (
    <StyledStepper
      className={className}
      activeStep={activeStep}
      alternativeLabel={alternativeLabel}
    >
      {steps.map((label, index) => (
        <StyledStep key={label}>
          {index < activeStep && (
            <StyledStepLabel
              StepIconComponent={IconCheck}
              iconsize="26px"
              checked
              completed="true"
              active="true"
              error={null}
            >
              {label}
            </StyledStepLabel>
          )}
          {index === activeStep && (
            <StyledStepLabel
              StepIconComponent={IconCircle}
              padding="6px"
              completed="false"
              active="true"
              error={null}
            >
              {label}
            </StyledStepLabel>
          )}
          {index > activeStep && (
            <StyledStepLabel
              StepIconComponent={IconCircleDisabled}
              padding="2px"
              disabled
              completed={null}
              active={null}
              error={null}
            >
              {label}
            </StyledStepLabel>
          )}
        </StyledStep>
      ))}
    </StyledStepper>
  );
}
export default StepperDefault;

StepperDefault.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.array,
  alternativeLabel: PropTypes.bool,
  className: PropTypes.string,
};

StepperDefault.defaultProps = {
  steps: [],
  alternativeLabel: true,
  className: '',
};
