import styled from 'styled-components';

export const ButtonDefault = styled.button` 
    width: 175px;
    height: 40px;
    background: #F9B233;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    margin-top: 20px;
    margin-left: auto;
`

export const ModalBody = styled.div `
  background-color: #FFFFFF;
  width: 470px;
  margin:  auto;
  padding: 40px;
  margin-top: 12%;

  h1 {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
    margin: 40px 0px 20px;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    color: #185F9B;
    margin-top: 5px;
  }

  h3 {
    font-weight: 600;
    font-size: 14px;
    color: #568CCC;
    margin-top: 5px;
  }

  h4 {
    font-weight: 600;
    margin-top: 5px;
    font-size: 24px;
    color: #00366C;
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    color: #9E9E9E;
  }

  .MuiInputBase-input{
    border: 0!important;
  }

  .MuiFormControl-root {
    margin: 0;
    justify-content: flex-end;
  }
  .MuiInputLabel-formControl {
    text-align: start;
    font-size: 19px!important;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 4px!important;
  }
  .MuiInput-root {
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 2px;
    width: 204px;
    height: 40px;
    padding: 10px 16px;
    font-size: 14px;
    margin-top:20px;
  }
  .MuiInput-underline:before{
    border: 0;
  }
  .MuiInputBase-root {
    height: 40px;
    width: 180px;
  }
  .MuiIconButton-root {
    color: #F9B233;
  }
`;

export const TitleContent = styled.div `
  display: flex;
  justify-content: space-between;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

