import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  padding: 0px 40px 40px 40px;

  .ui.form {
    .field {
      margin-top: 40px;
      label {
        font-family: "Montserrat", "sans-serif";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        leter-spacing: 0.1px;
        color: #424242;
        margin-bottom: 5px;
      }
      input {
        font-family: "Montserrat", "sans-serif";
        background: #eeeeee;
      }
    }
    .fields .wide.field {
      margin-right: 10px;
    }
  }
  h1 {
    font-family: "Montserrat", "sans-serif";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0c0142;
    margin: 40px 0px 0px;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Button = styled.div`
  border: 1px solid #185f9b;
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  text-transform: uppercase;
  color: #185f9b;
  font-weight: bold;
  font-size: 12px;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const SectionTable = styled.section`
  margin: ${props => props.margin || "38px 0px"};
  width: 100%;
  .indiana-scroll-container {
    width: 100%;
  }
  padding: 0px !important;
`;

export const Table = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Montserrat", "sans-serif";
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
  padding: 0px;
`;

export const TableScroll = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  cursor: grab;
`;

export const CellHeader = styled.div`
  font-style: normal;
  font-weight: ${props => props.fontWeight || "bold"};
  font-size: 12px;
  line-height: 20px;
  color: #24126a;
  padding: 15px 16px;
  padding-left: ${props => props.paddingLeft || "16px"};
  word-break: keep-all;
  background: #ffffff;
  margin-bottom: 8px;
  display: flex;
  justify-content: ${props => props.justifyContent || "center"};
  flex-direction: column;
  align-items: ${props => props.alignItems || "flex-start"};
  width: ${props => props.width};
  p {
    width: ${props => props.widthText};
    text-align: center;
  }
`;

export const Cell = styled.div`
  font-style: normal;
  font-weight: ${props => props.fontWeight || "normal"};
  font-size: ${props => props.fontSize || "12px"};
  display: flex;
  color: #2c3e50;
  background: #ffffff;
  padding: 20px 16px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: ${props => props.justifyContent || "center"};
  align-items: ${props => props.alignItems || "start"};
  white-space: nowrap;

  .field {
    width: ${props => props.width};
    margin-top: 0px !important;
  }

  p {
    margin-bottom: 2px;
    width: ${props => props.widthText};
    text-align: ${props => props.textAlign || "center"};
    white-space: pre-wrap;
  }

  .label-permissoes,
  .ui.circular.label {
    background-color: #2e5aac;
    color: white;
    padding: 5px 15px !important;
    white-space: nowrap;
    min-height: 0px;
  }
`;
