/* eslint-disable react/forbid-prop-types */
import React from 'react';
import loadable from 'loadable-components';
import PropTypes from 'prop-types';

const Chart = loadable(() => import ('react-apexcharts'), {ssr: false});

function ChartLine(props) {
    const {
        categories,
        data,
        height,
        width
    } = props;

    const options = {
        chart: {
            toolbar: {
                show: false
            },
            height: 350,
            type: 'line'
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        yaxis: {
            show: false,
            min: 0,
        },
        xaxis: {
            categories: categories,
            tickAmount: 10,
            labels:{
              style:{
                fontSize: '10px',
                colors:'#A3A3A3',
                fontFamily: 'Montserrat'
              }
            }
        },
        colors: ['#00366C'],
        markers: {
            size: 4,
            colors: ["#00366C"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7
            }
        },
        grid: {
            show: true,
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            }
        }
    };

    const series = [
        {
            data: data
        }
    ]

    return (
        <div>
            <Chart
                options={options}
                series={series}
                type="line"
                height={height}
                width={width}/>
        </div>
    );
}

export default ChartLine;

ChartLine.propTypes = {
    colors: PropTypes.array,
    columnWidth: PropTypes.string,
    endingShape: PropTypes.oneOf(['flat', 'rounded']),
    dataLabels: PropTypes.bool,
    legend: PropTypes.bool,
    dataType: PropTypes.string,
    categories: PropTypes.array,
    horizontalLabels: PropTypes.bool,
    verticalLabels: PropTypes.bool,
    data: PropTypes.array,
    height: PropTypes.string,
    width: PropTypes.string
};

ChartLine.defaultProps = {
    colors: [
        '#00684F', '#9966FF', '#F99D1C', '#F26522'
    ],
    columnWidth: '50%',
    endingShape: 'rounded',
    dataLabels: false,
    legend: false,
    dataType: null,
    categories: [],
    horizontalLabels: false,
    verticalLabels: false,
    data: [],
    height: '150px',
    width: '100%'
};
