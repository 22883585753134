/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from './api';

export const setValues = (values) => ({
    type: 'SET_VALUES',
    payload: {
        values
    }
});

export const setDashboard = (dashboard) => ({
    type: 'SET_DASHBOARD',
    payload: {
        dashboard
    }
});

export const setDashboardMes = (dashboardMes) => ({
    type: 'SET_DASHBOARD_MES',
    payload: {
        dashboardMes
    }
});

export const listValues = () => async(dispatch) => {
    try {
        const response = await axios.get(`bipbop-report/values`);
        dispatch(setValues(response.data))
    } catch (err) {
        console.log(err);
    }
};

export const editValues = (values) => async(dispatch) => {
    try {
        await axios.put(`bipbop-report/values/1`, values);
        dispatch(listValues())
    } catch (err) {
        console.log(err);
    }
};


export const getDashboard = () => async(dispatch) => {
    try {
        const response = await axios.get(`bipbop-report/dashboard`);
        dispatch(setDashboard(response.data))
    } catch (err) {
        console.log(err);
    }
};

export const getDashboardMes = (mes) => async(dispatch) => {
    try {
        const response = await axios.get(`bipbop-report/dashboard/${mes}`);
        dispatch(setDashboardMes(response.data))
    } catch (err) {
        console.log(err);
    }
};
