import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 640px;
  height: 536px;
  margin: auto;
  margin-top: 2%;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
    line-height: 32px;
  }

  div{
    display: flex;
  }
`;

export const Content = styled.div`
  margin-left: 40px;
  margin-right: 40px;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00235C;
    letter-spacing: 0.1px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }
`;

export const ButtonIndividual = styled.button`
  width: 160px;
  height: 40px;
  background: #F8FAFD;
  border-radius: 10px 10px 10px 10px;
  color: #00366C;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin-top: 36px;
  margin-bottom: 40px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 35, 92, 0.04);
  }
`;

export const FormProcessNumber = styled.form`
  margin-top: 24px;

  label{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #424242;
  }

  input{
    display: none;
  }

  div{
    margin-top: 82px;
    display: flex;
    justify-content: space-between;
  }

  p{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1px;
    color: #000000;
  }
`;

export const NameFile = styled.span`
  width: 100%;
  display: flex;

  p{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #000000;

  }

  button{
    background: none;
    border: none;
    color: #F80A0A;
    width: 14px;
    height: 14px;
    font-size: 14px;
    margin-top: 10px;
  }
`;
