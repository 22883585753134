import React, { useEffect, useState } from 'react';
import { Dropdown, Grid, Progress } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { formatToNumber } from 'brazilian-values';
import { MenuItem, Select } from '@material-ui/core';
import { FaFilter } from 'react-icons/fa';
import Navbar from '../../components/Navbar/index';
import {
  SectionFilter,
  Card,
  CardHeader,
  DivContent,
  DivDataChart,
  Circle,
  TextLegend,
  SectionCard,
  TextValueLegend,
  Separator,
  LabelProgress,
  TitleCard,
  SubtitleCard,
  Label,
} from './style';
import ChartColumn from '../../components/Chart/ChartColumn';
import {
  getAllDashboard,
  getDashboardConset,
  getDashboardConsetCompany,
  getDashboardLimits,
} from '../../actions/dashboard';
import { getPoliciesResume } from '../../actions/policy';
import ButtonIcon from '../../components/UI/ButtonIcon';
import { findAllCompany } from '../../actions/company';
import { numberMask } from '../../services/mask';
import CardChartDashboard from '../../components/Card/CardChartDashboard';
import ModalFilterDashboard from '../../components/Modal/ModalFilterDashboard';
import { setModalFilterDashboard, setModalLimits } from '../../actions/modal';
import ModalLimits from '../../components/Modal/ModalLimits';
import { setLoadingModalLimits } from '../../actions/loading';
import { decodeToken, getToken } from '../../actions/auth';

function DashboardADM() {
  const dispatch = useDispatch();

  const dashboardConset = useSelector((state) => state.dashboard.dashboardConset);
  const dashboardConsetCompany = useSelector(
    (state) => state.dashboard.dashboardConsetCompany,
  );
  const companies = useSelector((state) => state.company.companies);
  const allDashboard = useSelector((state) => state.dashboard.allDashboard);
  const policiesResume = useSelector((state) => state.policy.policiesResume);
  const query = useSelector((state) => state.dashboard.queryDashboard);
  const companiesOptions = [
    {
      text: '-',
      value: null,
      key: 0,
    },
    ...companies.map((company) => ({
      key: company.id,
      value: company.id,
      cnpj: company.cnpj,
      text: company.nome,
    })),
  ];

  const [daysPoliciesResume, setDaysPoliciesResume] = useState(30);
  const [searchCompanyId, setSearchCompanyId] = useState('');
  const [searchCompanyCnpj, setSearchCompanyCnpj] = useState('');

  const optionsDays = [
    {
      text: '30',
      value: 30,
      key: 30,
    },
    {
      text: '60',
      value: 60,
      key: 60,
    },
    {
      text: '90',
      value: 90,
      key: 90,
    },
    {
      text: '120',
      value: 120,
      key: 120,
    },
  ];

  const colors = ['#00684F', '#9966FF', '#F99D1C', '#F26522'];

  const dataInsurers = dashboardConset.seguradoraCard.map(
    (item) => item.premioTotal,
  );
  const categoriesInsurers = dashboardConset.seguradoraCard.map(
    (item) => item.seguradora,
  );
  const dataInsurersList = dashboardConset.seguradoraCard.map((item, i) => ({
    color: colors[i],
    categorie: item.seguradora,
    value: item.premioTotal,
  }));

  const dataTakers = dashboardConset.tomadorCard.map((item) => item.premioTotal);
  const categoriesTakers = dashboardConset.tomadorCard.map(
    (item) => item.nomeTomador,
  );
  const dataTakersList = dashboardConset.tomadorCard.map((item, i) => ({
    color: colors[i],
    categorie: `${item.nomeTomador.slice(0, 10)}...`,
    value: item.premioTotal,
  }));

  const [modalLimitInfo, setModalLimitInfo] = useState({
    title: '',
    type: '',
  });
  const [valuesToModal, setValuesToModal] = useState([]);

  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  useEffect(() => {
    dispatch(findAllCompany());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDashboardConset({ ...query }));
    dispatch(
      getPoliciesResume({
        ...query,
        days: daysPoliciesResume,
        idEmpresa: searchCompanyId,
      }),
    );
  }, [dispatch, daysPoliciesResume, searchCompanyId, query]);

  useEffect(() => {
    if (searchCompanyId) {
      dispatch(getAllDashboard(numberMask(searchCompanyCnpj)));
      dispatch(
        getDashboardConsetCompany({
          ...query,
          idEmpresa: searchCompanyId,
        }),
      );
    }
  }, [dispatch, searchCompanyId, searchCompanyCnpj, query]);

  const eventSelectCompany = (company) => {
    setSearchCompanyId(company);
    setSearchCompanyCnpj(
      companies[companies.findIndex(({ id }) => id === company)].cnpj,
    );
  };

  const handleOpenModalFilterDashboard = () => {
    dispatch(setModalFilterDashboard(true));
  };

  const handleCloseModalFilterDashboard = () => {
    dispatch(setModalFilterDashboard(false));
  };

  const user = decodeToken(getToken());
  function handleOpenModalLimit(type) {
    switch (type) {
      case 'aprovado':
        setModalLimitInfo({ ...modalLimitInfo, title: 'Limite aprovado', type });
        setValuesToModal(allDashboard.allLimiteTotal);
        break;
      case 'tomado':
        setModalLimitInfo({ ...modalLimitInfo, title: 'Limite tomado', type });
        setValuesToModal(allDashboard.allLimiteUtilizado);
        break;
      case 'disponivel':
        setModalLimitInfo({ ...modalLimitInfo, title: 'Limite disponível', type });
        setValuesToModal(allDashboard.allLimiteDisponivel);
        break;
      default:
        setModalLimitInfo({ ...modalLimitInfo });
        break;
    }
    dispatch(setLoadingModalLimits(true));
    dispatch(getDashboardLimits({
      cnpj: numberMask(searchCompanyCnpj),
      insurer: 'tokio',
    }));
    dispatch(setModalLimits(true));
  }

  function SectionCardsCompany() {
    return (
      <SectionCard>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Card height="480px">
                <CardHeader>
                  <h1>
                    Apólices emitidas nos últimos
                    <Select
                      value={daysPoliciesResume}
                      onChange={(e) => setDaysPoliciesResume(e.target.value)}
                    >
                      {optionsDays.map((op) => (
                        <MenuItem key={op.key} value={op.value}>
                          {op.text}
                        </MenuItem>
                      ))}
                    </Select>
                    dias
                  </h1>
                </CardHeader>
                <DivContent margin="100px 0px">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign="center">
                        <TitleCard>{policiesResume.apolicesEmitidas}</TitleCard>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign="bottom">
                      <Grid.Column width={7} textAlign="right">
                        <h3>Comissão:</h3>
                      </Grid.Column>
                      <Grid.Column width={9} textAlign="left">
                        <SubtitleCard>{`R$ ${formatToNumber(0)}`}</SubtitleCard>
                      </Grid.Column>
                      <Grid.Column width={7} textAlign="right">
                        <h3>Prêmio:</h3>
                      </Grid.Column>
                      <Grid.Column width={9} textAlign="left">
                        <SubtitleCard>
                          {`R$ ${formatToNumber(
                            parseInt(policiesResume.totalPremio),
                          )}`}
                        </SubtitleCard>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </DivContent>
              </Card>
            </Grid.Column>
            <Grid.Column width={4}>
              <Card height="480px">
                <CardHeader>
                  <h1>Processos em acompanhamento automático</h1>
                </CardHeader>
                <DivContent margin="100px 0px">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign="center">
                        <TitleCard>
                          {dashboardConsetCompany.processosCard.qtdProcessos}
                        </TitleCard>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16} textAlign="center">
                        <h3>Faturamento</h3>
                        <SubtitleCard>
                          {`R$ ${formatToNumber(
                            parseInt(
                              dashboardConsetCompany.processosCard
                                .faturamento || 0,
                            ),
                          )}`}
                        </SubtitleCard>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </DivContent>
              </Card>
            </Grid.Column>
            <Grid.Column width={4}>
              <Card height="480px">
                <CardHeader>
                  <h1>Status da vigência de renovação das apólices</h1>
                </CardHeader>
                <DivContent>
                  <Grid>
                    <Grid.Row verticalAlign="bottom">
                      <Grid.Column width={8} textAlign="right">
                        <h3>Quantidade:</h3>
                      </Grid.Column>
                      <Grid.Column width={8} textAlign="left">
                        <h1>{dashboardConsetCompany.expiredPolicies.total}</h1>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Progress
                          color="blue"
                          percent={
                            dashboardConsetCompany.expiredPolicies.healthy
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.expiredPolicies.healthy
                                .total
                            }
                            {' '}
                            apólices com 120 dias para vencer
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="green"
                          percent={
                            dashboardConsetCompany.expiredPolicies.attention
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.expiredPolicies.attention
                                .total
                            }
                            {' '}
                            apólices com 90 dias para vencer
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="yellow"
                          percent={
                            dashboardConsetCompany.expiredPolicies.late
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {dashboardConsetCompany.expiredPolicies.late.total}
                            {' '}
                            apólices com 65 dias para vencer
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="orange"
                          percent={
                            dashboardConsetCompany.expiredPolicies.critical
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.expiredPolicies.critical
                                .total
                            }
                            {' '}
                            apólices com 60 dias para vencer
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="red"
                          percent={
                            dashboardConsetCompany.expiredPolicies.expired
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.expiredPolicies.expired
                                .total
                            }
                            {' '}
                            apólices vencidas e não renovadas
                          </LabelProgress>
                        </Progress>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </DivContent>
              </Card>
            </Grid.Column>
            <Grid.Column width={4}>
              <Card height="480px">
                <CardHeader>
                  <h1>Acompanhamento da data de aniversário das apólices</h1>
                </CardHeader>
                <DivContent>
                  <Grid>
                    <Grid.Row verticalAlign="bottom">
                      <Grid.Column width={8} textAlign="right">
                        <h3>Quantidade:</h3>
                      </Grid.Column>
                      <Grid.Column width={8} textAlign="left">
                        <h1>{dashboardConsetCompany.birthdayPolicies.total}</h1>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Progress
                          color="blue"
                          percent={
                            dashboardConsetCompany.birthdayPolicies.healthy
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.birthdayPolicies.healthy
                                .total
                            }
                            {' '}
                            apólices com 120 dias para o aniversário
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="green"
                          percent={
                            dashboardConsetCompany.birthdayPolicies.attention
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.birthdayPolicies.attention
                                .total
                            }
                            {' '}
                            apólices com 90 dias para o aniversário
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="yellow"
                          percent={
                            dashboardConsetCompany.birthdayPolicies.late
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {dashboardConsetCompany.birthdayPolicies.late.total}
                            {' '}
                            apólices com 65 dias para o aniversário
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="orange"
                          percent={
                            dashboardConsetCompany.birthdayPolicies.critical
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.birthdayPolicies.critical
                                .total
                            }
                            {' '}
                            apólices com 60 dias para o aniversário
                          </LabelProgress>
                        </Progress>
                        <Progress
                          color="red"
                          percent={
                            dashboardConsetCompany.birthdayPolicies.expired
                              .porcentagem
                          }
                          progress
                        >
                          <LabelProgress>
                            {
                              dashboardConsetCompany.birthdayPolicies.expired
                                .total
                            }
                            {' '}
                            apólices com data de aniversário vencida
                          </LabelProgress>
                        </Progress>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </DivContent>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ justifyContent: 'center' }}>
            <Grid.Column
              width={5}
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <CardChartDashboard
                title="Limite aprovado"
                textTop="Valor total:"
                totalValue={`R$ ${formatToNumber(
                  parseInt(allDashboard.allLimiteTotal.reduce(reducer)),
                )}`}
                categories={['Tokio Marine', 'BMG', 'Junto', 'Pottencial']}
                data={allDashboard.allLimiteTotal}
                onClickDetails={() => handleOpenModalLimit('aprovado')}
              />
            </Grid.Column>
            <Grid.Column
              width={5}
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <CardChartDashboard
                title="Limite tomado"
                textTop="Valor total:"
                totalValue={`R$ ${formatToNumber(
                  parseInt(allDashboard.allLimiteUtilizado.reduce(reducer)),
                )}`}
                categories={['Tokio Marine', 'BMG', 'Junto', 'Pottencial']}
                data={allDashboard.allLimiteUtilizado}
                onClickDetails={() => handleOpenModalLimit('tomado')}
              />
            </Grid.Column>
            <Grid.Column
              width={5}
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <CardChartDashboard
                title="Limite disponível"
                textTop="Valor total:"
                totalValue={`R$ ${formatToNumber(
                  parseInt(allDashboard.allLimiteDisponivel.reduce(reducer)),
                )}`}
                categories={['Tokio Marine', 'BMG', 'Junto', 'Pottencial']}
                data={allDashboard.allLimiteDisponivel}
                onClickDetails={() => handleOpenModalLimit('disponivel')}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalLimits
          title={modalLimitInfo.title}
          insurersValues={valuesToModal}
          companyCNPJ={searchCompanyCnpj}
        />
      </SectionCard>
    );
  }

  function SectionCardsAdm() {
    return (
      <SectionCard>
        <Grid>
          <Grid.Row>
            <Grid.Column
              width={8}
              style={{
                alignItems: 'normal',
                display: 'flex',
              }}
            >
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Card>
                      <CardHeader>
                        <h1>
                          Apólices emitidas nos últimos
                          <Select
                            value={daysPoliciesResume}
                            onChange={(e) => setDaysPoliciesResume(e.target.value)}
                          >
                            {optionsDays.map((op) => (
                              <MenuItem key={op.key} value={op.value}>
                                {op.text}
                              </MenuItem>
                            ))}
                          </Select>
                          dias
                          <ButtonIcon
                            color="default"
                            margin="0px 10px 5px"
                            padding="9px"
                            width="35px"
                            background="transparent"
                            onClick={() => handleOpenModalFilterDashboard()}
                          >
                            <FaFilter color="#F9B233" />
                          </ButtonIcon>
                        </h1>
                      </CardHeader>
                      <DivContent>
                        <Grid>
                          <Grid.Row verticalAlign="middle">
                            <Grid.Column width={8}>
                              <h1>{policiesResume.apolicesEmitidas}</h1>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                              <h3>Prêmio</h3>
                              <h2>
                                {`R$ ${formatToNumber(
                                  parseInt(policiesResume.totalPremio),
                                )}`}

                              </h2>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </DivContent>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Card>
                      <CardHeader>
                        <h1>Processos em acompanhamento automático</h1>
                      </CardHeader>
                      <DivContent>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={8}>
                              <h1>
                                {dashboardConset.processosCard.qtdProcessos}
                              </h1>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                              <h3>Faturamento</h3>
                              <h2>
                                {`R$ ${formatToNumber(
                                  parseInt(
                                    dashboardConset.processosCard.faturamento,
                                  ),
                                )}`}
                              </h2>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </DivContent>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Card>
                      <CardHeader>
                        <h1>Prêmio e comissão das seguradoras</h1>
                      </CardHeader>
                      <DivContent>
                        <Grid>
                          <Grid.Row
                            verticalAlign="middle"
                            style={{
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid.Column width={6}>
                              <h3>Prêmio</h3>
                              <h4>
                                {`R$ ${formatToNumber(
                                  parseInt(
                                    dashboardConset.premioCard.premioTotal,
                                  ),
                                )}`}
                              </h4>
                            </Grid.Column>
                            <Grid.Column width={1}>
                              <Separator />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <h3>Comissão</h3>
                              <h4>
                                {`R$ ${formatToNumber(
                                  parseInt(dashboardConset.premioCard.comissao),
                                )}`}
                              </h4>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </DivContent>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Card>
                      <CardHeader>
                        <h1>Pipeline</h1>
                      </CardHeader>
                      <DivContent>
                        <Grid>
                          <Grid.Row
                            verticalAlign="middle"
                            style={{
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid.Column width={6}>
                              <h3>Leads</h3>
                              <h4>{dashboardConset.pipelineCard.leads}</h4>
                            </Grid.Column>
                            <Grid.Column width={1}>
                              <Separator />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <h3>Potencial de substituição</h3>
                              <h4>
                                {`R$ ${formatToNumber(
                                  parseInt(
                                    dashboardConset.pipelineCard
                                      .potencialSubstituicao,
                                  ),
                                )}`}
                              </h4>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </DivContent>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={8}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Card>
                      <CardHeader>
                        <h1>Tomador que mais emitiu apólice</h1>
                      </CardHeader>
                      <DivContent>
                        <Grid>
                          <Grid.Row verticalAlign="middle">
                            <Grid.Column width={11} textAlign="left">
                              <h3>Comissão total</h3>
                              <h5>
                                {`R$ ${formatToNumber(
                                  parseInt(
                                    dashboardConset.tomadorCard.reduce(
                                      (acc, { premioTotal }) => acc + premioTotal,
                                      0,
                                    ),
                                  ),
                                )}`}
                              </h5>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <ChartColumn
                              dataType="tomadores"
                              data={dataTakers}
                              categories={categoriesTakers}
                            />
                            {' '}
                            {dataTakersList.map((item) => (
                              <DivDataChart key={item.categorie}>
                                <Circle circleColor={item.color} />
                                <TextLegend>{item.categorie}</TextLegend>
                                <TextValueLegend textColor={item.color}>
                                  {' '}
                                  {`R$ ${formatToNumber(parseInt(item.value))}`}
                                </TextValueLegend>
                              </DivDataChart>
                            ))}
                          </Grid.Row>
                        </Grid>
                      </DivContent>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Card>
                      <CardHeader>
                        <h1>Seguradoras por prêmio</h1>
                      </CardHeader>
                      <DivContent>
                        <Grid>
                          <Grid.Row verticalAlign="middle">
                            <Grid.Column width={11} textAlign="left">
                              <h3>Prêmio total</h3>
                              <h5>
                                {`R$ ${formatToNumber(
                                  parseInt(
                                    dashboardConset.seguradoraCard.reduce(
                                      (acc, { premioTotal }) => acc + premioTotal,
                                      0,
                                    ),
                                  ),
                                )}`}
                              </h5>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <ChartColumn
                              dataType="seguradoras"
                              data={dataInsurers}
                              categories={categoriesInsurers}
                            />
                            {' '}
                            {dataInsurersList.map((item) => (
                              <DivDataChart key={item.categorie}>
                                <Circle circleColor={item.color} />
                                <TextLegend>{item.categorie}</TextLegend>
                                <TextValueLegend textColor={item.color}>
                                  {' '}
                                  {`R$ ${formatToNumber(parseInt(item.value))}`}
                                </TextValueLegend>
                              </DivDataChart>
                            ))}
                          </Grid.Row>
                        </Grid>
                      </DivContent>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionCard>
    );
  }

  return (
    <>
      <Navbar pageSelected="dashboard" />
      <SectionFilter>
        <Grid>
          <Grid.Row verticalAlign="bottom">
            <Grid.Column width={4}>
              <Label>Buscar empresas</Label>
              <Dropdown
                label="Buscar"
                search
                selection
                options={companiesOptions}
                icon="search"
                onChange={(e, { value }) => eventSelectCompany(value)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionFilter>
      {searchCompanyId ? <SectionCardsCompany /> : <SectionCardsAdm />}
      <ModalFilterDashboard onClose={handleCloseModalFilterDashboard} />
    </>
  );
}

export default DashboardADM;
