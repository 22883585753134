/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardHeaderStyled from '../../UI/CardHeaderStyled';
import ChartColumn from '../../Chart/ChartColumn';
import {
  StyledCard,
  StyledCardContent,
  Text,
  TextTotalValue,
  DivChart,
  DivDataChart,
  Circle,
  TextLegend,
  TextValueLegend,
} from './style';

function CardChartColumn(props) {
  const {
    title, textTop, totalValue, totalValueColor, colors, dataType, categories, data, endingShape,
  } = props;

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const list = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      list.push({
        color: colors[i],
        categorie: categories[i],
        value: data[i],
      });
    }
    setDataList(list);
  }, [colors, categories, data]);

  return (
    <StyledCard>
      <CardHeaderStyled title={title} />
      <StyledCardContent>
        <Text>{textTop}</Text>
        <TextTotalValue totalValueColor={totalValueColor}>
          {totalValue}
        </TextTotalValue>
        <DivChart>
          <ChartColumn
            colors={colors}
            dataType={dataType}
            data={data}
            endingShape={endingShape}
            categories={categories}
          />
        </DivChart>
        {
          dataList.map((item) => (
            <DivDataChart key={item.categorie}>
              <Circle circleColor={item.color} />
              <TextLegend>{item.categorie}</TextLegend>
              <TextValueLegend textColor={item.color}>
                {' '}
                {item.value}
              </TextValueLegend>
            </DivDataChart>
          ))
        }
      </StyledCardContent>
    </StyledCard>
  );
}

export default CardChartColumn;

CardChartColumn.propTypes = {
  title: PropTypes.string,
  textTop: PropTypes.string,
  totalValue: PropTypes.string,
  totalValueColor: PropTypes.string,
  colors: PropTypes.array,
  dataType: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.array,
  endingShape: PropTypes.oneOf(['flat', 'rounded']),
};

CardChartColumn.defaultProps = {
  title: '',
  textTop: '',
  totalValue: '',
  totalValueColor: '#3498DB',
  colors: ['#9966FF', '#F99D1C', '#F26522', '#00684F'],
  dataType: '',
  categories: [],
  data: [],
  endingShape: 'rounded',
};
