/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import { ModalBody, TitleContent, Content, BtnFinish, Row } from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { findAllCompany, setIdCompany } from "../../../actions/company";
import { Dropdown } from "semantic-ui-react";

function ModalNewPolicyAdm(props) {
  const { onClose } = props;
  const open = useSelector(state => state.modal.ModalNewPolicyAdm);
  const dispatch = useDispatch();

  const companies = useSelector(state => state.company.companies);

  useEffect(() => {
    console.log(companies);
  }, [companies]);

  const companiesOptions = companies.map(company => {
    return { key: company.id, value: company.id, text: company.nome };
  });

  useEffect(() => {
    dispatch(findAllCompany());
  }, [dispatch]);

  const handleChangeCompany = value => {
    dispatch(setIdCompany(companies.filter(({ id }) => id === value)[0]));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Cotação/Emissão</h1>
          <ButtonIcon onClick={onClose} color="default">
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <h2>Selecione o grupo empresarial</h2>
          <div
            style={{
              marginTop: "50px"
            }}
          >
            <h1>Grupo</h1>
            <Dropdown
              style={{
                width: "100%"
              }}
              search
              selection
              options={companiesOptions}
              onChange={(e, { value }) => handleChangeCompany(value)}
            />
          </div>
          <Row>
            <BtnFinish onClick={onClose}>Prosseguir</BtnFinish>
          </Row>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalNewPolicyAdm;

ModalNewPolicyAdm.propType = {
  onClose: PropType.func
};
