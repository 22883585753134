import StepLabel from '@material-ui/core/StepLabel';
import styled from 'styled-components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Step from '@material-ui/core/Step';
import CheckIcon from '@material-ui/icons/Check';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';

export const StyledStepper = withStyles({
  root: {
    background: 'none',
    padding: '0',
  },
})(Stepper);

export const StyledStep = styled(Step)`
  .MuiStepConnector-line {
    border: 2px solid #C4C4C4;
    border-top-width: 3;
  }

  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 13px);
    right: calc(50% + 13px);
  }

`;

export const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-label {
    color: #BDBDBD;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  .MuiStepLabel-active {
    color: #6453A8;
  }

  .MuiStepLabel-completed {
    color: #6453A8;
  }

  .MuiStepLabel-iconContainer {
    padding: ${(props) => (props.padding || '0')};
    border-radius: 50%;
    box-shadow: ${(props) => (props.disabled ? 'none' : '-2px 4px 4px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1)')};
    border: ${(props) => (props.disabled ? '4px solid #BDBDBD' : 'none')};
  }

  .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    padding-right: ${(props) => (props.padding || '0')};
  }

  .MuiSvgIcon-root {
    font-size: ${(props) => props.iconsize || '14px'};
    padding: ${(props) => props.checked && '3px'};
  }
`;

export const IconCircleDisabled = styled(FiberManualRecordIcon)`
  color: #FFFFFF;
`;

export const IconCircle = styled(FiberManualRecordIcon)`
  color: #6453A8;
`;

export const IconCheck = styled(CheckIcon)`
  color: #FFFFFF;
  background: #24126A;
  border-radius: 50%;
`;
