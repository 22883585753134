/* eslint-disable radix */
/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatToNumber } from 'brazilian-values';
import LaunchIcon from '@material-ui/icons/Launch';
import CardHeaderStyled from '../../UI/CardHeaderStyled';
import ChartColumn from '../../Chart/ChartColumn';
import {
  StyledCard,
  StyledCardContent,
  Text,
  TextTotalValue,
  DivChart,
  DivDataChart,
  Circle,
  TextLegend,
  TextValueLegend,
  SectionDataChart,
  DivLoadingContent,
  SectionTop,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault';

function CardChartDashboard(props) {
  const {
    title,
    textTop,
    totalValue,
    totalValueColor,
    colors,
    dataType,
    categories,
    data,
    endingShape,
    onClickDetails,
  } = props;

  const [dataList, setDataList] = useState([]);
  const loading = useSelector((state) => state.loading.LoadingAllDashboard);

  useEffect(() => {
    const list = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      list.push({
        color: colors[i],
        categorie: categories[i],
        value: `R$ ${formatToNumber(parseInt(data[i]))}`,
      });
    }
    setDataList(list);
  }, [colors, categories, data]);

  return (
    <StyledCard>
      <CardHeaderStyled title={title} />
      {!loading ? (
        <StyledCardContent>
          <SectionTop>
            <div className="div-value">
              <Text>{textTop}</Text>
              <TextTotalValue totalValueColor={totalValueColor}>
                {totalValue}
              </TextTotalValue>
            </div>
            <ButtonDefault
              variant="text"
              color="primary"
              size="small"
              startIcon={<LaunchIcon />}
              onClick={onClickDetails}
            >
              Detalhes
            </ButtonDefault>
          </SectionTop>
          <DivChart>
            <ChartColumn
              colors={colors}
              dataType={dataType}
              data={data}
              endingShape={endingShape}
              categories={categories}
            />
          </DivChart>
          <SectionDataChart>
            {dataList.map((item) => (
              <DivDataChart key={item.categorie}>
                <Circle circleColor={item.color} />
                <TextLegend>{item.categorie}</TextLegend>
                <TextValueLegend textColor={item.color}>
                  {item.value}
                </TextValueLegend>
              </DivDataChart>
            ))}
          </SectionDataChart>
        </StyledCardContent>
      ) : (
        <DivLoadingContent>
          <CircularProgress />
        </DivLoadingContent>
      )}
    </StyledCard>
  );
}

export default CardChartDashboard;

CardChartDashboard.propTypes = {
  title: PropTypes.string,
  textTop: PropTypes.string,
  totalValue: PropTypes.string,
  totalValueColor: PropTypes.string,
  colors: PropTypes.array,
  dataType: PropTypes.string,
  categories: PropTypes.array,
  data: PropTypes.array,
  endingShape: PropTypes.oneOf(['flat', 'rounded']),
  onClickDetails: PropTypes.func,
};

CardChartDashboard.defaultProps = {
  title: '',
  textTop: '',
  totalValue: '',
  totalValueColor: '#3498DB',
  colors: ['#9966FF', '#F99D1C', '#F26522', '#00684F'],
  dataType: '',
  categories: [],
  data: [],
  endingShape: 'rounded',
  onClickDetails: () => {},
};
