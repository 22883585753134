/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from './api';

export const setEndorsement = (endorsement) => ({
    type: 'SET_ENDORSEMENT',
    payload: {
        endorsement,
    },
});

export const setAllEndorsements = (allEndorsements) => ({
    type: 'SET_ALL_ENDORSEMENTS',
    payload: {
        allEndorsements,
    },
});

export const createSolicitation = (newEndorsement) => async(dispatch) => {
    try {
        await axios.post(`endorsement/new-solicitation`, newEndorsement);
    } catch (err) {
        console.log(err);
    }
};

export const getEndorsement = (idEndorsement) => async(dispatch) => {
    try {
       const response = await axios.get(`endorsement/${idEndorsement}`);
       dispatch(setEndorsement(response.data))
    } catch (err) {
        console.log(err);
    }
};

export const getDetailEndorsement = async(idEndorsement) => {
    try {
       const response = await axios.get(`endorsement/${idEndorsement}`);
       return response.data
    } catch (err) {
        console.log(err);
    }
};

export const getAllEndorsements = (query) => async(dispatch) => {
    try {
       const response = await axios.get(`endorsement`, {
           params: query
       });
       dispatch(setAllEndorsements(response.data.endorsements));
    } catch (err) {
        console.log(err);
    }
};

export const respondSolicitation = (data) => async(dispatch) => {
    try {
       await axios.post(`endorsement/respond-solicitation`, data);
       dispatch(getEndorsement(data.idEndosso));
    } catch (err) {
        console.log(err);
    }
};

export const returnProblem = (data, id) => async(dispatch) => {
    try {
       await axios.post(`endorsement/return-problem`, data);
       dispatch(getEndorsement(id));
    } catch (err) {
        console.log(err);
    }
};

export const sendInsurer = (data) => async(dispatch) => {
    try {
       await axios.post(`endorsement/send-insurer`, data);
       dispatch(getEndorsement(data.idEndosso));
    } catch (err) {
        console.log(err);
    }
};

export const completeSolicitation = (data, id) => async(dispatch) => {
    try {
       await axios.post(`endorsement/complete-solicitation`, data);
       dispatch(getEndorsement(id));
    } catch (err) {
        console.log(err);
    }
};