import styled from 'styled-components';
import bg_login from '../../assets/images/bg_login.jpg';
import backgroundLogin from '../../assets/images/backgroundLogin.jpg';

export const LoginContent = styled.div `
  background-image:url(${bg_login});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
`;

export const FormLogin = styled.form `
  width: 470px;
  margin-left: auto;
  padding-top: 80px;
`

export const InputLogin = styled.input `
  width: 354px;
  border: none;
  border-bottom: 1px solid #B3E0F5;
  color: #fff!important;
  margin: 15px 0px;

  input {
    border: none!important;
    background: transparent!important;
    color: #fff!important;
  }
`

export const Button = styled.button `
  width: 354px;
  border: none;
  height: 41px;
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 12px;
  font-family: 'Montserrat';
  color: #185F9B;
  margin: 10px 0px;
  :hover {
    cursor: pointer;
  }
`;

export const Label = styled.label `
  align-items: center;
  justify-content: ${props => props.justifyContent || 'left'};
  width: 354px;
  display: flex;
  border: none;
  font-size: 12px;
  color: ${props => props.color || '#fff'};
  margin: 10px 0px;
`

export const Title = styled.h2 `
  font-family: Montserrat;
  margin: 10px 0px 70px ;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  width: 354px;

`

export const Background = styled.div`
  background-image:url(${backgroundLogin});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
`;

export const SectionLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .consetLogo {
    width: 130px;
    margin: 40px;
  }

  .divLinks {
    display: flex;
    flex-direction: column;
    margin: 40px;
  }

  .divLinks > a > img {
    width: 230px;
    margin-top: 10px;
  }
`;

export const SectionRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logoSmartProcess {
    width: 250px;
  }

  h1 {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    width: 250px;
    margin-top: -0.1px;
    margin-bottom: 50px;
  }
`;
