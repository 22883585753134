/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import CardHeaderStyled from '../../UI/CardHeaderStyled';
import {
  StyledCard,
  StyledCardContent,
  TextTotalValue,
  TextLegend,
} from './style';

function CardCauseValue(props) {
  const somaValorCausa = useSelector(state => state.pipeline.dashboard.somaValorCausa);

  return (
    <StyledCard>
      <CardHeaderStyled title="Valor da causa" />
      <StyledCardContent>
        <TextTotalValue>
          R$ {somaValorCausa.sumValorCausa}
        </TextTotalValue>
        <TextLegend>Valor de todas as empresas</TextLegend>
      </StyledCardContent>
    </StyledCard>
  );
}

export default CardCauseValue;