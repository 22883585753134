import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import PropType from 'prop-types';

import Modal from '@material-ui/core/Modal';
import {Form, Grid} from 'semantic-ui-react';

import {ModalBody, TitleContent, Content, BtnFinish, BtnCancel} from './style';

import {telMask} from '../../../services/mask';

import {setModalConsetUser} from '../../../actions/modal';
import { updateConsetUser } from '../../../actions/consetUser';

function ModalConsetUser(props) {
    const dispatch = useDispatch()

    const {onClose} = props;
    const open = useSelector(state => state.modal.ModalConsetUser);

    const user = useSelector(state => state.consetUser.user)
    
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [permissoes, setPermissoes] = useState([])

    useEffect(() => {
        setNome(user.nome)
        setEmail(user.email)
        setTelefone(user.telefone)
        setPermissoes(user.permissoes)
    }, [dispatch, user])
    
    const permissoesOp = [
        {
            value: "Administração",
            text: "Administração"
        }, {
            value: "Pipeline",
            text: "Pipeline"
        }
    ]

    const handleChangePermissoes = (event, {value}) => {
        setPermissoes(value)
    }

    const sendForm = async() => {
        const userChange = {
            nome,
            email,
            telefone,
            permissoes: `{${permissoes
                .reduce((acc, permissao, i) => acc + permissao + (i !== permissoes.length - 1
                    ? ','
                    : ' '), ' ')}}`
        }

            dispatch(updateConsetUser(user.id, userChange))
            dispatch(setModalConsetUser(false))

    }

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <ModalBody>
                    <TitleContent>
                        <h1>Editar acesso</h1>
                    </TitleContent>
                    <Content>
                        <Form onSubmit={() => sendForm()}>
                            <Form.Input
                                required
                                label="Nome"
                                onChange={e => setNome(e.target.value)}
                                defaultValue={user.nome}
                                placeholder="Nome"
                                width={16}/>
                            <Form.Input
                                label="E-mail"
                                placeholder="E-mail"
                                type="email"
                                defaultValue={user.email}
                                onChange={e => setEmail(e.target.value)}
                                width={16}
                                required/>
                            <Form.Input
                                label="Telefone"
                                placeholder="Telefone"
                                width={16}
                                defaultValue={user.telefone}
                                value={telefone}
                                onChange={e => setTelefone(telMask(e.target.value))}
                                required/>
                            <Form.Select
                                label="Permissão"
                                placeholder="Permissão"
                                width={16}
                                options={permissoesOp}
                                multiple
                                onChange={handleChangePermissoes.bind()}
                                defaultValue={[user.permissoes]}
                                />
                            <Grid>
                                <Grid.Row textAlign="right">
                                    <Grid.Column width={16}>
                                        <BtnCancel className="btn-cancel">CANCELAR</BtnCancel>
                                        <BtnFinish type='submit'>ATUALIZAR</BtnFinish>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Content>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalConsetUser;

ModalConsetUser.propType = {
    onClose: PropType.func
};
