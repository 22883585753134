import axios from 'axios';

const baseUrl = 'https://viacep.com.br/ws/';
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

export const setValidCEP = (validCEP) => ({
  type: 'SET_VALID_CEP',
  payload: {
    validCEP,
  },
});

export const getValidCEP = (CEP) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`${CEP}/json/`);
    dispatch(setValidCEP(response.data));
  } catch (err) {
    console.log(err);
  }
};
