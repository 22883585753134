/* eslint-disable react/forbid-prop-types */
import React from 'react';
import loadable from 'loadable-components';
import PropTypes from 'prop-types';

const Chart = loadable(() => import ('react-apexcharts'), {ssr: false});

function ChartLineColumn(props) {
    const {
        data,
        labels,
        height,
        width
    } = props;

    const options = {
        chart: {
            toolbar: {
                show: false
            },
            height: 450,
            type: 'line'
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        dataLabels: {
            enabled: false
        },
        labels: labels,
        markers: {
            size: 4,
            colors: ["#00366C"],
            strokeColors: "#fff",
            strokeWidth: 0,
            hover: {
                size: 7
            }
        },
        xaxis:{
          labels:{
            show: false,
            style:{
              fontSize: '12px',
              colors:'#616161',
              fontFamily: 'Montserrat'
            }
          }
        },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: false
                },
                labels: {
                    align: 'top',
                    style: {
                        colors: "#287D3C",
                        fontWeight: '600'

                    }
                },
                title: {
                    rotate: 0,
                    offsetX: 55,
                    offsetY: -180,
                    text: "Valor da causa",
                    style: {
                        color: "#287D3C"
                    }
                }
            }, {
                opposite: true,
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: false
                },
                labels: {
                    align: 'top',
                    style: {
                        colors: "#2E5AAC",
                        fontWeight: '600'

                    }
                },
                title: {
                    rotate: 0,
                    offsetX: -25,
                    offsetY: -180,
                    text: "Qtd",
                    style: {
                        color: "#2E5AAC"
                    }
                }
            }
        ],
        legend: {
            show: false
        },
        plotOptions: {
            bar: {
                columnWidth: "25%",
                endingShape: 'rounded'

            }
        },
        colors: ["#287D3C", "#2E5AAC"],
        grid: {
          padding:{
            top: 30
          }
        }
    };

    return (
        <div>
            <Chart
                options={options}
                series={data}
                type="line"
                height={height}
                width={width}/>
        </div>
    );
}

export default ChartLineColumn;

ChartLineColumn.propTypes = {
    colors: PropTypes.array,
    columnWidth: PropTypes.string,
    dataLabels: PropTypes.bool,
    legend: PropTypes.bool,
    dataType: PropTypes.string,
    categories: PropTypes.array,
    horizontalLabels: PropTypes.bool,
    verticalLabels: PropTypes.bool,
    data: PropTypes.array,
    height: PropTypes.string,
    width: PropTypes.string
};

ChartLineColumn.defaultProps = {
    colors: [
        '#00684F', '#9966FF', '#F99D1C', '#F26522'
    ],
    columnWidth: '50%',
    endingShape: 'rounded',
    dataLabels: false,
    legend: false,
    dataType: null,
    categories: [],
    horizontalLabels: false,
    verticalLabels: false,
    data: [],
    height: '400px',
    width: '100%'
};
