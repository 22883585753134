export const initialState = {
  allDashboard: {
    allLimiteUtilizado: [0],
    allLimiteDisponivel: [0],
    allLimiteTotal: [0],
  },
  resumeDashboard: {
    apolicesEmitidas: 0,
    totalIS: 0,
    totalPremio: 0,
  },
  dashboardConset: {
    seguradoraCard: [],
    tomadorCard: [],
    processosCard: {},
    pipelineCard: {},
    premioCard: {},
  },
  dashboardConsetCompany: {
    expiredPolicies: {
      healthy: {},
      attention: {},
      late: {},
      critical: {},
      expired: {},
    },
    birthdayPolicies: {
      healthy: {},
      attention: {},
      late: {},
      critical: {},
      expired: {},
    },
    processosCard: {},
  },
  queryDashboard: {},
  dashboardLimitsData: {
    recursal: {
      total: 0,
      disponivel: 0,
      utilizado: 0,
      taxa: 0,
    },
    execucaoFiscal: {
      total: 0,
      disponivel: 0,
      utilizado: 0,
      taxa: 0,
    },
    judicial: {
      total: 0,
      disponivel: 0,
      utilizado: 0,
      taxa: 0,
    },
  },
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_DASHBOARD':
      return { ...state, allDashboard: action.payload.data };
    case 'SET_RESUME_DASHBOARD':
      return { ...state, resumeDashboard: action.payload.data };
    case 'SET_DASHBOARD_CONSET':
      return { ...state, dashboardConset: action.payload.data };
    case 'SET_DASHBOARD_CONSET_COMPANY':
      return { ...state, dashboardConsetCompany: action.payload.data };
    case 'SET_QUERY_DASHBOARD':
      return { ...state, queryDashboard: action.payload.data };
    case 'SET_DASHBOARD_LIMITS':
      return { ...state, dashboardLimitsData: action.payload.data };
    default:
      return state;
  }
};

export default dashboard;
