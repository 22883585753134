import styled from "styled-components";

export const TabStyle = styled.div`
  .ui.secondary.pointing.menu {
    margin: 0 50px;
    border-bottom: 2px solid #c4c4c4;
    display: flex;
    justify-content: space-between;

    a {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #c4c4c4;
      width: 50%;
      display: flex;
      justify-content: center;
    }

    a:hover {
      color: #c4c4c4;
    }

    .active.item {
      color: #00366c;
      border-bottom: 7px solid #00366c;
      border-radius: 5px;
    }
  }
`;

export const DivTop = styled.div`
  margin: 0px 55px;
  padding: 50px 0px !important;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
    color: #00366c;
  }

  h4 {
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
    font-weight: 400;
    font-family: Montserrat;
    margin-top: 0;
  }
`;
export const DivContent = styled.div`
  margin: 0px 55px;
  padding: 50px 0px !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .ui.grid {
    width: 100%;
  }

  .ui.grid > .row {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const IconFilter = styled.div`
  background: white;
  padding: 10px;
  height: 38px;
  width: min-content;
`;

export const ButtonLead = styled.button`
  width: 160px;
  border-radius: 15px;
  padding: 15px 30px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  background: #1299f3 !important;
  color: white;
  border: none;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 35, 92, 0.04);
  }
`;

export const InputSearch = styled.input`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #424242;
    margin-bottom: 5px;
  }

  input {
    width: 400px;
  }
`;

export const TitleLead = styled.h2`
  font-weight: bold;
  font-size: ${props => props.fontSize || "14px"};
  line-height: 20px;
  color: #00366c;
  font-family: Montserrat;
  margin: ${props => props.margin || "60px 0px 40px"}!important;
  width: 154px;
`;

export const TitleHeaderStep = styled.div`
  width: 100%;
  padding: 15px;
  background: #00366c;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
`;

export const ContentStep = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardHeaderStep = styled.div`
  background: #cecfcf;
  border-radius: 15px;
  width: 154px;
  height: 168px;
  color: #00366c;
  padding: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;

  h6 {
    font-size: 10px;
    font-weight: normal;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    margin: 15px 0px 0px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
`;

export const CardBodyStep = styled(CardHeaderStep)`
  background: #fff;
  border-radius: 0px;
  border: 1px solid #00366c;
  margin: 20px 0px;
  height: auto;

  h1 {
    font-weight: 500;
    font-size: 14px;
    color: #212121;
    margin-top: 10px;
  }

  h2 {
    font-weight: normal;
    font-size: 14px;
    color: #212121;
  }

  h5 {
    color: #212121;
    margin: 0;
  }

  h6 {
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #24126a;
    margin: 10px 0px 0px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new {
    background: rgba(40, 125, 60, 0.2);
    font-weight: 500;
    font-size: 10px;
    color: #287d3c;
    padding: 0px 15px;
    margin: -13px -13px 13px;
    justify-content: space-between;
  }

  .details {
    background: rgba(0, 49, 180, 0.1);
    font-weight: 500;
    font-size: 10px;
    color: #0031b4;
    padding: 3px 7px;
    margin: 5px -13px -13px;
    justify-content: space-between;
  }
`;

export const ContentResult = styled.div`
  border: 1px solid #00366c;
`;

export const BodyResult = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 40px 20px;
`;

export const TitleHeaderResult = styled.div`
  border-bottom: 1px solid #00366c;
  padding: 15px;
  display: flex;
  justify-content: space-between;

  h1 {
    font-weight: bold;
    font-size: 14px;
    color: #00366c;
    margin: 0px 0px 0px 80px;
  }

  h2 {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #185f9b;
    margin: 0px 0px 0px 20px;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const CardHeaderResult = styled(CardHeaderStep)`
  background: ${props => props.bgColor};
  color: #fff;
`;

export const Table = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Montserrat", "sans-serif";
  margin-top: 70px;
  margin-right: 20px;
  width: 100%;
  overflow-x: scroll;
`;

export const TableScroll = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat || "5"}, 1fr);
  cursor: grab;
`;

export const TableFixed = styled.div``;

export const CellHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #24126a;
  padding: 10px 16px;
  padding-left: ${props => props.paddingLeft || "16px"};
  word-break: keep-all;
  white-space: nowrap;
  background: #ffffff;
  width: ${props => props.width};
  margin-bottom: 8px;
`;

export const CellHeaderDetails = styled(CellHeader)`
  box-shadow: rgba(0, 0, 0, 0.25) -6px 0px 4px -3.5px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
`;

export const CellHeaderSelect = styled(CellHeaderDetails)`
  box-shadow: none;
  min-width: 50px;
`;

export const CellStatus = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #2c3e50;
  background: #ffffff;
  height: 72px;
  padding-left: 32px;
  padding-right: 32px;
  border-left: 4px solid #ffffff;
  margin-bottom: 4px;
`;

export const Cell = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 72px;
  color: #2c3e50;
  background: #ffffff;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 4px;
`;

export const CellMais = styled(Cell)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #4f75ba;
`;

export const CellDetails = styled(Cell)`
  min-width: 114px;
  min-height: 72px;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) -6px 0px 4px -3.5px;
`;

export const CellSelect = styled(CellDetails)`
  box-shadow: none;
  min-width: 50px;
`;
