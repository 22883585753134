import styled from 'styled-components';

export const Table = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  font-family: 'Montserrat', 'sans-serif';
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
`;

export const TableScroll = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  cursor: grab;
`;

export const CellHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #24126A;
  padding: 10px 16px;
  padding-left: ${(props) => props.paddingLeft || '16px'};
  word-break: keep-all;
  white-space: nowrap;
  background: #FFFFFF;
  width: ${(props) => props.width};
  margin-bottom: 8px;
`;

export const CellStatus = styled.div`
  display: flex;
  background: #FFFFFF;
  height: 72px;
  padding-right: 32px;
  border-left: 4px solid #FFFFFF;
  margin-bottom: 4px;

  h1 {
    color: #2C3E50;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
`;

export const Cell = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #2C3E50;
  background: #FFFFFF;
  padding-left: ${props => props.paddingLeft || '16px'};
  padding-right: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: ${props => props.width};
`;

export const TableFixed = styled.div`

`;

export const CellHeaderDetails = styled(CellHeader)`
  box-shadow: rgba(0, 0, 0, 0.25) -6px 0px 4px -3.5px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
`;

export const CellDetails = styled(Cell)`
  width: 114px;
  min-height: 72px;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.25) -6px 0px 4px -3.5px;
`;

export const DivIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
`;

export const DivIconDate = styled.div`
  display: flex;
  border-left: 4px solid ${(props) => props.color};
  color: ${(props) => props.color};
  height: 45%;
  align-items: center;
`;

export const DivIconBirthday = styled.div`
  display: flex;
  border-left: 4px solid ${(props) => props.color};
  color: ${(props) => props.color};
  height: 45%;
  align-items: center;
`;
