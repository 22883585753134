import styled from 'styled-components';

export const ModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  width: 640px;
  height: 500px;
  margin: auto;
  margin-top: 2%;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const TextDiv = styled.div`
  margin: 0 40px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #424242;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  margin: 0 40px;
  align-items: center;
  justify-content: flex-end;
  .buttonAccept {
    margin-left: 20px;
  }
`;
