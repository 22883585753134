import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setModalCompromisePolicy } from '../../../actions/modal';
import {
  ModalBody,
  TitleContent,
  TextDiv,
  ButtonsDiv,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault/index';

function ModalCompromisePolicy(props) {
  const { onClickAccept } = props;
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.ModalCompromisePolicy);

  function handleCloseModal() {
    dispatch(setModalCompromisePolicy(false));
  }

  return (
    <Modal open={open}>
      <ModalBody>
        <TitleContent>
          <h1> Termos de Compromisso </h1>
        </TitleContent>
        <TextDiv>
          <p>
            Na qualidade de Tomador, devidamente habilitado no sistema de emissão SMART PROCESS da CONSET, assumo integral responsabilidade pelas informações e dados ora prestados e afirmo que estes refletem as reais condições do(s) instrumento(s) que subsidia(m) a presente proposta, parte integrante da apólice de seguro. Desta forma, isento a CONSET ADMINISTRAÇÃO E CORRETAGEM DE SEGUROS LTDA. de quaisquer ônus decorrentes de informações incompletas ou inverídicas. Igualmente, estou ciente que o Seguro Garantia que pretendo contratar não assegura riscos estranhos a este ramo de seguros.
          </p>
        </TextDiv>
        <ButtonsDiv>
          <ButtonDefault
            color="primary"
            variant="text"
            onClick={handleCloseModal}
          >
            Discordo
          </ButtonDefault>
          <ButtonDefault
            customColor="#F9B233"
            colorLabel="#FFFFFF"
            className="buttonAccept"
            onClick={onClickAccept}
          >
            Concordo
          </ButtonDefault>
        </ButtonsDiv>
      </ModalBody>
    </Modal>
  );
}

export default ModalCompromisePolicy;

ModalCompromisePolicy.propType = {
  onClickAccept: PropTypes.func,
};

ModalCompromisePolicy.defaultProps = {
  onClickAccept: () => {},
};
