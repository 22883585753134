export const initialState = {
  users: [],
  user: {
    tomadores: [],
  },
};

const companyUser = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_USERS':
      return { ...state, users: action.payload.users };
    case 'SET_COMPANY_USER':
      return { ...state, user: action.payload.user };
    default:
        return state;
  }
};

export default companyUser;
