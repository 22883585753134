import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  GridColumn,
  Label,
} from "semantic-ui-react";

import {
  DivGarantia,
  DivTop,
  DivContent,
} from "./style";

import { findAllGuarantee, updateGuarantee } from "../../actions/guarantee";

function TipoGarantia() {
    const dispatch = useDispatch();
  const guarantees = useSelector(state => state.guarantee.guarantees);

  const [wordGuarantee, setWordGuarantee] = useState("");

    useEffect(() => {
        dispatch(findAllGuarantee());
      }, [dispatch]);
    
    
    const editGuarantee = (guarantee, value) => {
        guarantee.palavrasChave.push(value);
    
        const palavrasChave = `{${guarantee.palavrasChave.reduce(
          (acc, palavra, i) =>
            acc + palavra + (i !== guarantee.palavrasChave.length - 1 ? ", " : ""),
          ""
        )}}`;
    
        const newGuarantee = {
          palavrasChave
        };
    
        setWordGuarantee("");
        dispatch(updateGuarantee(guarantee.id, newGuarantee));
      };
    
      const removeGuarantee = (guarantee, idxPalavra) => {
        const newPalavras = guarantee.palavrasChave;
    
        newPalavras.splice(idxPalavra, 1);
    
        const palavrasChave = `{${newPalavras.reduce(
          (acc, palavra, i) =>
            acc + palavra + (i !== guarantee.palavrasChave.length - 1 ? ", " : ""),
          ""
        )}}`;
    
        dispatch(updateGuarantee(guarantee.id, { palavrasChave }));
      };
    
    
    return (
      <div>
        <DivTop>
          <GridColumn>
            <h2>Palavras-chaves</h2>
            <h4>
              Cadastre palavras-chaves pra busca do tipo de garantia dos
              processos das empresa
            </h4>
          </GridColumn>
        </DivTop>
        <DivContent>
          {guarantees.map(guarantee => {
            return (
              <div
                style={{
                  width: "100%"
                }}
              >
                <h2>{guarantee.garantia}</h2>
                <DivGarantia>
                  <Input
                    placeholder="Digite a palavra-chave aqui..."
                    onBlur={e => editGuarantee(guarantee, e.target.value)}
                    value={wordGuarantee}
                    onChange={e => setWordGuarantee(e.target.value)}
                  />{" "}
                  {guarantee.palavrasChave.map((palavra, index) => (
                    <Label>
                      {palavra} &emsp;{" "}
                      <span onClick={() => removeGuarantee(guarantee, index)}>
                        x
                      </span>
                    </Label>
                  ))}
                </DivGarantia>
              </div>
            );
          })}
        </DivContent>
      </div>
    );
  }

  export default TipoGarantia;
