import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import PropType from 'prop-types';

import Modal from '@material-ui/core/Modal';
import {Form, Grid} from 'semantic-ui-react';

import {ModalBody, TitleContent, Content, BtnFinish, BtnCancel} from './style';

import {cnpjMask, cepMask, telMask} from '../../../services/mask';
import {updateCompany} from '../../../actions/company';
import {setModalEditCompany} from '../../../actions/modal';

function ModalEditCompany(props) {
    const dispatch = useDispatch()

    const open = useSelector((state) => state.modal.ModalEditCompany);
    const {onClose} = props;

    const company = useSelector(state => state.company.company)

    const [companyEdit,
        setCompanyEdit] = useState({})

    const ufs = [
        {
            value: 'AC',
            text: 'AC'
        }, {
            value: 'AL',
            text: 'AL'
        }, {
            value: 'AP',
            text: 'AP'
        }, {
            value: 'AM',
            text: 'AM'
        }, {
            value: 'BA',
            text: 'BA'
        }, {
            value: 'CE',
            text: 'CE'
        }, {
            value: 'DF',
            text: 'DF'
        }, {
            value: 'ES',
            text: 'ES'
        }, {
            value: 'GO',
            text: 'GO'
        }, {
            value: 'MA',
            text: 'MA'
        }, {
            value: 'MT',
            text: 'MT'
        }, {
            value: 'MS',
            text: 'MS'
        }, {
            value: 'MG',
            text: 'MG'
        }, {
            value: 'PA',
            text: 'PA'
        }, {
            value: 'PB',
            text: 'PB'
        }, {
            value: 'PR',
            text: 'PR'
        }, {
            value: 'PE',
            text: 'PE'
        }, {
            value: 'PI',
            text: 'PI'
        }, {
            value: 'RJ',
            text: 'RJ'
        }, {
            value: 'RN',
            text: 'RN'
        }, {
            value: 'RS',
            text: 'RS'
        }, {
            value: 'RO',
            text: 'RO'
        }, {
            value: 'RR',
            text: 'RR'
        }, {
            value: 'SC',
            text: 'SC'
        }, {
            value: 'SP',
            text: 'SP'
        }, {
            value: 'SE',
            text: 'SE'
        }, {
            value: 'TO',
            text: 'TO'
        }
    ]

    const companyChange = (item, value) => {
        const companyNew = company;

        if (item === 'cep') 
            value = cepMask(value);
        if (item === 'cnpj') 
            value = cnpjMask(value);
        if (item === 'telefone') 
            value = telMask(value)

        companyNew[item] = value
        setCompanyEdit(companyNew);
    }

    const ufChange = (event, {value}) => {
        const companyNew = company
        companyNew['uf'] = value

        setCompanyEdit(companyNew)
    }

    const sendForm = async() => {
        dispatch(updateCompany(company.id, companyEdit))
        dispatch(setModalEditCompany(false))
    }

    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <ModalBody>
                    <TitleContent>
                        <h1>Editar empresa</h1>
                    </TitleContent>
                    <Content>
                        <Form onSubmit={() => sendForm()}>
                            <Form.Group>
                                <Form.Input
                                    required
                                    label="CNPJ"
                                    onChange={e => companyChange('cnpj', e.target.value)}
                                    defaultValue={company.cnpj}
                                    placeholder="CNPJ"
                                    width={4}/>
                                <Form.Input
                                    label="Razão Social"
                                    placeholder="Razão Social"
                                    defaultValue={company.razaoSocial}
                                    onChange={e => companyChange('razaoSocial', e.target.value)}
                                    width={6}
                                    required/>
                                <Form.Input
                                    label="Nome da empresa"
                                    placeholder="Nome da empresa"
                                    width={6}
                                    defaultValue={company.nome}
                                    onChange={e => companyChange('nome', e.target.value)}
                                    required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    label="E-mail"
                                    placeholder="E-mail"
                                    width={6}
                                    defaultValue={company.email}
                                    onChange={e => companyChange('email', e.target.value)}
                                    required/>
                                <Form.Input
                                    label="Telefone"
                                    placeholder="Telefone"
                                    width={4}
                                    defaultValue={company.telefone}
                                    onChange={e => companyChange('telefone', e.target.value)}
                                    required/>
                            </Form.Group>
                            <h1>Endereço</h1>
                            <Form.Group>
                                <Form.Input
                                    label="Estado"
                                    placeholder="Estado"
                                    width={4}
                                    defaultValue={company.estado}
                                    onChange={e => companyChange('estado', e.target.value)}
                                    required/>
                                <Form.Select
                                    label="UF"
                                    placeholder="UF"
                                    width={2}
                                    options={ufs}
                                    defaultValue={company.uf}
                                    onChange={ufChange.bind(this)}
                                    required/>
                                <Form.Input
                                    label="CEP"
                                    placeholder="CEP"
                                    width={3}
                                    defaultValue={company.cep}
                                    onChange={e => companyChange('cep', e.target.value)}
                                    required/>
                                <Form.Input
                                    label="Cidade"
                                    placeholder="Cidade"
                                    width={4}
                                    defaultValue={company.cidade}
                                    onChange={e => companyChange('cidade', e.target.value)}
                                    required/>
                                <Form.Input
                                    label="Bairro"
                                    placeholder="Bairro"
                                    width={4}
                                    defaultValue={company.bairro}
                                    onChange={e => companyChange('bairro', e.target.value)}
                                    required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Input
                                    label="Logradouro"
                                    placeholder="Logradouro"
                                    width={5}
                                    defaultValue={company.logradouro}
                                    onChange={e => companyChange('logradouro', e.target.value)}
                                    required/>
                                <Form.Input
                                    label="Número"
                                    placeholder="Nº"
                                    width={2}
                                    defaultValue={company.numero}
                                    onChange={e => companyChange('numero', e.target.value)}
                                    required/>
                                <Form.Input
                                    label="Complemento"
                                    placeholder="Complemento"
                                    defaultValue={company.complemento}
                                    width={4}
                                    onChange={e => companyChange('complemento', e.target.value)}/>
                            </Form.Group>

                            <Grid>
                                <Grid.Row textAlign="right">
                                    <Grid.Column width={16}>
                                        <BtnCancel className="btn-cancel">CANCELAR</BtnCancel>
                                        <BtnFinish type='submit'>ATUALIZAR</BtnFinish>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </Content>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalEditCompany;

ModalEditCompany.propType = {
    onClose: PropType.func
};
