import styled from "styled-components";

export const TabStyle = styled.div`
  .ui.secondary.pointing.menu {
    margin: 0 50px;
    border-bottom: 2px solid #24126a;
    display: flex;
    justify-content: space-between;

    a {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #a1aaae;
      width: 16%;
      display: flex;
      justify-content: center;
    }

    .active.item {
      color: #00366c;
      border-bottom: 5px solid #f9b233;
      border-radius: 3px;
    }
  }
`;

export const DivTop = styled.div`
  margin: 0px 55px;
  padding: 50px 0px !important;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ui.grid {
    width: 100%;
    margin-left: 0px;
  }

  .form {
    width: 100%;

    button {
      margin-left: auto;
      margin-top: 50px;
      background: #185f9b;
      color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1.25px;
      border-radius: 2px;
    }
  }

  .column {
    padding-left: 0px !important;
  }
  h2 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
    font-weight: 400;
    font-family: Montserrat;
    margin-top: 0;
  }
`;

export const DivContent = styled(DivTop)`
  flex-direction: column;
  align-items: start;

  h2 {
    padding-bottom: 20px;
  }

  .row {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }

  h3 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
    color: #424242;
  }

  .ui.input,
  input {
    font-family: Montserrat;
    width: 100%;
  }
`;

export const Search = styled.div`
  width: 320px;
`;
export const IconFilter = styled.div`
  background: white;
  padding: 10px;
  height: 35px;
  margin-left: 10px;
`;

export const ButtonCompany = styled.button`
  width: 160px;
  border-radius: 15px;
  padding: 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  background: #1299f3 !important;
  color: white;
  border: none;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 35, 92, 0.04);
  }
`;

export const ButtonInsurance = styled(ButtonCompany)``;

export const ButtonSend = styled.button`
  font-family: Montserrat;
  border: none;
  background: #f9b233;
  color: white;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
  margin: ${props => props.margin || "67px 10px 0px"};
  padding: 13px 20px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;
export const ButtonBorder = styled(ButtonSend)`
  border: 1px solid #185f9b;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius || "2px"};
  background: white;
  color: #185f9b;
`;
export const ButtonBorderEdit = styled(ButtonBorder)`
  color: ${props => props.color || "#F9B233"};
  border: 1px solid ${props => props.color || "#F9B233"};
  padding: 12px;
  font-weight: bold;
  font-size: 12px;
  margin: 0px;
`;

export const TitleContent = styled.div`
  display: flex;
  margin: 0 50px;
  padding: ${props => props.padding || "0px 40px 40px 0px"};
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-style: normal;
    font-family: "Montserrat", "sans-serif";
    font-weight: 500;
    font-size: 20px;
    color: #616161;
    margin: 0;
  }

  div {
    display: flex;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: ${props => props.padding || "0px 40px 40px"};
  h1 {
    font-family: "Montserrat", "sans-serif";
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0c0142;
    margin: 40px 0px 0px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }

  h3 {
    font-weight: 500;
    font-size: 14px;
    color: #616161;
    margin-top: 10px;
  }

  .column-form {
    display: flex;
    flex-direction: column;
  }

  .ui.form {
    margin-left: 50px;

    .field {
      margin-top: 40px;
      label {
        font-family: "Montserrat", "sans-serif";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        leter-spacing: 0.1px;
        color: #424242;
        margin-bottom: 5px;
      }
      input {
        font-family: "Montserrat", "sans-serif";
      }
    }
    .fields .wide.field {
      margin-right: 10px;
    }
    .ui.selection.dropdown {
      min-width: 0px;
    }
    .button {
      border-radius: 2px;
      font-family: Montserrat;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      margin: 67px 10px 0px;
    }
  }
`;

export const DetalhesContent = styled.div`
  margin: 0px 65px;
  .top {
    display: flex;
    align-items: center;
    h2 {
      margin: 0px 0px 0px 40px;
    }
  }
  h2 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    color: #0c0142;
    margin: 30px 0px;
  }
`;
export const InputSla = styled.input`
  font-family: "Montserrat";
  border: inherit !important;
  background: #fff;
  width: 70px !important;
  margin-right: 10px;
  text-align: center !important;
`;

export const SectionTable = styled.section`
  margin: ${props => props.margin || "38px 0px"};
  width: 100%;
  .indiana-scroll-container {
    width: 100%;
  }
  padding: 0px !important;
  .icone-tabela {
    cursor: pointer;
  }
`;

export const Table = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  font-family: "Montserrat", "sans-serif";
  margin-top: 20px;
  margin-right: 20px;
  width: 100%;
  padding: 0px;
`;

export const TableScroll = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
`;

export const CellHeader = styled.div`
  font-style: normal;
  font-weight: ${props => props.fontWeight || "bold"};
  font-size: 12px;
  line-height: 20px;
  color: #24126a;
  padding: 15px 16px;
  padding-left: ${props => props.paddingLeft || "16px"};
  word-break: keep-all;
  background: #ffffff;
  margin-bottom: 8px;
  display: flex;
  justify-content: ${props => props.justifyContent || "center"};
  flex-direction: column;
  align-items: ${props => props.alignItems || "flex-start"};
  width: ${props => props.width};
  p {
    width: ${props => props.widthText};
    text-align: center;
  }
`;

export const Cell = styled.div`
  font-style: normal;
  font-weight: ${props => props.fontWeight || "normal"};
  font-size: ${props => props.fontSize || "12px"};
  display: flex;
  color: #2c3e50;
  background: #ffffff;
  padding: 20px 16px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  justify-content: ${props => props.justifyContent || "center"};
  align-items: ${props => props.alignItems || "start"};
  white-space: nowrap;
  flex-wrap: wrap;

  .field {
    width: ${props => props.width};
    margin-top: 0px !important;
  }

  p {
    margin-bottom: 2px;
    width: ${props => props.widthText};
    text-align: ${props => props.textAlign || "center"};
    white-space: pre-wrap;
  }
  .label-tomadores {
    margin-bottom: 10px;
  }
  .label-permissoes,
  .ui.circular.label {
    background-color: #2e5aac;
    color: white;
    padding: 5px 15px !important;
    white-space: nowrap;
    min-height: 0px;
  }
`;

export const DivGarantia = styled.div`
  background: #ffffff;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  align-items: baseline;
  width: 100%;
  flex-wrap: wrap;

  .ui.input input {
    border-radius: 12px !important;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    font-family: Montserrat;
    color: #bdbdbd;
    background: #fafafa;
    height: 24px;
    max-width: 200px;
    border: inherit;
  }

  .ui.input {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .label {
    height: 24px;
    background: #eef2fa;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #2e5aac;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;

export const DivRelatorio = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: ${props => props.padding || "40px"};
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  h4 {
    font-weight: bold;
    font-size: 12px;
    color: #0c0142;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    color: #9e9e9e;
    margin-bottom: 5px;
  }

  h5 {
    font-weight: 300;
    font-size: 12px;
    color: #da1414;
    margin: 0;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    color: #00366c;
    margin: 0;
  }

  h2 {
    font-weight: 600;
    font-size: 20px;
    color: #185f9b;
    margin: 0;
    padding-bottom: 0;
  }
`;
