export const initialState = {
  policyRoute: '/nova-apolice', // /nova-apolice
};

const navbar = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROUTE':
      return { ...state, policyRoute: action.payload.route };
    default:
      return state;
  }
};

export default navbar;
