import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 640px;
  padding-bottom: 20px;
  margin: auto;
  margin-top: 10%;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div`
  margin: 0px 40px;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00235C;
    letter-spacing: 0.1px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }

  .ui.form .field {
    label {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export const BtnFinish = styled.button `
  font-family: Montserrat;
  border: none;
  background: #F9B233;
  color:white;
  font-weight: 700;
  border-radius:4px;
  text-transform: uppercase;
  font-size: 12px;
  margin: 30px 0px 0px;
  padding: 13px 20px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export const BtnBorder = styled(BtnFinish)`
  border: 1px solid #185F9B;
  background: #fff;
  color: #185F9B;
  margin-right: 20px;
`

export const InputFile = styled.div`
  border: 1px solid #185F9B!important;
  border-radius: 4px!important;
  font-family: Montserrat!important;
  font-weight: bold;
  font-size: 12px;
  color: #185F9B!important;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-bottom: 15px;
`

export const ItemFile = styled.div`
  display: flex;
  justify-content: space-between;  
  padding: 8px;
`

export const Row = styled.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`