export const setSnackbarOpen = (trigger) => ({
  type: 'SET_SNACKBAR_OPEN',
  payload: {
    trigger,
  },
});

export const setSnackbarContent = (content) => ({
  type: 'SET_SNACKBAR_CONTENT',
  payload: {
    content,
  },
});

export const setSnackbarResult = (result) => ({
  type: 'SET_SNACKBAR_RESULT',
  payload: {
    result,
  },
});

export const setSnackbarRoute = (route) => ({
  type: 'SET_SNACKBAR_ROUTE',
  payload: {
    route,
  },
});

export const setSnackbarOpenSaneamento = (trigger) => ({
  type: 'SET_SNACKBAR_OPEN_SANEAMENTO',
  payload: {
    trigger,
  },
});

export const setSnackbarContentSaneamento = (content) => ({
  type: 'SET_SNACKBAR_CONTENT_SANEAMENTO',
  payload: {
    content,
  },
});

export const setSnackbarResultSaneamento = (result) => ({
  type: 'SET_SNACKBAR_RESULT_SANEAMENTO',
  payload: {
    result,
  },
});

export const setSnackbarRouteSaneamento = (route) => ({
  type: 'SET_SNACKBAR_ROUTE_SANEAMENTO',
  payload: {
    route,
  },
});

export const setSnackbarErrorOpen = (trigger) => ({
  type: 'SET_SNACKBAR_ERROR_OPEN',
  payload: {
    trigger,
  },
});

export const setSnackbarErrorContent = (content) => ({
  type: 'SET_SNACKBAR_ERROR_CONTENT',
  payload: {
    content,
  },
});
