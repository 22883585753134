import React from 'react';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  StyledSnackbar,
  SuccessIcon,
  DivContentText,
  DivContent,
  FailIcon,
} from './style';
import { setSnackbarOpen } from '../../../actions/snackbar';

function SnackbarProposal() {
  const open = useSelector((state) => state.snackbar.open);
  const content = useSelector((state) => state.snackbar.content);
  const result = useSelector((state) => state.snackbar.result);
  const route = useSelector((state) => state.snackbar.route);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(setSnackbarOpen(false));
  }

  function goToPage() {
    dispatch(push(route));
    dispatch(setSnackbarOpen(false));
  }

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
    >
      <SnackbarContent
        message={
          // eslint-disable-next-line react/jsx-fragments, react/jsx-wrap-multilines
          <DivContent onClick={goToPage}>
            { result ? (
              <SuccessIcon style={{ width: '43px', height: '32px' }} />
            ) : (
              <FailIcon style={{ width: '43px', height: '32px' }} />
            )}
            <DivContentText>
              <h1>{content.title ? content.title : ''}</h1>
              <p>{content.subtitle ? content.subtitle : ''}</p>
            </DivContentText>
          </DivContent>
        }
        action={
          // eslint-disable-next-line react/jsx-fragments, react/jsx-wrap-multilines
          <IconButton>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        }
      />
    </StyledSnackbar>
  );
}

export default SnackbarProposal;
