import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 960px;
  height: 468px;
  margin: auto;
  margin-top: 4%;
  font-family: Montserrat;
`;

export const ModalContent = styled.div`
  width: 100%;
  padding: 40px 30px;
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
  h1 {
    paddin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: 0.15px;
  }
`;

export const SectionChart = styled.div`
  display: flex;
`;

export const Menu = styled.div`
  width: 17.78%;
  border-width: 1px;
  border-right-style: solid;
  border-color: #EEEEEE;
`;

export const ButtonMenu = styled.div`
  display: flex;
  padding: 8px 18px 8px 0px;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  cursor: pointer;
  h2 {
    font-family: Montserrat,;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${(props) => props.color};
    padding: 0;
    margin: 0;
    align-self: flex-start;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #BDBDBD;
    padding: 0;
    margin: 0;
    align-self: baseline;
    width: 100%;
  }
  span {
    display: block;
    width: 6px;
    height: 6px;
    background: #F9B233;
    border-radius: 50%;
    align-self: center;
  }
`;

export const ChartArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const InfoTopChart = styled.div`
  font-family: 'Montserrat', 'sans-serif';
  width: 100%;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding: 0 40px 20px 40px;

  .container-info-2 {
    display: flex;
  }

  .info-1 > h1 {
    font-size: 12px;
    color: #DBDBDB;
    padding: 0;
    margin: 0;
  }

  .info-1 > p {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .info-2 {
    margin-left: 40px;
  }

  .info-2 > h1 {
    font-size: 10px;
    color: #DBDBDB;
    padding: 0;
    margin: 0;
  }

  .info-2 > p {
    font-size: 18px;
    color: #185F9B;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }
`;

export const InfoAsideChart = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #BDBDBD;
    padding: 0;
    margin: 0;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #00366C;
    padding: 0;
    margin: 0;
  }

  .info-2 {
    margin-top: 15px;
  }

  .info-2 > p {
    color: #185F9B;
  }

  .info-3 {
    margin-top: 15px;
  }

  .info-3 > p {
    color: #568CCC;
  }
`;

export const LoadingArea = styled.div`
  width: 80%;
  height: 223px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
