/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  ItemFile,
  Subtitle,
  BtnFinish,
  InputFile,
  Row
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { FiUpload } from "react-icons/fi";
import { VscClose } from "react-icons/vsc";
import { Dropdown, Form, Grid } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { completeSolicitation } from "../../../actions/endorsement";

function ModalDevolutiveEndorsement(props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const open = useSelector(state => state.modal.ModalDevolutiveEndorsement);
  const { idEndorsement } = useParams();

  const endorsement = useSelector(state => state.endorsement.endorsement);

  const [statusApolice, setStatusApolice] = useState("");
  const [descricaoDevolutiva, setDescricaoDevolutiva] = useState("");
  const [files, setFiles] = useState([]);
  const [change, setChange] = useState(true);

  const selectFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.onchange = handleUploadFile;
    input.click();
  };

  const removeFile = file => {
    const newFiles = files;
    newFiles.splice(
      newFiles.findIndex(item => item === file),
      1
    );
    setFiles(newFiles);
    setChange(!change);
  };

  const handleUploadFile = e => {
    const file = e.target.files[0];
    const newFiles = files;
    newFiles.push(file);
    setFiles(newFiles);
    setChange(!change);
  };

  const sendForm = () => {
    let formData = new FormData();
    formData.append("statusApolice", statusApolice);
    formData.append("descricaoDevolutiva", descricaoDevolutiva);
    formData.append("idEndosso", idEndorsement);
    files.map(file => {
      return formData.append("upfile", file);
    });

    dispatch(completeSolicitation(formData, idEndorsement));
    onClose();
  };

  const statusPolicyOptions = [
    {
      key: "Cancelada",
      value: "Cancelada",
      text: "Cancelada"
    },
    {
      key: "Baixada",
      value: "Baixada",
      text: "Baixada"
    },
    {
      key: "A substituir",
      value: "A substituir",
      text: "A substituir"
    }
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Detalhes da solicitação</h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <Subtitle>Nº {endorsement.id}</Subtitle>
          <h3>{endorsement.idApolice.process.idEmpresa.nome}</h3>
          <h6>{endorsement.idApolice.process.idEmpresa.email}</h6>
          <label>Descrição do chamado:</label>
          <h4>{endorsement.descricaoSolicitacao}</h4>
          <label>Tipo de endosso</label>
          <h4>{endorsement.tipoEndosso}</h4>
          <Form onSubmit={() => sendForm()}>
            <Subtitle>Devolutiva</Subtitle>
            <h4
              style={{
                marginBottom: "5px"
              }}
            >
              Alterar status da apólice
            </h4>
            <Dropdown
              placeholder="Selecione"
              fluid
              selection
              options={statusPolicyOptions}
              value={statusApolice}
              onChange={(e, { value }) => setStatusApolice(value)}
              style={{ marginBottom: "40px" }}
            />
            <h4
              style={{
                marginBottom: "5px"
              }}
            >
              Descrição
            </h4>
            <Form.TextArea
              rows={5}
              onChange={e => setDescricaoDevolutiva(e.target.value)}
            />
            <InputFile onClick={() => selectFile()}>
              <FiUpload
                color="#F9B233"
                size={20}
                style={{
                  marginRight: "10px"
                }}
              />
              ANEXAR ARQUIVO
            </InputFile>
            {files.map(file => (
              <ItemFile as={Grid}>
                <Grid.Row>
                  <Grid.Column width={8}>{file.name}</Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <VscClose
                      color="#F80A0A"
                      size={20}
                      onClick={() => removeFile(file)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </ItemFile>
            ))}
            <Row>
              <BtnFinish type="submit">Enviar</BtnFinish>
            </Row>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalDevolutiveEndorsement;

ModalDevolutiveEndorsement.propType = {
  onClose: PropType.func
};
