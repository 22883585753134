import styled from 'styled-components';

export const ModalBody = styled.div `
  background-color: #FFFFFF;
  width: 420px;
  margin:  auto;
  padding: 40px;
  margin-top: 12%;
`;

export const TitleContent = styled.div `
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div `
  .ui.form label {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #424242;
  }

  .checkbox-modal {
    margin-left: auto!important;
    padding-left: 8px;
  }
  
  .ui.form .fields {
    padding-top: 25px;
  }

`;

export const Text = styled.h4 `
  font-weight: 500;
  font-size: 14px;
  margin: 15px 0px;
  color: ${props => props.color || '#000'};
`
export const BtnFinish = styled.button `
  font-family: Montserrat;
  border: none;
  background: #F9B233;
  color:white;
  font-weight: 500;
  border-radius:2px;
  text-transform: uppercase;
  font-size: 14px;
  margin: 30px 10px 0px;
  padding: 13px 20px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`
export const BtnCancel = styled(BtnFinish)`
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius || '2px'};
  background: white;
  color: #185F9B;
`

export const Row = styled.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`