import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Form, Grid } from "semantic-ui-react";
import { FaFilter, FaRegFileAlt } from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";
import { VscListSelection } from "react-icons/vsc";

import ModalNewLead from "../../components/Modal/ModalNewLead";
import ModalArchiveLead from "../../components/Modal/ModalArchiveLead";

import {
  DivTop,
  IconFilter,
  ButtonLead,
  DivContent,
  InputSearch,
  TitleLead,
  ContentStep,
  CardHeaderStep,
  CardBodyStep,
  TitleHeaderStep,
  ContentResult,
  TitleHeaderResult,
  CardHeaderResult,
  BodyResult
} from "./style";

import {
  setModalNewLead,
  setModalArchiveLead,
  setModalFilterPipeline
} from "../../actions/modal";
import {
  editLead,
  listLeadGroups,
  listLeads,
  setLead
} from "../../actions/pipeline";
import { useDispatch, useSelector } from "react-redux";
import { moneyMask } from "../../services/mask";
import { formatToBRL } from "brazilian-values";
import ModalFilterPipeline from "../../components/Modal/ModalFilterPipeline";

function FasesComerciais() {
  const dispatch = useDispatch();

  const leads = useSelector(state => state.pipeline.leads);
  const changePipeline = useSelector(state => state.modal.ModalNewLead);

  const itemsLead = leads.filter(({ status }) => status === "LEAD");
  const itemsContact = leads.filter(({ status }) => status === "CONTACT");
  const itemsProposal = leads.filter(({ status }) => status === "PROPOSAL");
  const itemsApproved = leads.filter(({ status }) => status === "APPROVED");
  const itemsDisapproved = leads.filter(
    ({ status }) => status === "DISAPPROVED"
  );

  const [searchEmpresa, setSearchEmpresa] = useState("");
  const [query, setQuery] = useState({});

  useEffect(() => {
    dispatch(listLeads({ arquivado: false, nome: searchEmpresa, ...query }));
    dispatch(listLeadGroups());
  }, [dispatch, searchEmpresa, query, changePipeline]);

  const idLists = {
    LEAD: itemsLead,
    CONTACT: itemsContact,
    PROPOSAL: itemsProposal,
    APPROVED: itemsApproved,
    DISAPPROVED: itemsDisapproved
  };

  const getList = id => idLists[id];

  const move = (source, destination, droppableSource, droppableDestination) => {
    const [removed] = source.splice(droppableSource.index, 1);

    destination.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = source;
    result[droppableDestination.droppableId] = destination;

    return result;
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) return;

    if (
      ["APPROVED", "DISAPPROVED"].includes(source.droppableId) &&
      ["APPROVED", "DISAPPROVED"].includes(destination.droppableId)
    )
      return;

    if (source.droppableId !== destination.droppableId) {
      const leadChange = getList(source.droppableId)[source.index];

      dispatch(editLead(leadChange.id, { status: destination.droppableId }));

      move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
    }
  };

  const handleCloseModalNewLead = () => {
    dispatch(setModalNewLead(false));
  };

  const handleOpenModalNewLead = () => {
    dispatch(setModalNewLead(true));
  };

  const handleCloseModalFilterPipeline = () => {
    dispatch(setModalFilterPipeline(false));
  };

  const handleOpenModalFilterPipeline = () => {
    dispatch(setModalFilterPipeline(true));
  };

  const handleCloseModalArchiveLead = () => {
    dispatch(setModalArchiveLead(false));
  };

  const handleOpenModalArchiveLead = lead => {
    dispatch(setLead(lead));
    dispatch(setModalArchiveLead(true));
  };

  return (
    <div>
      <DivTop>
        <div>
          <h2>Nova empresa</h2>
          <h4>Busque uma nova empresa</h4>
        </div>
        <div>
          <ButtonLead onClick={() => handleOpenModalNewLead()}>
            Novo lead
          </ButtonLead>
        </div>
      </DivTop>
      <DivContent>
        <Grid>
          <Grid.Row verticalAlign="bottom">
            <Grid.Column width={5}>
              <InputSearch
                as={Form.Input}
                icon="search"
                label="Nome"
                onChange={e => setSearchEmpresa(e.target.value)}
              />
            </Grid.Column>
            <Grid.Column>
              <IconFilter onClick={() => handleOpenModalFilterPipeline()}>
                <FaFilter color="#F9B233" size={18} />
              </IconFilter>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <TitleLead>TOTAL DE LEADS: {leads.length}</TitleLead>
        <Grid>
          <Grid.Row>
            <Grid.Column width={9}>
              <TitleHeaderStep>ETAPAS</TitleHeaderStep>
              <ContentStep>
                <div>
                  <TitleLead fontSize="12px" margin="30px 0px">
                    LEAD
                  </TitleLead>
                  <CardHeaderStep>
                    <div>
                      <h6>Oportunidade de seguro</h6>
                      <h5>R$</h5>
                      <h1>
                        {moneyMask(
                          `${itemsLead.reduce((acc, { substituicaoEstudo }) => {
                            return acc + substituicaoEstudo;
                          }, 0) * 100}`
                        )}
                      </h1>
                    </div>
                    <h6>
                      Empresas
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginLeft: "20px"
                        }}
                      >
                        {itemsLead.length}
                      </span>
                    </h6>
                  </CardHeaderStep>
                </div>
                <div>
                  <TitleLead fontSize="12px" margin="30px 0px">
                    CONTATO FEITO
                  </TitleLead>
                  <CardHeaderStep>
                    <div>
                      <h6>Oportunidade de seguro</h6>
                      <h5>R$</h5>
                      <h1>
                        {moneyMask(
                          `${itemsContact.reduce(
                            (acc, { substituicaoEstudo }) => {
                              return acc + substituicaoEstudo;
                            },
                            0
                          ) * 100}`
                        )}
                      </h1>
                    </div>
                    <h6>
                      Empresas
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginLeft: "20px"
                        }}
                      >
                        {itemsContact.length}
                      </span>
                    </h6>
                  </CardHeaderStep>
                </div>
                <div>
                  <TitleLead fontSize="12px" margin="30px 0px">
                    PROPOSTA FEITA
                  </TitleLead>
                  <CardHeaderStep>
                    <div>
                      <h6>Oportunidade de seguro</h6>
                      <h5>R$</h5>
                      <h1>
                        {moneyMask(
                          `${itemsProposal.reduce(
                            (acc, { substituicaoEstudo }) => {
                              return acc + substituicaoEstudo;
                            },
                            0
                          ) * 100}`
                        )}
                      </h1>
                    </div>
                    <h6>
                      Empresas
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginLeft: "20px"
                        }}
                      >
                        {itemsProposal.length}
                      </span>
                    </h6>
                  </CardHeaderStep>
                </div>
              </ContentStep>
            </Grid.Column>
            <Grid.Column width={7}>
              <ContentResult
                style={{
                  borderBottom: "none"
                }}
              >
                <TitleHeaderResult>
                  <h1>RESULTADO</h1>
                  <div onClick={() => dispatch(push("/pipeline/arquivados"))}>
                    <VscListSelection
                      size={18}
                      color="#F9B233"
                      style={{
                        strokeWidth: 1
                      }}
                    />
                    <h2>ARQUIVADOS</h2>
                  </div>
                </TitleHeaderResult>
                <BodyResult>
                  <div>
                    <TitleLead fontSize="12px" margin="20px 0px">
                      NEGOCIAÇÕES NÃO APROVADAS
                    </TitleLead>
                    <CardHeaderResult bgColor="#E74C3C">
                      <div>
                        <h6>Oportunidade de seguro</h6>
                        <h5>R$</h5>
                        <h1>
                          {moneyMask(
                            `${itemsDisapproved.reduce(
                              (acc, { substituicaoEstudo }) => {
                                return acc + substituicaoEstudo;
                              },
                              0
                            ) * 100}`
                          )}
                        </h1>
                      </div>
                      <h6>
                        Leads
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginLeft: "20px"
                          }}
                        >
                          {itemsDisapproved.length}
                        </span>
                      </h6>
                    </CardHeaderResult>
                  </div>
                  <div>
                    <TitleLead fontSize="12px" margin="20px 0px">
                      NEGOCIAÇÕES APROVADAS
                    </TitleLead>
                    <CardHeaderResult bgColor="#2ECC71">
                      <div>
                        <h6>Oportunidade de seguro</h6>
                        <h5>R$</h5>
                        <h1>
                          {moneyMask(
                            `${itemsApproved.reduce(
                              (acc, { substituicaoEstudo }) => {
                                return acc + substituicaoEstudo;
                              },
                              0
                            ) * 100}`
                          )}
                        </h1>
                      </div>
                      <h6>
                        Leads
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginLeft: "20px"
                          }}
                        >
                          {itemsApproved.length}
                        </span>
                      </h6>
                    </CardHeaderResult>
                  </div>
                </BodyResult>
              </ContentResult>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid.Column width={9}>
                <ContentStep>
                  <Droppable droppableId="LEAD" className="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          width: "154px"
                        }}
                      >
                        {itemsLead.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <CardBodyStep
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {/* <div className="new">
                                                                Novo
                                                            </div> */}
                                <div>
                                  {item.valorInvestido > 0 ? (
                                    <FaRegFileAlt size={18} color="#F9B233" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h1>
                                  {item.grupo.nome}
                                  <br /> {item.nome}
                                </h1>
                                <h6>Oportunidade de substi.</h6>
                                <h5>
                                  {formatToBRL(`${item.substituicaoProposta}`)}
                                </h5>
                                <h6>Qtd. de processos</h6>
                                <h5>{item.quantidadeProcessos}</h5>
                                <h6>Valor investido</h6>
                                <h5>{formatToBRL(`${item.valorInvestido}`)}</h5>
                                <div
                                  className="details"
                                  onClick={() =>
                                    dispatch(
                                      push(`/pipeline/lead/detalhes/${item.id}`)
                                    )
                                  }
                                >
                                  Detalhes
                                  <BsArrowRightShort size={20} />
                                </div>
                              </CardBodyStep>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="CONTACT" className="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          width: "154px"
                        }}
                      >
                        {itemsContact.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <CardBodyStep
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div>
                                  {item.valorInvestido > 0 ? (
                                    <FaRegFileAlt size={18} color="#F9B233" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h1>
                                  {item.grupo.nome}
                                  <br /> {item.nome}
                                </h1>
                                <h6>Oportunidade de substi.</h6>
                                <h5>
                                  {formatToBRL(`${item.substituicaoProposta}`)}
                                </h5>
                                <h6>Qtd. de processos</h6>
                                <h5>{item.quantidadeProcessos}</h5>
                                <h6>Valor investido</h6>
                                <h5>{formatToBRL(`${item.valorInvestido}`)}</h5>
                                <div
                                  className="details"
                                  onClick={() =>
                                    dispatch(
                                      push(`/pipeline/lead/detalhes/${item.id}`)
                                    )
                                  }
                                >
                                  Detalhes
                                  <BsArrowRightShort size={20} />
                                </div>
                              </CardBodyStep>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="PROPOSAL" className="droppable">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          width: "154px"
                        }}
                      >
                        {itemsProposal.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={`${item.id}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <CardBodyStep
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div>
                                  {item.valorInvestido > 0 ? (
                                    <FaRegFileAlt size={18} color="#F9B233" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h1>
                                  {item.grupo.nome}
                                  <br /> {item.nome}
                                </h1>
                                <h6>Oportunidade de substi.</h6>
                                <h5>
                                  {formatToBRL(`${item.substituicaoProposta}`)}
                                </h5>
                                <h6>Qtd. de processos</h6>
                                <h5>{item.quantidadeProcessos}</h5>
                                <h6>Valor investido</h6>
                                <h5>{formatToBRL(`${item.valorInvestido}`)}</h5>
                                <div
                                  className="details"
                                  onClick={() =>
                                    dispatch(
                                      push(`/pipeline/lead/detalhes/${item.id}`)
                                    )
                                  }
                                >
                                  Detalhes
                                  <BsArrowRightShort size={20} />
                                </div>
                              </CardBodyStep>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </ContentStep>
              </Grid.Column>
              <Grid.Column width={7}>
                <ContentResult
                  style={{
                    borderTop: "none"
                  }}
                >
                  <BodyResult>
                    <Droppable droppableId="DISAPPROVED" className="droppable">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            width: "154px"
                          }}
                        >
                          {itemsDisapproved.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={`${item.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <CardBodyStep
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    style={{
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    {item.valorInvestido > 0 ? (
                                      <FaRegFileAlt size={18} color="#F9B233" />
                                    ) : (
                                      ""
                                    )}
                                    <VscListSelection
                                      size={18}
                                      color="#F9B233"
                                      onClick={() =>
                                        handleOpenModalArchiveLead(item)
                                      }
                                      style={{
                                        strokeWidth: 1
                                      }}
                                    />
                                  </div>
                                  <h1>
                                    {item.grupo.nome}
                                    <br /> {item.nome}
                                  </h1>
                                  <h6>Oportunidade de substi.</h6>
                                  <h5>
                                    {formatToBRL(
                                      `${item.substituicaoProposta}`
                                    )}
                                  </h5>
                                  <h6>Qtd. de processos</h6>
                                  <h5>{item.quantidadeProcessos}</h5>
                                  <h6>Valor investido</h6>
                                  <h5>
                                    {formatToBRL(`${item.valorInvestido}`)}
                                  </h5>
                                  <div
                                    className="details"
                                    onClick={() =>
                                      dispatch(
                                        push(
                                          `/pipeline/lead/detalhes/${item.id}`
                                        )
                                      )
                                    }
                                  >
                                    Detalhes
                                    <BsArrowRightShort size={20} />
                                  </div>
                                </CardBodyStep>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Droppable droppableId="APPROVED" className="droppable">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            width: "154px"
                          }}
                        >
                          {itemsApproved.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={`${item.id}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <CardBodyStep
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    style={{
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    {item.valorInvestido > 0 ? (
                                      <FaRegFileAlt size={18} color="#F9B233" />
                                    ) : (
                                      ""
                                    )}
                                    <VscListSelection
                                      size={18}
                                      color="#F9B233"
                                      onClick={() =>
                                        handleOpenModalArchiveLead(item)
                                      }
                                      style={{
                                        strokeWidth: 1
                                      }}
                                    />
                                  </div>
                                  <h1>
                                    {item.grupo.nome}
                                    <br /> {item.nome}
                                  </h1>
                                  <h6>Oportunidade de substi.</h6>
                                  <h5>
                                    {formatToBRL(
                                      `${item.substituicaoProposta}`
                                    )}
                                  </h5>
                                  <h6>Qtd. de processos</h6>
                                  <h5>{item.quantidadeProcessos}</h5>
                                  <h6>Valor investido</h6>
                                  <h5>
                                    {formatToBRL(`${item.valorInvestido}`)}
                                  </h5>
                                  <div
                                    className="details"
                                    onClick={() =>
                                      dispatch(
                                        push(
                                          `/pipeline/lead/detalhes/${item.id}`
                                        )
                                      )
                                    }
                                  >
                                    Detalhes
                                    <BsArrowRightShort size={20} />
                                  </div>
                                </CardBodyStep>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </BodyResult>
                </ContentResult>
              </Grid.Column>
            </DragDropContext>
          </Grid.Row>
        </Grid>
      </DivContent>
      <ModalArchiveLead onClose={handleCloseModalArchiveLead} />
      <ModalNewLead onClose={handleCloseModalNewLead} />
      <ModalFilterPipeline
        onClose={handleCloseModalFilterPipeline}
        handleChangeQuery={e => setQuery(e)}
      />
    </div>
  );
}

export default FasesComerciais;
