import styled from 'styled-components';
import arrowDownIcon from '../../../assets/images/arrowDownIcon.svg';

export const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const SelectStyled = styled.select`
  background: #FFFFFF;
  border: 1px solid ${(props) => props.border || '#EEEEEE'};
  box-sizing: border-box;
  border-radius: 2px;
  width: ${(props) => props.width || '318px'};
  height: 40px;
  padding: 10px 16px;
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${arrowDownIcon});
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  :disabled {
    background: #EEEEEE;
    border: 1px solid #BDBDBD;
    -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(${arrowDownIcon});
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
  }
`;

export const Label = styled.label`
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${(props) => props.color || '#424242'};
  margin-bottom: 4px;

  p{
    display: inline;
    color: red!important;
  }
`;
