import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';

export const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    background-color: #FFFFFF;
    color: #000000;
  }
`;

export const SuccessIcon = styled(CheckIcon)`
  color: #5ACA65;
`;

export const FailIcon = styled(SmsFailedIcon)`
  color: #C95A5A;
`;

export const DivContent = styled.div`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const DivContentText = styled.div`
  display: inline;
  h1{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #000000;
    margin: 0;
  }
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #616161;
    margin: 0;
  }
`;
