import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { push } from "connected-react-router";
import Navbar from "../../components/Navbar/index";
import {
  SectionTop,
  SectionStepper,
  SectionCards,
  SectionBottom,
  DivSendMail
} from "./style";
import StepperApolice from "../../components/Stepper/StepperApolice/index";
import CardDownloadPDF from "../../components/Card/CardDownloadPDF/index";
import CardDownloadImg from "../../components/Card/CardDownloadImg/index";
import ButtonDefault from "../../components/UI/ButtonDefault/index";
import { setRoute } from "../../actions/navbar";
import InputText from "../../components/UI/InputText/index";
import {
  sendMailPolicyDocs,
  setProposalData,
  setStatusEmitPolicy
} from "../../actions/policy";
import TitleForm from "../../components/UI/TitleForm/index";
import { useDebounce } from "../../services/hooks";
import { emailIsValid } from "../../services/mask";
import {
  setSnackbarErrorOpen,
  setSnackbarErrorContent
} from "../../actions/snackbar";
import { setLoadingCardDocument } from "../../actions/loading";
import { setValidCEP } from "../../actions/viacep";
import { setIdCompany } from "../../actions/company";
import { decodeToken, getToken } from "../../actions/auth";

function EmitirApolice() {
  const dispatch = useDispatch();
  const emitedPolicy = useSelector(state => state.policy.emitedPolicy);
  const allLoading = useSelector(state => state.loading);
  const route = useSelector(state => state.navbar.policyRoute);
  const [emails, setEmail] = useState([]);
  const [inputEmail, setInputEmail] = useState("");
  const debouncedInputEmail = useDebounce(inputEmail);
  const [assunto, setAssunto] = useState("");
  const statusEmitPolicy = useSelector(state => state.policy.statusEmitPolicy);
  const user = decodeToken(getToken());

  useEffect(() => {
    if (route !== "/emitir-apolice") {
      dispatch(push(route));
    }
  }, []);

  function sendMail() {
    const newEmails = [];
    emails.forEach(email => {
      if (emailIsValid(email.label)) {
        newEmails.push(email.label);
      }
    });
    dispatch(
      sendMailPolicyDocs({
        emails: newEmails,
        template: "policy",
        idPolicy: emitedPolicy.policy.id
      })
    );
  }

  function finalize() {
    try {
      if (!emitedPolicy.policy.id) {
        dispatch(
          setSnackbarErrorContent({
            title: "Falha ao enviar documentos",
            subtitle: "Aguarde os documentos serem emitidos"
          })
        );
        dispatch(setSnackbarErrorOpen(true));
      }

      // if (!assunto) {
      //   dispatch(setSnackbarErrorContent({
      //     title: 'Falha ao enviar documentos',
      //     subtitle: 'É necessário preencher o campo "Assunto"',
      //   }));
      //   dispatch(setSnackbarErrorOpen(true));
      // }

      if (emails.length < 1) {
        dispatch(
          setSnackbarErrorContent({
            title: "Falha ao enviar documentos",
            subtitle: 'É necessário preencher o campo "E-mails"'
          })
        );
        dispatch(setSnackbarErrorOpen(true));
      }

      if (emitedPolicy.policy.id && emails.length >= 1) {
        dispatch(push("/finalizar-apolice"));
        dispatch(setRoute("/finalizar-apolice"));
        sendMail();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleDelete = emailToDelete => () => {
    setEmail(chips => chips.filter(chip => chip.key !== emailToDelete.key));
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      const newEmail = {
        key: emails.length + 1,
        label: inputEmail
      };
      setEmail([...emails, newEmail]);
      setInputEmail("");
    }
  };

  useEffect(() => {
    if (emailIsValid(debouncedInputEmail)) {
      const newEmail = {
        key: emails.length + 1,
        label: debouncedInputEmail
      };
      setEmail([...emails, newEmail]);
      setInputEmail("");
    }
  }, [debouncedInputEmail]);

  function returnToTheForm() {
    dispatch(setLoadingCardDocument(true, "APOLICE"));
    dispatch(setLoadingCardDocument(true, "BOLETO"));
    dispatch(setLoadingCardDocument(true, "CERTIFICADO"));
    dispatch(setLoadingCardDocument(true, "INSURANCE"));
    dispatch(setValidCEP({}));
    if (user.perfil === "empresa") {
      dispatch(setIdCompany(user.idEmpresa));
    } else {
      dispatch(
        setIdCompany({
          id: "",
          tomadores: []
        })
      );
    }
    dispatch(setProposalData({}));
    dispatch(setRoute("/nova-apolice"));
    dispatch(push("/nova-apolice"));
    dispatch(setStatusEmitPolicy(null));
  }

  return (
    <>
      <Navbar pageSelected="cotacao" />
      <SectionTop>
        <h1>Documentos Emitidos</h1>
      </SectionTop>
      <SectionStepper>
        <StepperApolice activeStep={2} />
      </SectionStepper>
      <SectionCards>
        <CardDownloadPDF
          title="Apólice"
          pdf={emitedPolicy.documentos.policyBase64}
          loading={allLoading.LoadingCardDocumentApolice}
        />
        <CardDownloadPDF
          title="Boleto"
          pdf={emitedPolicy.documentos.billetBase64}
          loading={allLoading.LoadingCardDocumentBoleto}
        />
        <CardDownloadPDF
          title="Certificado da Apólice"
          loading={allLoading.LoadingCardDocumentCertificado}
          pdf={emitedPolicy.documentos.certificadoApoliceBase64}
        />
        <CardDownloadPDF
          title="Certificado da Seguradora"
          loading={allLoading.LoadingCardSusepInsurance}
          pdf={emitedPolicy.documentos.certificadoSeguradoraBase64}
        />
      </SectionCards>
      <SectionBottom>
        <div style={{ display: "grid", width: "100%", marginBottom: "40px" }}>
          <TitleForm className="title" width="100%" title="Envio" />
        </div>
        <DivSendMail>
          {/* <InputText */}
          {/*   label="Assunto" */}
          {/*   value={assunto} */}
          {/*   onChange={(e) => setAssunto(e.target.value)} */}
          {/* /> */}
          <div className="divPaper">
            <label className="label-emails">E-mails</label>
            <Paper>
              <InputText
                placeholder="exemplo@gmail.com"
                width="180px"
                onKeyPress={handleKeyPress}
                value={inputEmail}
                onChange={e => setInputEmail(e.target.value)}
              />
              {emails.map((email, index) => (
                <Chip
                  key={index}
                  id={index}
                  label={email.label}
                  onDelete={handleDelete(email)}
                />
              ))}
            </Paper>
          </div>
        </DivSendMail>
        {statusEmitPolicy === false && (
          <ButtonDefault
            customColor="#C95A5A"
            colorLabel="#FFFFFF"
            onClick={returnToTheForm}
          >
            Voltar para o formulário
          </ButtonDefault>
        )}
        {statusEmitPolicy === true && (
          <ButtonDefault
            customColor="#F9B233"
            colorLabel="#FFFFFF"
            onClick={finalize}
          >
            Finalizar e Enviar Documentação
          </ButtonDefault>
        )}
      </SectionBottom>
    </>
  );
}

export default EmitirApolice;
