import styled from 'styled-components';

export const SectionFilter = styled.div`
  display: flex;
  align-items: flex-end;
  margin: -40px 48px 0px;

  .ui.form .field label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
  }

  .ui.grid, .ui.search.dropdown {
    width: 100%;
  }

  i.icon {
    padding: 9px!important;
    color: #F9B233;
  }

`;

export const Label = styled.label`
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${(props) => props.color || '#424242'};
  margin-bottom: 4px;
`;

export const SectionCard = styled.div`
  margin: 30px 45px;
`;

export const Card = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.1);
  height: ${props => props.height};
`;

export const CardHeader = styled.div`
  width: 100%;
  background: #568CCC;
  border-radius: 8px 8px 0 0;
  min-height: 50px;
  display:flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', 'sans-serif';

  h1{
    font-family: 'Montserrat', 'sans-serif';
    color: #FFFFFF;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
  }

  .MuiSelect-select.MuiSelect-select, .MuiInput-underline:before {
    border: inherit;
    background: transparent;
    color: #fff;
    width: fit-content;
    margin: 0px 10px;
    padding-right: 15px;
  }
  .MuiSelect-icon {
    color: #fff;
  }
`;

export const DivContent = styled.div`
  padding: 20px 24px;
  margin: ${props => props.margin};

  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.15px;
    color: #185F9B;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #287D3C;
    margin-top: 0px;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: #616161;
    margin-bottom: 5px;
  }

  h4 {
    color: #2E5AAC;
    font-weight: 500;
    margin-top: 5px;
  }

  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #3498DB;
    margin-top: 5px;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    color: #2E5AAC;
    margin-top: 5px;
  }
`;


export const Separator = styled.div`
  width: 1px;
  height: 50px;
  background: #BDBDBD;
`

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  left: 24px;
  top: 340px;
  background: ${(props) => props.circleColor || '#00684F'} ;
  border-radius: 4px;
  display: inline-block;
`;

export const TextLegend = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #424242;
  margin: 0 0 0 8px;
  font-family:'Montserrat', 'sans-serif';
  font-feature-settings: 'pnum' on, 'lnum' on;
  letter-spacing: 0.16px;
  display: inline;
`;

export const TextValueLegend = styled.p`
  font-family:'Montserrat', 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.32px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.textColor || '#00684F'};
  margin: 0;
`;

export const DivDataChart = styled.div`
  padding: 3px 12px 12px 20px;
  justify-content: center;
  flex-wrap: wrap;
  width: 50%;
  word-break: keep-all;
  white-space: nowrap;
`;

export const LabelProgress = styled.div `
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #2C3E50;
  text-align:left;
`

export const TitleCard = styled.div`
  font-weight: 600;
  font-size: 45px;
  line-height: 48px;
  color: #24126A;
`

export const SubtitleCard = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #3498DB;
`