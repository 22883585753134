import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Email from '@material-ui/icons/Email';

export const StyledFormControl = withStyles({
  root: {
    width: '344px',
  },
})(FormControl);

export const StyledLabel = withStyles({
  root: {
    marginLeft: '59px',
    marginTop: '-8px',
    color: '#FFF',
  },
  shrink: {
    marginTop: '0',
  },
})(InputLabel);

export const StyledInput = withStyles({
  root: {
    paddingLeft: '59px',
    color: '#FFF',
  },
})(Input);

export const StyledIconEmail = withStyles({
  root: {
    // position: 'absolute',
    // padding: '10px 20px 0 20px',
    margin: '0 -30px -20px 0',
    color: '#FFF',
  },
})(Email);
