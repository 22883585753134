import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch, useSelector } from 'react-redux';
import LuxonUtils from '@date-io/luxon';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  ModalBody, TitleContent, FilterContent, Label,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault/index';
import Select from '../../UI/Select/index';
import {
  setQueryDashboardConset,
  getFilteredDashboardResume,
} from '../../../actions/dashboard';

import { decodeToken, getToken } from '../../../actions/auth';

function ModalFilterDashboard(props) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal.ModalFilterDashboard);
  const { onClose } = props;

  const user = decodeToken(getToken());

  const [seguradora, setSeguradora] = useState('');
  const [status, setStatus] = useState('');
  const [ultimos, setUltimos] = useState('');
  const [dataFinal, setDataFinal] = useState();
  const [dataInicio, setDataInicio] = useState();

  const seguradorasOptions = [
    {
      value: '',
      text: '-',
    },
    {
      value: 'Junto',
      text: 'Junto',
    },
    {
      value: 'Tokyo',
      text: 'Tokio',
    },
    {
      value: 'BMG',
      text: 'BMG',
    },
    {
      value: 'Pottencial',
      text: 'Pottencial',
    },
  ];

  const statusOptions = [
    {
      value: '',
      text: '-',
    },

    {
      value: 'Emitida',
      text: 'Ativa',
    },

    {
      value: 'Vencida',
      text: 'Vencida',
    },

    {
      value: 'Baixada',
      text: 'Baixada',
    },
    {
      value: 'A substituir',
      text: 'Renovada',
    },
    {
      value: 'Cancelada',
      text: 'Cancelada',
    },
  ];

  const ultimosOptions = [
    {
      value: '',
      text: '-',
    },
    {
      value: 120,
      text: '120 dias',
    },
    {
      value: 90,
      text: '90 dias',
    },
    {
      value: 65,
      text: '65 dias',
    },
    {
      value: 60,
      text: '60 dias',
    },
  ];

  function clearQuery() {
    setSeguradora('');
    setStatus('');
    setUltimos('');
    setDataFinal();
    setDataInicio();

    dispatch(
      setQueryDashboardConset({
        seguradora: '',
        status: '',
        ultimos: '',
        dataFinal: new Date(dataFinal).toLocaleDateString(),
        dataInicio: new Date(dataInicio).toLocaleDateString(),
        idEmpresa: user.idEmpresa ? user.idEmpresa.id : '',
      }),
    );
  }

  const applyFilter = () => {
    console.log(status);

    dispatch(
      setQueryDashboardConset({
        seguradora,
        status,
        ultimos,
        dataFinal: new Date(dataFinal).toLocaleDateString(),
        dataInicio: new Date(dataInicio).toLocaleDateString(),
        idEmpresa: user.idEmpresa ? user.idEmpresa.id : '',
      }),
    );
  };
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Filtros</h1>

          <ButtonDefault
            color="primary"
            customColor="#3498DB"
            onClick={applyFilter}
          >
            Pesquisar
          </ButtonDefault>
          <ButtonDefault variant="text" color="primary" onClick={clearQuery}>
            Limpar
          </ButtonDefault>
        </TitleContent>
        <FilterContent>
          <div className="select">
            <Select
              colorLabel="#185F9B"
              width="304px"
              label="Seguradora"
              valueOption={seguradorasOptions}
              valueSelect={seguradora}
              onChange={(e) => setSeguradora(e.target.value)}
            />
            <Select
              colorLabel="#185F9B"
              width="304px"
              label="Status da apólice"
              valueOption={statusOptions}
              valueSelect={status}
              onChange={(e) => setStatus(e.target.value)}
            />
          </div>
          <div className="dateSelect">
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <div className="inputDate">
                <Label color="#185F9B">De</Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  error={false}
                  id="date-picker-inline"
                  placeholder="DD/MM/YY"
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e)}
                />
              </div>
              <div className="inputDate">
                <Label color="#185F9B">Até</Label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YY"
                  margin="normal"
                  id="date-picker-inline"
                  error={false}
                  value={dataFinal}
                  onChange={(e) => setDataFinal(e)}
                />
              </div>
              <Select
                colorLabel="#185F9B"
                width="204px"
                label="Últimos"
                valueOption={ultimosOptions}
                valueSelect={ultimos}
                onChange={(e) => setUltimos(e.target.value)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </FilterContent>
      </ModalBody>
    </Modal>
  );
}

export default ModalFilterDashboard;
