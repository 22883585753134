import styled from 'styled-components';

export const ModalBody = styled.div `
  background-color: #FFFFFF;
  width: 640px;
  padding-bottom: 20px;
  margin: auto;
  margin-top: 10%;
`;

export const TitleContent = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 40px 40px 0px;
  h1{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #000000;
  }
`;

export const Text = styled.div `
  height: 240px;
  overflow-y: scroll;
`

export const Content = styled.div `
  margin: 0px 40px;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #00235C;
    letter-spacing: 0.1px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }

  .ui.form .field {
    label {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;


export const Row = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  
  .field {
    width: 290px!important;
  }
`
