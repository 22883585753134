/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import { ModalBody, TitleContent, Content, Text } from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { editLeadProcess } from "../../../actions/pipeline";
import { useParams } from "react-router-dom";

function ModalProcessSentence(props) {
  const { onClose, process, pageProcess } = props;
  const open = useSelector(state => state.modal.ModalProcessSentence);
  const { idLead } = useParams();
  const dispatch = useDispatch();
  const sentencas = process.sentencas || [{ data: "", descricao: "" }];

  const [tipoGarantia] = useState("");

  const sendForm = () => {
    const data = {
      tipoGarantia
    };
    onClose();
    dispatch(editLeadProcess(process.id, data, idLead, pageProcess));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Sentenças</h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <Text>
            {sentencas[0] ? (
              <p>
                <b>Ultima sentença: </b>
                {sentencas[0].data} - {sentencas[0].descricao}
              </p>
            ) : (
              ""
            )}

            {sentencas.slice(1).map(sentence => (
              <p>
                {sentence.data} - {sentence.descricao}
              </p>
            ))}
          </Text>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalProcessSentence;

ModalProcessSentence.propType = {
  onClose: PropType.func
};
