import styled from 'styled-components';

export const SectionFilter = styled.div`
  display: flex;
  margin: 0 48px;
  .select {
    margin-right: 24px;
  }
  .MuiFormControl-root {
    width: 204px;
    margin: 0;
    margin-right: 24px;
  }
  .MuiInputBase-root {
    height: 40px;
  }
  .MuiIconButton-root {
    color: #F9B233;
  }
  .inputDate {
    display: flex;
    flex-direction: column;
  }
`;

export const Label = styled.label`
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${(props) => props.color || '#424242'};
  margin-bottom: 4px;
`;

export const SectionCard = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 65px;
`;
