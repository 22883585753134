import styled from 'styled-components';

export const DivCard = styled.div`
  padding: 24px;
  width: 49.5%;
  height: 200px;
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: 'Montserrat', 'sans-serif';

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #24126A;
    margin: 0;
    width: 100%;
  }

  h2{
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #616161;
    margin: 0;
  }
`;

export const DivContentCenter = styled.div`
  h2 {
    margin-bottom: 18px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #00366C;
  }
`;

export const DivContentEnd = styled.div`
  h2 {
    margin-bottom: 8px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #00366C;
    margin-bottom: 24px;
  }
`;
