import styled from 'styled-components';

export const HeaderPage = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #0C0142;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
`

export const Content = styled.div `
    padding: 0px 60px;
`

export const CardInfo = styled.div `
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    height: ${props => props.height || '200px'};

    .ver-grupo {
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #00366C;
    }
`

export const TitleCard = styled.div `
    font-weight: 500;
    font-size: ${props => props.fontSize || '12px'};
    color: ${props => props.color || '#9E9E9E'};
    display: flex;
    justify-content: ${props => props.justifyContent || 'space-between'};
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    margin-left: auto;
    margin-right: auto;
    width: ${props => props.width};
`

export const LegendCard = styled.div`
    margin-top: ${({marginTop}) => marginTop};
    margin-bottom: 8px;
    font-weight: ${props => props.weight || '500'};
    font-size: ${props => props.fontSize || '12px'};
    color: ${props => props.color || '#9E9E9E'};
`

export const ValueLegendCard = styled.div`
    font-size: ${props => props.fontSize || '14px'};
    color: ${props => props.color || '#00366C'};
    font-weight: ${props => props.weight || '500'};
    margin: ${({margin}) => margin};
`

export const ButtonDefault = styled.button`
    width: 139px;
    height: 40px;
    background: #F9B233;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    margin-top: ${props => props.marginTop || '40px'};
`

export const ButtonBorder = styled.button`
    height: 40px;
    background: #fff;
    border: 1px solid #185F9B;
    border-radius: 2px;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #185F9B;
    padding: 7px 10px;
    margin-left: 8px;
`

export const CardLoading = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 432px;
    height: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    margin: 40px auto;

    h1 {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: #000000;
    }

    p {
        width: 220px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #616161;
    }
`

export const FooterBudget = styled.div`
    width: 100%;
    left: 0;
    bottom: 0;
    position: fixed;
    background: #fff;
    box-shadow: 4px -9px 20px #d1d1d1;
    font-weight: 500;
    font-family: Montserrat;
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
`
