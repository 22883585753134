export function telMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
}

export function cpfMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function cepMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
}

export function cnpjMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function numberMask(value) {
  return `${value}`.replace(/\D/g, "");
}

export function moneyMask(value) {
  let v = value.replace(/\D/g, "");
  v = `${(v / 100).toFixed(2)}`;
  return v
    .replace(".", ",")
    .replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
    .replace(/(\d)(\d{3}),/g, "$1.$2,");
}

export function percentMask(value) {
  let v = value.replace(/\D/g, "");
  v = `${(v / 100).toFixed(2)}`;
  return (
    v
      .replace(".", ",")
      .replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
      .replace(/(\d)(\d{3}),/g, "$1.$2,") + "%"
  );
}

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function formatToNumber(number) {
  return parseFloat(
    number
      .replace("R$", "")
      .replace(/\./g, "")
      .replace(",", ".")
  );
}

export function processNumberMask(value) {
  return value
    .replace(/\D/g, "")
    .replace(
      /(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})\d+?$/,
      "$1-$2.$3.$4.$5"
    );
}
