import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid } from "semantic-ui-react";
import { FiChevronLeft } from "react-icons/fi";

import Navbar from "../../components/Navbar";
import {
  TitleContent,
  Content,
  CellHeader,
  Cell,
  Table,
  TableScroll,
  SectionTable,
  ButtonBorder,
  ButtonSend
} from "./style";

import history from "../../routes/history";

import { calculateLimit, updateCompany } from "../../actions/company";
import { useParams } from "react-router-dom";
import { MenuItem, TextField } from "@material-ui/core";
import {
  setSnackbarErrorContent,
  setSnackbarErrorOpen
} from "../../actions/snackbar";

function EditarSeguradoras() {
  const dispatch = useDispatch();

  const empresa = useSelector(state => state.company.company);

  const { idEmpresa } = useParams();

  const [seguradoras, setSeguradoras] = useState([]);
  const [seguradoraSelecionada, setSeguradoraSelecionada] = useState({});

  const seguradorasOptions = [
    {
      text: "Tokyo Marine",
      value: "Tokyo"
    },
    {
      text: "BMG",
      value: "BMG"
    },
    {
      text: "Junto",
      value: "Junto"
    }
  ];

  const handleChangeData = async (situacaoCCG, validade, seguradoraOld) => {
    const seguradorasNew = seguradoras;

    seguradorasNew.forEach(seguradora => {
      if ((seguradora || {}).seguradora === seguradoraOld.seguradora) {
        seguradora.situacaoCCG = situacaoCCG;
        seguradora.validade = validade;
      }
    });

    setSeguradoras(seguradorasNew);
    setSeguradoraSelecionada({});
  };

  const handleChangeSeguradora = async event => {
    const limit = await dispatch(
      calculateLimit({
        insurer: event.target.value,
        cnpj: empresa.cnpj
          .replace(/\./g, "")
          .replace("/", "")
          .replace("-", "")
      })
    );

    if (limit && !limit.message) {
      const seguradora = {
        seguradora: event.target.value,
        limiteAprovado: limit.limiteAprovado || 0,
        limiteTomado: limit.limiteTomado || 0,
        limiteDisponivel: limit.limiteDisponivel || 0,
        reserva: limit.reserva || 0,
        taxa: limit.taxa || 0,
        situacaoCCG: limit.situacaoCCG || "",
        validade: limit.validade || new Date().toLocaleDateString()
      };

      const seguradorasNew = seguradoras;
      seguradorasNew.push(seguradora);
      setSeguradoras(seguradorasNew);
      setSeguradoraSelecionada(seguradora);
    } else {
      dispatch(
        setSnackbarErrorContent({
          title: "Seguradora",
          subtitle: "Não foi possível consultar os limites desta seguradora"
        })
      );
      dispatch(setSnackbarErrorOpen(true));
    }
  };

  const sendForm = async () => {
    const bmgNew = seguradoras.filter(
      ({ seguradora }) => seguradora.toLowerCase() === "bmg"
    );
    const tokyoNew = seguradoras.filter(
      ({ seguradora }) => seguradora.toLowerCase() === "tokyo"
    );
    const juntoNew = seguradoras.filter(
      ({ seguradora }) => seguradora.toLowerCase() === "junto"
    );

    let newCompany = {
      ...empresa
    };

    if (bmgNew[0])
      newCompany = {
        ...newCompany,
        bmg: {
          reserva: bmgNew[0].reserva,
          situacaoCCG: bmgNew[0].situacaoCCG,
          validade: bmgNew[0].validade
        }
      };

    if (tokyoNew[0])
      newCompany = {
        ...newCompany,
        tokyo: {
          reserva: tokyoNew[0].reserva,
          situacaoCCG: tokyoNew[0].situacaoCCG,
          validade: tokyoNew[0].validade
        }
      };

    if (juntoNew[0])
      newCompany = {
        ...newCompany,
        junto: {
          reserva: juntoNew[0].reserva,
          situacaoVVG: juntoNew[0].situacaoCCG,
          validade: juntoNew[0].validade
        }
      };
    dispatch(updateCompany(idEmpresa, newCompany));

    history.push("/empresa/detalhes/" + idEmpresa);
  };

  return (
    <div>
      <Navbar pageSelected="configuracoes" />

      <TitleContent>
        <FiChevronLeft
          size={35}
          color="#568CCC"
          onClick={() => history.push("/empresa/detalhes/" + idEmpresa)}
        />
        <h1>Edição Seguradoras</h1>
      </TitleContent>
      <Content>
        <Form
          onSubmit={() => sendForm()}
          style={{
            paddingTop: "35px"
          }}
        >
          <SectionTable>
            <Table
              style={{
                overflowX: "scroll"
              }}
            >
              <TableScroll columns="8">
                <CellHeader fontWeight="500">Seguradora</CellHeader>
                <CellHeader fontWeight="500">Limite aprovado</CellHeader>
                <CellHeader fontWeight="500">Limite tomado</CellHeader>
                <CellHeader fontWeight="500">Limite disponível</CellHeader>
                <CellHeader fontWeight="500">Reserva</CellHeader>
                <CellHeader fontWeight="500">Taxa</CellHeader>
                <CellHeader fontWeight="500" width="300px">
                  Situação CCG
                </CellHeader>
                <CellHeader fontWeight="500">Validade do cadastro</CellHeader>
                {seguradoras.map((seguradora, key) => (
                  <>
                    <Fragment key={key}>
                      <Cell fontWeight="600">{seguradora.seguradora}</Cell>
                      <Cell fontWeight="600">
                        R$ {seguradora.limiteAprovado}
                      </Cell>
                      <Cell fontWeight="600">R$ {seguradora.limiteTomado}</Cell>
                      <Cell fontWeight="600">
                        R$ {seguradora.limiteDisponivel}
                      </Cell>
                      <Cell fontWeight="600">R$ {seguradora.reserva}</Cell>
                      <Cell fontWeight="600">{seguradora.taxa}%</Cell>
                      <Cell fontWeight="600">
                        <TextField
                          value={seguradora.situacaoCCG}
                          onChange={e =>
                            handleChangeData(
                              e.target.value,
                              seguradora.validade,
                              seguradora
                            )
                          }
                        />
                      </Cell>
                      <Cell fontWeight="600">
                        <TextField
                          type="date"
                          value={seguradora.validade}
                          onChange={e =>
                            handleChangeData(
                              seguradora.situacaoCCG,
                              e.target.value,
                              seguradora
                            )
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Cell>
                    </Fragment>

                    <Fragment key={key}>
                      <Cell fontWeight="400">Judicial Recursal</Cell>
                      <Cell fontWeight="400">
                        R$ {seguradora.limiteAprovado}
                      </Cell>
                      <Cell fontWeight="400">R$ {seguradora.limiteTomado}</Cell>
                      <Cell fontWeight="400">
                        R$ {seguradora.limiteDisponivel}
                      </Cell>
                      <Cell fontWeight="400">R$ {seguradora.reserva}</Cell>
                      <Cell fontWeight="400">{seguradora.taxa}%</Cell>
                      <Cell fontWeight="400">{seguradora.situacaoCCG}</Cell>
                      <Cell fontWeight="400">
                        {new Date(
                          seguradoraSelecionada.validade || Date.now()
                        ).toLocaleDateString()}
                      </Cell>
                    </Fragment>
                  </>
                ))}
                <Fragment>
                  <Cell>
                    <TextField
                      select
                      style={{
                        width: "200px"
                      }}
                      label="Nova Seguradora"
                      onChange={handleChangeSeguradora}
                    >
                      {seguradorasOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.limiteAprovado}
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.limiteTomado}
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.limiteDisponivel}
                  </Cell>
                  <Cell fontWeight="bold">
                    R$ {seguradoraSelecionada.reserva}
                  </Cell>
                  <Cell width="80px" fontWeight="bold">
                    {seguradoraSelecionada.taxa}%
                  </Cell>
                  <Cell></Cell>
                  <Cell>
                    {new Date(
                      seguradoraSelecionada.validade || Date.now()
                    ).toLocaleDateString()}
                  </Cell>
                </Fragment>
              </TableScroll>
            </Table>
          </SectionTable>

          <Grid>
            <Grid.Row textAlign="right">
              <Grid.Column width={16}>
                <ButtonBorder onClick={() => history.push("/configuracoes")}>
                  CANCELAR
                </ButtonBorder>
                <ButtonSend type="submit">FINALIZAR</ButtonSend>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Content>
    </div>
  );
}

export default EditarSeguradoras;
