import styled from 'styled-components';
import Input from '../../components/UI/InputText';

export const Content = styled.div`
  margin: 0px 50px 70px;
`
export const SectionSteps = styled.div`
  display: flex;
  margin: 50px 0px 0;
  justify-content: center;
`;

export const TitleStep = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: ${props => props.size || '14px'};
  letter-spacing: 0.16px;
  color: ${props => props.color || '#2E5AAC'};
  margin-bottom: 5px;
`

export const DateTimeText = styled.div`
  font-family: Montserrat;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.32px;
  color: #424242;
`

export const ButtonCard = styled.div`
  padding: 5px;
  background: #568CCC;
  border-radius: 2px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #FFFFFF;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

export const TextCard = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #212121;
`

export const CardStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 268px;
  height: 239px;
  background: #fff;
  padding: 15px;
  border-bottom: ${props => props.borderBottom || '10px solid #EEEEEE'};

  .ui.grid {
    margin: 0px!important;
  }
`

export const SectionCards = styled.div`
  display: flex;
  margin: 50px 0px 0;
  justify-content: space-around;
`;

export const SectionTable = styled.div`
  margin: 0 48px 0 48px;
`;

export const SectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  margin-top: -38px;
  align-items: center;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #0C142;
    margin: 0;
    margin-left: 15px;
  }
`;

export const DivStatus = styled.div`
  background: ${props => props.vencida ? '#FEEFEF' : '#EDF9F0'};
  border: 1px solid ${props => props.vencida ? '#F48989' : '#5ACA65'};
  border-radius: 8px;
  width: 316px;
  padding: 10px;
  margin: 50px 0 80px;
  display: flex;
`

export const TextStatus = styled.div`
  font-family: Montserrat;
  font-weight: ${props => props.weight || '500'};
  font-size: ${props => props.size || '14px'};
  color: ${props => props.color || props.vencida ? '#F48989' : '#5ACA65'};
  margin-right: 15px;
`

export const SectionProcessDetails = styled.div`
  display: flex;
  margin: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 30px 40px 100px 40px;
  flex-wrap: wrap;

  h1{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #00235C;
    width: 100%;
  }
`;

export const DetailsDiv = styled.div`
  width: 100%;
  padding: 0px 50px;
  margin-top: 50px;
  background: #fff;
  border-radius: 8px;
`;

export const ButtonBorder = styled.button`
  height: 40px;
  background: #fff;
  border: 1px solid #185F9B;
  border-radius: 2px;
  font-weight: 600;
  font-family: Montserrat;
  font-size: 12px;
  text-transform: uppercase;
  color: #185F9B;
  padding: 7px 10px;
  margin-left: 8px;
  font-weight: bold;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: ${props => props.size || '20px'};
  color: #24126A;
  letter-spacing: 0.5px;
  margin: 50px 0px;
  display:flex;
  justify-content: space-between;
  h5 {
    font-weight: 500;
    font-size: 14px;  
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #24126A;
  }
`

export const TitleDetails = styled(Title)`
  font-size: 12px;
  letter-spacing: 0.1px;
  margin: 50px 0px 20px;
`

export const Row = styled.div`
  display: flex;
`

export const InputText = styled(Input)`
  margin-right: 20px;
  margin-bottom: 20px;
`