import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 750px;
  height: 500px;
  margin:  auto;
  padding: 40px;
  margin-top: 5%;
  overflow-y: scroll;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div`

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
    padding:40px 0px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }
  .ui.selection.dropdown {
    min-width:0px;
  }
`;

export const BtnFinish = styled.button`
  width: 120px;
  height: 35px;
  box-shadow: inset -1px 0px 0px rgba(0,0,0, 0.25);
  border: none;
  font-size: 14px;
  margin-top: 36px;
  background: #00366C;
  color:white;
  font-weight: 400;
  font-family:'Montserrat';
  &:hover {
    cursor: pointer;
  }

`;

export const BtnCancel = styled(BtnFinish)`
  border:1px solid #00366C;
  color: #00366C;
  background: white;
  font-weight: 400;
  margin-right: 10px;
`;
