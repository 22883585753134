import {Grid} from 'semantic-ui-react';
/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import CardHeaderStyled from '../../UI/CardHeaderStyled';
import {StyledCard, StyledCardContent, TextTotalValue, TextLegend, TextValueLegend} from './style';
import { useSelector } from 'react-redux';

function CardProcessActive(props) {
    const processosAtivos = useSelector(state => state.pipeline.dashboard.processosAtivos);

    return (
        <StyledCard>
            <CardHeaderStyled title="Processos ativos"/>
            <StyledCardContent>
                <TextTotalValue>
                    {processosAtivos.total}
                </TextTotalValue>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={9}>
                            {processosAtivos
                                .garantia
                                .map(({nome}) => (
                                    <TextLegend>{nome}</TextLegend>
                                ))}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {processosAtivos
                                .garantia
                                .map(({quantidade}) => (
                                    <TextValueLegend>{quantidade}</TextValueLegend>
                                ))}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            {processosAtivos
                                .garantia
                                .map(({porcentagem}) => (
                                    <TextLegend>{porcentagem}</TextLegend>
                                ))}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </StyledCardContent>
        </StyledCard>
    );
}

export default CardProcessActive;