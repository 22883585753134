import styled from 'styled-components';

export const ModalBody = styled.div`
  background-color: #FFFFFF;
  width: 550px;
  height: 500px;
  margin:  auto;
  padding: 40px;
  margin-top: 5%;
  overflow-y: scroll;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;

export const Content = styled.div`

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
    padding:40px 0px;
  }

  h2{
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #616161;
  }
  .ui.selection.dropdown {
    min-width:0px;
  }
`;
export const BtnFinish = styled.button `
  font-family: Montserrat;
  border: none;
  background: #F9B233;
  color:white;
  font-weight: 500;
  border-radius:2px;
  text-transform: uppercase;
  font-size: 14px;
  margin: 30px 10px 0px;
  padding: 13px 20px;
  &:hover {
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`
export const BtnCancel = styled(BtnFinish)`
  border: 1px solid #185F9B;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius || '2px'};
  background: white;
  color: #185F9B;
`