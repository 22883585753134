import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropType from "prop-types";

import Modal from "@material-ui/core/Modal";
import { Form, Grid } from "semantic-ui-react";

import {
  ModalBody,
  TitleContent,
  Content,
  BtnFinish,
  BtnCancel
} from "./style";

import { telMask } from "../../../services/mask";

import {
  setModalCreateMember,
  setModalEditMember
} from "../../../actions/modal";
import {
  createCompanyUser,
  updateCompanyUser
} from "../../../actions/companyUser";
import { useParams } from "react-router-dom";

function ModalCompanyUser(props) {
  const dispatch = useDispatch();

  const { onClose, labelBtnFinish, title, open, action, empresa } = props;

  const { idEmpresa } = useParams();

  const member = useSelector(state => state.companyUser.user);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [telefone, setTelefone] = useState("");
  const [tipo, setTipo] = useState("");
  const [permissoes, setPermissoes] = useState([]);
  const [selectTomadores, setselectTomadores] = useState([]);

  const listSelectTomadores = empresa
    ? empresa.tomadores.map(tomador => {
        return {
          text: tomador.nome,
          value: tomador.cnpj
        };
      })
    : [];

  useEffect(() => {
    setNome(member.nome);
    setEmail(member.email);
    setTelefone(member.telefone);
    setTipo(member.tipo);
    setPermissoes(member.permissoes);
    setselectTomadores(listSelectTomadores);
  }, [dispatch, member]);

  const tipos = [
    {
      value: "Interno",
      text: "Interno"
    },
    {
      value: "Externo",
      text: "Externo"
    }
  ];

  const permissoesOp = [
    {
      value: "Gerar Apólice",
      text: "Gerar Apólice"
    },
    {
      value: "Acompanhamento Jurídico",
      text: "Acompanhamento Jurídico"
    }
  ];

  const handleChangeTipo = (event, { value }) => {
    setTipo(value);
  };

  const handleChangePermissoes = (event, { value }) => {
    setPermissoes(value);
  };

  const [selectedTomadores, setSelectedTomadores] = useState([]);
  const handleChangeTomadores = (event, { value }) => {
    setSelectedTomadores(value);
  };

  const sendForm = async () => {
    const newTomadores = [];
    selectedTomadores.forEach(tomador => {
      empresa.tomadores.forEach(tomadorObj => {
        if (tomadorObj.cnpj === tomador) {
          newTomadores.push(tomadorObj);
        }
      });
    });

    const memberChange = {
      nome,
      email,
      telefone,
      tipo,
      idEmpresa,
      senha,
      tomadores: newTomadores,
      permissoes: `{${permissoes.reduce(
        (acc, permissao, i) =>
          acc + permissao + (i !== permissoes.length - 1 ? "," : " "),
        " "
      )}}`
    };

    if (action === "edit") {
      delete memberChange.senha;
      dispatch(updateCompanyUser(member.id, memberChange, idEmpresa));
      dispatch(setModalEditMember(false));
    }
    if (action === "create") {
      dispatch(createCompanyUser(memberChange));
      dispatch(setModalCreateMember(false));
    }
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalBody>
          <TitleContent>
            <h1> {title} </h1>
          </TitleContent>
          <Content>
            <Form onSubmit={() => sendForm()}>
              <Form.Input
                required
                label="Nome"
                onChange={e => setNome(e.target.value)}
                defaultValue={member.nome}
                placeholder="Nome"
                width={16}
              />
              <Form.Input
                label="E-mail"
                placeholder="E-mail"
                type="email"
                defaultValue={member.email}
                onChange={e => setEmail(e.target.value)}
                width={16}
                required
              />
              {action === "create" ? (
                <Form.Input
                  label="Senha"
                  placeholder="Senha"
                  type="password"
                  onChange={e => setSenha(e.target.value)}
                  width={16}
                  required
                />
              ) : (
                ""
              )}
              <Form.Input
                label="Telefone"
                placeholder="Telefone"
                width={16}
                defaultValue={member.telefone}
                value={telefone}
                onChange={e => setTelefone(telMask(e.target.value))}
                required
              />
              <Form.Select
                label="Tipo"
                placeholder="Tipo"
                width={16}
                onChange={handleChangeTipo.bind(this)}
                defaultValue={member.tipo}
                options={tipos}
                required
              />
              <Form.Select
                label="Permissão"
                placeholder="Permissão"
                width={16}
                options={permissoesOp}
                multiple
                onChange={handleChangePermissoes.bind()}
                defaultValue={member.permissoes}
                required
              />
              <Form.Select
                label="Tomador"
                placeholder="Tomador"
                width={16}
                options={listSelectTomadores}
                multiple
                onChange={handleChangeTomadores.bind()}
                required
              />
              <Grid>
                <Grid.Row textAlign="right">
                  <Grid.Column width={16}>
                    <BtnCancel className="btn-cancel"> CANCELAR </BtnCancel>
                    <BtnFinish type="submit"> {labelBtnFinish} </BtnFinish>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Content>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalCompanyUser;

ModalCompanyUser.propType = {
  onClose: PropType.func
};
