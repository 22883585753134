export const initialState = {
  DialogNewMassiveProcess: false
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
     case 'SET_DIALOG_NEW_MASSIVE_PROCESS':
       return { ...state, DialogNewMassiveProcess: action.payload.trigger };
    default:
      return state;
  }
};

export default dialog;
