import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropType from "prop-types";

import Modal from "@material-ui/core/Modal";

import { ModalBody, TitleContent, ButtonDefault } from "./style";

import { Grid } from "semantic-ui-react";
import { setModalBudget } from "../../../actions/modal";

function ModalUpdateProcessesLead(props) {
  const { onClose, action } = props;
  const dispatch = useDispatch();
  const open = useSelector(state => state.modal.ModalUpdateProcessesLead);
  const lead = useSelector(state => state.pipeline.lead);

  const realizeSearchProcesses = () => {
    onClose();
    action("FULL_SCAN");
  };

  const realizeFullScan = () => {
    onClose();
    action("SEARCH_PROCESS");
  };

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalBody>
          <TitleContent>
            <h1>Atualização</h1>
          </TitleContent>
          <h1>
            Deseja atualizar os processos buscados ou iniciar uma nova busca?
          </h1>
          <Grid>
            <Grid.Row verticalAlign="bottom">
              <ButtonDefault onClick={() => realizeFullScan()}>
                Atualizar
              </ButtonDefault>
              <ButtonDefault onClick={() => realizeSearchProcesses()}>
                Nova busca
              </ButtonDefault>
            </Grid.Row>
          </Grid>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ModalUpdateProcessesLead;

ModalUpdateProcessesLead.propType = {
  onClose: PropType.func
};
