import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  CardHeader,
  CardContent,
  FormOfPayment,
  ButtonSelect,
  CardContentLoading,
  CardContentError,
} from './style';
import { formatToBRL } from 'brazilian-values';

function CardPolicyProposal(props) {
  const {
    title,
    width,
    formOfPayment,
    selected,
    premium,
    onClick,
    buttonName,
    loading,
    error,
    errorTitle,
    errorText,
    limiteAprovado,
    limiteDisponivel,
    limiteTomado,
  } = props;

  return (
    <Card width={width}>
      <CardHeader>
        <h1>{title}</h1>
      </CardHeader>
      {loading ? (
        <CardContentLoading>
          <CircularProgress />
        </CardContentLoading>
      ) : !error ? (
        <CardContent>
          <h2>Limite Aprovado</h2>
          <p className="smallBlue">{formatToBRL(limiteAprovado)}</p>
          <h2>Limite Disponível</h2>
          <p className="smallGreen">{formatToBRL(limiteDisponivel)}</p>
          <h2>Limite tomado</h2>
          <p className="smallBlue">{formatToBRL(limiteTomado)}</p>
          <h2>Prêmio</h2>
          <p className="mediumBlue">{formatToBRL(premium)}</p>
          <h2>Formas de pagamento disponíveis</h2>
          <div>
            {formOfPayment.map((item) => (
              <FormOfPayment key={item}>{item.toLowerCase()}</FormOfPayment>
            ))}
          </div>
          <div className="divButton">
            <ButtonSelect
              color={selected ? '#00366C' : '#FFFFFF'}
              textcolor={selected ? '#FFFFFF' : '#00366C'}
              border={selected ? '1px solid #00366C' : '1px solid #00366C'}
              onClick={onClick}
              name={buttonName}
            >
              {selected ? 'Selecionado' : 'Selecionar'}
            </ButtonSelect>
          </div>
        </CardContent>
      ) : (
        <CardContentError>
          <h2 className="errorTitle">{errorTitle}</h2>
          <p className="errorText">{errorText}</p>
        </CardContentError>
      )}
    </Card>
  );
}

export default CardPolicyProposal;

CardPolicyProposal.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  formOfPayment: PropTypes.array,
  selected: PropTypes.bool,
  premium: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  onClick: PropTypes.any,
  buttonName: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorTitle: PropTypes.string,
  errorText: PropTypes.string,
  limiteAprovado: PropTypes.number,
  limiteDisponivel: PropTypes.number,
  limiteTomado: PropTypes.number,
};

CardPolicyProposal.defaultProps = {
  title: 'Nome Seguradora',
  width: '300px',
  formOfPayment: [],
  selected: false,
  premium: 0,
  onClick: () => {},
  buttonName: '',
  loading: false,
  error: false,
  errorTitle: '',
  errorText: '',
  limiteAprovado: 0,
  limiteDisponivel: 0,
  limiteTomado: 0,
};
