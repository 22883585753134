import styled from "styled-components";

export const ButtonDefault = styled.button`
  width: 175px;
  height: 40px;
  background: #f9b233;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  margin: 20px auto;
`;

export const ModalBody = styled.div`
  background-color: #ffffff;
  width: 470px;
  margin: auto;
  padding: 40px;
  margin-top: 12%;

  h1 {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
    margin: 40px 0px 20px;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin: 0;
  }
`;
