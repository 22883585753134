export const initialState = {
  LoadingCardProposalTokio: true,
  LoadingCardProposalJunto: true,
  LoadingCardProposalBMG: true,
  LoadingCardProposalPottencial: true,
  LoadingCardDocumentApolice: true,
  LoadingCardDocumentBoleto: true,
  LoadingCardDocumentFatura: true,
  LoadingCardDocumentCertificado: true,
  LoadingCardSusepInsurance: true,
  LoadingEmittingPolicy: false,
  LoadingAllDashboard: true,
  LoadingResumeDashboard: true,
  LoadingModalLimits: true,
  LoadingAccompanimentProcess: true,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_CARD_PROPOSAL_TOKIO':
      return {
        ...state, LoadingCardProposalTokio: action.payload.trigger,
      };
    case 'SET_LOADING_CARD_PROPOSAL_JUNTO':
      return {
        ...state, LoadingCardProposalJunto: action.payload.trigger,
      };
    case 'SET_LOADING_CARD_DOCUMENT_APOLICE':
      return {
        ...state, LoadingCardDocumentApolice: action.payload.trigger,
      };
    case 'SET_LOADING_CARD_DOCUMENT_BOLETO':
      return {
        ...state, LoadingCardDocumentBoleto: action.payload.trigger,
      };
    case 'SET_LOADING_CARD_DOCUMENT_FATURA':
      return {
        ...state, LoadingCardDocumentFatura: action.payload.trigger,
      };
    case 'SET_LOADING_CARD_DOCUMENT_CERTIFICADO':
      return {
        ...state, LoadingCardDocumentCertificado: action.payload.trigger,
      };
    case 'SET_LOADING_EMITING_POLICY':
      return {
        ...state, LoadingEmittingPolicy: action.payload.trigger,
      };
    case 'SET_LOADING_ALL_DASHBOARD':
      return { ...state, LoadingAllDashboard: action.payload.trigger };
    case 'SET_LOADING_RESUME_DASHBOARD':
      return { ...state, LoadingResumeDashboard: action.payload.trigger };
    case 'SET_LOADING_CARD_PROPOSAL_BMG':
      return { ...state, LoadingCardProposalBMG: action.payload.trigger };
    case 'SET_LOADING_CARD_DOCUMENT_INSURANCE':
      return { ...state, LoadingCardSusepInsurance: action.payload.trigger };
    case 'SET_LOADING_DETAIL_PROCESS':
      return { ...state, LoadingDetailProcess: action.payload.trigger };
    case 'SET_LOADING_MODAL_LIMITS':
      return { ...state, LoadingModalLimits: action.payload.trigger };
    case 'SET_LOADING_ACCOMPANIMENT_PROCESS':
      return { ...state, LoadingAccompanimentProcess: action.payload.trigger };
    case 'SET_LOADING_CARD_PROPOSAL_POTTENCIAL':
      return { ...state, LoadingCardProposalPottencial: action.payload.trigger };
    default:
      return state;
  }
};

export default loading;
