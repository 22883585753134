import React, { useEffect, useState} from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useSelector, useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {useParams} from 'react-router-dom';
import {
    SectionTop,
    SectionSteps,
    SectionCards,
    DetailsDiv,
    Row,
    TextStatus,
    DivStatus,
    Title,
    Content,
    TitleDetails,
    InputText,
    TitleStep,
    CardStep,
    DateTimeText,
    ButtonCard,
    TextCard
} from './style';
import Navbar from '../../components/Navbar/index';
import {getPolicy} from '../../actions/policy';
import {Accordion, Grid, GridRow} from 'semantic-ui-react';
import CardDownloadPDF from '../../components/Card/CardDownloadPDF';
import {cnpjMask} from '../../services/mask'
import {setModalDevolutiveEndorsement, setModalEndorsementHistoric, setModalSolicitation} from '../../actions/modal';
import {BiDotsVerticalRounded} from 'react-icons/bi';
import {getEndorsement, sendInsurer} from '../../actions/endorsement';
import ModalSolicitation from '../../components/Modal/ModalSolicitationEndorsement';
import {decodeToken, getToken} from '../../actions/auth';
import ModalDevolutiveEndorsement from '../../components/Modal/ModalDevolutiveEndorsement';
import {BsCheck} from 'react-icons/bs';
import ModalEndorsementHistoric from '../../components/Modal/ModalEndorsementHistoric';

function Endosso() {
    const policy = useSelector((state) => state.policy.policy.policy);
    const documentos = useSelector((state) => state.policy.policy.documentos);
    const endorsement = useSelector((state) => state.endorsement.endorsement);
    const user = decodeToken(getToken())
    const dispatch = useDispatch();
    const {idEndorsement} = useParams();

    const [openProcess,
        setOpenProcess] = useState(false);
        const [action, setAction] = useState('')

    useEffect(() => {
        dispatch(getEndorsement(idEndorsement))
    }, [dispatch, idEndorsement]);

    useEffect(() => {
        if (endorsement.idApolice.id) 
            dispatch(getPolicy(endorsement.idApolice.id));
        }
    , [dispatch, endorsement])

    const handleCloseModalSolicitation = () => {
        dispatch(setModalSolicitation(false))
    }

    const handleOpenModalSolicitation = (action) => {
        dispatch(setModalSolicitation(true))
        setAction(action)
    }

    const handleCloseModalDevolutive = () => {
        dispatch(setModalDevolutiveEndorsement(false))
    }

    const handleOpenModalDevolutive = () => {
        dispatch(setModalDevolutiveEndorsement(true))
    }

    const handleOpenModalEndorsementHistoric = () => {
        dispatch(setModalEndorsementHistoric(true))
    }

    const handleCloseModalEndorsementHistoric = () => {
        dispatch(setModalEndorsementHistoric(false))
    }

    const eventSendInsurer = () => {
        const data = {
            idEndosso: idEndorsement
        }

        dispatch(sendInsurer(data))
    }

    return (
        <div>
            <Navbar pageSelected="documentos-emitidos"/>
            <Content>
                <SectionTop>
                    <Grid>
                        <GridRow>
                            <ArrowBackIosIcon onClick={() => dispatch(push('/documentos-emitidos'))}/>
                            <h1>Documentos</h1>
                        </GridRow>
                    </Grid>
                </SectionTop>
                <DivStatus vencida={new Date(policy.finalVigencia) <= new Date()}>
                    <TextStatus
                        weight="400"
                        size="12px"
                        style={{
                        color: '#424242'
                    }}>Status da apólice</TextStatus>
                    <TextStatus>{new Date(policy.finalVigencia) >= new Date()
                            ? 'Vigente'
                            : 'Vencida'}</TextStatus>
                </DivStatus>
                <Title>Endosso
                    <h5 onClick={() => handleOpenModalEndorsementHistoric()}>histórico</h5>
                </Title>
                <SectionSteps>
                    {endorsement.status === 'SOLICITAÇÃO ENVIADA'
                        ? <CardStep borderBottom="10px solid #2E5AAC">
                                <div>
                                    <TitleStep>Solicitação recebida</TitleStep>
                                    <DateTimeText>{new Date(endorsement.dataEndosso).toLocaleString()}</DateTimeText>
                                </div>
                                <div>
                                    <TextCard>{endorsement.idApolice.process.idEmpresa.razaoSocial}</TextCard>
                                    <TextCard>{endorsement.idApolice.process.idEmpresa.cnpj}</TextCard>
                                </div>
                                <ButtonCard onClick={() => handleOpenModalSolicitation('solicitacao')}>ver Solicitação</ButtonCard>
                            </CardStep>
                        : <CardStep borderBottom="10px solid #2E5AAC">
                            <TextCard>{endorsement.tipoEndosso}</TextCard>
                            <div>
                                <TitleStep>Solicitação recebida</TitleStep>
                                <DateTimeText>{new Date(endorsement.dataEndosso).toLocaleString()}</DateTimeText>
                            </div>
                            {endorsement.descricaoProblema ? <TitleStep color="#DA1414">Houve um problema com a solicitação</TitleStep> : ''}
                            <Grid>
                                <Grid.Row>
                                    <div>
                                        <TitleStep size="12px" color="#287D3C">Etapa concluída em</TitleStep>
                                        <DateTimeText>{new Date(endorsement.dataResposta).toLocaleString()}</DateTimeText>
                                    </div>
                                    <ButtonCard onClick={() => handleOpenModalSolicitation('solicitacao')}><BiDotsVerticalRounded size={25}/></ButtonCard>
                                </Grid.Row>
                            </Grid>
                        </CardStep>
                    }
                    {['ENVIADA PARA SEGURADORA', 'FINALIZADA'].includes(endorsement.status)
                        ? <CardStep borderBottom="10px solid #2E5AAC">
                                <TextCard>{endorsement.tipoEndosso}</TextCard>
                                <div>
                                    <TitleStep>Enviada para a seguradora</TitleStep>
                                    <DateTimeText>{new Date(endorsement.dataResposta).toLocaleString()}</DateTimeText>
                                </div>
                                <div>
                                    <TitleStep size="12px" color="#287D3C">Etapa concluída em</TitleStep>
                                    <DateTimeText>{new Date(endorsement.dataEnvioSeguradora).toLocaleString()}</DateTimeText>
                                </div>

                            </CardStep>
                        : <CardStep borderBottom={endorsement.status === 'SOLICITAÇÃO RECEBIDA' ? '10px solid #2E5AAC' : null}>
                            <TextCard>{endorsement.tipoEndosso}</TextCard>
                            <div>
                                <TitleStep>Enviada para a seguradora</TitleStep>
                            </div>
                            {endorsement.status === 'SOLICITAÇÃO RECEBIDA' && user.perfil === 'conset'
                                ? <ButtonCard onClick={() => eventSendInsurer()}>avançar etapa</ButtonCard>
                                : ''}
                        </CardStep>
}
                    {endorsement.status === 'FINALIZADA'
                        ? <CardStep borderBottom="10px solid #2E5AAC">
                                <TextCard>{endorsement.tipoEndosso}</TextCard>
                                <div>
                                    <TitleStep>Devolvida ao tomador</TitleStep>
                                    <DateTimeText>{new Date(endorsement.dataEnvioSeguradora).toLocaleString()}</DateTimeText>
                                </div>
                                <Grid>
                                    <Grid.Row>
                                        <div>
                                            <TitleStep size="12px" color="#287D3C">Etapa concluída em</TitleStep>
                                            <DateTimeText>{new Date(endorsement.dataFinalizacao).toLocaleString()}</DateTimeText>
                                        </div>
                                        <ButtonCard onClick={() => handleOpenModalSolicitation('devolutive')}><BiDotsVerticalRounded size={25}/></ButtonCard>
                                    </Grid.Row>
                                </Grid>

                            </CardStep>
                        : <CardStep borderBottom={endorsement.status === 'ENVIADA PARA A SEGURADORA' ? '10px solid #2E5AAC' : null}>
                            <TextCard>{endorsement.tipoEndosso}</TextCard>
                            <div>
                                <TitleStep>Devolvida ao tomador</TitleStep>
                            </div>
                            {endorsement.status === 'ENVIADA PARA SEGURADORA' && user.perfil === 'conset'
                                ? <ButtonCard onClick={() => handleOpenModalDevolutive()}>inserir devolutiva</ButtonCard>
                                : ''}
                        </CardStep>
}
                    {endorsement.status === 'FINALIZADA'
                        ? <CardStep borderBottom="10px solid #2ECC71">
                                <TextCard>{endorsement.tipoEndosso}</TextCard>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={11}>
                                            <TitleStep>Finalizada</TitleStep>
                                            <DateTimeText>{new Date(endorsement.dataFinalizacao).toLocaleString()}</DateTimeText>
                                        </Grid.Column>
                                        <Grid.Column width={5}>
                                            <BsCheck size={35} color="#287D3C"/>

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                            </CardStep>
                        : <CardStep borderBottom={endorsement.status === 'FINALIZADA' ? '10px solid #2ECC71' : null}>
                            <TextCard>{endorsement.tipoEndosso}</TextCard>
                            <div>
                                <TitleStep>Finalizada</TitleStep>
                            </div>
                        </CardStep>
}
                </SectionSteps>
                <Title>Apólice</Title>
                <SectionCards>
                    <CardDownloadPDF title="Apólice" pdf={documentos.policyBase64}/>
                    <CardDownloadPDF title="Boleto" pdf={(documentos.billetBase64 || [])[0]}/>
                </SectionCards>
                <DetailsDiv>
                    <Accordion>
                        <Accordion.Title>
                            <Title size="16px">Informações do processo
                                <h5 onClick={() => setOpenProcess(!openProcess)}>ver mais</h5>
                            </Title>
                        </Accordion.Title>
                        <Accordion.Content active={openProcess}>
                            <InputText
                                className="firstInput"
                                label="Tipo de recurso"
                                width="278px"
                                disabled
                                value={policy.tipoRecurso}/>
                            <TitleDetails>Tomador</TitleDetails>
                            <Row>
                                <InputText
                                    label="Nome do Tomador"
                                    width="430px"
                                    disabled
                                    value={policy.nomeTomador}/>
                                <InputText
                                    label="CNPJ"
                                    width="320px"
                                    disabled
                                    value={cnpjMask(policy.tomadorCNPJ || '')}/>
                            </Row>
                            <TitleDetails>Informações da obrigação do segurado</TitleDetails>
                            <Row>
                                <InputText
                                    label="CPF/CNPJ do segurado"
                                    width="320px"
                                    disabled
                                    value={cnpjMask(policy.process.cpfReclamante || '')}/>
                                <InputText
                                    label="Nome do Segurado"
                                    width="430px"
                                    disabled
                                    value={policy.nomeSegurado}/>
                            </Row>
                            <TitleDetails>Endereço</TitleDetails>
                            <Row>
                                <InputText
                                    label="CEP do Segurado"
                                    width="200px"
                                    disabled
                                    value={policy.process.cepReclamante}/>
                                <InputText
                                    label="Cidade do Segurado"
                                    width="320px"
                                    disabled
                                    value={policy.process.cidadeReclamante}/>
                                <InputText
                                    label="UF"
                                    width="90px"
                                    disabled
                                    value={policy.process.ufReclamante}/>
                                <InputText
                                    label="Bairro"
                                    width="320px"
                                    disabled
                                    value={policy.process.bairroReclamante}/>
                            </Row>
                            <Row>
                                <InputText
                                    label="Logradouro"
                                    width="320px"
                                    disabled
                                    value={policy.process.logradouroReclamante}/>
                                <InputText
                                    label="Número"
                                    width="90px"
                                    disabled
                                    value={policy.process.numeroReclamante}/>

                                <InputText
                                    label="Complemento"
                                    width="546px"
                                    disabled
                                    value={policy.process.complementoReclamante}/>
                            </Row>
                            <TitleDetails>Processo</TitleDetails>
                            <Row>
                                <InputText
                                    label="Número do Processo"
                                    width="278px"
                                    disabled
                                    value={policy.process.numeroProcesso}/>
                                <InputText
                                    label="Vara vinculada"
                                    width="392px"
                                    disabled
                                    value={policy.process.vara}/>
                            </Row>
                            <Row>
                                <InputText
                                    className="secondInput"
                                    label="Tribunal e região da justiça de trabalho"
                                    width="500px"
                                    disabled
                                    value={policy.process.tribunal}/>
                                <InputText
                                    label="Identificador do cliente"
                                    width="278px"
                                    disabled
                                    value={policy.identificadorCliente}/>
                            </Row>
                        </Accordion.Content>
                    </Accordion>
                </DetailsDiv>
            </Content>
            <ModalSolicitation onClose={handleCloseModalSolicitation} action={action}/>
            <ModalDevolutiveEndorsement onClose={handleCloseModalDevolutive}/>
            <ModalEndorsementHistoric onClose={handleCloseModalEndorsementHistoric}/>
        </div>
    );
}

export default Endosso;
