import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';

export const Card = styled.div`
  width: 378px;
  border: 1px solid #9E9E9E;
  border-radius: 8px;
  min-height: 472px;
  background: #FAFAFA;
`;

export const CardHeader = styled.div`
  background: #4F75BA;
  border-radius: 8px 8px 0 0;

  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    padding: 16px 0;
    margin: 0px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 72px 360px;
  grid-auto-rows: 1fr;
  padding-top: 32px;
`;

export const ContentStatus = styled.div`
  display: flex;
  width: 100%;
  padding-top: 154px;
  justify-content: center;
  align-content: center;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0,5px;
    color: #616161;
    width: 228px;
  }
`;

export const CircleNumber = styled.div`
  background: #00235C;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  align-self: center;
  justify-self: center;
  margin-bottom: 16px;
`;

export const StatusDiv = styled.div`
  width: 282px;
  height: 56px;
  border-radius: 8px;
  height: 56px;
  background: #00235C;
  display: grid;
  grid-template-columns: 72px 264px;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 16px;

  h2{
    grid-column: 2;
    grid-row: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    margin: 0;
    align-self: end;
  }

  p{
    grid-column: 2;
    grid-row: 2;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: #EEEEEE;
    margin: 0;
  }
`;

export const CircleStatus = styled(CheckIcon)`
  background: #FFFFFF;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: #00235C;
  grid-column: 1;
  grid-row: 1/3;
  margin: 16px 24px;
`;

export const CircleNumberDisabled = styled(CircleNumber)`
  background: #BDBDBD;
`;

export const StatusDivDisabled = styled(StatusDiv)`
  background: #BDBDBD;
`;

export const CircleStatusDisabled = styled(CircleStatus)`
  color: #FFFFFF;
`;
