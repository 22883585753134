/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import PropType from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { push } from "connected-react-router";
import {
  ModalBody,
  TitleContent,
  Content,
  ButtonIndividual,
  FormProcessNumber
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import ButtonDefault from "../../UI/ButtonDefault/index";
import {
  setModalNewIndividualProcess,
  setModalNewProcess
} from "../../../actions/modal";
import { setProcessNumber } from "../../../actions/process";

function ModalNewIndividualProcess(props) {
  const { onClose } = props;
  const open = useSelector(state => state.modal.ModalNewIndividualProcess);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");

  const handleBackToModalNewProcess = () => {
    dispatch(setModalNewIndividualProcess(false));
    dispatch(setModalNewProcess(true));
  };

  const handleChangeInputValue = event => {
    setInputValue(event.target.value);
  };

  const onSubmitNewProcess = event => {
    event.preventDefault();
    dispatch(setProcessNumber(inputValue));
    dispatch(push("/novo-processo-individual"));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <div>
            <ButtonIcon onClick={handleBackToModalNewProcess}>
              <ArrowBackIosIcon />
            </ButtonIcon>
            <h1>Novo Processo</h1>
          </div>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <div>
            <h1>Escolha sua opção</h1>
            <h2>Cadastre um novo processo de forma individual ou massiva</h2>
          </div>
          <div>
            <ButtonIndividual>INDIVIDIAL</ButtonIndividual>
          </div>
          <div>
            <h1>Processo</h1>
            <h2>Insira o número do processo.</h2>
          </div>
          <FormProcessNumber onSubmit={onSubmitNewProcess}>
            <label htmlFor="process-number">Número do Processo</label>
            <input
              type="search"
              id="process-number"
              name="process-number"
              onChange={handleChangeInputValue}
              value={inputValue}
            />
            <div>
              {/* <p>Esta busca está sujeita à taxa de R$ 5,00</p> */}
              <ButtonDefault color="primary" type="submit">
                Buscar
              </ButtonDefault>
            </div>
          </FormProcessNumber>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalNewIndividualProcess;

ModalNewIndividualProcess.propType = {
  onClose: PropType.func,
  inputValue: PropType.string,
  inputOnChange: PropType.func
};
