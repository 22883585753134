/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import {
  push,
} from 'connected-react-router';
import axios from './api';
import {
  setLoadingAccompanimentProcess
} from './loading';
import {
  setSnackbarErrorContent,
  setSnackbarErrorOpen
} from './snackbar';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

axios.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
};

export const clearProcessState = () => ({
  type: 'CLEAR_CACHE',
  payload: {},
});

export const setProcess = (process) => ({
  type: 'SET_PROCESS',
  payload: {
    process,
  },
});

export const createProcess = (newProcess) => async (dispatch) => {
  try {
    await axios.post('process', newProcess);
    dispatch(push('/acompanhamento-processual'));
  } catch (err) {
    dispatch(setSnackbarErrorOpen(true));
    dispatch(setSnackbarErrorContent({
      title: 'Erro no formulário',
      subtitle: 'Campo obrigatório não preenchido',
    }));
  }
};

export const setProcessNumber = (processNumber) => ({
  type: 'SET_PROCESS_NUMBER',
  payload: {
    processNumber,
  },
});

const setProcesses = (process) => ({
  type: 'SET_PROCESSES',
  payload: {
    process,
  },
});

export const getProcess = (query) => async (dispatch) => {
  try {
    const response = await axios.get('process', {
      params: query,
    });
    dispatch(setProcesses(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const setFilteredProcess = (process) => ({
  type: 'SET_FILTERED_PROCESS',
  payload: {
    process,
  },
});

const setProcessWithoutBipbop = (process) => ({
  type: 'SET_PROCESS_WITHOUT_BIPBOP',
  payload: {
    process,
  },
});

export const getProcessWithoutBipbop = (processId) => async (dispatch) => {
  try {
    const response = await axios.get(`process/list-free/${processId}`);
    dispatch(setProcessWithoutBipbop(response.data));
  } catch (err) {
    console.log(err);
  }
};

const setProcessWithBipbop = (process) => ({
  type: 'SET_PROCESS_WITH_BIPBOP',
  payload: {
    process,
  },
});

export const getProcessWithBipbop = (processId) => async (dispatch) => {
  try {
    const response = await axios.get(`process/list/${processId}`);
    dispatch(setProcessWithBipbop(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateProcess = (processId, dataToUpdate) => async (dispatch) => {
  try {
    await axios.patch(`process/update/${processId}`, dataToUpdate);
  } catch (err) {
    console.log(err);
  }
};

export const deleteProcess = (processId) => async () => {
  try {
    await axios.delete(`process/delete/${processId}`);
  } catch (err) {
    console.log(err);
  }
};

export function adjustingInstanceData(data, instance) {
  if (instance) {
    const andamentosCard = [];
    instance.ultimosAndamentos.forEach((andamento, index) => {
      if (andamento instanceof Object) {
        andamentosCard.push({
          id: index + 1,
          title: andamento.nome,
          date: andamento.data,
          description: andamento.descricao
        })
      }
    });

    return (andamentosCard);
  }
  return undefined;
}

function adjustingAllDetails(data) {
  let firstInstance;
  let secondInstance;
  let lastInstance;
  if (!data.firstInstance || !data.secondInstance || !data.lastInstance) {
    if (!data.firstInstance) {
      firstInstance = {
        ativoPartes: [],
        passivoPartes: [],
        tribunal: '',
      };
    } else {
      firstInstance = data.firstInstance;
    }

    if (!data.secondInstance) {
      secondInstance = {
        tribunal: '',
      };
    } else {
      secondInstance = data.secondInstance;
    }

    if (!data.lastInstance) {
      lastInstance = {
        tribunal: '',
      };
    } else {
      lastInstance = data.lastInstance;
    }
    return {
      firstInstance,
      secondInstance,
      lastInstance
    };
  }
  return data;
}

const setAccompanimentProcess = (accompanimentProcess) => ({
  type: 'SET_ACCOMPANIMENT_PROCESS',
  payload: {
    accompanimentProcess,
  },
});

export const getAccompanimentProcess = (processId) => async (dispatch) => {
  try {
    const response = await axios.get(`process/accompaniment/${processId}`);

    const firstInstance = adjustingInstanceData(response.data, response.data.firstInstance);
    const secondInstance = adjustingInstanceData(response.data, response.data.secondInstance);
    const lastInstance = adjustingInstanceData(response.data, response.data.lastInstance);
    const allDetails = adjustingAllDetails(response.data);
    const status = response.data.erro;

    const accompanimentProcess = {
      firstInstance,
      secondInstance,
      lastInstance,
      allDetails,
      status,
    };

    if (accompanimentProcess.status) {
      dispatch(setSnackbarErrorOpen(true));
      dispatch(setSnackbarErrorContent({
        title: 'Acompanhamento Bipbop',
        subtitle: accompanimentProcess.status,
      }));
    }

    dispatch(setAccompanimentProcess(accompanimentProcess));
    dispatch(setLoadingAccompanimentProcess(false));
  } catch (err) {
    console.log(err);
  }
};

const setAccompanimentProcessWithoutStatus = (accompanimentProcess) => ({
  type: 'SET_ACCOMPANIMENT_PROCESS_WITHOUT_STATUS',
  payload: {
    accompanimentProcess,
  },
});

export const getAccompanimentProcessWithoutStatus = (processId) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}process/list-free/${processId}`);
    const firstInstance = {
      numeroProcesso: response.data.numeroProcesso,
      tribunal: response.data.tribunal,
      vara: response.data.vara,
      ativoPartes: [{
        nome: response.data.nomeReclamante,
        documento: response.data.cpfReclamante,
      }],
      passivoPartes: [],
      valorCausa: '',
    };

    const accompanimentProcess = {
      firstInstance
    };
    dispatch(setAccompanimentProcessWithoutStatus(accompanimentProcess));
  } catch (err) {
    console.log(err);
  }
};

export const getExportProcess = async (query) => {
  axios({
      url: 'process/export',
      method: 'GET',
      responseType: 'blob',
      params: query,
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'processos.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => console.log(err));
};

const setMassiveProcesses = (massiveProcesses) => ({
  type: 'SET_MASSIVE_PROCESS',
  payload: {
    massiveProcesses,
  },
});

export const upoladProcess = (file) => async (dispatch) => {
  const formData = new FormData();

  formData.append('file', file);
  axios({
    url: 'process/upload',
    method: 'POST',
    data: formData,
  }).then((response) => {
    console.log(response.data.values);
    dispatch(setMassiveProcesses(response.data.values));
    dispatch(push('/novo-processo-massivo'));
  }).catch((err) => console.log(err));
};

export const postMultipleProcess = (processes) => async (dispatch) => {
  try {
    await axios.post('process/create-multiple', {
      process: processes,
    });
    dispatch(push('/acompanhamento-processual'));
  } catch (err) {
    console.log(err);
  }
};
