/* eslint-disable react/forbid-prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { StyledInputBase, IconSearch, Label } from './style';

function InputSearch(props) {
  const {
    placeholder, label, width, value, onChange,
  } = props;

  return (
    <>
      <Label>{label}</Label>
      <StyledInputBase type="text" onChange={onChange} value={value} placeholder={placeholder} width={width} endAdornment={<IconSearch color="secondary" />} />
    </>
  );
}

export default memo(InputSearch);

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.any,
};

InputSearch.defaultProps = {
  placeholder: '',
  label: '',
  width: null,
  value: null,
  onChange: null,
};
