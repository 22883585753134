import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  StyledIconEmail, StyledFormControl, StyledLabel, StyledInput,
} from './style';

function InputEmail({ label, onChange, value }) {
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <div>
      <StyledIconEmail />
      <StyledFormControl>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          onChange={onChange}
          value={value}
          required
          type="email"
        />
      </StyledFormControl>
    </div>
  );
}

export default InputEmail;

InputEmail.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

InputEmail.defaultProps = {
  label: 'Seu e-mail',
  onChange: () => {},
  value: '',
};
