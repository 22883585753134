import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import {
  ModalBody,
  TitleContent,
  Content,
  Item,
  CloseButton,
} from './style';
import { setModalListTakers } from '../../../actions/modal';
import PropTypes from 'prop-types';

function ModalListTakers(props) {
  const open = useSelector((state) => state.modal.ModalListTakers);
  const dispatch = useDispatch();
  const { takers, showButtonDelete } = props;

  function handleClose() {
    dispatch(setModalListTakers(false));
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalBody>
        <TitleContent>
          <h1>Tomadores</h1>
        </TitleContent>
        <Content>
          {takers.map((tomador) => (
            <Item maxwidth={showButtonDelete ? '320px' : '222px'}>
              <p>{tomador.nome}</p>
              { showButtonDelete === true && (
                <CloseButton display={showButtonDelete === true && 'none'}>X</CloseButton>
              )}
            </Item>
          ))}
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalListTakers;

ModalListTakers.propTypes = {
  takers: PropTypes.array,
  showButtonDelete: PropTypes.bool,
};

ModalListTakers.defaultProps = {
  takers: [],
  showButtonDelete: false,
};
