export const initialState = {
  processes: [],
  qtdProcesses: 0,
  filteredProcesses: [],
  processWithoutBipbop: {},
  processWithBipbop: {},
  accompanimentProcess: {
    firstInstance: [],
    secondInstance: [],
    lastInstance: [],
    allDetails: {
      firstInstance: {
        ativoPartes: [],
        passivoPartes: [],
        tribunal: '',
      },
      secondInstance: {
        tribunal: '',
      },
      lastInstance: {
        tribunal: '',
      },
    },
    status: '',
  },
  process: {
    andamentos: {
      firstInstance: {},
    },
  },
  processNumber: '',
  massiveProcesses: [],
  accompanimentProcessWithoutStatus: {},
};

const process = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROCESSES':
      return { ...state, processes: action.payload.process.processes, qtdProcesses: action.payload.process.total };
    case 'SET_FILTERED_PROCESS':
      return { ...state, filteredProcesses: action.payload.process };
    case 'SET_PROCESS_WITHOUT_BIPBOP':
      return { ...state, processWithoutBipbop: action.payload.process };
    case 'SET_PROCESS_WITH_BIPBOP':
      return { ...state, processWithBipbop: action.payload.process.process };
    case 'SET_ACCOMPANIMENT_PROCESS':
      return { ...state, accompanimentProcess: action.payload.accompanimentProcess };
    case 'SET_PROCESS':
      return { ...state, process: action.payload.process };
    case 'SET_PROCESS_NUMBER':
      return { ...state, processNumber: action.payload.processNumber };
    case 'SET_MASSIVE_PROCESS':
      return { ...state, massiveProcesses: action.payload.massiveProcesses };
    case 'SET_ACCOMPANIMENT_PROCESS_WITHOUT_STATUS':
      return { ...state, accompanimentProcessWithoutStatus: action.payload.accompanimentProcess };
    case 'CLEAR_CACHE':
      return initialState;
    default:
      return state;
  }
};

export default process;
