export const initialState = {
  values: {
    pesquisaEmpresa:{},
    numeroProcessos:{},
    documento:{},
    acrescimoDocumento:{},
    acompanhamento:{},
    acrescimoAcompanhamento:{}
  },
  dashboard: {
    reports:[]
  },
  dashboardMes: {},
};

const bipbopReport = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VALUES":
      return { ...state, values: action.payload.values };
    case "SET_DASHBOARD":
      return { ...state, dashboard: action.payload.dashboard };
    case "SET_DASHBOARD_MES":
      return { ...state, dashboardMes: action.payload.dashboardMes };
    default:
      return state;
  }
};

export default bipbopReport;
