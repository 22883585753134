/* eslint-disable react/jsx-fragments */
import React, { Fragment, memo } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector, useDispatch } from 'react-redux';
import CakeIcon from '@material-ui/icons/Cake';
import TodayIcon from '@material-ui/icons/Today';
import { push } from 'connected-react-router';
import {
  Table,
  TableScroll,
  CellHeader,
  CellStatus,
  Cell,
  TableFixed,
  CellHeaderDetails,
  CellDetails,
  DivIcons,
  DivIconDate,
  DivIconBirthday,
} from './style';
import ButtonDefault from '../../UI/ButtonDefault';
import { getPolicy } from '../../../actions/policy';
import { decodeToken, getToken } from '../../../actions/auth';

function TableListPolicy() {
  const dispatch = useDispatch();
  const allPolicies = useSelector((state) => state.policy.allPolicies);
  const user = decodeToken(getToken());

  const openEndorsement = async (id) => {
    const policy = await dispatch(getPolicy(id, { documentos: false }));
    if (policy.policy.endorsement.length > 0) {
      dispatch(
        push(
          `/documentos-emitidos/endosso/${
            policy.policy.endorsement[policy.policy.endorsement.length - 1].id
          }`,
        ),
      );
    }
  };

  const openPolicy = async (id) => {
    await dispatch(getPolicy(id, { documentos: false }));
    dispatch(push(`/documentos-emitidos/detalhes/${id}`));
  };

  function getColorIcon(type, date) {
    const today = new Date();
    let futureDate = date;

    if (type === 'birthday') {
      const day = date.substr(0, 2);
      const month = date.substr(3, 5) - 1;
      const year = new Date().getFullYear();
      futureDate = new Date(year, month, day);
      if (
        futureDate.getMonth() < today.getMonth()
        || futureDate.getDate() < today.getDate()
      ) {
        // entra aqui caso o aniversário já tenha passado esse ano.
        futureDate = new Date(year + 1, month, day);
      }
    }

    const timeDiff = Math.abs(
      new Date(futureDate).getTime() - new Date(today).getTime(),
    );
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays >= 120) {
      // Azul
      return '#2E70CC';
    }

    if (diffDays <= 119 && diffDays >= 90) {
      // Verde
      return '#2ECC71';
    }

    if (diffDays <= 89 && diffDays >= 65) {
      // Amarelo
      return '#F1C40F';
    }

    if (diffDays <= 64 && diffDays >= 60) {
      // Laranja
      return '#FF8B00';
    }

    if (diffDays <= 60) {
      // Vermelho
      return '#E74C3C';
    }

    return '#FFFFFF';
  }

  return (
    <Table>
      <ScrollContainer>
        <TableScroll>
          <CellHeader paddingLeft="36px">Status</CellHeader>
          <CellHeader>Nome do Tomador</CellHeader>
          <CellHeader>Tomador CNPJ</CellHeader>
          <CellHeader>Acompanhamento processual</CellHeader>
          <CellHeader>Judicial Deposito Recursal</CellHeader>
          <CellHeader>Tipo de Recurso</CellHeader>
          <CellHeader>Porcentagem de Agravo</CellHeader>
          <CellHeader>Importância Segurada</CellHeader>
          <CellHeader>Prazo</CellHeader>
          <CellHeader>Nome do Segurado</CellHeader>
          <CellHeader>Tipo Pessoa Segurado</CellHeader>
          <CellHeader>Seguradora</CellHeader>
          <CellHeader>Início de Vigência</CellHeader>
          <CellHeader>Final de Vigência</CellHeader>
          <CellHeader>Data de Emissão</CellHeader>
          <CellHeader>Aniversário</CellHeader>
          <CellHeader>Prêmio</CellHeader>
          <CellHeader>Vencimento Primeira Parcela</CellHeader>
          <CellHeader>Forma de Pagamento</CellHeader>
          <CellHeader>Número de Cotação</CellHeader>
          <CellHeader>Endosso</CellHeader>
          <CellHeader>Número da Proposta</CellHeader>
          <CellHeader>Número da Apólice</CellHeader>
          <CellHeader>Apólice Susep</CellHeader>
          {allPolicies.map((policy) => (
            <Fragment key={policy.id}>
              <CellStatus>
                <DivIcons>
                  <DivIconBirthday
                    color={getColorIcon('birthday', policy.aniversario)}
                  >
                    <CakeIcon style={{ height: '16px' }} />
                  </DivIconBirthday>
                  <DivIconDate
                    color={getColorIcon('validity', policy.finalVigencia)}
                  >
                    <TodayIcon style={{ height: '16px' }} />
                  </DivIconDate>
                </DivIcons>
                <h1>{!policy.status ? '-' : policy.status}</h1>
              </CellStatus>
              <Cell>{!policy.nomeTomador ? '-' : policy.nomeTomador}</Cell>
              <Cell>{!policy.tomadorCNPJ ? '-' : policy.tomadorCNPJ}</Cell>
              <Cell>{policy.process.acompanhamentoBipbop ? 'Sim' : 'Não'}</Cell>
              <Cell>
                {!policy.judicialDepositoRecursal
                  ? '-'
                  : policy.judicialDepositoRecursal}
              </Cell>
              <Cell>{!policy.tipoRecurso ? '-' : policy.tipoRecurso}</Cell>
              <Cell>
                {!policy.porcentagemAgravo ? '-' : policy.porcentagemAgravo}
              </Cell>
              <Cell>
                {!policy.importanciaSegurada ? '-' : policy.importanciaSegurada}
              </Cell>
              <Cell>{!policy.prazo ? '-' : policy.prazo}</Cell>
              <Cell>{!policy.nomeSegurado ? '-' : policy.nomeSegurado}</Cell>
              <Cell>
                {!policy.tipoPessoaSegurado ? '-' : policy.tipoPessoaSegurado}
              </Cell>
              <Cell>{!policy.seguradora ? '-' : policy.seguradora}</Cell>
              <Cell>
                {!policy.inicioVigencia ? '-' : policy.inicioVigencia}
              </Cell>
              <Cell>{!policy.finalVigencia ? '-' : policy.finalVigencia}</Cell>
              <Cell>{!policy.dataEmissao ? '-' : policy.dataEmissao}</Cell>
              <Cell>{!policy.aniversario ? '-' : policy.aniversario}</Cell>
              <Cell>{!policy.premio ? '-' : policy.premio}</Cell>
              <Cell>
                {!policy.vencimentoPrimeiraParcela
                  ? '-'
                  : policy.vencimentoPrimeiraParcela}
              </Cell>
              <Cell>
                {!policy.formaPagamento ? '-' : policy.formaPagamento}
              </Cell>
              <Cell>{!policy.nroCotacao ? '-' : policy.nroCotacao}</Cell>
              <Cell>{!policy.endosso ? '-' : policy.endosso}</Cell>
              <Cell>{!policy.nroProposta ? '-' : policy.nroProposta}</Cell>
              <Cell>{!policy.nroApolice ? '-' : policy.nroApolice}</Cell>
              <Cell>
                {!policy.cdApoliceSusep ? '-' : policy.cdApoliceSusep}
              </Cell>
            </Fragment>
          ))}
        </TableScroll>
      </ScrollContainer>
      <TableFixed>
        <CellHeaderDetails>Detalhes</CellHeaderDetails>
        {allPolicies.map((policy) => (
          <CellDetails key={policy.id}>
            <ButtonDefault
              color="primary"
              customColor="#3498DB"
              colorLabel="#FFFFFF"
              size="small"
              fontSize="12px"
              fullWidth="100px"
              onClick={() => openPolicy(policy.id)}
            >
              Ver mais
            </ButtonDefault>
            {user.perfil === 'empresa' && policy.endorsement.length > 0 ? (
              <ButtonDefault
                color="primary"
                customColor="#3498DB"
                colorLabel="#FFFFFF"
                size="small"
                fontSize="12px"
                fullWidth="100px"
                onClick={() => openEndorsement(policy.id)}
              >
                Endosso
              </ButtonDefault>
            ) : (
              ''
            )}
          </CellDetails>
        ))}
      </TableFixed>
    </Table>
  );
}

export default memo(TableListPolicy);
