/* eslint-disable no-console */
import io from 'socket.io-client';
import store from '../store/index';
import {
  setProposalJunto,
  setProposalJuntoError,
  setProposalTokyo,
  setProposalTokyoError,
  setPolicyEmited,
  setProposalBMG,
  setProposalBMGError,
  setStatusEmitPolicy,
  setProposalPottencial,
  setProposalPottencialError,
} from './policy';
import {
  setLoadingCardProposalJunto,
  setLoadingCardProposalTokio,
  setLoadingCardProposalBMG,
  setLoadingCardProposalPottencial,
  setLoadingCardDocument,
  setLoadingSearchProcess,
} from './loading';
import {
  setSnackbarOpen,
  setSnackbarContent,
  setSnackbarResult,
  setSnackbarRoute,
  setSnackbarContentSaneamento,
  setSnackbarResultSaneamento,
  setSnackbarRouteSaneamento,
  setSnackbarOpenSaneamento,
} from './snackbar';
import {
  listLead,
  listLeadProcesses,
} from './pipeline';

import {
  decodeToken,
  getToken,
} from './auth';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

let socketIO = null;

export const setClientID = (clientID) => ({
  type: 'SET_CLIENT_ID',
  payload: {
    clientID,
  },
});

export const getClientID = () => (dispatch) => {
  socketIO.on('CLIENT_SETUP', (data) => {
    console.log('my id', data);
    dispatch(setClientID(data));
  });
};

export const getProposalReady = () => (dispatch) => {
  socketIO.on('PROPOSAL_READY', (data) => {
    const seguradora = data.seguradora.toUpperCase();
    console.log(seguradora, data);
    switch (seguradora) {
      case 'JUNTO':
        dispatch(setProposalJunto(data));
        dispatch(setLoadingCardProposalJunto(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Proposta recebida',
            subtitle: 'Junto enviou uma proposta',
          }),
        );
        dispatch(setSnackbarResult(true));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      case 'TOKYO':
        dispatch(setProposalTokyo(data));
        dispatch(setLoadingCardProposalTokio(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Proposta recebida',
            subtitle: 'Tokio Marine enviou uma proposta',
          }),
        );
        dispatch(setSnackbarResult(true));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      case 'BMG':
        dispatch(setProposalBMG(data));
        dispatch(setLoadingCardProposalBMG(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Proposta recebida',
            subtitle: 'BMG enviou uma proposta',
          }),
        );
        dispatch(setSnackbarResult(true));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      case 'POTTENCIAL':
        dispatch(setProposalPottencial(data));
        dispatch(setLoadingCardProposalPottencial(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Proposta recebida',
            subtitle: 'Pottencial enviou uma proposta',
          }),
        );
        dispatch(setSnackbarResult(true));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      default:
        break;
    }
  });
};

export const getProposalFailed = () => (dispatch) => {
  socketIO.on('PROPOSAL_FAILED', (data) => {
    console.log('PROPOSAL_FAILED', data);

    const seguradora = data.seguradora.toUpperCase();
    switch (seguradora) {
      case 'TOKYO':
        dispatch(
          setProposalTokyoError({
            error: true,
            errorTitle: 'Falha na proposta Tokio',
            errorText: data.erro,
          }),
        );
        dispatch(setLoadingCardProposalTokio(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Falha na proposta Tokio',
            subtitle: data.erro,
          }),
        );
        dispatch(setSnackbarResult(false));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      case 'JUNTO':
        dispatch(
          setProposalJuntoError({
            error: true,
            errorTitle: 'Falha na proposta Junto',
            errorText: data.erro,
          }),
        );
        dispatch(setLoadingCardProposalJunto(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Falha na proposta Junto',
            subtitle: data.erro,
          }),
        );
        dispatch(setSnackbarResult(false));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      case 'BMG':
        dispatch(
          setProposalBMGError({
            error: true,
            errorTitle: 'Falha na proposta BMG',
            errorText: JSON.stringify(data.erro),
          }),
        );
        dispatch(setLoadingCardProposalBMG(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Falha na proposta BMG',
            subtitle: JSON.stringify(data.erro),
          }),
        );
        dispatch(setSnackbarResult(false));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      case 'POTTENCIAL':
        dispatch(
          setProposalPottencialError({
            error: true,
            errorTitle: 'Falha na proposta Pottencial',
            errorText: data.erro,
          }),
        );
        dispatch(setLoadingCardProposalPottencial(false));
        dispatch(setSnackbarOpen(false));
        dispatch(
          setSnackbarContent({
            title: 'Falha na proposta Pottencial',
            subtitle: data.erro,
          }),
        );
        dispatch(setSnackbarResult(false));
        dispatch(setSnackbarRoute('/propostas'));
        dispatch(setSnackbarOpen(true));
        break;
      default:
        break;
    }
  });
};

export const getPolicyReady = () => (dispatch) => {
  socketIO.on('POLICY_READY', (data) => {
    console.log('POLICY_READY', data);
    dispatch(setPolicyEmited(data));
    dispatch(setLoadingCardDocument(false, 'APOLICE'));
    dispatch(setLoadingCardDocument(false, 'BOLETO'));
    dispatch(setLoadingCardDocument(false, 'INSURANCE'));
    dispatch(setLoadingCardDocument(false, 'CERTIFICADO'));
    dispatch(
      setSnackbarContent({
        title: 'Documentos emitidos',
        subtitle: 'Os documentos foram emitidos com sucesso!',
      }),
    );
    dispatch(setSnackbarResult(true));
    dispatch(setSnackbarOpen(true));
    dispatch(setSnackbarRoute('/emitir-apolice'));
    dispatch(setStatusEmitPolicy(true));
  });
};

export const getPolicyFailed = () => (dispatch) => {
  socketIO.on('POLICY_FAILED', (data) => {
    console.log('POLICY_FAILED', data);
    dispatch(setLoadingCardDocument(false, 'APOLICE'));
    dispatch(setLoadingCardDocument(false, 'BOLETO'));
    dispatch(setLoadingCardDocument(false, 'INSURANCE'));
    dispatch(setLoadingCardDocument(false, 'CERTIFICADO'));
    dispatch(setSnackbarOpen(false));
    dispatch(
      setSnackbarContent({
        title: 'Falha na Emissão',
        subtitle: data.erro,
      }),
    );
    dispatch(setSnackbarResult(false));
    dispatch(setSnackbarRoute('/emitir-apolice'));
    dispatch(setSnackbarOpen(true));
    dispatch(setStatusEmitPolicy(false));
  });
};

export const getSearchProcessReady = () => (dispatch) => {
  socketIO.on('SEARCH_PROCESS_READY', (data) => {
    console.log('SEARCH_PROCESS_READY', data);
    dispatch(
      setSnackbarContentSaneamento({
        title: 'Concluído',
        subtitle: 'O saneamento foi concluído',
      }),
    );
    dispatch(setSnackbarResultSaneamento(true));
    dispatch(
      setSnackbarRouteSaneamento(`/pipeline/lead/detalhes/${data.leadId}`),
    );
    dispatch(setSnackbarOpenSaneamento(true));
    dispatch(listLead(data.leadId));
    dispatch(listLeadProcesses({
      leadId: data.leadId,
      limit: 50,
    }));
    dispatch(setLoadingSearchProcess(false));
  });
};

export const getSearchProcessFailed = () => (dispatch) => {
  socketIO.on('SEARCH_PROCESS_FAILED', (data) => {
    console.log('SEARCH_PROCESS_FAILED', data);
    dispatch(
      setSnackbarContentSaneamento({
        title: 'Falha',
        subtitle: 'Não foi possível consultar os processos!',
      }),
    );
    dispatch(setSnackbarResultSaneamento(false));
    dispatch(setSnackbarOpenSaneamento(true));
  });
};

export const getDetailProcessReady = () => (dispatch) => {
  socketIO.on('DETAIL_PROCESS_READY', (data) => {
    console.log('DETAIL_PROCESS', data);
    dispatch(
      setSnackbarContentSaneamento({
        title: 'Concluído',
        subtitle: 'A busca de processos foi concluída!',
      }),
    );
    dispatch(setSnackbarResultSaneamento(true));
    dispatch(
      setSnackbarRouteSaneamento(`/pipeline/lead/detalhes/${data.result.id}`),
    );
    dispatch(setSnackbarOpenSaneamento(true));
    dispatch(listLead(data.result.id));
    dispatch(listLeadProcesses({
      leadId: data.result.id,
      limit: 50,
    }));
    dispatch(setLoadingSearchProcess(false));
  });
};

export const getDetailProcessFailed = () => (dispatch) => {
  socketIO.on('DETAIL_PROCESS_FAILED', (data) => {
    console.log('DETAIL_PROCESS_FAILED', data);
    dispatch(
      setSnackbarContentSaneamento({
        title: 'Falha',
        subtitle: 'Não foi possível consultar os processos!',
      }),
    );
    dispatch(setSnackbarResultSaneamento(false));
    dispatch(setSnackbarOpenSaneamento(true));
  });
};

export const startSocket = () => {
  const token = getToken();
  const loginInfo = decodeToken(token);
  socketIO = io(baseURL, {
    transports: ['websocket', 'polling'],
    forceNew: true,
    timeout: 300000,
    query: {
      user: loginInfo.email,
    },
  });

  socketIO.on('connect', () => console.log('io connected!'));
  socketIO.on('connect', () => console.log('io disconnected!'));
  store.dispatch(getClientID());
  store.dispatch(getProposalReady());
  store.dispatch(getProposalFailed());
  store.dispatch(getPolicyReady());
  store.dispatch(getSearchProcessReady());
  store.dispatch(getSearchProcessFailed());
  store.dispatch(getPolicyFailed());
  store.dispatch(getDetailProcessFailed());
  store.dispatch(getDetailProcessReady());
};
