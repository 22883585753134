/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropType from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useSelector, useDispatch } from "react-redux";
import {
  ModalBody,
  TitleContent,
  Content,
  BtnFinish,
  Row,
  InputFile,
  ItemFile,
  BtnBorder
} from "./style";
import ButtonIcon from "../../UI/ButtonIcon/index";
import { decodeToken, getToken } from "../../../actions/auth";
import { FiUpload } from "react-icons/fi";
import { VscClose } from "react-icons/vsc";
import { Form, Grid } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { createSolicitation } from "../../../actions/endorsement";
import { getPolicy } from "../../../actions/policy";

function ModalNewEndorsement(props) {
  const { onClose } = props;
  const open = useSelector(state => state.modal.ModalNewEndorsement);
  const dispatch = useDispatch();
  const { idApolice } = useParams();
  const user = decodeToken(getToken());

  const [files, setFiles] = useState([]);
  const [descricaoSolicitacao, setDescricaoSolicitacao] = useState("");
  const [change, setChange] = useState(true);

  const selectFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.onchange = handleUploadFile;
    input.click();
  };

  const removeFile = file => {
    const newFiles = files;
    newFiles.splice(
      newFiles.findIndex(item => item === file),
      1
    );
    setFiles(newFiles);
    setChange(!change);
  };

  const handleUploadFile = e => {
    const file = e.target.files[0];
    const newFiles = files;
    newFiles.push(file);
    setFiles(newFiles);
    setChange(!change);
  };

  const sendForm = async () => {
    let formData = new FormData();
    formData.append("descricaoSolicitacao", descricaoSolicitacao);
    formData.append("idApolice", idApolice);
    formData.append("idCompanyUser", user.id);
    files.map(file => {
      return formData.append("upfile", file);
    });

    await dispatch(createSolicitation(formData));
    dispatch(getPolicy(idApolice, { documentos: false }));
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <TitleContent>
          <h1>Nova solicitação</h1>
          <ButtonIcon onClick={onClose}>
            <CloseIcon />
          </ButtonIcon>
        </TitleContent>
        <Content>
          <Form onSubmit={() => sendForm()}>
            <Form.TextArea
              label="Descriçao"
              rows={5}
              onChange={e => setDescricaoSolicitacao(e.target.value)}
            />
            <InputFile onClick={() => selectFile()}>
              <FiUpload
                color="#F9B233"
                size={20}
                style={{
                  marginRight: "10px"
                }}
              />
              ANEXAR ARQUIVO
            </InputFile>
            {files.map(file => (
              <ItemFile as={Grid}>
                <Grid.Row>
                  <Grid.Column width={8}>{file.name}</Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <VscClose
                      color="#F80A0A"
                      size={20}
                      onClick={() => removeFile(file)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </ItemFile>
            ))}
            <Row>
              <BtnBorder onClick={() => onClose()}>Cancelar</BtnBorder>
              <BtnFinish type="submit">Enviar</BtnFinish>
            </Row>
          </Form>
        </Content>
      </ModalBody>
    </Modal>
  );
}

export default ModalNewEndorsement;

ModalNewEndorsement.propType = {
  onClose: PropType.func
};
