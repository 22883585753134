/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from './api';

export const setDeadline = (deadline) => ({
  type: 'SET_DEADLINE',
  payload: {
    deadline,
  },
});

export const findDeadline = () => async (dispatch) => {
  try {
    const response = await axios.get(`deadline`);
    dispatch(setDeadline(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateDeadline = (newDeadline) => async (dispatch) => {
  try {
    const response = await axios.patch(`deadline`, newDeadline);
    dispatch(setDeadline(response.data));
  } catch (err) {
    console.log(err);
  }
};

