import styled from 'styled-components';

export const DivDialog = styled.div`
  width: 800px;
  height: 176px;
  padding: 40px;

  div{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  h1{
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #000000;
  }

  .buttonClose{
    display: inline-block;
  }

  p {
    width: 100%;
    display: block;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
`;
