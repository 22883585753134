/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from './api';

export const createGuarantee = (newGuarantee) => async (dispatch) => {
  try {
    const response = await axios.post(`guarantee`, newGuarantee);
    return response.data
  } catch (err) {
    console.log(err);
  }
};

export const setGuarantees = (guarantees) => ({
  type: 'SET_GUARANTEES',
  payload: {
    guarantees,
  },
});

export const findAllGuarantee = () => async (dispatch) => {
  try {
    const response = await axios.get(`guarantee/list`);
    dispatch(setGuarantees(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateGuarantee = (id, newGuarantee) => async (dispatch) => {
  try {
    await axios.patch(`guarantee/update/${id}`, newGuarantee);
    dispatch(findAllGuarantee())
  } catch (err) {
    console.log(err);
  }
};

export const deleteOneGuarantee = (id) => async (dispatch) => {
  try {
    await axios.delete(`guarantee/delete/`+id);
  } catch (err) {
    console.log(err);
  }
};

