import React, {useEffect, useState, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import ScrollContainer from 'react-indiana-drag-scroll';

import {FiChevronLeft} from 'react-icons/fi';
import Navbar from '../../../components/Navbar';
import {HeaderPage, Content, Filter} from './style';
import {
    Table,
    CellStatus,
    CellMais,
    TableScroll,
    Cell,
    CellHeader
} from '../style'

import {listLeads, editLead} from '../../../actions/pipeline';
import {push} from 'connected-react-router';
import {FaRegFileAlt} from 'react-icons/fa';
import Select from '../../../components/UI/Select';
import {TextField} from '@material-ui/core';

function ArchivedLead() {
    const dispatch = useDispatch();

    const leads = useSelector(state => state.pipeline.leads);

    const [buscarLead,
        setBuscarLead] = useState('')
    const [dataArquivamento,
        setDataArquivamento] = useState()
    const [status,
        setStatus] = useState()

    useEffect(() => {
        dispatch(listLeads({arquivado: true, nome: buscarLead, status, dataArquivamento}))
    }, [dispatch, buscarLead, status, dataArquivamento])

    const statusOptions = [
        {
            text: '-',
            value: null
        }, {
            text: 'Reprovado',
            value: 'DISAPPROVED'
        }, {
            text: 'Aprovado',
            value: 'APPROVED'
        }
    ]

    const reactivateLead = async(id) => {
        const newLead = {
            arquivado: false
        }

        await dispatch(editLead(id, newLead))
        dispatch(listLeads({arquivado: true}))
    }

    const formatDate = (iso = '') => {
        let date = (iso || '').split('-');
        return date[2]+'/'+date[1]+'/'+date[0];
    }

    return (
        <div>
            <Navbar pageSelected="pipeline"/>
            <Content>
                <HeaderPage>
                    <FiChevronLeft
                        size={30}
                        onClick={() => dispatch(push('/pipeline'))}
                        color="#185F9B"
                        style={{
                        marginRight: '30px'
                    }}/>
                    Arquivados
                </HeaderPage>
                <Filter>
                    <TextField
                        label="Buscar lead"
                        type="text"
                        onChange={e => setBuscarLead(e.target.value)}
                        InputLabelProps={{
                        shrink: true
                    }}
                        style={{
                        width: '400px'
                    }}/>
                    <TextField
                        label="Data de arquivamento"
                        type="date"
                        onChange={e => setDataArquivamento(e.target.value)}
                        InputLabelProps={{
                        shrink: true
                    }}/>
                    <Select
                        width="204px"
                        label="Status"
                        valueOption={statusOptions}
                        valueSelect={status}
                        onChange={e => setStatus(e.target.value)}/>
                </Filter>
                <Table>
                    <ScrollContainer>
                        <TableScroll repeat="7">
                            <CellHeader paddingLeft="36px" width="200px">Grupo econômico</CellHeader>
                            <CellHeader width="200px">Razão social</CellHeader>
                            <CellHeader width="200px">CNPJ</CellHeader>
                            <CellHeader width="100px">status</CellHeader>
                            <CellHeader>data de arquivamento</CellHeader>
                            <CellHeader >dados</CellHeader>
                            <CellHeader >mais</CellHeader>
                            {leads.map((lead) => (
                                <Fragment key={lead.id}>
                                    <CellStatus>
                                        {lead.grupo.nome}
                                    </CellStatus>
                                    <Cell>{lead.razaoSocial}</Cell>
                                    <Cell>{lead.cnpj}</Cell>
                                    <Cell>{lead.status === 'APPROVED'
                                            ? 'Aprovado'
                                            : 'Reprovado'}</Cell>
                                    <Cell>
                                        {formatDate(lead.dataArquivamento)}
                                    </Cell>
                                    <Cell onClick={() => dispatch(push(`/pipeline/lead/detalhes/${lead.id}`))}>
                                        <FaRegFileAlt size={22} color="#2E5AAC"/>
                                    </Cell>
                                    <CellMais onClick={() => reactivateLead(lead.id)}>
                                        REATIVAR
                                    </CellMais>
                                </Fragment>
                            ))}
                        </TableScroll>
                    </ScrollContainer>

                </Table>
            </Content>
        </div>
    )
}

export default ArchivedLead;