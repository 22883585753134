import React from 'react';
import PropTypes from 'prop-types';
import { Subtitle } from './style';

function SubtitleForm(props) {
  const { text, className } = props;

  return <Subtitle className={className}>{text}</Subtitle>;
}

export default SubtitleForm;

SubtitleForm.propTypes = {
  text: PropTypes.any,
  className: PropTypes.string,
};

SubtitleForm.defaultProps = {
  text: '',
  className: '',
};
