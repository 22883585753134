/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import axios from "./api";

export const setCompany = (company, insurers) => ({
  type: "SET_COMPANY",
  payload: {
    company
  }
});

export const setIdCompany = idCompany => ({
  type: "SET_ID_COMPANY",
  payload: {
    idCompany
  }
});

export const createCompany = newCompany => async dispatch => {
  try {
    const response = await axios.post(`company`, newCompany);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const setCompanies = companies => ({
  type: "SET_COMPANIES",
  payload: {
    companies
  }
});

export const findAllCompany = query => async dispatch => {
  try {
    const response = await axios.get(`/company`, { params: query });
    dispatch(setCompanies(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const findOneCompany = id => async dispatch => {
  try {
    const response = await axios.get(`company/list/${id}`);

    dispatch(setCompany(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const updateCompany = (id, newCompany) => async dispatch => {
  try {
    const response = await axios.patch(`company/update/${id}`, newCompany);
    dispatch(setCompany(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const deleteOneCompany = id => async dispatch => {
  try {
    await axios.delete(`company/delete/` + id);
  } catch (err) {
    console.log(err);
  }
};

export const calculateLimit = company => async dispatch => {
  try {
    const resp = await axios.post(`company/limit`, company);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteTomador = (idTomador, idEmpresa) => async (dispatch) => {
  try {
    await axios.delete(`company/taker/${idTomador}`);
    dispatch(findOneCompany(idEmpresa));
  } catch (err) {
    console.log(err);
  }
};

export const createNewTaker = (newTaker, idEmpresa) => async(dispatch) => {
  try {
    await axios.post('/company/taker', newTaker);
    dispatch(findOneCompany(idEmpresa));
  } catch (err) {
    console.log(err);
  }
};
