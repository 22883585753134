export const initialState = {
  insurer:{},
  insurers: []
};

const insurer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INSURER':
      return { ...state, insurer: action.payload.insurer };
    case 'SET_INSURERS':
        return { ...state, insurers: action.payload.insurers };
    default:
        return state;
  }
};

export default insurer;
