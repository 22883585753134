export const initialState = {
  groups: [],
  leads: [],
  lead: {},
  processes: [],
  processPage: 1,
  process: {},
  dashboard: {},
  actionDashboard: [],
  warrantyDashboard: []
};

const pipeline = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GROUPS":
      return {
        ...state, groups: action.payload.groups
      };
    case "SET_LEADS":
      return {
        ...state, leads: action.payload.leads
      };
    case "SET_LEAD":
      return {
        ...state, lead: action.payload.lead
      };
    case "SET_LEAD_PROCESSES":
      return {
        ...state, processes: action.payload.processes
      };
    case "SET_LEAD_PROCESS":
      return {
        ...state, process: action.payload.process
      };
    case "SET_DASHBOARD_LEAD":
      return {
        ...state, dashboard: action.payload.dashboard
      };
    case "SET_ACTION_DASHBOARD":
      return {
        ...state, actionDashboard: action.payload.actionDashboard
      };
    case "SET_WARRANTY_DASHBOARD":
      return {
        ...state, warrantyDashboard: action.payload.warrantyDashboard
      };
    case "SET_PROCESS_PAGE":
      return {
        ...state, processPage: action.payload.processPage
      }
      default:
        return state;
  }
};

export default pipeline;
