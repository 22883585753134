export const initialState = {
  guarantees: [],
};

const guarantee = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GUARANTEES':
      return { ...state, guarantees: action.payload.guarantees };   
    default:
        return state;
  }
};

export default guarantee;
