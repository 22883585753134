import React from 'react';
import PropTypes from 'prop-types';
import { StyledDiv } from './style';

function TitleForm(props) {
  const { title, width, className } = props;

  return (
    <StyledDiv width={width} className={className}>
      <h1 className="title">{title}</h1>
      <span className="line" />
    </StyledDiv>
  );
}

export default TitleForm;

TitleForm.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
};

TitleForm.defaultProps = {
  width: '100%',
  className: '',
};
