import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { push } from "connected-react-router";
import { parseToNumber, isCNPJ, formatToBRL } from "brazilian-values";
import { SectionTop, Form, DivForm, DivTop, Line } from "./style";
import InputText from "../../components/UI/InputText";
import Navbar from "../../components/Navbar/index";
import Select from "../../components/UI/Select";
import ButtonDefault from "../../components/UI/ButtonDefault";
import { listStates } from "../../assets/lists/uf";
import {
  telMask,
  cpfMask,
  cepMask,
  numberMask,
  cnpjMask,
  moneyMask
} from "../../services/mask";
import { getValidCEP, setValidCEP } from "../../actions/viacep";
import { createProcess } from "../../actions/process";
import { setModalNewIndividualProcess } from "../../actions/modal";
import { decodeToken, getToken } from "../../actions/auth";
import { getValidCNPJ, setValidCNPJ } from "../../actions/receitaws";
import { listValues } from "../../actions/bipbop-report";
import axios from "../../actions/api";

function NewIndividualProcess() {
  const tipoPessoaArray = [
    {
      value: "",
      text: "-"
    },
    {
      value: "cpf",
      text: "Pessoa Física"
    },
    {
      text: "Pessoa Jurídica",
      value: "cnpj"
    }
  ];

  const dispatch = useDispatch();
  const processNumber = useSelector(state => state.process.processNumber);
  const idEmpresa = useSelector(state => state.company.idCompany);
  const validCEP = useSelector(state => state.validCEP.validCEP);
  const user = decodeToken(getToken());
  const validCNPJ = useSelector(state => state.validCNPJ.validCNPJ);
  const bipbopValues = useSelector(state => state.bipbopReport.values);

  useEffect(() => {
    dispatch(listValues());
  }, []);

  useEffect(() => {
    dispatch(setModalNewIndividualProcess(false));
  }, [dispatch]);

  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [tribunal, setTribunal] = useState("");
  const [vara, setVara] = useState("");
  const [tipoPessoa, setTipoPessoa] = useState("");
  const [documento, setDocumento] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [CEP, setCEP] = useState("");
  const [cidade, setCidade] = useState("");
  const [UF, setUF] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [valorCausa, setValorCausa] = useState("");
  const [acompanhamento, setAcompanhamento] = useState(false);
  const [listOfVara, setListOfVara] = useState([]);

  const getCourts = async number => {
    try {
      setTribunal("");
      setListOfVara([]);
      const response = await axios.get(
        `/courts/?numeroProcesso=${numberMask(number)}`
      );
      setTribunal(response.data.nome);
      const newListOfVara = response.data.vara.map(varaOfList => ({
        text: varaOfList.nome,
        value: varaOfList.nome
      }));
      const initialSelectValue = { value: "", text: "-" };
      setListOfVara([initialSelectValue, ...newListOfVara]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setNumeroProcesso(processNumber);
    getCourts(processNumber);
  }, [processNumber]);

  function handleChangeTelefone(e) {
    const text = telMask(e.target.value);
    setTelefone(text);
  }

  function handleChangeTipoPessoa(e) {
    setTipoPessoa(e.target.value);
    setDocumento("");
  }

  function handleChangeCPF(e) {
    const text = cpfMask(e.target.value);
    setDocumento(text);
  }

  function handleChangeCNPJ(e) {
    const text = cnpjMask(e.target.value);
    setDocumento(text);
    if (isCNPJ(e.target.value)) {
      dispatch(getValidCNPJ(numberMask(e.target.value)));
    }
  }

  useEffect(() => {
    if (validCNPJ.nome && validCNPJ.cep) {
      setNome(validCNPJ.nome);
      setCEP(validCNPJ.cep);
      setTelefone(validCNPJ.telefone);
      dispatch(getValidCEP(numberMask(validCNPJ.cep)));
    }
  }, [dispatch, validCNPJ]);

  function handleChangeCEP(e) {
    const text = cepMask(e.target.value);
    setCEP(text);
    const cep = numberMask(text);
    if (cep.length === 8) {
      dispatch(getValidCEP(cep));
    }
  }

  useEffect(() => {
    setCidade(validCEP.localidade);
    setUF(validCEP.uf);
    setBairro(validCEP.bairro);
    setLogradouro(validCEP.logradouro);
    setComplemento(validCEP.complemento);
  }, [validCEP]);

  function handleChangeValorCausa(e) {
    const text = moneyMask(e.target.value);
    setValorCausa(text);
  }

  useEffect(() => {
    if (user.permissoes.includes("Administração") && !idEmpresa.id) {
      dispatch(push("/acompanhamento-processual"));
    }
  }, [dispatch, user, idEmpresa]);

  function onSubmitForm(e) {
    e.preventDefault();
    const newProcess = {
      cpfReclamante: numberMask(documento),
      nomeReclamante: nome,
      tipoPessoaReclamante: tipoPessoa,
      telefoneReclamante: numberMask(telefone),
      cepReclamante: numberMask(CEP),
      cidadeReclamante: cidade,
      ufReclamante: UF,
      bairroReclamante: bairro,
      logradouroReclamante: logradouro,
      numeroReclamante: numero,
      complementoReclamante: complemento,
      numeroProcesso,
      vara,
      tribunal,
      acompanhamentoBipbop: acompanhamento,
      valorCausa: parseToNumber(valorCausa),
      idEmpresa: idEmpresa.id || user.idEmpresa.id
    };
    dispatch(
      setValidCNPJ({
        nome: "",
        cep: ""
      })
    );
    dispatch(
      setValidCEP({
        localidade: "",
        uf: "",
        bairro: "",
        logradouro: "",
        complemento: ""
      })
    );
    dispatch(createProcess(newProcess));
  }

  function onCancel() {
    dispatch(push("/acompanhamento-processual"));
    dispatch(
      setValidCNPJ({
        nome: "",
        cep: ""
      })
    );
    dispatch(
      setValidCEP({
        localidade: "",
        uf: "",
        bairro: "",
        logradouro: "",
        complemento: ""
      })
    );
  }

  return (
    <>
      <Navbar pageSelected="acompanhamento-processual" />
      <SectionTop>
        <h1>Resultado da Busca</h1>
      </SectionTop>
      <Form onSubmit={onSubmitForm}>
        <DivTop>
          <h1>Informações do Processo</h1>
          <Line />
        </DivTop>
        <DivForm>
          <InputText
            label="Número do Processo"
            className="firstInput"
            value={numeroProcesso}
            onChange={e => setNumeroProcesso(e.target.value)}
            onBlur={() => getCourts(numeroProcesso)}
            isRequired
          />
          <InputText
            label="Tribunal e região da justiça o trabalho"
            width="546px"
            className="secondInput"
            value={tribunal}
            onChange={e => setTribunal(e.target.value)}
          />
          {listOfVara.length === 0 && (
            <InputText
              label="Vara"
              className="thirdInput"
              value={vara}
              onChange={e => setVara(e.target.value)}
            />
          )}
          {listOfVara.length !== 0 && (
            <Select
              label="Vara"
              className="thirdInput"
              valueOption={listOfVara}
              valueSelect={vara}
              name="vara"
              onChange={e => setVara(e.target.value)}
            />
          )}
          <h2 className="fourthInput">Autor</h2>
          <Select
            label="Tipo Pessoa"
            valueOption={tipoPessoaArray}
            onChange={handleChangeTipoPessoa}
            className="fifthInput"
          />
          {tipoPessoa === "cpf" && (
            <InputText
              label="CPF"
              className="sixthInput"
              value={documento}
              onChange={handleChangeCPF}
            />
          )}
          {tipoPessoa === "cnpj" && (
            <InputText
              label="CNPJ"
              className="sixthInput"
              value={documento}
              onChange={handleChangeCNPJ}
            />
          )}
          <InputText
            label="Nome"
            className="seventhInput"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
          <InputText
            label="Telefone"
            className="eighthInput"
            value={telefone}
            onChange={handleChangeTelefone}
          />
          <InputText
            label="CEP"
            className="ninthInput"
            value={CEP}
            onChange={handleChangeCEP}
          />
          <InputText
            label="Cidade"
            className="tenthInput"
            value={cidade}
            onChange={e => setCidade(e.target.value)}
          />
          <Select
            label="Estado"
            className="eleventhInput"
            valueOption={listStates}
            valueSelect={UF}
            onChange={e => setUF(e.target.value)}
          />
          <InputText
            label="Logradouro"
            className="twelfthInput"
            value={logradouro}
            onChange={e => setLogradouro(e.target.value)}
          />
          <InputText
            label="Bairro"
            className="thirteenthInput"
            value={bairro}
            onChange={e => setBairro(e.target.value)}
          />
          <InputText
            label="Número"
            className="fourteenthInput"
            value={numero}
            onChange={e => setNumero(e.target.value)}
          />
          <InputText
            label="Complemento"
            className="fifteenthInput"
            value={complemento}
            onChange={e => setComplemento(e.target.value)}
          />
          <h2 className="sixteenthInput">Valor</h2>
          <InputText
            label="Valor da causa"
            className="seventeenthInput"
            value={valorCausa}
            onChange={handleChangeValorCausa}
          />
          <div className="eighteenthInput">
            <h3>
              Você deseja ativar o acompanhamento do processo de forma
              automática?
            </h3>
            <p>
              Acompanhe os status da evolução do processo, do início ao fim, de
              maneira automática. Esssa funcionalidade está sujeita a taxas.
            </p>
            <h4>
              Valor:{" "}
              {formatToBRL(
                bipbopValues.acompanhamento.valor *
                  (bipbopValues.acrescimoAcompanhamento.valor / 100 + 1)
              )}
              /Mês
            </h4>
          </div>
          <div className="nineteenthInput">
            <FormControlLabel
              control={<Switch />}
              label="Acompanhamento automático"
              checked={acompanhamento}
              onChange={() => {
                setAcompanhamento(!acompanhamento);
              }}
            />
          </div>
          <ButtonDefault
            className="twentiethInput"
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onCancel}
          >
            Cancelar
          </ButtonDefault>
          <ButtonDefault
            className="twenty-first"
            color="secondary"
            customColor="#F9B233"
            type="submit"
          >
            finalizar
          </ButtonDefault>
        </DivForm>
      </Form>
    </>
  );
}

export default NewIndividualProcess;
