export const initialState = {
  open: false,
  content: {
    title: '',
    subtitle: '',
  },
  result: true,
  route: '',
  openError: false,
  errorContent: {
    title: '',
    subtitle: '',
  },
  openSaneamento: false,
  contentSaneamento: {
    title: '',
    subtitle: '',
  },
  resultSaneamento: true,
  routeSaneamento: '',
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SNACKBAR_OPEN':
      return { ...state, open: action.payload.trigger };
    case 'SET_SNACKBAR_CONTENT':
      return { ...state, content: action.payload.content };
    case 'SET_SNACKBAR_RESULT':
      return { ...state, result: action.payload.result };
    case 'SET_SNACKBAR_ROUTE':
      return { ...state, route: action.payload.route };
    case 'SET_SNACKBAR_ERROR_OPEN':
      return { ...state, openError: action.payload.trigger };
    case 'SET_SNACKBAR_ERROR_CONTENT':
      return { ...state, errorContent: action.payload.content };
    case 'SET_SNACKBAR_OPEN_SANEAMENTO':
      return { ...state, openSaneamento: action.payload.trigger };
    case 'SET_SNACKBAR_CONTENT_SANEAMENTO':
      return { ...state, contentSaneamento: action.payload.content };
    case 'SET_SNACKBAR_RESULT_SANEAMENTO':
      return { ...state, resultSaneamento: action.payload.result };
    case 'SET_SNACKBAR_ROUTE_SANEAMENTO':
      return { ...state, routeSaneamento: action.payload.route };
    default:
      return state;
  }
};

export default snackbar;
