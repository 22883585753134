export const setModalNewProcess = (trigger) => ({
  type: 'SET_MODAL_NEW_PROCESS',
  payload: {
    trigger,
  },
});

export const setModalNewIndividualProcess = (trigger) => ({
  type: 'SET_MODAL_NEW_INDIVIDUAL_PROCESS',
  payload: {
    trigger,
  },
});

export const setModalNewMassiveProcess = (trigger) => ({
  type: 'SET_MODAL_NEW_MASSIVE_PROCESS',
  payload: {
    trigger,
  },
});

export const setModalFilterProcess = (trigger) => ({
  type: 'SET_MODAL_FILTER_PROCESS',
  payload: {
    trigger,
  },
});

export const setModalEditCompany = (trigger) => ({
  type: 'SET_MODAL_EDIT_COMPANY',
  payload: {
    trigger,
  },
});

export const setModalEditMember = (trigger) => ({
  type: 'SET_MODAL_EDIT_MEMBER',
  payload: {
    trigger,
  },
});

export const setModalCreateMember = (trigger) => ({
  type: 'SET_MODAL_CREATE_MEMBER',
  payload: {
    trigger,
  },
});

export const setModalMinuta = (trigger) => ({
  type: 'SET_MODAL_MINUTA',
  payload: {
    trigger,
  },
});

export const setModalNewProcessError = (trigger) => ({
  type: 'SET_MODAL_NEW_PROCESS_ERROR',
  payload: {
    trigger,
  },
});

export const setModalNewLead = (trigger) => ({
  type: 'SET_MODAL_NEW_LEAD',
  payload: {
    trigger,
  },
});

export const setModalArchiveLead = (trigger) => ({
  type: 'SET_MODAL_ARCHIVE_LEAD',
  payload: {
    trigger,
  },
});

export const setModalFilterPolicy = (trigger) => ({
  type: 'SET_MODAL_FILTER_POLICY',
  payload: {
    trigger,
  },
});

export const setModalBudget = (trigger) => ({
  type: 'SET_MODAL_BUDGET',
  payload: {
    trigger,
  },
});

export const setModalFilterPipeline = (trigger) => ({
  type: 'SET_MODAL_FILTER_PIPELINE',
  payload: {
    trigger,
  },
});

export const setModalNewPolicyAdm = (trigger) => ({
  type: 'SET_MODAL_NEW_POLICY_ADM',
  payload: {
    trigger,
  },
});

export const setModalConsetUser = (trigger) => ({
  type: 'SET_MODAL_CONSET_USER',
  payload: {
    trigger,
  },
});

export const setModalNewEndorsement = (trigger) => ({
  type: 'SET_MODAL_NEW_ENDORSEMENT',
  payload: {
    trigger,
  },
});

export const setModalSolicitation = (trigger) => ({
  type: 'SET_MODAL_SOLICITATION',
  payload: {
    trigger,
  },
});

export const setModalEndorsementProblem = (trigger) => ({
  type: 'SET_MODAL_ENDORSEMENT_PROBLEM',
  payload: {
    trigger,
  },
});

export const setModalDevolutiveEndorsement = (trigger) => ({
  type: 'SET_MODAL_DEVOLUTIVE_ENDORSEMENT',
  payload: {
    trigger,
  },
});

export const setModalEndorsementHistoric = (trigger) => ({
  type: 'SET_MODAL_ENDORSEMENT_HISTORIC',
  payload: {
    trigger,
  },
});

export const setModalProcessSentence = (trigger) => ({
  type: 'SET_MODAL_PROCESS_SENTENCE',
  payload: {
    trigger,
  },
});

export const setModalProcessWarranty = (trigger) => ({
  type: 'SET_MODAL_PROCESS_WARRANTY',
  payload: {
    trigger,
  },
});

export const setModalActionWarranty = (trigger) => ({
  type: 'SET_MODAL_ACTION_WARRANTY',
  payload: {
    trigger,
  },
});

export const setModalCompromisePolicy = (trigger) => ({
  type: 'SET_MODAL_COMPROMISE_POLICY',
  payload: {
    trigger,
  },
});

export const setModalFilterDashboard = (trigger) => ({
  type: 'SET_MODAL_FILTER_DASHBOARD',
  payload: {
    trigger,
  },
});

export const setModalFilterEndorsement = (trigger) => ({
  type: 'SET_MODAL_FILTER_ENDORSEMENT',
  payload: {
    trigger,
  },
});

export const setModalEditPasswordCompany = (trigger) => ({
  type: 'SET_MODAL_EDIT_PASSWORD_COMPANY',
  payload: {
    trigger,
  },
});

export const setModalEditPasswordConset = (trigger) => ({
  type: 'SET_MODAL_EDIT_PASSWORD_CONSET',
  payload: {
    trigger,
  },
});

export const setModalPaymentHistoric = (trigger) => ({
  type: 'SET_MODAL_PAYMENT_HISTORIC',
  payload: {
    trigger,
  },
});

export const setModalEditCauseValue = (trigger) => ({
  type: 'SET_MODAL_EDIT_CAUSE_VALUE',
  payload: {
    trigger,
  },
});

export const setModalUpdateProcessesLead = (trigger) => ({
  type: 'SET_MODAL_UPDATE_PROCESSES_LEAD',
  payload: {
    trigger,
  },
});

export const setModalNewTomador = (trigger) => ({
  type: 'SET_MODAL_NEW_TOMADOR',
  payload: {
    trigger,
  },
});

export const setModalListTakers = (trigger) => ({
  type: 'SET_MODAL_LIST_TAKERS',
  payload: {
    trigger,
  },
});

export const setModalLimits = (trigger) => ({
  type: 'SET_MODAL_LIMITS',
  payload: {
    trigger,
  },
});
